import { FINISH_STEPPER, NEXT_STEP, START_STEPPER } from "../../actions/stepper/types";

const initialState = {
    completedSteps: [],
    currentStep: 0,
    finalStep: 0
}

export default function stepperReducer(state = initialState, action) {
    switch (action.type) {
        case START_STEPPER:
            return {
                ...state,
                currentStep: action.payload.currentStep,
                finalStep: action.payload.finalStep
            };
        case NEXT_STEP:
            return {
                ...state,
                currentStep: action.payload.currentStep,
                finalStep: action.payload.finalStep
            };
        case FINISH_STEPPER:
            return {
                ...state,
                currentStep: 0,
                finalStep: 0
            };
        default:
            return state;
    }
}
import { CircularProgress, Grid } from "@mui/material";
import { t } from "i18next";
import { Download } from "phosphor-react";
import { useState } from "react";
import { downloadCV } from "./../../../services/Helper";
import EnhancedIconButton from "./../../../views/pages/abdelrahman/EnhancedIconButton/EnhancedIconButton";
import EnhancedButton from "./../../../views/pages/yahya/components/enhanced-button/EnhancedButton";
import AddToCvBankDialog from "./AddToCvBankDialog";
import InviteDialog from "./InviteDialog";

const EnhancedInviteAndToCvBank = ({ applicant_id, cvBank }) => {
  const [openCvBank, setOpenCvBank] = useState();
  const [openInvite, setOpenInvite] = useState();
  const [loading, setLoading] = useState(false);

  const handleCLoseCvBank = () => {
    setOpenCvBank(false);
  };

  const handleOpenCvBank = () => {
    setOpenCvBank(true);
  };

  const handleOpenInvite = () => {
    setOpenInvite(true);
  };

  const handleDownloadCV = async () => {
    setLoading(true);
    const link = await downloadCV(applicant_id);
    setLoading(false);
    var element = document.createElement("a");
    element.setAttribute("href", link);
    element.setAttribute("target", "_blank");

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };
  return (
    <Grid
      container
      columnSpacing={1}
      alignItems={"center"}
      justifyContent={{ sm: "space-evenly", md: "flex-end" }}
      columns={24}
    >
      <Grid item xs={4} sm={4} md={"auto"} lg={"auto"} align={"right"}>
        <EnhancedIconButton
          onClick={handleDownloadCV}
          icon={
            loading ? (
              <CircularProgress size={20} sx={{ color: "#fff" }} />
            ) : (
              <Download color={"white"} />
            )
          }
          variant={"gray"}
        />
      </Grid>
      <Grid item xs={6} sm={6} md={"auto"} lg={"auto"}>
        <EnhancedButton
          variant={"outlined"}
          buttonText={t("invite")}
          padding={"15px 20px"}
          onClick={handleOpenInvite}
        />
      </Grid>
      <Grid item xs={14} sm={14} md={"auto"} lg={"auto"} xl={"auto"}>
        <EnhancedButton
          variant={"outlined"}
          buttonText={
            cvBank.isAdded ? t("edit-cv-bank") : t("add-to-cv")
          }
          padding={"15px 20px"}
          onClick={handleOpenCvBank}
        />
      </Grid>
      <AddToCvBankDialog
        open={openCvBank}
        setOpen={setOpenCvBank}
        applicant_id={applicant_id}
        cvBank={cvBank}
      />
      <InviteDialog
        open={openInvite}
        setOpen={setOpenInvite}
        applicant_id={applicant_id}
      />
    </Grid>
  );
};

export default EnhancedInviteAndToCvBank;

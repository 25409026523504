import { Grid, Typography, Stack, Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Envelope, MagnifyingGlass } from "phosphor-react";
import { useEffect, useState } from "react";
import "./FAQ.scss";
import {
  getStaticPages,
  translateField,
  contactSupport,
} from "../../../../../services/Helper";
import Fuse from "fuse.js";

import IconInput from "../../../../../components/IconInput";
import OneQuestion from "./one-question/OneQuestion";
import Show from "../../../../../components/Show";
import Hide from "../../../../../components/Hide";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const FAQ = () => {
  const { t, i18n } = useTranslation();
  const roles = useSelector((state) => state.profile?.data?.role);
  const userEmail = useSelector(
    (state) => state.profile?.data?.cv?.User?.work_email
  );
  const loginCompleted = useSelector((state) => state.profile?.loginCompleted);
  const [role, setRole] = useState();

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const [faqs, setFaqs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState(searchTerm);
  const [messageToSupport, setMessageToSupport] = useState("");
  const [emailToSupport, setEmailToSupport] = useState("");
  const [messageToSupportError, setMessageToSupportError] = useState(null);
  const [emailToSupportError, setEmailToSupportError] = useState(null);
  const [loading, setLoading] = useState(false);

  const validateEmail = (value) => {
    if (value === "" || value === null || value === undefined) {
      setEmailToSupportError({ message: t("required_input") });
    } else if (
      !emailToSupport.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
    ) {
      setEmailToSupportError({ message: t("email_format") });
    } else {
      setEmailToSupportError(null);
    }
  };

  const validateMessage = (value) => {
    if (value === "" || value === null || value === undefined) {
      setMessageToSupportError({ message: t("required_input") });
    } else if (value.length > 150) {
      setMessageToSupportError({
        message: t("max_input", { number: 150 }),
      });
    } else {
      setMessageToSupportError(null);
    }
  };

  const handleEmailChange = (e) => {
    setEmailToSupport(e.target.value);
    validateEmail(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessageToSupport(e.target.value);
    validateMessage(e.target.value);
  };

  const schema = yup
    .object({
      job_title: yup.string().required(t("required_input")),
    })
    .required();
  const {
    control,
    register,
    reset,
    formState: { errors },
    setError,
    handleSubmit,
    setValue,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const sendMessageToSupport = () => {
    validateEmail(emailToSupport);
    validateMessage(messageToSupport);

    if (loginCompleted) {
      if (messageToSupportError == null)
        contactSupport(messageToSupport, userEmail).then(() => {
          setMessageToSupport("");
          setMessageToSupportError(null);
        });
    } else {
      if (emailToSupportError == null && messageToSupportError == null) {
        contactSupport(messageToSupport, emailToSupport).then(() => {
          setMessageToSupport("");
          setMessageToSupportError(null);
          setEmailToSupport("");
          setEmailToSupportError(null);
        });
      }
    }
  };

  const onSubmit = () => {
    validateEmail(emailToSupport);
    validateMessage(messageToSupport);
    setLoading(true);

    if (loginCompleted) {
      if (messageToSupportError == null)
        contactSupport(messageToSupport, userEmail).then(() => {
          setMessageToSupport("");
          setMessageToSupportError(null);
        }).finally(() => {
          setLoading(false);
        });;
    } else {
      if (emailToSupportError == null && messageToSupportError == null) {
        contactSupport(messageToSupport, emailToSupport).then(() => {
          setMessageToSupport("");
          setMessageToSupportError(null);
          setEmailToSupport("");
          setEmailToSupportError(null);
        }).finally(() => {
          setLoading(false);
        });;
      }
    }
  };

  return (
    <>
      <Grid container direction="row" className="account-settings-container">
        <Grid item xs={12}>
          <Typography mb={5} className="settings-title">
            {t("need_help")}
          </Typography>
          <Stack direction={"column"} sx={{ width: "100%" }} spacing={5}>
            <Stack direction={"column"} spacing={1} className="faq-section">
              <Typography className="faq-subtitle" mb={2}>
                {t("send_a_message")}
              </Typography>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container direction={"column"}>
                  <Grid item>
                    <Hide condition={loginCompleted}>
                      <IconInput
                        id="job_title"
                        placeholder={t("email")}
                        className="input-message"
                        type="text"
                        label={t("email")}
                        icon={Envelope}
                        iconColor={"#919191"}
                        value={emailToSupport}
                        onChange={(e) => handleEmailChange(e)}
                        // error={emailToSupportError}
                        error={errors.job_title}
                        register={register}
                        control={control}
                        fieldName="job_title"
                      />
                    </Hide>
                    <IconInput
                      id="job_title"
                      placeholder={t("message-content")}
                      className="input-message"
                      type="text"
                      label={t("message")}
                      icon={Envelope}
                      iconColor={"#919191"}
                      fieldName="job_title"
                      // register={register}
                      value={messageToSupport}
                      onChange={(e) => handleMessageChange(e)}
                      register={register}
                      // error={messageToSupportError}
                      error={errors.job_title}
                      control={control}
                    />
                  </Grid>
                  <Grid item container justifyContent={"flex-end"}>
                    <Box>
                      <Button className="btn btn-dark send-msg" type="submit" disabled={loading}>
                        {t("send-message")}{" "}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Stack>
            <Stack spacing={2}>
              <Typography className={"faq-title"}>{t("faq")}</Typography>
              <IconInput
                id="faq_search"
                placeholder={t("search") + ".."}
                className="input-search"
                type="text"
                icon={MagnifyingGlass}
                fieldName="faq_search"
                iconColor={"#919191"}
                onChange={handleInputChange}
                register={register}
              />
              <CustomizedAccordions searchTerm={searchTerm} />
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default FAQ;
const CustomizedAccordions = ({ searchTerm }) => {
  const { i18n } = useTranslation();
  const [expanded, setExpanded] = useState("panel1");
  const [faqs, setFaqs] = useState([]);
  const [filteredFaqs, setFilteredFaqs] = useState([]);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const role = useSelector((state) => state.profile.role);

  useEffect(() => {
    const handleGetFaqs = async () => {
      if (role.name === "RECRUITER") {
        return await getStaticPages("FAQsRecruiter");
      } else {
        return await getStaticPages("FAQS");
      }
    };

    handleGetFaqs().then((data) => {
      setFaqs(data);
      setFilteredFaqs(data);
    });
  }, []);

  useEffect(() => {
    if (
      searchTerm?.trim === "" ||
      searchTerm === "" ||
      searchTerm == undefined ||
      searchTerm == null
    ) {
      setFilteredFaqs(faqs);
    } else {
      const options = {
        includeScore: true,
        keys: [`name_${i18n.language}`, `value_${i18n.language}`],
      };
      const fuse = new Fuse(faqs, options);
      const result = fuse.search(searchTerm);

      const temp = result.map((one) => one.item);
      setFilteredFaqs(temp);
    }
  }, [searchTerm]);

  return (
    <Stack direction={"column"} spacing={1}>
      {filteredFaqs?.map((faq, index) => {
        return (
          <OneQuestion
            questionDetails={translateField(faq, "value")}
            questionSummary={translateField(faq, "name")}
            panel={`panel${index + 1}`}
            expanded={expanded}
            handleChange={handleChange}
          />
        );
      })}
    </Stack>
  );
};

import {
    Grid,
    Typography,
    useMediaQuery,
    Divider,
    Button,
  } from "@mui/material";
  import { Box } from "@mui/system";
  import { useEffect } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { startStepper } from "../../state/actions/stepper/stepper";
  import Hide from "../Hide";
  import Show from "../Show";
  import ChangeLang from "../ChangeLang/ChangeLang";
  import Sidebar from "./Sidebar";
  import { logout } from "../../services/auth/Auth";
  import { useTranslation } from "react-i18next";
  import { deleteProfile } from "state/actions/profile/profile";
  import { authLogout } from "state/actions/auth/auth";
  import { useNavigate } from "react-router-dom";
  
  const AuthStepper = ({
    data,
    startStep,
    finalStep,
    renderStepContent,
    label,
    stepsFromApi,
    hideHeader,
    pass,
    hideLogoutLang,
  }) => {
    const { t, i18n } = useTranslation();
    const profile = useSelector((state) => state.profile.data);
    const matches = useMediaQuery("(max-width:600px)");
    const dispatch = useDispatch();
    const nextStep = finalStep + 1;
  
    const navigate = useNavigate();
  
    useEffect(() => {
      if (data) {
        dispatch(
          startStepper({ currentStep: startStep + 1, finalStep: finalStep + 1 })
        );
      }
    }, []);
    return (
      <>
        <Hide condition={hideLogoutLang}>
          <Show condition={matches}>
            <Grid
              container
              spacing={1}
              position={"absolute"}
              top={"15px"}
              right={i18n.language == "ar" ? "unset" : "15px"}
              left={i18n.language == "ar" ? "15px" : "unset"}
              width={"fit-content"}
            >
              <Show condition={profile}>
                <Grid item>
                  <Button
                    style={{ zIndex: "200" }}
                    className="btn btn-home"
                    onClick={() => {
                      dispatch(deleteProfile());
                      dispatch(authLogout(navigate));
                    }}
                  >
                    {t("log_out")}
                  </Button>
                </Grid>
              </Show>
              <Grid item>
                <Divider light orientation="vertical" style={{ zIndex: "200" }} />
              </Grid>
              <Grid item>
                <ChangeLang />
              </Grid>
            </Grid>
          </Show>
        </Hide>
        <Grid
          container
          direction="row"
          sx={{
            minWidth: "fit-content",
          }}
        >
          <Grid item xs={2} sm={3} md={4} lg={3} xl={3}>
            <Sidebar
              data={data}
              label={label}
              stepsFromApi={stepsFromApi}
              pass={pass}
              hideHeader={hideHeader}
              hideLogoutLang={hideLogoutLang}
            />
          </Grid>
          <Grid
            item
            container
            xs={10}
            sm={9}
            md={8}
            lg={9}
            xl={8}
            className="submit"
          >
            <Grid item xs={12} md={10} lg={8} sx={{ px: 2 }}>
              <Box component="div" sx={{ pt: 7, pb: 3 }} className="site-id">
                <Hide condition={hideHeader}>
                  {matches && (
                    <>
                      <Typography className="title">Joblink</Typography>
                      <Typography className="text">{label}</Typography>
                    </>
                  )}
                </Hide>
              </Box>
              {renderStepContent()}
            </Grid>
            <Grid item xs={12} md={true}></Grid>
          </Grid>
        </Grid>
      </>
    );
  };
  export default AuthStepper;
  
import "./Terms.scss";

import {
  Grid,
  Icon,
  MenuItem,
  Select,
  Typography,
  FormControl,
  Stack,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import DecsisionButton from "../../../../../components/decision-button/DecisionButton";
import { useSelector } from "react-redux";
import { CaretDown } from "phosphor-react";
import { useEffect, useState } from "react";
import { getStaticPages, translateField } from "../../../../../services/Helper";

import * as moment from 'moment'
import { Box } from "@mui/system";

const Terms = () => {
  const { t, i18n } = useTranslation();



  const roles = useSelector((state) => state.profile?.data?.role);

  const [role, setRole] = useState();
  const [terms, setTerms] = useState(null);

  const handleUpdatedDate = (date) => {
    if (date) {
      const d = new Date(date);
      return moment(d).format('YYYY MMMM');
    } else {
      return '';
    }

  }

  useEffect(() => {
    const handleGetTerms = async () => {
      return await getStaticPages('Terms');
    }

    handleGetTerms().then((data) => {
      setTerms(data.length > 0 ? data[0] : null);
    })
  }, [])





  return (
    <>
      <Grid container direction="column" className="account-settings-container">
        <Grid item xs={12}>
          <Typography className="settings-title">{t("terms")}</Typography>

          <Stack
            direction={"column"}
            spacing={2}
          >
            <Typography className="updated-on" >Updated {handleUpdatedDate(terms?.updated_at)} </Typography>

            <Box style={{fontFamily:'Urbanist'}} component="div" dangerouslySetInnerHTML={{ __html: translateField(terms, "value") }}>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
export default Terms;

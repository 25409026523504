/* eslint-disable react/react-in-jsx-scope */
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box, FormHelperText,
  Grid,
  IconButton,
  Input, Stack,
  Typography
} from "@mui/material";
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { FileDoc, FileImage, FilePdf, FilePlus, FileText, Image, Trash } from "phosphor-react";
import { useEffect, useState } from "react";
import { FileDrop } from "react-file-drop";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import * as yup from "yup";
import IconInput from "../../../../components/IconInput";
import JLSelect from "../../../../components/JLSelect";
import {
  FILE_TYPES,
  IMAGE_TYPES,
  PRIVATE_FILES
} from "../../../../constants/constants";
import {
  deleteDocument,
  getCity,
  getCompanyIndustry,
  getCompanySize,
  getCountry,
  getGovernorate,
  getRecruiterPosition
} from "../../../../services/Helper";
import { getStep } from "../../../../services/Stepper";
import {
  addCompanyInfo,
  updateCompanyInfo
} from "../../../../state/actions/profile/profile";
import { DELETE_COMMERCIAL_FILE } from "../../../../state/actions/profile/types";
import { nextStep } from "../../../../state/actions/stepper/stepper";
import { stepperData } from "./stepper";

const CompanyInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const currentStepNo = useSelector((state) => state.stepper.currentStep);
  const finalStepNo = useSelector((state) => state.stepper.finalStep);
  const stepFinished = useSelector(
    (state) => state.profile.data?.recruiter?.step_finished
  );
  const recruiter = useSelector((state) => state.profile.data?.recruiter);
  const current = getStep(stepperData, currentStepNo);

  const [countries, setCountries] = useState(null);
  const [governorates, setGovernorates] = useState(null);
  const [cities, setCities] = useState(null);
  const [companyIndustry, setCompanyIndustry] = useState(null);
  const [companySize, setCompanySize] = useState(null);
  const [recruiterPosition, setRecruiterPosition] = useState(null);
  const [commercialFile, setCommercialFile] = useState(
    recruiter?.commercial_id ? JSON.parse(recruiter?.commercial_id) : []
  );
  const [currentImg, setCurrentImg] = useState(recruiter?.image);
  const [currentImgFile, setCurrentImgFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const { t, i18n } = useTranslation();
  const requiredMessage = t("required_input");
  const emailMessage = t("email_format");
  const schema = yup
    .object({
      recruiter_position_id: yup.string().required(requiredMessage),
      company_industry_id: yup.string().required(requiredMessage),
      company_size_id: yup.string().required(requiredMessage),
      bio: yup
        .string()
        .transform((value, originalValue) => {
          return value ? value : undefined;
        })
        .required(requiredMessage)
        .min(3, t("min_input", { number: 3 }))
        .max(500, t("max_input", { number: 500 }))
        .trim(),
      city_id: yup.string().required(requiredMessage),
      image: yup
        .mixed()
        .required(requiredMessage)
        .test("fileFormat", t("file_type"), (value) => {
          if (
            typeof value === "string" ||
            value === null ||
            value === undefined
          ) {
            return true;
          }
          return value && IMAGE_TYPES.includes(value.type);
        })
        .test(
          "fileSize",
          t("file_size", { number: 5, unit: "MB" }),
          (value) => {
            if (
              typeof value === "string" ||
              value === null ||
              value === undefined
            ) {
              return true;
            }
            return value && value.size / 1024 / 1024 < 5;
          }
        ),
      commercial_id: yup
        .array()
        .of(
          yup
            .mixed()
            .test("fileFormat", t("file_type"), (value) => {
              if (
                typeof value === "string" ||
                value === null ||
                value === undefined
              ) {
                return true;
              }
              return FILE_TYPES.includes(value?.type);
            })
            .test(
              "fileSize",
              t("file_size", { number: 5, unit: "MB" }),
              (value) => {
                if (
                  typeof value === "string" ||
                  value === null ||
                  value === undefined
                ) {
                  return true;
                }
                return value?.size / 1024 / 1024 < 5;
              }
            )
        )
        .test({
          message: t("max_files", { number: 5 }),
          test: (arr) => arr.length < 6,
        }),
    })
    .required();

  const {
    control,
    register,
    getValues,
    reset,
    formState: { errors },
    setError,
    handleSubmit,
    setValue,
  } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      image: recruiter?.image,
      commercial_id: recruiter?.commercial_id ? recruiter?.commercial_id : [],
      recruiter_position_id: recruiter?.RecruiterPosition?.id,
      company_industry_id: recruiter?.CompanyIndustry?.id,
      company_size_id: recruiter?.CompanySize?.id,
      bio: recruiter?.bio,
      city_id: recruiter?.City?.id,
      country_id: recruiter?.City?.Governorate.country,
      governorate_id: recruiter?.City?.Governorate?.id,
    },
  });

  useEffect(() => {
    getCountry().then((data) => {
      setCountries(data);
    });

    getCompanyIndustry().then((data) => {
      setCompanyIndustry(data);
    });

    getCompanySize().then((data) => {
      setCompanySize(data);
    });

    getRecruiterPosition().then((data) => {
      setRecruiterPosition(data);
    });

    if (recruiter?.City?.governorate_id) {
      getCity(recruiter?.City?.governorate_id).then((data) => {
        setCities(data);
      });

      getGovernorate(recruiter?.City?.Governorate?.country).then((data) => {
        setGovernorates(data);
      });
    }

    return () => {
      setCountries(null);
      setCompanyIndustry(null);
      setCompanySize(null);
      setRecruiterPosition(null);
    };
  }, []);

  const onCountryChange = (e) => {
    const value = e.target.value;
    if (value !== "") {
      getGovernorate(value).then((data) => {
        setGovernorates(data);
        setValue("city_id", "");
        setValue("governorate_id", "");
      });
    } else {
      setGovernorates(null);
      setCities(null);
      setValue("city_id", "");
      setValue("governorate_id", "");
    }
  };
  const onGovernorateChange = (e) => {
    const value = e.target.value;
    if (value !== "") {
      getCity(value).then((data) => {
        setCities(data);
        setValue("city_id", "");
      });
    } else {
      setCities(null);
      setValue("city_id", "");
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    let dataToSend = new FormData();
    dataToSend.append("bio", data.bio);
    dataToSend.append("city_id", data.city_id);
    if (typeof data.commercial_id !== "string" && data.commercial_id != null) {
      data.commercial_id.forEach(element => {
        if (typeof element !== "string")
          dataToSend.append("commercial_id", element);
      });

    }
    dataToSend.append("company_industry_id", data.company_industry_id);
    dataToSend.append("company_size_id", data.company_size_id);
    dataToSend.append("recruiter_position_id", data.recruiter_position_id);
    for (var pair of dataToSend.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }
    if (currentImgFile) {
      dataToSend.append("image", currentImgFile, currentImgFile?.name);
    }

    if (stepFinished && stepFinished >= current.no) {
      // edit
      await dispatch(updateCompanyInfo(dataToSend)).finally(() => {
        setLoading(false);
      });
      handleNext();
    } else {
      // add
      await dispatch(addCompanyInfo(dataToSend)).finally(() => {
        setLoading(false);
      });
      handleNext();
    }
    setLoading(false);
  };

  const handleBack = () => {
    if (current.prev) {
      dispatch(nextStep({ currentStep: current.prev, finalStep: finalStepNo }));
    }
  };
  const handleNext = () => {
    if (current.next) {
      dispatch(nextStep({ currentStep: current.next, finalStep: finalStepNo }));
    }
  };

  useEffect(() => {
    if (
      typeof currentImg !== "string" &&
      currentImg !== null &&
      currentImg !== undefined
    ) {
      setCurrentImg(URL.createObjectURL(currentImg));
    }
  }, [currentImg]);

  const handleDeleteImage = async () => {
    setCurrentImg(null);
    document.getElementById("icon-button-file").value = "";
    await deleteDocument("e");
  };

  const handleDeleteFile = async (file, index) => {
    if (typeof file === "string") {
      await deleteDocument("commercial", file);
    }
    let filesAfterDelete = [...commercialFile];
    filesAfterDelete.splice(index, 1);
    setValue("commercial_id", filesAfterDelete);
    setCommercialFile(filesAfterDelete);
    dispatch({ type: DELETE_COMMERCIAL_FILE, payload: filesAfterDelete });
  };
  const chooseIconForFile = (file_name, from_server) => {
    console.log(file_name)
    var fileExt
    if (from_server) {
      fileExt = file_name?.split('.').pop();
    }
    else
      fileExt = file_name?.split('/').pop();
    console.log(fileExt, IMAGE_TYPES)
    IMAGE_TYPES.map((type) => {
      if (type.includes(fileExt))
        fileExt = "image"

    })
    switch (fileExt) {
      case "pdf":
        return <FilePdf size={28} weight="thin" />;
      case "image":
        return <FileImage size={28} weight="thin" />;
      default:
        return <FileDoc size={28} weight="thin" />
        break;
    }
    console.log(fileExt)
  }
  return (
    <Grid container direction="column">
      <Grid item container className="company-info step-content">
        <Grid item xs={12}>
          <Typography className="title">{t("company_title")}</Typography>
          <Typography className="text">
            {t("company_text")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              item
              xs={12}
              container
              direction="row"
              style={{ marginBottom: "16px" }}
            >
              <Grid item className={`pic ${currentImg && "no-bg"}`}>
                <img src={currentImg ? currentImg : ""} alt="" />
                {currentImg &&
                  <IconButton onClick={handleDeleteImage} className="delete-icon">
                    <Trash />
                  </IconButton>
                }
                <label htmlFor="icon-button-file" className="icon">
                  <Controller
                    control={control}
                    name="image"
                    render={({ field }) => {
                      return (
                        <Input
                          id="icon-button-file"
                          type="file"
                          style={{ display: "none" }}
                          inputProps={{
                            accept: ".png,.jpg,.jpeg",
                          }}
                          onChange={(e) => {
                            if (IMAGE_TYPES.includes(e.target.files[0]?.type)) {
                              field.onChange(e.target.files[0]);
                              setCurrentImg(e.target.files[0]);

                              setCurrentImgFile(e.target.files[0]);
                            } else {
                              setError("image", {
                                type: "manual",
                                message: t("file_type"),
                              });
                            }
                          }}
                        />
                      );
                    }}
                  />
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <Image style={{ color: "rgb(145, 145, 145)" }} />
                  </IconButton>
                </label>
              </Grid>
              <Grid item className="logo-desc">
                <Typography className="desc-1">
                  {t("company_logo")}
                </Typography>
                <Typography className="desc-2">
                  {t("company_logo_format")}
                </Typography>
                <Typography className="desc-2">
                  {t("company_logo_size", { size: 5 })}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {errors.image && (
                  <FormHelperText style={{ color: "red" }}>
                    {errors.image.message}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ mb: 2 }}>
              <JLSelect
                label={t("company_position")}
                selectLabel={t("company_position")}
                childs={recruiterPosition}
                fieldName="recruiter_position_id"
                control={control}
                error={errors.recruiter_position_id}
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 2 }}>
              <JLSelect
                label={t("industry")}
                selectLabel={t("industry")}
                childs={companyIndustry}
                fieldName="company_industry_id"
                control={control}
                error={errors.company_industry_id}
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 2 }}>
              <JLSelect
                label={t("company_size")}
                selectLabel={t("company_size")}
                childs={companySize}
                fieldName="company_size_id"
                control={control}
                error={errors.company_size_id}
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 2 }}>
              <IconInput
                label={t("company_bio")}
                id="bio"
                placeholder={t("company_bio")}
                type="text"
                icon={FileText}
                className="w100"
                multiline={true}
                rows={3}
                fieldName="bio"
                register={register}
                error={errors.bio}
              />
            </Grid>
            <Grid container item xs={12} sx={{ mb: 2 }} spacing={1}>
              <Grid item xs={4}>
                <JLSelect
                  label={t("country")}
                  selectLabel={t("country")}
                  childs={countries}
                  fieldName="country_id"
                  control={control}
                  error={errors.country_id}
                  onChange={onCountryChange}
                />
              </Grid>
              <Grid item xs={4}>
                <JLSelect
                  label={t("governrate")}
                  selectLabel={t("governrate")}
                  childs={governorates}
                  fieldName="governorate_id"
                  control={control}
                  error={errors.governorate_id}
                  onChange={onGovernorateChange}
                />
              </Grid>
              <Grid item xs={4}>
                <JLSelect
                  label={t("city")}
                  selectLabel={t("city")}
                  childs={cities}
                  fieldName="city_id"
                  control={control}
                  error={errors.city_id}
                />
              </Grid>
            </Grid>
            {/* <Grid item xs={12} className="commercial">
              {recruiter?.commercial_id && (
                <>
                  <Link
                    style={{ color: "#3a3b81", textDecorationColor: "#3a3b81" }}
                    href={recruiter?.commercial_id + PRIVATE_FILES}
                  >
                    {t("comercial_link")}
                  </Link>
                  <IconButton
                    onClick={handleDeleteFile}
                    className="delete-icon"
                  >
                    <Trash />
                  </IconButton>
                </>
              )}
            </Grid> */}
            <Grid item xs={12}>
              <FileDrop
                onDrop={(files, event) => {
                  let commercialFiles = [...commercialFile, ...files];
                  setValue("commercial_id", commercialFiles);
                  setCommercialFile(commercialFiles);
                }}
              >
                <div className="upload-commercialId">
                  <Typography className="text">
                    {" "}
                    {t("upload_comercial_first_text")}
                  </Typography>
                  <Typography className="text">
                    {t("upload_comercial_second_text")}
                  </Typography>
                  <Typography className="title">
                    {t("commercial_title")}
                  </Typography>
                  <label
                    htmlFor="commercial_id"
                    className="icon"
                    style={{ width: "100%" }}
                  >
                    <Controller
                      control={control}
                      name="commercial_id"
                      render={({ field }) => {
                        return (
                          <Input
                            id="commercial_id"
                            type="file"
                            style={{ display: "none" }}
                            inputProps={{
                              accept: ".docx,.pdf,.doc,.png,.jpg,.jpeg",
                              multiple: true,
                            }}
                            onChange={(e) => {
                              if (e.target.files[0] !== undefined) {
                                //commercialFiles.concat(Array.from(e.target.files));
                                let commercialFiles = [
                                  ...commercialFile,
                                  ...e.target.files,
                                ];
                                field.onChange(commercialFiles);
                                setCommercialFile(commercialFiles);
                              }
                            }}
                          />
                        );
                      }}
                    />
                    <IconButton
                      color="primary"
                      className="drag-and-drop"
                      aria-label="upload picture"
                      component="span"
                    >
                      <FilePlus className="icon" />
                      <Typography className="text">
                        {t("drag_and_drop")}
                      </Typography>
                    </IconButton>
                  </label>
                </div>
              </FileDrop>
              {errors.commercial_id && (
                <FormHelperText style={{ color: "red" }}>
                  {errors.commercial_id.message}
                </FormHelperText>
              )}
              <Stack
                direction={"column"}
                justifyContent={"space-between"}
                component={List}
                spacing={2}
              >
                {commercialFile &&
                  commercialFile?.map((file, index) => {
                    // console.log(file, "WhatsApp Image 2022-07-07 at 8.38.50 AM.jpeg")
                    return (
                      <Stack
                        direction={"column"}
                        spacing={1}
                        justifyContent={"space-between"}

                      >
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                        >
                          {typeof file === "string" ? (
                            <>
                              <ListItem
                                className="uploaded-file"
                                sx={{
                                  padding: "10px"
                                }}
                                onClick={() => {
                                  window.location.href = (`${file + PRIVATE_FILES}`)
                                }}>
                                <ListItemAvatar>
                                  <Avatar>
                                    {chooseIconForFile(file, true)}
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                  sx={{
                                    textDecoration: "underline",
                                    wordBreak: "break-all"
                                  }}
                                  primary={file.split("-")[file.split("-").length - 1]} />
                              </ListItem>
                            </>
                          ) : (
                            <ListItem sx={{
                              padding: "10"
                            }}>
                              <ListItemAvatar>
                                <Avatar>
                                  {chooseIconForFile(file.type)}
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText primary={file.name} />
                            </ListItem>
                          )}
                          <Box alignSelf={"center"}>
                            <IconButton
                              onClick={() => handleDeleteFile(file, index)}
                              className="file-delete-icon"
                            >
                              <Trash size={24} color={"#ba3a7d"} />
                            </IconButton>
                          </Box>
                        </Stack>
                        <Box>
                          {errors?.commercial_id?.[index] &&
                            <FormHelperText error sx={{
                              padding: "10px"
                            }}>
                              {errors?.commercial_id?.[index]?.message}
                            </FormHelperText>
                          }
                        </Box>
                      </Stack>
                    )
                  })}
              </Stack>

            </Grid>
            <Grid item container justifyContent="end">
              {current.prev && (
                <button className="back-btn" onClick={handleBack}>
                  {t("back_btn")}
                </button>
              )}
              <button
                type="submit"
                className="next-btn"
                disabled={loading}
                style={!loading ? { cursor: "pointer" } : {}}
              >
                {t("next_btn")}
              </button>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CompanyInfo;

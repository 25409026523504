import { Box } from "@mui/material";
import { GooglePlayLogo, TwitterLogo } from "phosphor-react";
import EnhancedMiniJobCard from "./EnhancedMiniJobCard/EnhancedMiniJobCard";

const Abdelrahman = () => {
  let options = [
    { name_en: "Test", name_ar: "Test", id: 1, to: "/hello" },
    { name_en: "Test2", name_ar: "Test2", id: 2, to: "/hello" },
    { name_en: "Test3", name_ar: "Test3", id: 3, to: "/hello" },
    { name_en: "Test4", name_ar: "Test2", id: 4, to: "/hello" },
    { name_en: "Test5", name_ar: "Test2", id: 5, to: "/hello" },
    { name_en: "Test6", name_ar: "Test2", id: 6, to: "/hello" },
  ];

  // let options = [
  //   { code: "EN", name_en: "English", name_ar: "English", icon: "GB" },
  //   { code: "AR", name_en: "Arabic", name_ar: "Arabic", icon: "SA" },
  // ];

  let socialMedia = [
    { url: "twitter.com", icon: <TwitterLogo /> },
    { url: "twitter.com", icon: <TwitterLogo /> },
  ];
  let appStores = [
    { url: "playstore.com", icon: <GooglePlayLogo /> },
    { url: "playstore.com", icon: <GooglePlayLogo /> },
  ];
  let description = {
    name_en:
      "Facere eos accusamus facere. Magni quia amet nisi voluptatibus molestiae harum. Ratione eveniet ",
    name_ar: "Test",
  };

  return (
    <>
      <Box
        sx={{
          width: "35%",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "80px",
        }}
      >
        <EnhancedMiniJobCard
          title="Habbad"
          company="Habd Ltd."
          location="Habdstan"
          date="12/03/1996"
          noOfApplicants={3}
          tags={["on-Site", "Full Time"]}
        />
      </Box>
    </>
  );
};
export default Abdelrahman;



import {
    CHANGE_PAGE_BACK_LINK 
}
    from '../../actions/pageBack/types'

let initialState = {
   pageLink:''
}
export const PageBackReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {

        case  CHANGE_PAGE_BACK_LINK:
            return {
                ...state,
                pageLink: payload
            }
       
        default:
            return state
    }
}
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
// import noDataAnimation from "../../assets/lootie-files/no-data.json";
import ErrorPage from "../../components/error-page/ErrorPage";
import JobDetailsCard from "../../components/job-details-card/JobDetailsCard";
import Loader from "../../components/loader/Loader";
import Show from "../../components/Show";
import Permissions from "../../constants/permissions";
import {
  changeCompanyStatus,
  getJob,
  perm,
  permAnd,
  translateField
} from "../../services/Helper";
import { loadProfileFromRedux } from "../../state/actions/profile/profile";
import ApplicantCard from "./components/applicants-card/ApplicantCard";
import useShareJobPost from './enhanced-share-job-post/useShareJobPost';

// const noDataAnimation = React.lazy(() => import("../../assets/lootie-files/no-data.json"));

const JobDetails = () => {
  const { setOpen } = useShareJobPost()
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [job, setJob] = useState(null);
  const [jobLoaded, setJobLoaded] = useState(false);
  const [showStatus, setShowStatus] = useState(false);

  const role = useSelector((state) => state.profile.role?.name);
  const permissions = useSelector((state) => state.profile.role?.permissions);
  const loaded = useSelector((state) => state.profile.loaded);
  const params = useParams();
  const [followed, setFollowed] = useState(false);

  const fetchJob = (id) => {
    getJob(id, true).then((data) => {
      console.log("FETCHING JOB", data);
      setJob(data);
    });
  };

  const handleFollowCompany = () => {
    changeCompanyStatus(job?.Recruiter?.id, role).then(() => {
      setFollowed(!followed);
    });
  };

  useEffect(() => {
    dispatch(loadProfileFromRedux());
  }, []);

  useEffect(() => {
    if (
      loaded &&
      permAnd(permissions, Permissions.viewJob, Permissions.editJob)
    ) {
      getJob(params.job_id, false)
        .then((data) => {
          setJob(data);
          setJobLoaded(true);
          if (loaded && perm(permissions, Permissions.viewStatusInJobDetails)) {
            if (data?.ApplicantApplyJobs?.length > 0) {
              setShowStatus(true);
            }
          }
        })
        .catch((error) => {
          setJobLoaded(true);
        });
    } else if (loaded && perm(permissions, Permissions.viewJob)) {
      getJob(params.job_id, true)
        .then((data) => {
          setJob(data);
          setJobLoaded(true);
          if (loaded && perm(permissions, Permissions.viewStatusInJobDetails)) {
            if (data?.ApplicantApplyJobs?.length > 0) {
              setShowStatus(true);
            }
          }
        })
        .catch((error) => {
          setJobLoaded(true);
        });
    }
  }, [loaded]);

  useEffect(() => {
    if (jobLoaded) {
      setFollowed(job?.Recruiter?.is_followed == 1 ? true : false);
    }
  }, [jobLoaded]);

  const [noDataAnimation, setNoDataAnimation] = useState(null);
  useEffect(() => {
    import("../../assets/lootie-files/no-data.json").then(data => setNoDataAnimation(data));
  }, [])

  return (
    <>
      <Grid container spacing={2} padding={2}>
        <Helmet>
          <meta
            property="og:title"
            content={translateField(job?.JobTitle, "name")}
          />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content="article" />
          <meta property="og:description" content={job?.job_descirption} />
          <meta
            property="og:image"
            content="https://sajoblinkshub.blob.core.windows.net/pubic/9b6dd510-e4b6-11ec-88dc-c11c3306689f-JobLink%20AppIcon%20(2).png"
          />

          <meta
            property="twitter:title"
            content={translateField(job?.JobTitle, "name")}
          />
          <meta property="twitter:description" content={job?.job_descirption} />
          <meta
            property="twitter:image"
            content="https://sajoblinkshub.blob.core.windows.net/pubic/9b6dd510-e4b6-11ec-88dc-c11c3306689f-JobLink%20AppIcon%20(2).png"
          />
          <meta property="twitter:card" content="" />
        </Helmet>
        <Show condition={perm(permissions, Permissions.viewJob)}>
          <Grid
            item
            xs={12}
            md={perm(permissions, Permissions.viewJobApplicants) ? 8 : 12}
          >
            <Loader
              data={jobLoaded}
              width={"100%"}
              height={400}
              style={{ borderRadius: "1rem" }}
            >
              <Show condition={jobLoaded && job}>
                <JobDetailsCard
                  hiringTime={job?.HiringTime}
                  external_apply_website={job?.external_apply_website}
                  //followHandler={handleFollowCompany}
                  fetchJob={fetchJob}
                  currency={job?.currency}
                  recruiterId={job?.Recruiter?.id}
                  jobId={params?.job_id}
                  followed={jobLoaded && job?.Recruiter?.is_followed}
                  status={job?.status}
                  jobTitle={translateField(job?.JobTitle, "name")}
                  experienceType={translateField(job?.ExperienceType, "name")}
                  applicantsCount={`${job?.count} ${t("applicants")}`}
                  date={job?.created_at}
                  location={`${translateField(
                    job?.Country,
                    "name"
                  )}, ${translateField(
                    job?.Governorate,
                    "name"
                  )}, ${translateField(job?.City, "name")}`}
                  yearsOfEx={translateField(job?.YearsOfExperience, "name")}
                  jobCategory={translateField(job?.JobCategory, "name")}
                  slaryRange={
                    job?.Salary?.id === 1
                      ? translateField(job?.Salary, "name")
                      : job?.Salary?.id === 2
                        ? job?.salary_from + " - " + job?.salary_to
                        : job?.Salary?.id === 3
                          ? job?.salary_from
                          : job?.Salary?.id === 4
                            ? job?.salary_from + " + " + "bonus"
                            : null
                  }
                  workStyle={translateField(job?.WorkStyle, "name")}
                  jobDescreption={job?.job_descirption}
                  jobRequirement={job?.job_requirement}
                  noOfApplicants={job?.count}
                  isApplied={job?.is_applied}
                  isSaved={job?.is_saved}
                  emptyData={job === undefined ? true : false}
                  companyName={job?.Recruiter?.company_name}
                  companyLogo={job?.Recruiter?.image}
                  companySize={translateField(
                    job?.Recruiter?.CompanySize,
                    "name"
                  )}
                  ApplicantApplyJobs={job?.ApplicantApplyJobs}
                  showStatus={showStatus}
                  careerLevel={translateField(job?.CareerLevel, "name")}
                  jobStatus={
                    job?.ApplicantApplyJobs?.[0]?.JobStatus?.name_en ===
                      "Pending"
                      ? job?.ApplicantApplyJobs?.[0]?.is_seen
                        ? "Seen"
                        : job?.ApplicantApplyJobs?.[0]?.JobStatus?.name_en
                      : job?.ApplicantApplyJobs?.[0]?.JobStatus?.name_en
                  }
                  showBottomShare={false}
                />
              </Show>
            </Loader>
          </Grid>
        </Show>
        <Show condition={perm(permissions, Permissions.viewJobApplicants)}>
          <Grid item xs={12} md={4}>
            <Loader
              data={jobLoaded}
              width={"100%"}
              height={400}
              style={{ borderRadius: "1rem" }}
            >
              <Show condition={jobLoaded && job}>
                <ApplicantCard
                  applicantsCount={job?.count}
                  applicantApplyJob={job?.ApplicantApplyJobs}
                  job_id={params.job_id}
                />
              </Show>
            </Loader>
          </Grid>
        </Show>
        <Show condition={jobLoaded && !job}>
          <Grid item xs={12}>
            <ErrorPage
              hideAction
              animationData={noDataAnimation}
              title={t("oops")}
              description={t("no_jobs")}
            />
          </Grid>
        </Show>
      </Grid>
    </>
  );
};

export default JobDetails;

import { B2C_LOGIN_COMPLETED, B2C_LOGIN_FINISHED, B2C_LOGIN_STARTED } from "./types";

export const finishB2cLogin = () => (dispatch) => {
    dispatch({ type: B2C_LOGIN_FINISHED })
}

export const startB2cLogin = () => (dispatch) => {
    dispatch({ type: B2C_LOGIN_STARTED })
}

export const completeB2cLogin = () => (dispatch) => {
    dispatch({ type: B2C_LOGIN_COMPLETED })
}
import { combineReducers } from "redux";

import { loadingBarReducer } from "react-redux-loading-bar";
import { reducer as toastrReducer } from "react-redux-toastr";
import { AddToCategoryReducer } from "./add-to-category/AddToCategoryReducer";
import { ApplicantListReducer } from "./applicant-list/ApplicantListReducer";
import b2cReducer from "./b2c/b2cReducer";
import { CategoryListReducer } from "./category-list/CategoryListReducer";
import { filterReducer } from "./filter/filterReducer";
import languageHandlerReducer from "./languages-handler/LanguageHandlerReducer";
import loadingReducer from "./loading/loadingReducer";
import loginModalReducer from "./login-modal/loginModalReducer";
import modeReducer from "./mode/modeReducer";
import newsReducer from "./news/newsReducer";
import { notificationReducer } from "./notification/notificationReducer";
import notificationsReducer from "./notifications/notificationsReducer";
import { PageBackReducer } from "./page-back/PageBackReducer";
import profileReducer from "./profile/profileReducer";
import { searchReducer } from "./search/searchReducer";
import { sidebarReducer } from "./sidebar/sidebar";
import stepperReducer from "./stepper/stepperReducer";
import authReducer from "./auth/authReducer";
import otpReducer from "./otp/otpReducer";
import processReducer from "./process/processReducer";

const reducer = combineReducers({
  languageHandler: languageHandlerReducer,
  loadingBar: loadingBarReducer,
  stepper: stepperReducer,
  toastr: toastrReducer,
  profile: profileReducer,
  loading: loadingReducer,
  sidebar: sidebarReducer,
  newNotification: notificationReducer,
  notifications: notificationsReducer,
  b2cLogin: b2cReducer,
  loginModal: loginModalReducer,
  search: searchReducer,
  filter: filterReducer,
  categoryList: CategoryListReducer,
  applicantList: ApplicantListReducer,
  pageBack: PageBackReducer,
  AddToCategory: AddToCategoryReducer,
  news: newsReducer,
  mode: modeReducer,
  auth: authReducer,
  otp: otpReducer,
  process: processReducer,
});

export default reducer;

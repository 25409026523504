import {
  CAREERLEVELS_FILTER,
  CITIES_FILTER,
  COMPANIES,
  COMPANYINDUSTRIES_FILTER,
  COUNTRIES_FILTER,
  DEGREES_FILTER,
  GOVERNORATES_FILTER,
  GRADES_FILTER, HIRING_TIME_FILTER, JOBINDUSTRIES_FILTER,
  JOBS,
  JOBTYPES_FILTER,
  MAX_FILTER,
  MIN_FILTER, NATIONALITIES_FILTER,
  PEOPLE, RESPONSIVE_COUNTRIES_FILTER,
  RESPONSIVE_COUNTRIES_FILTER_REMOVE,
  RESPONSIVE_FILTER_SET,
  RESPONSIVE_FILTER_TOGGLE,
  RESPONSIVE_FILTER_UNSET,
  RESPONSIVE_GOVERNORATES_FILTER,
  RESPONSIVE_GOVERNORATES_FILTER_REMOVE,
  SALARIES_FILTER,
  SEARCH_FILTER, SEARCH_FROM_HOME, SENIORITYLEVELS_FILTER,
  URL_SEARCH
} from "../../actions/filter/types";

let initialState = {
  people: false,
  companies: false,
  jobs: false,
  responsiveFilterState: false,
  activeFilter: "people",
  search: "",
  seniorityLevels: [],
  nationalities: [],
  hiring_time: [],
  degrees: [],
  grades: [],
  countries: [],
  governorates: [],
  cities: [],
  jobTypes: [],
  salaries: [],
  careerLevels: [],
  jobIndustries: [],
  companyIndustries: [],
  min: null,
  max: null,
};

export const filterReducer = (state = initialState, action) => {
  const { type, payload } = action;
  let selectedCountries;
  let selectedGovernorates;
  switch (type) {
    case URL_SEARCH:
      return {
        ...state,
        ...payload,
      };
    case SEARCH_FILTER:
      return {
        ...state,
        search: payload,
      };
    case SENIORITYLEVELS_FILTER:
      return {
        ...state,
        seniorityLevels: payload,
      };
    case HIRING_TIME_FILTER:
      return {
        ...state,
        hiring_time: payload,
      };
    case NATIONALITIES_FILTER:
      return {
        ...state,
        nationalities: payload,
      };
    case DEGREES_FILTER:
      return {
        ...state,
        degrees: payload,
      };
    case GRADES_FILTER:
      return {
        ...state,
        grades: payload,
      };
    case COUNTRIES_FILTER:
      return {
        ...state,
        countries: payload,
      };
    case RESPONSIVE_COUNTRIES_FILTER:
      selectedCountries = [...state.countries];
      selectedCountries.push(payload);
      return {
        ...state,
        countries: selectedCountries,
      };
    case RESPONSIVE_COUNTRIES_FILTER_REMOVE:
      selectedCountries = [...state.countries];
      selectedCountries.splice(selectedCountries.indexOf(payload), 1);
      selectedCountries.push(payload);
      return {
        ...state,
        countries: selectedCountries,
      };
    case GOVERNORATES_FILTER:
      return {
        ...state,
        governorates: payload,
      };
    case RESPONSIVE_GOVERNORATES_FILTER:
      selectedGovernorates = [...state.governorates];
      selectedGovernorates.push(payload);
      return {
        ...state,
        governorates: selectedGovernorates,
      };
    case RESPONSIVE_GOVERNORATES_FILTER_REMOVE:
      selectedGovernorates = [...state.governorates];
      selectedGovernorates.splice(selectedGovernorates.indexOf(payload), 1);
      selectedGovernorates.push(payload);
      return {
        ...state,
        governorates: selectedGovernorates,
      };
    case CITIES_FILTER:
      return {
        ...state,
        cities: payload,
      };
    case JOBTYPES_FILTER:
      return {
        ...state,
        jobTypes: payload,
      };
    case SALARIES_FILTER:
      return {
        ...state,
        salaries: payload,
      };
    case MIN_FILTER:
      return {
        ...state,
        min: payload,
      };
    case MAX_FILTER:
      return {
        ...state,
        max: payload,
      };
    case CAREERLEVELS_FILTER:
      return {
        ...state,
        careerLevels: payload,
      };
    case JOBINDUSTRIES_FILTER:
      return {
        ...state,
        jobIndustries: payload,
      };
    case COMPANYINDUSTRIES_FILTER:
      return {
        ...state,
        companyIndustries: payload,
      };
    case PEOPLE:
      return {
        ...state,
        companies: false,
        jobs: false,
        people: true,
        activeFilter: "people",
      };
    case COMPANIES:
      return {
        ...state,
        jobs: false,
        people: false,
        companies: true,
        activeFilter: "companies",
      };
    case JOBS:
      return {
        ...state,
        companies: false,
        people: false,
        jobs: true,
        activeFilter: "jobs",
      };
    case RESPONSIVE_FILTER_TOGGLE:
      return {
        ...state,
        responsiveFilterState: !state.responsiveFilterState,
      };
    case RESPONSIVE_FILTER_SET:
      return {
        ...state,
        responsiveFilterState: true,
      };
    case RESPONSIVE_FILTER_UNSET:
      return {
        ...state,
        responsiveFilterState: false,
      };
    case SEARCH_FROM_HOME:
      return {
        activeFilter: payload.filter,
        search: payload.search,
        hiring_time: [],
        seniorityLevels: [],
        nationalities: [],
        degrees: [],
        grades: [],
        countries: [],
        governorates: [],
        cities: [],
        jobTypes: [],
        salaries: [],
        careerLevels: [],
        jobIndustries: [],
        companyIndustries: [],
        min: null,
        max: null,
      };
    default:
      return state;
  }
};

export default filterReducer;

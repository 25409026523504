
const MilitaryIcon = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <g id="Group_35" data-name="Group 35" transform="translate(0.133 0.133)">
    <rect id="Rectangle_50" data-name="Rectangle 50" width="20" height="20" transform="translate(-0.133 -0.133)" fill="none" />
    <g id="officer-svgrepo-com" transform="translate(-2.41 -0.272)">
      <path id="Path_3" data-name="Path 3" d="M10.229,29.23s-.259-.251-.435-.208C7.909,29.485,6,30.429,6,31.85v2.392H18.555V31.85c0-1.421-1.909-2.365-3.794-2.828-.175-.043-.435.208-.435.208a18.636,18.636,0,0,1-2.207,1.176C12.114,30.406,10.229,29.23,10.229,29.23Zm4.369.455-.272-.455.272.455Zm0,0h0l.01.017h0Zm.01.017h0l.075.126h0l0,0-.017.01-.064.038-.229.135c-.19.111-.448.26-.713.41s-.543.3-.775.419c-.115.058-.228.112-.327.154-.049.021-.1.042-.16.06a.916.916,0,0,1-.273.045,1.285,1.285,0,0,1-.243-.044l-.052-.022-.034-.017-.021-.011L11.755,31l-.022-.013-.063-.039-.216-.134-.65-.4-.649-.4-.214-.133-.06-.037-.016-.01,0,0h0l.072-.116h0l-.072.116L9.8,29.782l-.034-.033a7.628,7.628,0,0,0-2.188.909c-.636.421-.878.828-.878,1.191v1.695h11.16V31.85c0-.363-.242-.771-.878-1.191a7.628,7.628,0,0,0-2.188-.909l-.039.038-.07.042Zm-4.676,0h0l.021-.034h0Zm.021-.034.275-.441-.275.441Z" transform="translate(0 -16.952)" fill="#404040" fill-rule="evenodd" />
      <path id="Path_4" data-name="Path 4" d="M12,37.348A.349.349,0,0,1,12.349,37h1.744a.349.349,0,1,1,0,.7H12.349A.349.349,0,0,1,12,37.348Z" transform="translate(-3.908 -22.151)" fill="#404040" />
      <path id="Path_5" data-name="Path 5" d="M30.977,34.052a.071.071,0,0,1,.138,0l.2.636a.073.073,0,0,0,.069.052h.639a.076.076,0,0,1,.043.137l-.517.393a.078.078,0,0,0-.026.085l.2.636a.073.073,0,0,1-.111.085l-.517-.393a.07.07,0,0,0-.085,0l-.517.393a.073.073,0,0,1-.111-.085l.2-.636a.078.078,0,0,0-.026-.085l-.517-.393a.076.076,0,0,1,.043-.137h.639a.073.073,0,0,0,.069-.052Z" transform="translate(-15.63 -20.197)" fill="#404040" />
      <path id="Path_6" data-name="Path 6" d="M31.13,34.1l-.015-.047a.071.071,0,0,0-.138,0l-.2.636a.073.073,0,0,1-.069.052h-.639a.076.076,0,0,0-.043.137l.517.393a.078.078,0,0,1,.026.085l-.2.636a.073.073,0,0,0,.111.085L31,35.684a.07.07,0,0,1,.085,0l.517.393a.073.073,0,0,0,.111-.085l-.2-.636a.078.078,0,0,1,.026-.085l.517-.393a.076.076,0,0,0-.043-.137h-.639a.073.073,0,0,1-.069-.052Z" transform="translate(-15.63 -20.197)" fill="#404040" fill-rule="evenodd" />
      <path id="Path_7" data-name="Path 7" d="M15,15.7V15h.7v.7a2.441,2.441,0,1,0,4.882,0V15h.7v.7A3.139,3.139,0,1,1,15,15.7Z" transform="translate(-5.861 -7.823)" fill="#404040" fill-rule="evenodd" />
      <path id="Path_8" data-name="Path 8" d="M16.234,3l-.055.344L16.125,3l.055-.009Zm3.436,3.586.026-.041c.057-.09.134-.218.216-.38a4.77,4.77,0,0,0,.447-1.333.677.677,0,0,0-.225-.6,1.943,1.943,0,0,0-.508-.334,8.094,8.094,0,0,0-1.388-.477c-.5-.131-1-.237-1.374-.309-.188-.036-.345-.064-.455-.084L16.281,3,16.247,3h-.013l-.055.344L16.125,3h-.012L16.078,3l-.128.022c-.11.019-.266.047-.453.083-.373.072-.872.177-1.369.309a8.118,8.118,0,0,0-1.387.477,1.976,1.976,0,0,0-.509.333A.687.687,0,0,0,12,4.82a4.321,4.321,0,0,0,.44,1.344,4.174,4.174,0,0,0,.224.383l.027.04a1.073,1.073,0,0,0,.025.186,1.306,1.306,0,0,0,.382.654c.458.443,1.356.8,3.084.8s2.626-.353,3.084-.8a1.306,1.306,0,0,0,.382-.654A1.074,1.074,0,0,0,19.671,6.583Zm-.805.245H13.494a.808.808,0,0,0,.087.1c.263.254.935.6,2.6.6s2.336-.345,2.6-.6A.808.808,0,0,0,18.866,6.828Zm-5.642-.7h5.908c.044-.071.1-.167.159-.284a4.084,4.084,0,0,0,.375-1.1l-.019-.02a1.287,1.287,0,0,0-.324-.2,7.443,7.443,0,0,0-1.262-.43c-.478-.126-.962-.228-1.328-.3-.183-.035-.335-.063-.442-.081l-.111-.019-.11.019c-.106.018-.258.046-.44.081-.365.07-.847.172-1.324.3a7.466,7.466,0,0,0-1.261.43,1.318,1.318,0,0,0-.328.206.316.316,0,0,0-.023.025,3.634,3.634,0,0,0,.364,1.09C13.119,5.963,13.178,6.06,13.223,6.131Zm-.54-1.363v0Zm6.994,0v0Z" transform="translate(-3.902 0)" fill="#404040" fill-rule="evenodd" />
      <path id="Path_9" data-name="Path 9" d="M23.244,6.465A3.226,3.226,0,0,1,22.372,6a3.226,3.226,0,0,1-.872.465s.174,1.279.872,1.279S23.244,6.465,23.244,6.465Z" transform="translate(-10.094 -1.962)" fill="#404040" />
    </g>
  </g>
</svg>

export default MilitaryIcon

import { Grid, Typography, IconButton } from "@mui/material";
import { PencilSimple } from "phosphor-react";
import "./ProfileInformation.scss";

const ProfileInformation = ({ title, showTitle, showEdit, children }) => {

  return (
    <Grid container direction={"row"} marginBottom={0.5}>
      <Grid item container justifyContent="space-between" alignItems={"center"}>
        {showTitle && (
          <Grid item>
            <Typography className="profile-title">{title}</Typography>
          </Grid>
        )}
        {showEdit && (
          <Grid item>
            <IconButton className="profile-edit">
              <PencilSimple />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <Grid item className="profile-box">
        {children}
      </Grid>
    </Grid>
  );
};
export default ProfileInformation;

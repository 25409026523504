import AuthStepper from "../../../components/auth-stepper/Stepper";

import { useSelector } from "react-redux";
import { APPLICANT_STEPPER } from "../../../constants/constants";
import GeneralInfo from "./GeneralInfo";
import ContactInfoPortfolio from "./ContactInfoPortfolio";
import Education from "./Education";
import Experience from "./Experience";
import Skills from "./Skills";
import CareerInterests from "./CareerInterests";
import { stepperData } from "./stepper";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const ApplicantSubmit = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const currentStep = useSelector((state) => state.stepper.currentStep);
    const stepFinished = useSelector((state) => state.profile.data?.cv?.User?.step_finished);
    const stepsFromApi = useSelector((state) => {
        let steps = state.profile.data?.cv?.User?.UserSteps;
        return steps && steps.length > 0 ? steps[0] : {};
    });
    const [stepperDataState, setStepperDataState] = useState(stepperData);
    const isCvCreated = useSelector((state) => state.profile.data?.cv?.User?.is_cv_created);
    const title = "JOBLINK"

    useEffect(() => {
        let tempStepperData = stepperData;
        Object.keys(stepsFromApi).map((key, index) => {
            tempStepperData[index].completed = stepsFromApi[key]
        })
        setStepperDataState(tempStepperData);
        
    }, [stepsFromApi])

    

    const switched = location.state?.switched;

    useEffect(() => {
        if (!switched || isCvCreated == 1) {
            navigate("/")
        }
    }, [isCvCreated])

    const renderStepContent = () => {
        switch (currentStep) {
            case 1:
                return (
                    <>
                        <Helmet>
                            <title>{title} - {t("general_info")}</title>
                        </Helmet>
                        <GeneralInfo />
                    </>
                );
            case 2:
                return (
                    <>
                        <Helmet>
                            <title>{title} - {t("contact_and_portfolio")}</title>
                        </Helmet>
                        <ContactInfoPortfolio />
                    </>
                );
            case 3:
                return (
                    <>
                        <Helmet>
                            <title>{title} - {t("education")}</title>
                        </Helmet>
                        <Education />
                    </>
                );
            case 4:
                return (
                    <>
                        <Helmet>
                            <title>{title} - {t("experience")}</title>
                        </Helmet>
                        <Experience />
                    </>
                );
            case 5:
                return (
                    <>
                        <Helmet>
                            <title>{title} - {t("skills")}</title>
                        </Helmet>
                        <Skills />
                    </>
                );
            case 6:
                return (
                    <>
                        <Helmet>
                            <title>{title} - {t("career_interset")}</title>
                        </Helmet>
                        <CareerInterests />
                    </>
                );
            default:
                break;
        }
    }

    return (
        <AuthStepper
            data={stepperDataState}
            finalStep={isCvCreated == 1 ? 5 : stepFinished ? stepFinished : 0}
            startStep={0}
            renderStepContent={renderStepContent}
            label={t("applicant_sidebar_label")}
            stepsFromApi={stepsFromApi}
        />
    )
}
export default ApplicantSubmit;
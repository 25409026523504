import { Grid, Stack, useMediaQuery } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, matchRoutes, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../../../assets/Logo5SVG";
import { scrollToSection } from "../../../../services/Helper";
import LandingPageSearchBar from "../../okba/components/landing-page-search-bar/LandingPageSearchBar";
import EnhancedButton from "../../yahya/components/enhanced-button/EnhancedButton";

import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import EnhancedNotificationsDropdown from "../../zeinnab/components/navbar/components/notifications-dropdown/EnhancedNotificationsDropdown";
import ProfileDropdown from "../../zeinnab/components/navbar/components/profile-dropdown/ProfileDropdown";
import AuthorizedMobileNavbar from "./mobile-navbar/AuthorizedMobileNavbar";
import RecruiterNavigationButtons from "./navigation-buttons/RecruiterNavigationButtons";
import "./RecruiterContent.scss";
const RecruiterContent = ({ currentSection, handleSelection }) => {
  const isMobile = useMediaQuery("(max-width:1200px)");
  const isLarge = useMediaQuery("(max-width:1300px)");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const recruiterContentPaths = {
    myJobs: {
      patterns: [
        { path: "/" },
        { path: "/my-jobs" },
        { path: "/jobs/:job_id/edit" },
        { path: "/jobs/:job_id/applicants" },
        { path: "/jobs/:job_id/applicants/:applicant_id" },
      ]
    },
    candidatesBank: {
      patterns: [
        { path: "candidates-bank" }
      ]
    }
  };



  const checkCurrentPath = (patterns) => {
    const exist = matchRoutes(patterns, location)

    return exist ? true : false;
  }

  const { t } = useTranslation();

  const [openDrawer, setOpenDrawer] = useState(false);

  const links = [
    {
      value: "home",
      label: "Home",
      onClick: () => {
        setOpenDrawer(false);
        handleSelection(null, "home");
        if (window.location.pathname === "/") {
          scrollToSection("home");
        } else {
          navigate("/");
          setTimeout(() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }, 100);
        }
      },
    },
    {
      value: "how-it-works",
      label: "How it Works",
      onClick: () => {
        setOpenDrawer(false);
        handleSelection(null, "how-it-works");
        if (window.location.pathname !== "/") {
          navigate("/");
          setTimeout(() => {
            scrollToSection("how-it-works");
          }, 100);
        } else {
          scrollToSection("how-it-works");
        }
      },
    },
    {
      value: "our-advantages",
      label: "Our Advantages",
      onClick: () => {
        setOpenDrawer(false);
        handleSelection(null, "our-advantages");
        if (window.location.pathname !== "/") {
          navigate("/");
          setTimeout(() => {
            scrollToSection("our-advantages");
          }, 100);
        } else {
          scrollToSection("our-advantages");
        }
      },
    },
    {
      value: "our-clients",
      label: "Clients",
      onClick: () => {
        setOpenDrawer(false);
        handleSelection(null, "our-clients");
        if (window.location.pathname !== "/") {
          navigate("/");
          setTimeout(() => {
            scrollToSection("our-clients");
          }, 100);
        } else {
          scrollToSection("our-clients");
        }
      },
    },
    {
      value: "download-app",
      label: "Download the App",
      onClick: () => {
        setOpenDrawer(false);
        handleSelection(null, "download-app");
        if (window.location.pathname !== "/") {
          navigate("/");
          setTimeout(() => {
            scrollToSection("download-app");
          }, 100);
        } else {
          scrollToSection("download-app");
        }
      },
    },
    {
      value: "contact-enhanced",
      label: "Contact Us",
      newPage: true,
      onClick: () => {
        setOpenDrawer(false);
        handleSelection(null, "contact-enhanced");
        navigate("/contact");
        setTimeout(() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }, 100);
      },
    },
  ];

  const searchBarStyle = {
    maxHeight: "98px",
    width: "100%",
  }

  return (
    <>
      {!isMobile ? (
        <Grid container direction={"row"} alignItems={"center"} paddingX={isLarge ? "0px" : "96px"} spacing={5}>
          <Grid item md={0.75}>
            <Link to={"/"}>
              <Logo />
            </Link>
          </Grid>
          <Grid item md={3.25} sx={{ maxHeight: "90px", }}>
            <LandingPageSearchBar searchBarStyle={searchBarStyle} hideSearchButton />
          </Grid>
          <Grid item md={6}>
            <Stack direction={"row"} spacing={5} alignItems={"center"} justifyContent={"center"}>

              <RecruiterNavigationButtons />

              <EnhancedButton
                buttonText={t("post_job")}
                variant={"contained"}
                padding={"11px 24px"}
                onClick={() => navigate("/new/job")}
              />
            </Stack>
          </Grid>
          <Grid item md={2}>
            <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}>
              <EnhancedNotificationsDropdown />
              <Box width={"16px"}></Box>
              <ProfileDropdown />
            </Stack>
            {/* <EnhancedButton buttonText="Logout" onClick={() => dispatch(deleteProfile());
dispatch(authLogout());} /> */}
          </Grid>
        </Grid>
      ) : (
        <AuthorizedMobileNavbar
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
        />
      )}
    </>
  );
};

export default RecruiterContent;

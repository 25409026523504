import { Box, Grid } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Hide from '../../components/Hide';
import Show from '../../components/Show';
import ApplicantListView from './components/applicant-list-view/ApplicantListView';
import CategoriesList from './components/categories-list/CategoriesList';
// import emptyPage from '../../assets/empty-page.json'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ErrorPage from '../../components/error-page/ErrorPage';
import Loader from '../../components/loader/Loader';
import { getCategoriesList } from '../../state/actions/categoriesList/categoriesList';
import './CandidatesBank.css';

// const emptyPage = React.lazy(() => import("../../assets/empty-page.json"));

const CandidatesBank = () => {
  const { t } = useTranslation();

  const [showApplicantList, setShowApplicantList] = useState(false);
  const [loadedCategories, setLoadedCategories] = useState(false);

  const loaded = useSelector((state) => state.profile.loaded);

  const dispatch = useDispatch();
  const categoriesList = useSelector((state) => state.categoryList.data);
  const categoriesLoaded = useSelector((state) => state.categoryList.loaded);

  const navigate = useNavigate();
  const isXs = useMediaQuery('(max-width:899.99px)');

  const handleShowComponents = () => {
    if (isXs) {
      setShowApplicantList(true);
    }
  };

  const handleHideComponents = () => {
    if (isXs) {
      setShowApplicantList(false);
    }
  };

  useEffect(() => {
    dispatch(getCategoriesList());
  }, []);

  useEffect(() => {
    if (categoriesLoaded) {
      setLoadedCategories(true);
    }
  }, [categoriesLoaded]);

  const [emptyPage, setEmptyPage] = useState(null);
  useEffect(() => {
    import('assets/empty-page.json').then((data) => setEmptyPage(data));
  }, []);

  return (
    <div className="candiates-bank">
      <Loader data={loadedCategories} width={'100%'} height={500}>
        <Show condition={loadedCategories && categoriesList.length > 0}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={isXs ? 12 : 6} lg={5}>
              <Hide condition={showApplicantList}>
                <CategoriesList handleShowComponents={handleShowComponents} handleHideComponents={handleHideComponents} categorList={categoriesList} />
              </Hide>
            </Grid>
            {showApplicantList ? (
              <>
                <Grid item xs={12} md={isXs ? 12 : 6} lg={7}>
                  <ApplicantListView showAndHideTrash={isXs} handleHideComponents={handleHideComponents} categorList={categoriesList} />
                </Grid>
              </>
            ) : (
              <>
                <Hide condition={isXs}>
                  <Grid item xs={12} md={isXs ? 12 : 6} lg={7}>
                    <Loader data={loadedCategories} width={'500px'} height={500}>
                      <ApplicantListView showAndHideTrash={isXs} handleHideComponents={handleHideComponents} categorList={categoriesList} />
                    </Loader>
                  </Grid>
                </Hide>
              </>
            )}
          </Grid>
        </Show>
      </Loader>
      <Show condition={loadedCategories && categoriesList.length == 0}>
        <Box component="div" padding={8}>
          <ErrorPage
            title={t('oops')}
            description={t('no_categories')}
            action={() => {
              navigate('/');
            }}
            actionText={t('back_to_home')}
            animationData={emptyPage}
          />
        </Box>
      </Show>
    </div>
  );
};
export default CandidatesBank;

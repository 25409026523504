import "./ColoredLogo.css";

import { Box } from "@mui/system";

const ColoredLogo = ({ first, second, fontSize }) => {

    return (
        <Box component="div" className="colored-logo" style={{ fontSize: fontSize ? fontSize : "30px" }}>
            <Box component="span" sx={{ color: first ? first : "#3A3B81" }}>job</Box>
            <Box component="span" sx={{ color: second ? second : "#D6D7FF" }}>link</Box>
        </Box>
    );
}
export default ColoredLogo;
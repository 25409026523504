import {
  Dialog,
  DialogContent,
  Grid,
  InputBase,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import { ArrowRight } from "phosphor-react";
import { useEffect, useState } from "react";

import Fuse from "fuse.js";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  getRecruiterJobs,
  inviteToJobByEmail,
  translateField
} from "../../../services/Helper";
import EnhancedButton from "../../../views/pages/yahya/components/enhanced-button/EnhancedButton";
import EnhancedMiniJobCard from "./../../../views/pages/abdelrahman/EnhancedMiniJobCard/EnhancedMiniJobCard";

const InviteDialog = ({ open, setOpen, applicant_id }) => {
  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const role = useSelector((state) => state.profile.role);
  const { t, i18n } = useTranslation();
  const handleClose = () => {
    setOpen(false);
  };
  const handleSearch = () => {
    if (
      searchTerm?.trim === "" ||
      searchTerm === "" ||
      searchTerm == undefined ||
      searchTerm == null
    ) {
      setFilteredJobs(jobs);
    } else {
      const options = {
        includeScore: true,
        includeMatches: true,
        keys: [`JobTitle.name_${i18n.language}`],
      };
      const fuse = new Fuse(jobs, options);
      const result = fuse.search(searchTerm);

      const temp = result.map((one) => one.item);
      setFilteredJobs(temp);
    }
  };
  const renderJobs = () => {
    console.log("ALL JOBS", filteredJobs);
    return filteredJobs?.map((job) => {
      console.log(job);
      return (
        <>
          <EnhancedMiniJobCard
            title={translateField(job?.JobTitle, "name")}
            company={job?.Recruiter?.company_name}
            logo={job?.Recruiter?.image}
            date={job?.created_at}
            recruiter
            noOfApplicants={job?.count}
            tags={[
              translateField(job?.WorkStyle, "name"),
              translateField(job?.ExperienceType, "name"),
            ]}
            location={`${translateField(job?.City, "name")}, ${translateField(
              job?.Country,
              "name"
            )}`}
            jobId={job?.id}
            isSaved={job?.is_saved}
            isApplied={job?.is_applied}
            handleInvite={() => inviteToJobByEmail(job.id, applicant_id)}
            invite
          />
        </>
      );
    });
  };

  useEffect(() => {
    getRecruiterJobs(1, 1, 15, role).then((res) => {
      setJobs(res.rows);
      setFilteredJobs(res.rows);
    });
  }, []);

  return (
    <Dialog open={open} scroll={"paper"}>
      <DialogContent sx={{ margin: "0", padding: "0" }}>
        <Paper
          sx={{
            padding: "24px",
            width: "600px",
            maxWidth: "600px",
            overflow: "hidden",
          }}
        >
          <Grid container alignItems={"center"} rowSpacing={2}>
            <Grid
              item
              xs={12}
              container
              alignItems={"center"}
              columnSpacing={1}
              sx={{ height: "50px" }}
            >
              <Grid item>
                <ArrowRight size={20} color={"#3A3B81"} />
              </Grid>
              <Grid item>
                <Typography
                  component={"span"}
                  sx={{ font: "normal normal bold 20px/24px Urbanist" }}
                >
                  {t("invite-to-jobs")}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container alignItems={"center"} columnSpacing={1}>
              <Grid item xs={true}>
                <InputBase
                  fullWidth
                  placeholder={t("enter_job_title")}
                  sx={{
                    border: " 1px solid #E4E4E4",
                    borderRadius: "8px",
                    padding: "2px 16px",
                  }}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </Grid>
              <Grid item xs={2.2}>
                <EnhancedButton
                  buttonText={t("search")}
                  padding={"10.5px 20px"}
                  onClick={handleSearch}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={1}>{jobs && renderJobs()}</Stack>
            </Grid>
            <Grid
              item
              xs={12}
              container
              columnSpacing={2}
              justifyContent={"flex-end"}
            >
              <Grid item xs={2.5}>
                {" "}
                <EnhancedButton
                  buttonText={t("cancel")}
                  variant={"outlined"}
                  padding={"8.5px 20px"}
                  onClick={handleClose}
                />{" "}
              </Grid>
              <Grid item xs={2.5}>
                {" "}
                <EnhancedButton
                  buttonText={t("done")}
                  onClick={handleClose}
                  padding={"10.5px 16px"}
                />{" "}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </DialogContent>
    </Dialog>
  );
};

export default InviteDialog;

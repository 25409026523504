import { getCategory, addCategory, editCategory, deleteCategory } from "../../../services/Helper";
import {
    GET_CATEGORIES_LIST,
    ADD_CATEGORY,
    EDIT_CATEGORY,
    DELETE_CATEGORY,
    GET_ID_CATEGORY,
    SET_ACTIVE
} from './types';
import _, { map } from "underscore";



export const getCategoriesList = () => async (dispatch) => {
    const data = await getCategory()
    const payload = {
        data: data.rows
    }
    dispatch({ type: GET_CATEGORIES_LIST, payload })
}

export const addNewCategory = (newCategory) => async (dispatch) => {
    const data = await addCategory(newCategory);
    const payload = data
    dispatch({ type: ADD_CATEGORY, payload })
}

export const editCategories = (newCategory, idCategory, categories) => async (dispatch) => {
    const data = await editCategory(newCategory, idCategory);

    // 


    let filterIndex = _.findLastIndex(categories, {
        id: data.id,
    });

    categories[filterIndex] = data;

    const payload = [...categories];

    // const payload = {
    //     data: [
    //         ...data2,
    //         data.data
    //     ]
    // }

    // const payload = data

    dispatch({ type: EDIT_CATEGORY, payload })
}

export const deleteCategories = (idCategory) => async (dispatch) => {
    await deleteCategory(idCategory);
    const data = await getCategory()
    const payload = {
        data: data.rows
    }
    dispatch({ type: DELETE_CATEGORY, payload })
}

export const getIdCategory = (idCategory) => (dispatch) => {
    const payload = idCategory
    dispatch({ type: GET_ID_CATEGORY, payload })
}

export const setActiveClass = (index) => (dispatch) => {
    const payload = index;
    dispatch({ type: SET_ACTIVE, payload })
}
import { getNewsList, readAllNews as readAllNewsHelper } from "../../../services/Helper"
import { OPENED } from "../notification/types";
import { SET_NEWS, OPEN_NEWS } from "./types"

export const getNews = (limit) => async (dispatch, getState) => {
    const role = getState().profile.role.name;
    if (role !== "GUEST_USER" && role !== "REGISTERD_USER") {
        const data = await getNewsList(role, limit)
        const payload = {
            limit,
            data: data.rows,
            count: data.meta.totalCount,
            new: data?.unread_news_count > 0
        }
        
        dispatch({ type: SET_NEWS, payload })
    }

}

export const openNews = (limit) => async (dispatch, getState) => {
    const payload = {
        opened: false
    }
    dispatch({ type: OPEN_NEWS, payload })
}

export const readAllNews = () => async (dispatch, getState) => {
    const role = getState().profile.role.name;
    if (role !== "GUEST_USER" && role !== "REGISTERD_USER") {
        const limit = getState().notifications.limit;
        const data = await readAllNewsHelper(role, limit)
        const payload = {
            limit: limit,
            data: data.rows,
            count: data.meta.totalCount,
            new: false
        }
        dispatch({ type: SET_NEWS, payload })
    }

} 
import React from 'react';
import ErrorPage from '../../../components/error-page/ErrorPage';
// import animateData from "../../../assets/empty-page.json"
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';

// const animateData = React.lazy(() => import("../../../assets/empty-page.json"));

const Page401 = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const [animateData, setAnimateData] = useState(null);
    useEffect(() => {
        import("assets/empty-page.json").then(data => setAnimateData(data));
    }, [])

    return (
        <Grid container justifyContent="center" alignItems="center" spacing={1} paddingTop={3} sx={{ height: "100%" }}>
            <Grid item xs={12}>
                <ErrorPage
                    title={t("401")}
                    animationData={animateData}
                    actionText={t("back_to_home")}
                    action={() => navigate("/")}
                />
            </Grid>
        </Grid>

    );
};

export default Page401;
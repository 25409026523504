import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import ApplicantAvatarCard from "../../components/applicant-avatar-card/ApplicantAvatarCard";
import Pagination from "../../components/pagination/Pagination";

import "./Followers.css";
import { getFollowers, translateField } from "../../services/Helper";
import Loader from "../../components/loader/Loader";
import Show from "../../components/Show";
import { Box } from "@mui/system";
import ErrorPage from "../../components/error-page/ErrorPage";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import emptyPage from "../../assets/empty-page.json";

// const emptyPage = React.lazy(() => import("../../assets/empty-page.json"));

const Followers = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [numberOfPages, setNumberOfPages] = useState(1);
  const [page, setPage] = useState(1);
  const [followers, setFollowers] = useState([]);
  const [followersLoaded, setFollowersLoaded] = useState(false);

  useEffect(() => {
    fetchPage(1);
  }, []);

  const fetchPage = (pageNumber) => {
    getFollowers(15, pageNumber).then((data) => {
      setFollowers(data?.rows);
      setNumberOfPages(data?.meta?.totalNumberOfPages);
      setFollowersLoaded(true);
    });
  };

  const changePage = (newPage) => {
    fetchPage(newPage);
    setPage(newPage);
  };

  const [emptyPage, setEmptyPage] = useState(null);
  useEffect(() => {
    import("assets/empty-page.json").then(data => setEmptyPage(data));
  }, [])

  return (
    <>
      <Loader data={followersLoaded} height={"400px"} width={"100%"}>
        <Show condition={followersLoaded && followers.length > 0}>
          <Grid container direction="column" className="followers">
            <Grid item className="followers-section">
              <Grid container direction="row" spacing={2}>
                {followers.map((follower) => {
                  return (
                    <Grid item xs={12} lg={4} className="one-follower">
                      <ApplicantAvatarCard
                        id={follower.id}
                        // name={`${follower.first_name} ${follower.second_name}`}
                        firstName={follower.first_name}
                        secondName={follower.second_name}
                        jobTitle={translateField(follower.JobTitle, "name")}
                        location={`${translateField(
                          follower?.City,
                          "name"
                        )}, ${translateField(
                          follower?.City?.Governorate?.Country,
                          "name"
                        )}`}
                        redirect
                        pageLink="followers"
                        img={follower.image}
                        showCardTag
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            {numberOfPages > 1 && (
              <Grid item>
                <Pagination
                  pagesManager={{
                    page: page,
                    setPage: changePage,
                    numberOfPages: numberOfPages,
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Show>
      </Loader>
      <Grid container direction="column">
        <Show condition={followersLoaded && followers.length == 0}>
          <Box component="div" padding={8}>
            <ErrorPage
              title={t("oops")}
              description={t("no_followers")}
              action={() => {
                navigate("/");
              }}
              actionText={t('back_to_home')}
              animationData={emptyPage}
            />
          </Box>
        </Show>
      </Grid>
    </>
  );
};
export default Followers;

import { Grid, Typography, Avatar, Divider, Paper, Box, Stack } from "@mui/material";
import { Circle } from "phosphor-react";
import { useTranslation } from "react-i18next";
import JobTag from "../job-tag/JobTag";
import ShareJob from "../share-job/ShareJob";
import "./MiniJobCard.scss";
import Show from "./../Show";
import { Buildings } from "phosphor-react";
import ApplicationStatus from "../application-status/ApplicationStatus";
import Loader from "../loader/Loader";

const MiniJobCard = ({
  noOfApplicants,
  jobTitle,
  location,
  company,
  logo,
  type,
  date,
  url,
  showLogo,
  active,
  showActive,
  micro,
  selectable,
  onClick,
  selected,
  className,
  showStatus,
  status
}) => {
  const { t, i18n } = useTranslation();
  const getDaysAgo = (date) => {
    const date1 = new Date(date);
    const date2 = new Date();
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };
  const daysAgo = getDaysAgo(date)
  return (
    <Loader data={company || jobTitle} width={'100%'} height={'200px'}>
      <Paper
        className={`job-card ${className} ${!active ? "inactive" : ""}`}
        sx={{
          // height: micro && "98px",
          cursor: selectable ? "pointer" : "default",
          backgroundColor: selected ? "#3A3B811A" : "#FFF",
          boxShadow: selected ? "0px 0px 12px #00000038" : ""
        }}
        elevation={0}
        direction={"column"}
        justifyContent="space-between"
        component={Stack}
        onClick={onClick}
      >
        {/* {!active && <div className="job-overlay"></div>} */}
        <Grid container>
          <Grid item xs={12} container className="job-section" >
            {showLogo && (
              <Grid item style={{ position: "relative" }} md={3}>
                {!active && <div className="job-overlay"></div>}
                <Show condition={logo}>
                  <Avatar className="job-logo" src={logo} />
                </Show>
                <Show condition={!logo}>
                  <Buildings size={60} />
                </Show>
              </Grid>
            )}
            <Grid item md={9} xs={9} className="job-title">
              <Typography className="job-header">{jobTitle}</Typography>
              <Typography className="job-subtitle">{company}</Typography>
              <Typography className="job-subtitle">{location}</Typography>
            </Grid>
          </Grid>
          {!micro && showActive && (
            <Grid item sx={{ color: active ? "#1E9F38" : "#919191" }}>
              <Circle weight="fill" className="job-active" />
            </Grid>
          )}
        </Grid>

        {!micro && (
          <Stack
            container
            alignItems="baseline"
            justifyContent="space-between"
            className="job-section tags-share-job-stack"
            rowGap={1}
            divider={<Divider light flexItem />}
          >
            <Box
              className="tags-section"
            >
              <Typography className="job-applicants">
                {t("applicants", { count: noOfApplicants })}
              </Typography>
              <JobTag
                text={daysAgo === 0 ? t("today") : t("days", { count: daysAgo })}
              />
              <JobTag text={type} />
            </Box>
            {!showStatus && (
              <Box className="job-section mini-card-share-section" >
                <ShareJob url={url} />
              </Box>
            )}
            {showStatus && (
              <Box container className="job-section" >
                <ApplicationStatus status={status} />
              </Box>
            )}
          </Stack>
        )}
      </Paper>
    </Loader>
  );
};
export default MiniJobCard;

import { JOB_POST_STEPPER } from "../../constants/constants";

export const stepperData = [
    {
        no: 1,
        title_en: "Basic Details",
        title_ar: "المعلومات الأساسية",
        name: JOB_POST_STEPPER.BASIC_DETAILS,
        next: 2,
        prev: false,
    },
    {
        no: 2,
        title_en: "Job Details",
        title_ar: "تفاصيل العمل",
        name: JOB_POST_STEPPER.JOB_DETAILS,
        next: 3,
        prev: 1,
    },
    {
        no: 3,
        title_en: "Review Job",
        title_ar: "مراجعة العمل",
        name: JOB_POST_STEPPER.REVIEW_JOBS,
        next: false,
        prev: 2,
    },
];
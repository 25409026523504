import "./ErrorPage.css";

import { Box, Button, Grid, Typography } from "@mui/material"
import Lottie from "lottie-react";
import DecisionButton from "../decision-button/DecisionButton";
import Hide from "../Hide";

const ErrorPage = ({ hideAction, title, description, action, actionText, animationData, mini, height }) => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData
    };

    return (
        <>
            <Grid container justifyContent="center" align="center" className={["error-page", mini ? "mini" : ""].join(" ")}>
                <Grid item xs={12}>
                    <Lottie
                        isClickToPauseDisabled
                        loop={true}
                        autoPlay={true}
                        animationData={animationData}
                        style={{ width: "auto", height: mini ? 200 : height ? height : 280 }}
                        //width={"auto"}
                        //height={mini ? 200 : height ? height : 280}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography className="title">
                        {title}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography className="description">
                        {description}
                    </Typography>
                </Grid>
                <Hide condition={mini || hideAction}>
                    <Grid item xs="auto">
                        <DecisionButton onClick={() => action()}>
                            <Typography className="action-text">
                                {actionText}
                            </Typography>
                        </DecisionButton>
                    </Grid>
                </Hide>
            </Grid>
        </>
    )
}
export default ErrorPage;
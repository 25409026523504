import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as moment from 'moment'
import { getStaticPages, translateField } from '../../../../../services/Helper';
import { Box, Grid, Typography } from '@mui/material';
import { Stack } from 'phosphor-react';

import "../terms/Terms.scss";
const PrivacyPolicy = () => {

    const { t } = useTranslation();


    const [policy, setPolicy] = useState(null);

    const handleUpdatedDate = (date) => {
        if (date) {
            const d = new Date(date);
            return moment(d).format('YYYY MMMM');
        } else {
            return '';
        }

    }

    useEffect(() => {
        const handleGetPrivacyPolicy = async () => {
            return await getStaticPages('PrivacyPolicy');
        }

        handleGetPrivacyPolicy().then((data) => {
            setPolicy(data.length > 0 ? data[0] : null);
        })
    }, [])

    return (
        <>
            <Grid container direction="column" className="account-settings-container">
                <Grid item xs={12}>
                    <Typography className="settings-title">{t("privacy_policy")}</Typography>
                    <Typography className="updated-on" >Updated {handleUpdatedDate(policy?.updated_at)} </Typography>

                    <Box style={{ fontFamily: 'Urbanist' }} component="div" dangerouslySetInnerHTML={{ __html: translateField(policy, "value") }}>
                    </Box>
                    
                </Grid>
            </Grid>
        </>
    );
};

export default PrivacyPolicy;
import React, { useState } from 'react';
import { Grid, Box, Typography, Avatar } from '@mui/material';
import UserAvatar from '../../../components/user-avatar/UserAvatar';
import Hide from '../../../components/Hide';
import Show from '../../../components/Show';
import './NotificationListCard.scss';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { readSingleNews, readSingleNotification } from '../../../services/Helper';
import { useSelector } from 'react-redux';
import { Buildings } from 'phosphor-react';
import dateFormat from "dateformat";
import notification from "../notification.svg"

const NewsListCard = ({ image, message, status, date, job_id, id, applicant_id, recruiter_id, redirect }) => {

    const navigate = useNavigate();
    const role = useSelector((state) => state.profile.role?.name);
    const displayDate = new Date(date);
    const displayDateString = dateFormat(displayDate, "dd mmm yy, h:MM tt")

    const openNotification = () => {
        readSingleNews(role, id).then((response) => {
            navigate(`/news/${redirect}`)
        })
    }

    return (
        <Box className={status == 0 ? "notification-list not-readed-card" : "notification-list"} onClick={() => openNotification()}>
            <Grid container >
                <Grid item container xs={12} md={9} alignItems={"center"} spacing={1} >
                    <Grid item xs="auto">
                        <Avatar sx={{
                            width: "50px",
                            height: "50px",
                            display: "inline-block",
                            objectFit: "contain",
                            backgroundColor: "#3a3b81",
                            padding: !image ? "10px" : "0px"
                        }}
                            src={image ? image : notification} />
                    </Grid>
                    <Grid item>
                        <span className='title'>{message}</span>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={3} container justifyContent={"flex-end"} alignItems={"center"}>
                    <Typography className='title'>{displayDateString}</Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

export default NewsListCard
import { Plus } from "phosphor-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../../../components/page-header/PageHeader";
import "./AllJobsHeader.css";

const AllJobsHeader = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    return (
        <>
            <PageHeader
                showButton
                title={t("all_jobs")}
                icon={<Plus color="#3A3B81" />}
                onClick={() => navigate("new/job")}
            />
        </>
    );
}
export default AllJobsHeader;
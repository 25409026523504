import { Box, Button, FormControl, FormGroup, Grid, Typography } from '@mui/material';
import { At, Password } from 'phosphor-react';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { submitChangeEmail, submitOTP, verifyEmail } from 'services/AuthHelper';
import { toastr } from 'react-redux-toastr';
import { authLogout, authSubmitOTP } from 'state/actions/auth/auth';
import IconInput from 'components/IconInput';
import AuthPageWrapper from 'components/auth-page-wrapper/AuthPageWrapper';
import { fetchProfileAndAssignRole } from 'state/actions/profile/profile';
import { AUTH_SUBMIT_OTP_SUCCESSFULL } from 'state/actions/auth/types';
const VerifyOTP = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const otp = useSelector((state) => state.otp);

  const schema = yup
    .object({
      otp: yup
        .string()
        .matches(/^[0-9]+$/, 'Must be only digits')
        .min(6, 'Must be exactly 6 digits')
        .max(6, 'Must be exactly 6 digits')
        .required(t('required_input')),
    })
    .required();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema),
  });
  console.log(location.pathname);
  const onSubmit = async (data) => {
    console.log('OTP', otp);
    try {
      switch (otp.type) {
        case 'VERIFY_EMAIL': {
          const submitOTPResult = await submitOTP(otp.email, data.otp, otp.type);
          // const verifyEmailResult = await verifyEmail(submitOTPResult.token);
          dispatch({ type: AUTH_SUBMIT_OTP_SUCCESSFULL, payload: submitOTPResult.data });
          toastr.success(submitOTPResult.message);
          dispatch(fetchProfileAndAssignRole(navigate));
          // navigate("/join-us-reason");

          break;
        }
        case 'RESET_PASSWORD':
          dispatch(authSubmitOTP(navigate, otp.email, data.otp, otp.type, '/reset-password'));
          break;
      }
    } catch (error) {
      toastr.error(error.response?.data?.error?.message);
      // navigate('/login');
    }
  };

  return (
    <AuthPageWrapper single={true}>
      <Grid className="signin-form" container item xs={12} justifyContent="center" alignItems="center">
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <FormControl className="mb24">
              <Typography className="title">Joblink</Typography>
              <Typography className="desc">{otp.title}</Typography>
            </FormControl>
            <IconInput
              label={'OTP'}
              id="email"
              placeholder={'Enter OTP number'}
              type="text"
              icon={Password}
              className="mb16"
              fieldName="otp"
              register={register}
              error={errors.otp}
            />
            <FormControl className="mb24">
              <Button type="submit" className="login-btn" variant="contained">
                {'Submit'}
              </Button>
            </FormControl>

            <FormControl>
              <Typography variant="div" className="navigation-text">
                {location.pathname === '/verify-otp' ? '' : t('remember_password')}{' '}
                <Box component={Link} className="navigation-btn" to="/login">
                  {t('back_to_login')}
                </Box>
              </Typography>
            </FormControl>
          </FormGroup>
        </form>
      </Grid>
    </AuthPageWrapper>
  );
};

export default VerifyOTP;

import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import moment from "moment";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import UserAvatar from 'components/user-avatar/UserAvatar';
import { translateField } from 'services/Helper';
import ProfileExperience from '../applicant-profile/components/profile-experience/ProfileExperience';
import ProfileContactInfo from './../applicant-profile/components/profile-contact-info/ProfileContactInfo';
import "./ApplicantCv.scss";



const ApplicantCv = ({ userData }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    console.log(userData?.User?.CvExperiences?.JobTitle)

    const viewFullProfileHandler = () => {
        navigate(`/applicant/${userData.User.id}`)
    }
    return (
        <Stack
            className="applicant-cv applicant-profile"
            spacing={2}
        >
            <Stack
                component="section"
                className="applicant-cv-info"
                spacing={2}
                divider={<Divider orientation="horizontal" flexItem />}
            >
                <Stack
                    direction={"row"}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Stack>
                        <UserAvatar
                            showAvatar
                            showTitle
                            showName
                            name={`${userData?.User?.first_name} ${userData?.User?.second_name}`}
                            title={`${translateField(userData?.User?.JobTitle, "name")}`}
                            size="large"
                            avatar={userData?.User?.image}
                        />
                    </Stack>
                    <Stack>
                        <Button
                            onClick={viewFullProfileHandler}
                            className="btn btn-dark view-profile-btn"
                        >{t('view-full-profile')}</Button>
                    </Stack>
                </Stack>
                <Stack>
                    <ProfileContactInfo
                        user={userData?.User}
                        justifyLeft={true}
                        searchPage
                    />
                </Stack>
                <Stack>
                    <Typography className='title'>{t("bio")}</Typography>
                    <Typography className='content'>{userData?.User?.bio}</Typography>

                </Stack>
                <Stack>
                    <Typography className='title'>{t("work_experience")}</Typography>
                    {userData?.User?.CvExperiences.map((exp) => (

                        <ProfileExperience
                            startDate={`${moment().month(exp?.from_month - 1).format("M")} ${exp?.from_year}`}
                            from_year={exp?.from_year}
                            to_year={exp?.to_year}
                            from_month={moment().month(exp?.from_month - 1).format("M")}
                            to_month={moment().month(exp?.to_month - 1).format("M")}
                            endDate={exp?.to_year ? `${moment().month(exp?.to_month - 1).format("M")} ${exp?.to_year}` : "Present"}
                            expTitle={exp?.JobTitle ? translateField(exp?.JobTitle, "name") : exp?.job_title}
                            expPlace={exp?.company_name}
                            companyType={translateField(exp?.JobCategory, "name")}
                            workStyle={translateField(exp?.ExperienceType, "name")}
                            expInfo={exp?.description}
                            careerLevel={translateField(exp?.CareerLevel, "name")}
                            searchPage
                        />
                    ))}
                </Stack>
            </Stack>
        </Stack>
    )
}

export default ApplicantCv
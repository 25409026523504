import React from 'react';
import ErrorPage from '../../../components/error-page/ErrorPage';
import Error403 from "../../../assets/lootie-files/403.json"
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
const Page403 = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    return (
        <Grid container justifyContent="center" alignItems="center" align="center" spacing={1} paddingTop={3} sx={{height:"100%"}}>
            <Grid item xs={12}>
                <ErrorPage
                    title={t("401")}
                    animationData={Error403}
                    actionText={t("back_to_home")}
                    action={() => navigate("/")}
                    height={320}
                />
            </Grid>
        </Grid>

    );
};

export default Page403;
import React, { useState, useEffect } from "react";

import { Box, Typography, Container } from "@mui/material";

import LinkText from "./../../../../components/link-text/LinkText";
import { useTranslation } from "react-i18next";
import _ from 'underscore';

const ProfileOtherLinks = ({ user }) => {
  const [links, setLinks] = useState([]);

  const { t, i18n } = useTranslation();

  useEffect(() => {

    let links = [...user.CvSocialMedia, ...user.CvPortfolios];
    let filteredLinks = _.where(links, { name: "Others" })
    setLinks(filteredLinks);
  }, []);

  return (<>
    {length > 0 &&
      <Box component="section" className="profile-section-other-links">
        <Typography component="span" className="profile-section-title">
          {t("other_links")}
        </Typography>
        <Box className="profile-section-links" my={1}>
          {links.map((link) => {
            return <LinkText text={link.link} />;
          })}
        </Box>
      </Box >
    }
  </>
  )

  // return (
  //   <Box component="section" className="profile-section-other-links">
  //     <Typography component="span" className="profile-section-title">
  //       {t("other_links")}
  //     </Typography>
  //     <Box className="profile-section-links">
  //       {map((link) => {
  //         
  //         return link.name === "Others" && <LinkText text={link.link} />;
  //       })}
  //     </Box>
  //   </Box>
  // );
};

export default ProfileOtherLinks;

import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import HeroSection from "../../../yahya/components/hero-section/HeroSection";
import "./About.css";
import ContactUsSection from "./components/contact-us-section/ContactUsSection";
import NeedhelpSection from "./components/need-help-section/NeedhelpSection";
import OurVissionMission from "./components/our-vision-mission/OurVissionMission";
import StatsInfoSection from "./components/stats-info-section/StatsInfoSection";

const About = () => {
  const mdMatches = useMediaQuery("(min-width:701px) and (max-width:1000px)");
  const smMatches = useMediaQuery("(max-width:700px)");

  const { t } = useTranslation();

  return (
    <Box >
      <Stack
        // paddingX={smMatches ? "24px" : "24px"}
        direction={"column"}
        spacing={10}
        alignItems={"center"}
      >
        <HeroSection small={true}>
            <Typography className={"hero-section-title colored"}>
              {t("about_us_hero")}
            </Typography>
          <Typography className={"hero-section-description"}>
            {t("about_us_hero_desc")}
          </Typography>
        </HeroSection>
        <Stack alignItems={"center"} sx={{ width: "90%" }} spacing={2}>
          <Box>
            <ContactUsSection />
          </Box>
          <Box>
            <OurVissionMission />
          </Box>
        </Stack>
        <Box sx={{ width: "100%" }}>
          <StatsInfoSection />
        </Box>
        <Box>
          <NeedhelpSection />
        </Box>
      </Stack>
    </Box>
  );
};

export default About;

import { LOADING_FINISHED, LOADING_STARTED } from "../../actions/loading/types";

let initialState = {
    loading: false
}

export const loadingReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOADING_STARTED:
            return { ...state, loading: true };
        case LOADING_FINISHED:
            return { ...state, loading: false };
        default:
            return state;
    }
}

export default loadingReducer;

export const ROUND_POSITION = {
  TOP_LEFT: "TOP_LEFT",
  BOTTOM_RIGHT: "BOTTOM_RIGHT",
};

export const COMPANY_STEPPER = {
  CONTACT_INFO: "CONTACT_INFO",
  COMPANY_INFO: "COMPANY_INFO",
  REVIEW_SUBMIT: "REVIEW_SUBMIT",
};

export const APPLICANT_STEPPER = {
  GENERAL_INFO: "GENERAL_INFO",
  CONTACT_INFO_PORTFOLIO: "CONTACT_INFO_PORTFOLIO",
  EDUCATION: "EDUCATION",
  EXPERIENCE: "EXPERIENCE",
  SKILLS: "SKILLS",
  CAREER_INTERESTS: "CAREER_INTERESTS",
};

export const JOB_POST_STEPPER = {
  BASIC_DETAILS: "BASIC_DETAILS",
  JOB_DETAILS: "JOB_DETAILS",
  REVIEW_JOBS: "REVIEW_JOBS",
};
export const APPLICANT_PROFILE_EDIT_STEPPER = {
  GENERAL_INFO: "GENERAL_INFO",
  CONTACT_INFO_PORTFOLIO: "CONTACT_INFO_PORTFOLIO",
  EDUCATION: "EDUCATION",
  EXPERIENCE: "EXPERIENCE",
  SKILLS: "SKILLS",
};

export const STEP_STATUS = {
  PENDING: "PENDING",
  NOT_STARTED: "NOT_STARTED",
  COMPLETED: "COMPLETED",
};

export const STATUS_CODES = {
  SUCCESS: 200,
  UNAUTHORIZED: 401,
  BLOCKED: 402,
  NOT_FOUND: 404,
  BAD_REQUEST: 400,
};

export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
export const LOCALES_MONTHS = [
  {
    name_en: "Jan",
    name_ar: "يناير",
  },
  {
    name_en: "Feb",
    name_ar: "فبراير",
  },
  {
    name_en: "Mar",
    name_ar: "مارس",
  },
  {
    name_en: "Apr",
    name_ar: "ابريل",
  },
  {
    name_en: "May",
    name_ar: "مايو",
  },
  {
    name_en: "Jun",
    name_ar: "يونيو",
  },
  {
    name_en: "Jul",
    name_ar: "يوليو",
  },
  {
    name_en: "Aug",
    name_ar: "أغسطس",
  },
  {
    name_en: "Sep",
    name_ar: "سبتمبر",
  },
  {
    name_en: "Oct",
    name_ar: "أكتوبر",
  },
  {
    name_en: "Nov",
    name_ar: "نوفمبر",
  },
  {
    name_en: "Dec",
    name_ar: "ديسمبر",
  },
];
export const FILE_TYPES = [
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/msword",
  "application/pdf",
  "image/png",
  "image/jpg",
  "image/jpeg",
];

export const IMAGE_TYPES = ["image/png", "image/jpg", "image/jpeg"];

export const PRIVATE_FILES =
  "?sv=2020-08-04&ss=bfqt&srt=sco&sp=rwdlacupitfx&se=2030-03-04T20:24:46Z&st=2022-03-04T12:24:46Z&spr=https&sig=wxQMD3PrF%2BWJ3JzJ8s%2F64sPTWcg7PMNbvRju9JR8h9Y%3D";

export const SALARY_TYPES = {
  NEGOTIABLE: "NEGOTIABLE",
  SALARY_RANGE: "SALARY_RANGE",
  FIXED: "FIXED",
  NET_SALARY: "NET_SALARY",
};

export const SALARY_TYPES_ID = {
  1: SALARY_TYPES.NEGOTIABLE,
  2: SALARY_TYPES.SALARY_RANGE,
  3: SALARY_TYPES.FIXED,
  4: SALARY_TYPES.NET_SALARY,
};

export const APPLY_TYPES = [
  { name_en: "Joblinks hub", name_ar: "تقديم من خلال جوب لينك", id: "EASY_APPLY" },
  { name_en: "Apply with external link", name_ar: "تقديم برابط خارجي", id: "EXTERNAL_APPLY" }
]


export const HIDDEN_OVERFLOW_ROUTES = [
  "/saved-jobs",
  "/applied-jobs",
  "/browsing-jobs",
];

export const LANGUAGES = [
  { code: "EN", name_en: "English", name_ar: "الانجليزية", icon: "GB", locale: "us" },
  { code: "AR", name_en: "Arabic", name_ar: "العربية", icon: "SA", locale: "sa" },
];

export const MAIN_PAGES = [
  "/",
  "/about",
  "/terms",
  "/privacy",
  "/help",
  "/contact",
];

import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { t } from "i18next";
import { Briefcase, CheckCircle } from "phosphor-react";
import FloatingNotificationCard from "../../../yahya/components/hero-section/components/floating-notification-card/FloatingNotificationCard";
import HeroSection from "../../../yahya/components/hero-section/HeroSection";
import LandingPageSearchBar from "../landing-page-search-bar/LandingPageSearchBar";
import "./ApplicantHeroSection.css";



const ApplicantHeroSection = () => {
    const mdMatches = useMediaQuery('(min-width:1200px)');
    const heroSectionCards = [];


    const leftCard = <FloatingNotificationCard
        icon={<Briefcase size={24} color={"#FFFF"} />}
    >
        <Typography
            sx={{
                font: " normal normal 600 12px/19px Urbanist"
            }}
        >
            {t("congratulations")}
            <br />
            <Typography
                sx={{
                    color: "#3A3B81",
                    font: "normal normal bold 16px/19px Urbanist"
                }}
            >
                {t("you-got-the-job")}
            </Typography>
        </Typography>
    </FloatingNotificationCard>

    const rightCard = <FloatingNotificationCard
        icon={<CheckCircle size={24} color={"#FFFF"} />}
    >
        <Typography
            sx={{
                font: "normal normal 600 12px/19px Urbanist"
            }}
        >
            {t("well-done")}
            <br />
            <Typography
                sx={{
                    color: "#3A3B81",
                    font: "normal normal bold 16px/19px Urbanist"
                }}
            >
                {t("shortlisted")}
            </Typography>
        </Typography>
    </FloatingNotificationCard>


    return (
        <Box component={"section"} id="home" >
            <Grid container direction={"column"}>
                <Grid item>
                    <HeroSection
                        leftCard={leftCard}
                        rightCard={rightCard}
                        heroSectionCards={[<LandingPageSearchBar />]}
                    >

                        <Stack mt={2} align={"center"} spacing={3} >
                            <Box>
                                <Typography
                                    className={"hero-section-title"}
                                >
                                    {t("find_your")}
                                    <br />
                                    <Typography
                                        className={"hero-section-title colored"}
                                    >
                                        {t("dream_job")}
                                        {" "}
                                    </Typography>
                                    {t("just_click")}
                                </Typography>
                            </Box>
                            <Typography
                                className={"hero-section-description"}
                            >
                                {t("hero_desc")}
                            </Typography>
                        </Stack>
                    </HeroSection>
                </Grid>
                {!mdMatches &&
                    <Grid item xs={12}>
                        <LandingPageSearchBar />
                    </Grid>
                }
            </Grid>
        </Box >
    )
}
export default ApplicantHeroSection;
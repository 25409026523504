import { Box, Grid, IconButton } from "@mui/material";
import { PencilSimple } from "phosphor-react";
import { getMonthName, translateField } from "../../../../services/Helper";
import { useTranslation } from "react-i18next";
import { LOCALES_MONTHS } from './../../../../constants/constants';


const InfoBox = (props) => {
    const { t, i18n } = useTranslation();

    const data = props?.data;
    
    let from_monthToRender = ""
    let to_monthToRender = ""

    const renderDate = () => {
        LOCALES_MONTHS.map((month) => {
            if (month.name_en === getMonthName(data?.from_month))
                from_monthToRender = translateField(month, "name")
            if (month.name_en === getMonthName(data?.to_month))
                to_monthToRender = translateField(month, "name")
        })
        let date = '';
        if (data.to_year && data.to_month) {
            date = from_monthToRender + " " + data?.from_year + " - " +
                to_monthToRender + " " + data?.to_year;
        } else {
            date = (i18n.language === "ar" ? " منذ" : "From ") + from_monthToRender + " " + data?.from_year + (i18n.language === "ar" ? " - حاليا" : " - Present");
        }
        return date;
    }


    return (
        <Grid container item className="grey-box">
            <Grid item xs={12}>
                <Grid item container justifyContent="space-between" alignItems="center">
                    <Box component="div" className="date">
                        {renderDate()}
                    </Box>
                    <IconButton size="small" onClick={props.handleEdit}>
                        <PencilSimple />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Box component="span" className="info">
                    {translateField(data?.CareerLevel, "name")}, {data?.job_title ? data.job_title : translateField(data.JobTitle, "name")} {t("at")} {data?.company_name}
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box component="div" className="type">
                    {translateField(data?.ExperienceType, "name")}{", "}{translateField(data?.JobCategory, "name")}
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box component="span" className="description">
                    {data?.description}
                </Box>
            </Grid>
        </Grid>
    )
}
export default InfoBox;
import { useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import "./ApplicantLandingPage.css";

import ApplicantHeroSection from "./components/applicant-hero-section/ApplicantHeroSection";
import FeaturedCompaniesSection from "./components/featured-companies-section/FeaturedCompaniesSection";

import { useTranslation } from "react-i18next";
import DashboardSection from "../abdelrahman/DashboardSection/DashboardSection";
import ApplicantDashboardBrowse from "./components/applicant-dashboard-browse/ApplicantDashboardBrowse";

const ApplicantLandingPage = () => {
  const mdMatches = useMediaQuery("(min-width:701px) and (max-width:1300px)");
  const smMatches = useMediaQuery("(max-width:700px)");
  const { t } = useTranslation();

  return (
    <Box >
      <ApplicantHeroSection />
      <Box className="browse-jobs">
        <DashboardSection type="Interests" />
      </Box>
      <Box className="browse-jobs">
        <DashboardSection type="FollowedCompany" />
      </Box>

      <ApplicantDashboardBrowse />
      <FeaturedCompaniesSection from="Applicant Dashboard" />
    </Box>
  );
};

export default ApplicantLandingPage;

import { Button, Dialog, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  editCategories
} from "../../../../../state/actions/categoriesList/categoriesList";
import "./EditCategoryModal.scss";

const EditCategoryModal = (props) => {
  const { onClose, open, title, idCategory, categroies } = props;

  const [loading, setLoading] = useState(false);

  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const { register, handleSubmit } = useForm();
  const onSubmit = async (data) => {

    if (data.newCategory !== title) {
      setLoading(true);
      await dispatch(editCategories(data.newCategory, idCategory, categroies));
      setLoading(false);
    }
    // dispatch(getCategoriesList())
  };

  return (
    <Dialog onClose={onClose} open={open} className="edit-category-dialog" disableScrollLock={true}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          direction={"column"}
          spacing={3}
        >
          <Typography className="title">{t("edit_category")}</Typography>
          <TextField
            style={{ width: "100%" }}
            className="input"
            defaultValue={title}
            {...register("newCategory")}
          />
          <Stack
            direction={"row"}
            justifyContent={"flex-end"}
            spacing={2}
          >
            <Button onClick={onClose} className="btn-cancel">
              {t("cancel")}
            </Button>
            <Button type="submit" onClick={onClose} className="btn-add" disabled={loading} xs={8}>
              {/* {loading ? <CircularProgress size={20} /> : t("save_category")} */}
              {t("save_category")}
            </Button>
          </Stack>
        </Stack>
      </form>

    </Dialog >
  );
};

export default EditCategoryModal;

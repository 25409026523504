import { Container, Grid, Typography } from "@mui/material";
import Card from "./Card";

const AuthPageWrapper = ({
	children,
	titleSlice1,
	titleSlice2,
	description,
	single,
}) => {
	const renderChildrens = () => {
		let result;
		if (single) {
			result = (
				<Grid item xs={12} md={6}>
					<Card single={true}>{children}</Card>
				</Grid>
			);
		} else {
			result = (
				<>
					<Grid item xs={12} md={6}>
						<Card single={false} bg={"white"}>
							<>{children}</>
						</Card>
					</Grid>
					<Grid item xs={12} md={6} >
						<Card single={false} bg={"dark"}>
							<Grid container>
								<Grid item xs={12}>
									<Typography variant="h4" className="title enj">
										{titleSlice1}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Typography variant="h4" className="title enj">
										{titleSlice2}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Typography className="description">
										{description}
									</Typography>
								</Grid>
							</Grid>
						</Card>
					</Grid>
				</>
			);
		}

		return result;
	};

	return (
		<>
			<Container
				maxWidth="xl"
				disableGutters
				sx={{ height: "100vh" }}
				className="auth-page-wrapper"
			>

				<div className="cards">
					<Grid container direction="row" xs={12} sx={{ height: "100%" }} justifyContent="center">
						{renderChildrens()}
					</Grid>
				</div>

			</Container>
		</>
	);
};
export default AuthPageWrapper;

import { Box, Grid, Typography } from "@mui/material";
import { CaretLeft, CaretRight } from "phosphor-react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Show from "../Show";
import "./PageBack.css";

const PageBack = ({ title, link, onClick, returnHome }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const pageLink = useSelector((state) => state.pageBack.pageLink)

  
  return (
    <Box className="page-back" onClick={() => onClick ? onClick() : returnHome ? navigate("/") : navigate((window.history.state && window.history.state.idx > 0) ? -1 : `/`)}>
      <Grid container direction="row" columnSpacing={1} alignItems="center">
        <Grid item>
          <Show condition={i18n.language == "ar"}>
            <CaretRight size={20} />
          </Show>
          <Show condition={i18n.language !== "ar"}>
            <CaretLeft size={20} />
          </Show>
        </Grid>
        <Grid item>
          <Typography className="title">{t(title)}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
export default PageBack;

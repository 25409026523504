import React, { useEffect } from "react";
import AuthPageWrapper from "../../../components/auth-page-wrapper/AuthPageWrapper";
import {
  Grid,
  Typography,
  Button,
  FormControl,
  Box,
  FormGroup,
} from "@mui/material";
import IconInput from "../../../components/IconInput";
import { At, Lock } from "phosphor-react";
import { useTranslation } from "react-i18next";

import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toastr } from "react-redux-toastr";
import { sendOTP } from "services/AuthHelper";
import { useDispatch, useSelector } from "react-redux";
import { authChangeEmail, authResetPassowrd } from "state/actions/auth/auth";

const ChangeEmail = () => {
  let width = window.innerWidth;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const otpToken = useSelector((state) => state.otp.token);

  const schema = yup
    .object({
      email: yup
        .string()
        .email(t("email_format"))
        .required(t("required_input")),
    })
    .required();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
  });

  const onSubmit = async (formData) => {
    const { email } = formData;
    dispatch(authChangeEmail(navigate, email, otpToken));
  };

  useEffect(() => {
    if (!otpToken) navigate("/login");
  }, [otpToken]);

  return (
    <AuthPageWrapper
      titleSlice1="Enjoy the"
      titleSlice2="Opportunities"
      description="Job Links allows connection between people and organizations through the world."
      className="sign-in-wrapper"
      single={true}
    >
      <Grid container direction="row" justifyContent="center">
        <Grid className="signin-form" justifyContent="center" xs={8}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <FormControl className="mb24">
                <Typography className="title">Joblink</Typography>
                <Typography className="desc">
                  {t("lets_reset")}
                </Typography>
              </FormControl>
              <IconInput
                label={t("email")}
                id="email"
                placeholder={t("email_placeholder")}
                type="email"
                icon={At}
                className="mb16"
                fieldName="email"
                register={register}
                error={errors.email}
              />
              <FormControl className="mb24">
                <Button type="submit" className="login-btn" variant="contained">
                  {"Submit"}
                </Button>
              </FormControl>
              <FormControl>
                <Typography variant="div" className="navigation-text">
                  {t("remember_password")}{" "}
                  <Box component={Link} className="navigation-btn" to="/login">
                    {t("back_to_login")}
                  </Box>
                </Typography>
              </FormControl>
            </FormGroup>
          </form>
        </Grid>
      </Grid>
    </AuthPageWrapper>
  );
};

export default ChangeEmail;

import axios from 'axios';
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const sv = "?sv=2020-08-04&ss=bfqt&srt=sco&sp=rwdlacupitfx&se=2030-03-04T20:24:46Z&st=2022-03-04T12:24:46Z&spr=https&sig=wxQMD3PrF%2BWJ3JzJ8s%2F64sPTWcg7PMNbvRju9JR8h9Y%3D"

const loadResources = async (locale) => {
    return await axios.get(
        `https://joblinkshub-prd-api-yzmj6.ondigitalocean.app/api/v1/account/language-file?side=web`,
        { params: { language: locale } }
    )
        .then((response) => {
            console.log("axios res", response);
            return response.data.data[0].json_file
        })
        .catch((error) => { console.log(error); });
}



i18next
    .use(HttpBackend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: "en",
        ns: "translation",
        defaultNS: "translation",
        backend: {
            loadPath: '{{lng}}|{{ns}}',
            request: (options, url, payload, callback) => {
                try {
                    console.log({ url })
                    const [lng] = url.split('|');
                    loadResources(lng).then(async (response) => {
                        const result = await axios.get(response).catch((e) => console.log(e));
                        console.log("load res", result)
                        callback(null, {
                            data: result?.data,
                            status: 200,
                        });
                    });
                } catch (e) {
                    console.error("catching");
                    callback(null, {
                        status: 500,
                    });
                }
            }
        }
    })





export default i18next;
import {
    Button,
    Divider,
    Grid,
    Step,
    StepLabel,
    Stepper,
    Typography,
  } from "@mui/material";
  import { Check, Circle } from "phosphor-react";
  import { useSelector, useDispatch } from "react-redux";
  import { getStep } from "../../services/Stepper";
  import { nextStep } from "../../state/actions/stepper/stepper";
  import useMediaQuery from "@mui/material/useMediaQuery";
  import { translateField } from "../../services/Helper";
  import { useEffect } from "react";
  import Hide from "../Hide";
  import Show from "../Show";
  import { logout } from "../../services/auth/Auth";
  import ChangeLang from "../ChangeLang/ChangeLang";
  import { useTranslation } from "react-i18next";
  import { deleteProfile } from "state/actions/profile/profile";
  import { authLogout } from "state/actions/auth/auth";
  import { useNavigate } from "react-router-dom";
  const Sidebar = ({
    data,
    label,
    stepsFromApi,
    hideHeader,
    pass,
    hideLogoutLang,
  }) => {
    const { t } = useTranslation();
    const matches = useMediaQuery("(max-width:600px)");
    const dispatch = useDispatch();
    const currentStepNo = useSelector((state) => state.stepper.currentStep);
    const finalStepNo = useSelector((state) => state.stepper.finalStep);
    const currentStep = getStep(data, currentStepNo);
    const profile = useSelector((state) => state.profile.data);
    const stepLabelIcon = <div style={{ visible: "hidden", opacity: 0 }}></div>;
    const handleClick = (no) => {
      if (no <= finalStepNo) {
        dispatch(nextStep({ currentStep: no, finalStep: finalStepNo }));
      }
    };
  
    // useEffect(() => {
    //
    // })
  
    const navigate = useNavigate();
  
    return (
      <div className="sidebar">
        <Hide condition={hideHeader}>
          {!matches && (
            <>
              <Typography className="title">Joblink</Typography>
              <Typography className="text">{label}</Typography>
            </>
          )}
        </Hide>
  
        <Stepper
          activeStep={currentStep ? currentStep.no - 1 : -1}
          orientation="vertical"
          connector={null}
        >
          {data?.map((step) => (
            <Step
              key={step.no}
              completed={step.completed && step.completed == 1}
              onClick={() => {
                if (pass) handleClick(step.no);
              }}
            >
              <StepLabel
                icon={stepLabelIcon}
                style={{ cursor: step.no <= finalStepNo ? "pointer" : "default" }}
              >
                <div className="step">
                  <Typography
                    sx={{
                      textAlign: matches ? "center" : null,
                      maxWidth: "max-content",
                      fontFamily: "Urbanist",
                    }}
                  >
                    {matches ? step.no : translateField(step, "title")}
                  </Typography>
                </div>
                {!matches && (
                  <>
                    <div className="icon">
                      <Check
                        style={{ width: "18px", height: "18px" }}
                        className="icon"
                      />
                    </div>
                    <div className="circle-icon">
                      <Circle
                        weight="fill"
                        style={{ width: "18px", height: "18px" }}
                        className="circle-icon"
                      />
                    </div>
                  </>
                )}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <Hide condition={hideLogoutLang}>
          <Hide condition={matches}>
            <Grid container spacing={1}>
              <Show condition={profile}>
                <Grid item>
                  <Button
                    style={{ zIndex: "200" }}
                    className="btn btn-home"
                    onClick={() => {
                      dispatch(deleteProfile());
                      dispatch(authLogout(navigate));
                    }}
                  >
                    {t("log_out")}
                  </Button>
                </Grid>
              </Show>
              <Grid item>
                <Divider light orientation="vertical" style={{ zIndex: "200" }} />
              </Grid>
              <Grid item>
                <ChangeLang />
              </Grid>
            </Grid>
          </Hide>
        </Hide>
      </div>
    );
  };
  export default Sidebar;
  
import { Button, CircularProgress, Dialog, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  deleteCategories
} from "../../../../../state/actions/categoriesList/categoriesList";
import { getDocumentDirection } from './../../../../../services/Helper';
import "./DeleteCategoryModal.scss";

const DeleteCategoryModal = (props) => {
  const { onClose, open, idCategory, title } = props;
  const textdirection = getDocumentDirection()
  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleDelete = async () => {
    setLoading(true);
    await dispatch(deleteCategories(idCategory));
    setLoading(false);
    // dispatch(getCategoriesList())
  };

  return (
    <Dialog onClose={onClose} open={open} className="delete-category-dialog" disableScrollLock={true}>
      <Stack
        direction={"column"}
        spacing={3}
      >
        <Typography className="title">
          {t("delete_category")} <span className="red-text"> {title}</span> {textdirection === "right" ? "؟" : "category?"}
        </Typography>
        <Stack
          direction={"row"}
          spacing={2}
          justifyContent={"flex-end"}
        >

          <Button onClick={onClose} className="btn-cancel">
            {t("cancel")}
          </Button>
          <Button
            disabled={loading}
            onClick={() => {
              handleDelete();
              onClose();
            }}
            className="btn-add"
          >
            {loading ? <CircularProgress size={20} /> : t("delete")}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default DeleteCategoryModal;

import { Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { Checkbox, Box, Grid } from "@mui/material";

import "./CheckText.scss";
import Show from "./../../../../components/Show";
import { getDocumentDirection } from "../../../../services/Helper";
import { Square } from "phosphor-react";
import { useDispatch } from "react-redux";
import _ from 'underscore';

const CheckText = ({
  text,
  count,
  field,
  id,
  checked,
  value,
  name,
  innnerOnChange,
  category,
  setIsAddDisabled,
  cvBank,

}) => {
  const textDirection = getDocumentDirection();
  const matches = useMediaQuery("(max-width:900px)");
  const dispatch = useDispatch();
  return (
    <Stack
      alignItems="center"
      direction={"row"}
      className="check-text-wrapper"
      spacing={0.5}
    >
      <Box>
        <Checkbox
          className={"check-text-checkbox"}
          icon={<Square size={24} color="#9191911A" weight="fill" />}
          sx={{
            color: "#3A3B81",
            border: "none",
            padding: "0",
            margin: "0",
            "&.Mui-checked": {
              color: "#3A3B81",
            },
          }}
          name={id}
          size={"medium"}
          checked={value}
          onChange={(e) => {
            if (cvBank) {
              if (e?.target?.checked) {
                let temp = [...cvBank?.cvBankState]
                temp.push({ id: category.id, CvBankCategory: category })
                cvBank?.setCvBankState(temp)
                setIsAddDisabled?.(false)
              }
              else {
                let currentChoices = _.reject(cvBank?.cvBankState, (choice) => choice?.CvBankCategory.id == id)
                cvBank?.setCvBankState(currentChoices)
              }
            }
            if (
              name?.split(".")[0] === "countries" ||
              name?.split(".")[0] === "governorates"
            ) {
              if (matches) {
                dispatch({
                  type: `RESPONSIVE_${name
                    .split(".")[0]
                    .toUpperCase()}_FILTER${e.target.checked ? "" : "_REMOVE"}`,
                  payload: e.target.name,
                });
              }
            }
            innnerOnChange?.(e);
          }}
        />
      </Box>

      <Typography className={`check-text-label ${cvBank && "no-break"}`} align={textDirection}>
        {text}
      </Typography>
      <Show condition={count}>
        <Box className="check-text-count" component={"section"}>
          {count}
        </Box>
      </Show>
    </Stack >
  );
};

export default CheckText;

import "./CompanyProfileEdit.scss"
import AuthStepper from './../../components/auth-stepper/Stepper';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { stepperData } from "./stepper"
import CompanyInfo from './../pages/recutiersubmit/information/CompanyInfo';
import { Helmet } from 'react-helmet';
import ReviewAndSubmit from './../pages/recutiersubmit/information/ReviewAndSubmit';
import ContactInfo from './../pages/recutiersubmit/information/ContactInfo';
import { Box } from '@mui/material';
import { Navigate, useNavigate } from "react-router-dom";


const CompanyProfileEdit = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const stepFinished = useSelector((state) => state.profile.data?.recruiter?.step_finished);
    const currentStepName = useSelector((state) => state.stepper.currentStep);
    const [stepperDataState, setStepperDataState] = useState(stepperData);
    const stepsFromApi = useSelector((state) => {
        let steps = state.profile.data?.recruiter?.RecruiterSteps;
        return steps && steps.length > 0 ? steps[0] : {};
    });
    useEffect(() => {
        let tempStepperData = stepperData;
        Object.keys(stepsFromApi).map((key, index) => {
            tempStepperData[index].completed = stepsFromApi[key]
        })
        setStepperDataState(tempStepperData);
        
    }, [stepsFromApi])
    const title = "JOBLINK"

    const renderStepContent = () => {
        switch (currentStepName) {
            case 1:
                return (
                    <>
                        <Helmet>
                            <title>{title} - {t("contact_info")}</title>
                        </Helmet>
                        <ContactInfo />
                    </>
                );
            case 2:
                return (<>
                    <Helmet>
                        <title>{title} - {t("company_info")}</title>
                    </Helmet>
                    <CompanyInfo />
                </>
                );
            case 3:
                return (<>
                    <Helmet>
                        <title>{title} - {t("review_and_submit")}</title>
                    </Helmet>
                    <ReviewAndSubmit handleEdit={() => { navigate("/my-profile/company") }} />
                </>
                );
            default:
                break;
        }
    }

    return (
        <Box className="common-dark-stepper" style={{ backgroundColor: "#FAFAFA", width: '100%' }}>
            <AuthStepper
                data={stepperDataState}
                finalStep={2}
                startStep={0}
                renderStepContent={renderStepContent}
                hideHeader={true}
                pass={true}
                label={t("recuriter_sideba_label")}
                hideLogoutLang={true}
            />
        </Box>
    )
}

export default CompanyProfileEdit
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ColoredLogo from "../../../../components/colored-logo/ColoredLogo";
import Hide from "../../../../components/Hide";
import PageHeader from "../../../../components/page-header/PageHeader";
import Show from "../../../../components/Show";
import Permissions from "../../../../constants/permissions";
import { perm } from "../../../../services/Helper";
import AllJobsHeader from "../../../jobs/components/header/AllJobsHeader";

const HomePageHeader = () => {
    const permissions = useSelector((state) => state.profile.role?.permissions);
    const role = useSelector((state) => state.profile.role?.name);

    const { t, i18n } = useTranslation();

    return (
        <>
            <Hide condition={perm(permissions, Permissions.createJob)}>
                <PageHeader
                    title={role == "GUEST_USER" ? <ColoredLogo fontSize={"40px"} /> : t("home")}
                />
            </Hide>
            <Show condition={perm(permissions, Permissions.createJob)}>
                <AllJobsHeader />
            </Show>
        </>
    );
}
export default HomePageHeader;
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-key */
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery
} from "@mui/material";
import { t } from "i18next";
import { Faders } from "phosphor-react";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
// import noDataAnimation from "../../assets/lootie-files/data-not-found.json";
import ApplicantAvatarCard from "../../components/applicant-avatar-card/ApplicantAvatarCard";
import ErrorPage from "../../components/error-page/ErrorPage";
import JobDetailsCard from "../../components/job-details-card/JobDetailsCard";
import Loader from "../../components/loader/Loader";
import {
  changeCompanyStatus,
  getCompanyProfile,
  getJob, getJobsByType, getReadProfileApplicant,
  scrollIntoView,
  translateField
} from "../../services/Helper";
import {
  COMPANIES,
  RESPONSIVE_FILTER_UNSET,
  URL_SEARCH
} from "../../state/actions/filter/types";
import EnhancedMiniJobCard from "../pages/abdelrahman/EnhancedMiniJobCard/EnhancedMiniJobCard";
import CompanyCard from "../pages/zeinnab/components/company-card/CompanyCard";
import Hide from "./../../components/Hide";
import Pagination from "./../../components/pagination/Pagination";
import Show from "./../../components/Show";
import {
  JOBS,
  PEOPLE,
  RESPONSIVE_FILTER_SET
} from "./../../state/actions/filter/types";
import ApplicantCv from "./../applicant-cv/ApplicantCv";
import ProfileCard from "./../Company-profile-page/components/company-profile-card/ProfileCard";
import Filter from "./components/filter/Filter";
import ResponsiveFilter from "./components/filter/responsive-filter/ResponsiveFilter";
import "./SearchPage.scss";

const SearchPage = () => {
  const [dataToRender, setDataToRender] = useState([]);
  const [selected, setSelected] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [page, setPage] = useState(1);
  const [ready, setReady] = useState(false);
  const [recentlyAddedJobs, setRecentlyAddedJobs] = useState([]);
  const [jobsLoaded, setJobsLoaded] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const role = useSelector((state) => state.profile.role?.name);
  const responsiveFilterState = useSelector(
    (state) => state.filter.responsiveFilterState
  );
  const params = useParams();
  const matches = useMediaQuery("(max-width:890px)");
  const mdMatches = useMediaQuery("(min-width:701px) and (max-width:1300px)");
  const smMatches = useMediaQuery("(max-width:700px)");

  const [searchParams, setSearchParams] = useSearchParams();
  const ref = useRef();

  const filterType = useSelector((state) => state.filter.activeFilter);

  const {
    control,
    getValues,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    let payload = {};
    Array.from(searchParams.entries()).forEach(([id, value]) => {
      if (id === "type") {
        payload["activeFilter"] = value;
      } else {
        payload[id] = id === "search" ? value : value.split(",");
      }
    });
    if (Object.keys(payload).length > 0) {
      dispatch({
        type: URL_SEARCH,
        payload,
      });
    }
    //
  }, []);

  const fetchJob = (id) => {
    if (matches) {
      navigate(`/jobs/${id}`);
    } else {
      setSelected(id);
      getJob(id).then((data) => {
        setSelectedData(data);
      });
    }
  };
  const fetchApplicant = (id) => {
    if (matches) {
      navigate(`/applicant/${id}`);
    } else {
      setSelected(id);
      getReadProfileApplicant(id, "").then((data) => {
        setSelectedData({ User: data });
      });
    }
  };
  const fetchCompany = (id) => {
    if (matches) {
      navigate(`/company/${id}`);
    } else {
      setSelected(id);
      getCompanyProfile(false, "", id).then((data) => {
        setSelectedData(data.recruiter);
      });
    }
  };

  const handleFollowCompany = (id) => {
    changeCompanyStatus(id, role).then(() => {
      switch (filterType) {
        case "jobs":
          fetchJob(selected);
          break;
        case "people":
          fetchApplicant(selected);
          break;
        case "companies":
          fetchCompany(selected);
          break;
        default:
          break;
      }
      // setFollowed(!followed)
    });
  };

  useEffect(() => {
    console.log("DATA TO RENDER", filterType, dataToRender);
    !matches &&
      filterType === "jobs" &&
      dataToRender?.[0]?.Recruiter &&
      fetchJob(dataToRender?.[0]?.id);
    !matches &&
      filterType === "people" &&
      dataToRender?.[0]?.first_name &&
      fetchApplicant(dataToRender?.[0]?.id);
    !matches &&
      filterType === "companies" &&
      dataToRender?.[0]?.company_name &&
      fetchCompany(dataToRender?.[0]?.id);
  }, [dataToRender]);

  useEffect(() => {
    getJobsByType("RecentlyAdded", 2, role === "RECRUITER" ? role : "", false).then((response) => {
      setRecentlyAddedJobs(response.rows);
      setJobsLoaded(true);
    });
  }, []);

  const renderSidebarContent = () => {
    switch (filterType) {
      case "people": {
        return dataToRender.map((person) => (
          <Loader
            data={ready}
            width={"100%"}
            height={"200px"}
            style={{ borderRadius: "1rem" }}
          >
            <ApplicantAvatarCard
              selected={selected === person?.id}
              firstName={person?.first_name}
              secondName={person?.second_name}
              location={`${translateField(
                person?.City,
                "name"
              )}, ${translateField(
                person?.City?.Governorate?.Country,
                "name"
              )}`}
              jobTitle={`${translateField(person?.JobTitle, "name")}`}
              onClick={() => {
                fetchApplicant(person?.id);
                document.getElementById("details_wrap").scrollTop = 0;
              }}
              img={person.image}
              showCardTag
            />
          </Loader>
        ));
      }
      case "jobs": {
        return dataToRender.map((person, index) => (
          <Loader
            data={ready}
            width={"100%"}
            height={"200px"}
            style={{ borderRadius: "1rem" }}
          >
            <EnhancedMiniJobCard
              selected={selected === person?.id}
              noOfApplicants={person?.count}
              title={`${translateField(person?.JobTitle, "name")}`}
              location={`${translateField(
                person?.Country,
                "name"
              )}, ${translateField(
                person?.Governorate,
                "name"
              )}, ${translateField(person?.City, "name")}`}
              company={`${person?.Recruiter?.company_name}`}
              logo={person?.Recruiter?.image}
              type={`${translateField(person?.ExperienceType, "name")}`}
              date={person?.created_at}
              url={`https://${window.location.host}/jobs/${person?.id}`}
              onClick={() => {
                console.log("person.id", person?.id);
                fetchJob(person?.id);
                document.getElementById("details_wrap").scrollTop = 0;
              }}
              tags={[
                translateField(person?.WorkStyle, "name"),
                translateField(person?.ExperienceType, "name"),
              ]}
              // showLogo
              active={person?.status}
              jobId={person?.id}
              isApplied={person?.is_applied}
            // selectable
            // status
            />
          </Loader>
        ));
      }
      case "companies": {
        return dataToRender.map((person) => (
          <Loader
            data={ready}
            width={"100%"}
            height={"200px"}
            style={{ borderRadius: "1rem" }}
          >
            <CompanyCard
              companyId={person?.id}
              selected={selected === person?.id}
              companyName={person.company_name}
              companyIndustry={`${translateField(
                person?.CompanyIndustry,
                "name"
              )}`}
              // logo={person.image}
              // is_followed={person?.is_followed}
              companyFollowers={person?.follower_count}
              companyUser={`${translateField(person?.CompanySize, "name")}`}
              companyLocation={`${translateField(
                person?.City,
                "name"
              )}, ${translateField(person?.City?.Governorate, "name")} `}
              onClick={() => {
                fetchCompany(person?.id);
                document.getElementById("details_wrap").scrollTop = 0;
              }}
              isFollowed={person?.is_followed}
            />
            {/* <MiniCompanyCard
              id={person?.id}
              selected={selected === person?.id}
              name={person.company_name}
              companyIndustry={`${translateField(
                person?.CompanyIndustry,
                "name"
              )}`}
              // logo={person.image}
              // is_followed={person?.is_followed}
              companyFollowers={person?.follower_count}
              companyUser={`${translateField(
                person?.CompanySize,
                "name"
              )}`}
              companyLocation={`${translateField(person?.City,"name")}, ${translateField(person?.City?.Governorate,"name")} `}

              onClick={() => {
                fetchCompany(person?.id);
                document.getElementById("details_wrap").scrollTop = 0;
              }}
            /> */}
          </Loader>
        ));
      }
      default:
        break;
    }
  };
  
  
  const renderDetailsCard = () => {
    switch (filterType) {
      case "people": {
        return (
          <Loader data={selectedData} width={"100%"} height={"300px"}>
            <ApplicantCv userData={selectedData} />
          </Loader>
        );
      }
      case "jobs": {
        return (
          <Loader data={selectedData} width={"100%"} height={"300px"}>
            <JobDetailsCard
              hiringTime={selectedData?.HiringTime}
              external_apply_website={selectedData?.external_apply_website}
              stickyApply={true}
              // followHandler={() =>
              //   handleFollowCompany(selectedData?.Recruiter?.id)
              // }
              jobId={selectedData?.id}
              recruiterId={selectedData?.Recruiter?.id}
              fetchJob={fetchJob}
              jobTitle={translateField(selectedData?.JobTitle, "name")}
              date={selectedData?.created_at}
              location={
                translateField(selectedData?.Governorate, "name") +
                ", " +
                translateField(selectedData?.Country, "name")
              }
              type={translateField(selectedData?.ExperienceType, "name")}
              noOfApplicants={selectedData?.count}
              yearsOfEx={translateField(
                selectedData?.YearsOfExperience,
                "name"
              )}
              jobCategory={translateField(selectedData?.JobCategory, "name")}
              slaryRange={
                selectedData?.salary_from && selectedData?.salary_to
                  ? selectedData?.salary_from + " - " + selectedData?.salary_to
                  : translateField(selectedData?.Salary, "name")
              }
              currency={selectedData?.currency}
              jobDescreption={selectedData?.job_descirption}
              jobRequirement={selectedData?.job_requirement}
              companyName={selectedData?.Recruiter?.company_name}
              companyLogo={selectedData?.Recruiter?.image}
              followed={selectedData?.Recruiter?.is_followed == 1}
              companySize={translateField(
                selectedData?.Recruiter?.CompanySize,
                "name"
              )}
              showBottomShare
              isApplied={selectedData?.is_applied}
              isSaved={selectedData?.is_saved}
              experienceType={translateField(
                selectedData?.ExperienceType,
                "name"
              )}
              viewDetails
              careerLevel={translateField(selectedData?.CareerLevel, "name")}
              workStyle={translateField(selectedData?.WorkStyle, "name")}
              jobStatus={
                selectedData?.ApplicantApplyJobs?.[0]?.JobStatus?.name_en ===
                  "Pending"
                  ? selectedData?.ApplicantApplyJobs?.[0]?.is_seen
                    ? "Seen"
                    : selectedData?.ApplicantApplyJobs?.[0]?.JobStatus?.name_en
                  : selectedData?.ApplicantApplyJobs?.[0]?.JobStatus?.name_en
              }
              showUserThreeicon
            />
          </Loader>
        );
      }
      case "companies": {
        return (
          <>
            <Loader data={selectedData} width={"100%"} height={"300px"}>
              <ProfileCard
                recruiterId={selectedData?.id}
                viewProfile={true}
                name={selectedData?.company_name}
                logo={selectedData?.image}
                phone={selectedData?.mobile}
                email={selectedData?.work_email}
                location={`${translateField(
                  selectedData?.City?.Governorate?.Country,
                  "name"
                )}, ${translateField(
                  selectedData?.City?.Governorate,
                  "name"
                )}, ${translateField(selectedData?.City, "name")}`}
                description={selectedData?.bio}
                industry={translateField(selectedData?.CompanyIndustry, "name")}
                companySize={translateField(selectedData?.CompanySize, "name")}
                followed={selectedData?.is_followed}
                id={selectedData?.id}
                searchPage={true}
                fetchJob={fetchJob}
              />
            </Loader>
            <Loader data={jobsLoaded}>
              <Typography
                sx={{
                  marginTop: "16px",
                  marginBottom: "8px",
                  fontFamily: "Urbanist",
                  color: "#000000",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              >
                Recent Jobs
              </Typography>
              <Grid container spacing={2}>
                {recentlyAddedJobs?.map((job) => (
                  <Grid item xs={12} md={6}>
                    <EnhancedMiniJobCard
                      jobId={job?.id}
                      title={translateField(job?.JobTitle, "name")}
                      company={job?.Recruiter?.company_name}
                      logo={job?.Recruiter?.image}
                      date={job?.created_at}
                      noOfApplicants={job?.count}
                      tags={[
                        translateField(job?.WorkStyle, "name"),
                        translateField(job?.ExperienceType, "name"),
                      ]}
                      location={`${translateField(
                        job?.City,
                        "name"
                      )}, ${translateField(job?.Country, "name")}`}
                      onClick={() => navigate(`/jobs/${job?.id}`)}
                      isSaved={job?.is_saved}
                      isApplied={job?.is_applied}
                    />
                  </Grid>
                ))}
              </Grid>
            </Loader>
          </>
        );
      }
      default:
        break;
    }
  };
  useEffect(() => {
    if (!matches) dispatch({ type: RESPONSIVE_FILTER_UNSET });
  }, [responsiveFilterState]);

  useEffect(() => {
    setSelected(null);
    setSelectedData(null);
    setPage(1);
    setNumberOfPages(1);
  }, [filterType]);

  useEffect(() => {
    scrollIntoView("searching-page-top");
  }, [ready]);

  const changePage = (page) => {
    setPage(page);
  };

  const [noDataAnimation, setNoDataAnimation] = useState(null);
  useEffect(() => {
    import("../../assets/lootie-files/data-not-found.json").then(data => setNoDataAnimation(data));
  }, [])

  return (
    <Grid
      container
      component={"main"}
      columnSpacing={1}
      rowSpacing={1}
      className="search-page"
      justifyContent={"flex-start"}
      ref={ref}
      sx={{ position: "relative" }}
      style={{ height: "calc(100vh - 265px)", width: "100%" }}
    >
      {/* <Scroller id={"searching-page-top"} /> */}
      <Hide condition={responsiveFilterState}>
        <Grid className="scrollable-filters" item component={"section"} xs={12}>
          {!matches ? (
            <>
              <Filter
                setData={setDataToRender}
                page={page}
                setNumberOfPages={setNumberOfPages}
                setReady={setReady}
              />
            </>
          ) : (
            <Stack
              direction={"column"}
              justifyContent={"flex-start"}
              spacing={2}
            >
              <Stack
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems="center"
                alignContent={"center"}
                spacing={2}
                mt={2}
              >
                <Button
                  className={`btn rounded-filter-btn ${filterType === "jobs" && "btn-dark"
                    } `}
                  onClick={() => {
                    dispatch({ type: JOBS });
                    const url = new URL(window.location);
                    url.searchParams.set("type", "jobs");
                    window.history.pushState(null, "", url.toString());
                  }}
                >
                  {t("radio_filter_jobs")}
                </Button>
                <Button
                  className={`btn rounded-filter-btn ${filterType === "companies" && "btn-dark"
                    } `}
                  onClick={() => {
                    dispatch({ type: COMPANIES });
                    const url = new URL(window.location);
                    url.searchParams.set("type", "companies");
                    window.history.pushState(null, "", url.toString());
                  }}
                >
                  {t("radio_filter_companies")}
                </Button>
                <Button
                  className={`btn rounded-filter-btn  ${filterType === "people" && "btn-dark"
                    }`}
                  onClick={() => {
                    dispatch({ type: PEOPLE });
                    const url = new URL(window.location);
                    url.searchParams.set("type", "people");
                    window.history.pushState(null, "", url.toString());
                  }}
                >
                  {t("radio_filter_people")}
                </Button>
              </Stack>
            </Stack>
          )}
        </Grid>
        <Loader data={ready} width={"100%"} height={"100%"}>
          <Grid item component={"section"} xs={12}>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems="center"
              marginY={2}
            >
              <Typography className="search-result-title">
                {t("results")}
              </Typography>
              {matches && (
                <IconButton
                  className="btn"
                  onClick={() => {
                    dispatch({ type: RESPONSIVE_FILTER_SET });
                  }}
                  sx={{
                    borderRadius: "8px",
                  }}
                >
                  <Faders color="#3A3B81" size={20} weight="fill" />
                </IconButton>
              )}
            </Stack>
          </Grid>
          <Hide condition={responsiveFilterState}>
            <Show condition={dataToRender.length > 0}>
              <Grid
                item
                mt={1}
                component={"section"}
                xs={12}
                sm={12}
                md={4}
                lg={filterType === "companies" ? 4 : 5.5}
                xl={filterType === "companies" ? 4 : 4}
                className="scroll-inside"
                height={"70vh"}
                sx={{ paddingX: "20px" }}
              >
                <Stack alignItems="center" spacing={1}>
                  {renderSidebarContent()}
                </Stack>
              </Grid>
              <Hide condition={matches}>
                <Grid
                  item
                  component={"section"}
                  md={8}
                  lg={filterType === "companies" ? 8 : 6.1}
                  xl={filterType === "companies" ? 8 : 8}
                  className="scroll-inside"
                  height={"70vh"}
                  id="details_wrap"
                >
                  {renderDetailsCard()}
                </Grid>
              </Hide>
            </Show>
          </Hide>
        </Loader>
      </Hide>
      <Grid item xs={12} container justifyContent={{ xs: "center", sm: "center", md: "space-between" }} alignItems={"center"}>
        <Grid
          item
          mt={1}
          component={"section"}
          xs={12}
          sm={12}
          md={4}
          lg={filterType === "companies" ? 4 : 5.5}
          xl={filterType === "companies" ? 4 : 4}
        >
          {numberOfPages > 1 && (
            <Box
              mt={1}
              sx={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Pagination
                pagesManager={{
                  page: page,
                  setPage: changePage,
                  numberOfPages: numberOfPages,
                  onNext: () => {
                    // document.getElementById(
                    //   "searching-page-top"
                    // ).scrollTop = 0;
                  },
                  onBack: () => {
                    // document.getElementById(
                    //   "searching-page-top"
                    // ).scrollTop = 0;
                  },
                }}
                changeMdLg={true}
              />
            </Box>
          )}
        </Grid>
      </Grid>
      <Show condition={responsiveFilterState}>
        <Grid
          item
          mt={1}
          component={"section"}
          xs={12}
          sm={12}
          md={6}
          lg={4}
          xl={4}
        >
          <ResponsiveFilter
            setData={setDataToRender}
            page={page}
            setNumberOfPages={setNumberOfPages}
            getValues={getValues}
            reset={reset}
            control={control}
          />
        </Grid>
      </Show>
      <Hide condition={dataToRender.length > 0 || !ready}>
        <Grid item mt={1} component={"section"} xs={12}>
          <ErrorPage
            animationData={noDataAnimation}
            title={t("oops")}
            description={t("no_results")}
            hideAction
          />
        </Grid>
      </Hide>
    </Grid >
  );
};

export default SearchPage;
import { Box, Grid, Stack, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, matchRoutes, useLocation, useNavigate } from 'react-router-dom';
import Logo from '../../../../assets/Logo5SVG';
import { scrollToSection } from '../../../../services/Helper';
import LandingPageSearchBar from '../../okba/components/landing-page-search-bar/LandingPageSearchBar';
import EnhancedNotificationsDropdown from '../../zeinnab/components/navbar/components/notifications-dropdown/EnhancedNotificationsDropdown';
import ProfileDropdown from '../../zeinnab/components/navbar/components/profile-dropdown/ProfileDropdown';
import AuthorizedMobileNavbar from '../RecruiterContent/mobile-navbar/AuthorizedMobileNavbar';
import ApplicantNavigationButtons from './navigation-buttons/ApplicantNavigationButtons';

const ApplicantContent = ({ currentSection, handleSelection }) => {
    const isMobile = useMediaQuery('(max-width:1200px)');
    const isLarge = useMediaQuery('(max-width:1300px)');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const location = useLocation();

    const [openDrawer, setOpenDrawer] = useState(false);

    const applicantContentPaths = {
        exploreJobs: {
            patterns: [{ path: '/' }],
        },
        appliedJobs: {
            patterns: [{ path: 'applied-jobs' }],
        },
        savedJobs: {
            patterns: [{ path: 'saved-jobs' }],
        },
    };

    const checkCurrentPath = (patterns) => {
        const exist = matchRoutes(patterns, location);

        return exist ? true : false;
    };

    const links = [
        {
            value: 'home',
            label: 'Home',
            onClick: () => {
                setOpenDrawer(false);
                handleSelection(null, 'home');
                if (window.location.pathname === '/') {
                    scrollToSection('home');
                } else {
                    navigate('/');
                    setTimeout(() => {
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth',
                        });
                    }, 100);
                }
            },
        },
        {
            value: 'about',
            label: 'About',
            onClick: () => {
                setOpenDrawer(false);
                handleSelection(null, 'about');
                if (window.location.pathname !== '/') {
                    navigate('/');
                    setTimeout(() => {
                        scrollToSection('about');
                    }, 100);
                } else {
                    scrollToSection('about');
                }
            },
        },
        {
            value: 'how-it-works',
            label: 'How it Works',
            onClick: () => {
                setOpenDrawer(false);
                handleSelection(null, 'how-it-works');
                if (window.location.pathname !== '/') {
                    navigate('/');
                    setTimeout(() => {
                        scrollToSection('how-it-works');
                    }, 100);
                } else {
                    scrollToSection('how-it-works');
                }
            },
        },
        {
            value: 'browse-jobs',
            label: 'Browse Jobs',
            onClick: () => {
                setOpenDrawer(false);
                handleSelection(null, 'browse-jobs');
                if (window.location.pathname !== '/') {
                    navigate('/');
                    setTimeout(() => {
                        scrollToSection('browse-jobs');
                    }, 100);
                } else {
                    scrollToSection('browse-jobs');
                }
            },
        },
        {
            value: 'download-app',
            label: 'Download the App',
            onClick: () => {
                setOpenDrawer(false);
                handleSelection(null, 'download-app');
                if (window.location.pathname !== '/') {
                    navigate('/');
                    setTimeout(() => {
                        scrollToSection('download-app');
                    }, 100);
                } else {
                    scrollToSection('download-app');
                }
            },
        },
        {
            value: 'contact-enhanced',
            label: 'Contact Us',
            newPage: true,
            onClick: () => {
                setOpenDrawer(false);
                handleSelection(null, 'contact-enhanced');
                navigate('/contact');
                setTimeout(() => {
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });
                }, 100);
            },
        },
    ];

    const searchBarStyle = {
        maxHeight: '98px',
        width: '100%',
    };
    return (
        <>
            {!isMobile ? (
                <Grid container direction={'row'} alignItems={'center'} paddingX={isLarge ? '0px' : '58px'} spacing={5} sx={{ height: '100px' }}>
                    <Grid item md={0.75}>
                        <Link to={'/'}>
                            <Logo />
                        </Link>
                    </Grid>
                    <Grid item md={5} sx={{ maxHeight: '90px' }}>
                        <LandingPageSearchBar searchBarStyle={searchBarStyle} hideSearchButton mini />
                    </Grid>
                    <Grid item md={4}>
                        <Stack direction={'row'} columnGap={5} alignItems={'center'} justifyContent={'center'}>
                            <ApplicantNavigationButtons />
                        </Stack>
                    </Grid>
                    <Grid item md={2}>
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
                            <EnhancedNotificationsDropdown />
                            <Box width={'16px'}></Box>
                            <ProfileDropdown />
                        </Stack>
                    </Grid>
                </Grid>
            ) : (
                <AuthorizedMobileNavbar openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
            )}
        </>
    );
};

export default ApplicantContent;

const Logo = () => {
    return(

<svg xmlns="http://www.w3.org/2000/svg" width="180" height="40" viewBox="0 0 578.06 97.7">
  <g id="logo-02" transform="translate(-250.95 -491.15)">
    <g id="Group_552" data-name="Group 552">
      <g id="Group_551" data-name="Group 551">
        <path id="Path_126" data-name="Path 126" d="M409.32,519.39a1.454,1.454,0,0,1,.5-1.31,1.585,1.585,0,0,1,.84-.32h8.1c1.09,0,1.64.63,1.64,1.9V564.4a17.328,17.328,0,0,1-1.19,6.55,14.205,14.205,0,0,1-8.3,8.17,17.613,17.613,0,0,1-6.29,1.1,18.433,18.433,0,0,1-6.7-1.27,13.717,13.717,0,0,1-5.58-3.94,4.5,4.5,0,0,1-.32-.5,3.005,3.005,0,0,1-.3-.69,2.374,2.374,0,0,1-.09-.75,1.171,1.171,0,0,1,.32-.73l4.09-5a1.6,1.6,0,0,1,1.29-.71,1.811,1.811,0,0,1,1.29.71,8.928,8.928,0,0,0,2.41,1.68,6.821,6.821,0,0,0,3.02.69,5.442,5.442,0,0,0,2.33-.47,4.73,4.73,0,0,0,1.64-1.27,5.417,5.417,0,0,0,.97-1.9,8.139,8.139,0,0,0,.32-2.35V519.39Z" fill="#59595c"/>
        <path id="Path_127" data-name="Path 127" d="M427.08,532.15a15.343,15.343,0,0,1,1.31-6.44,14.288,14.288,0,0,1,3.58-4.85,16.05,16.05,0,0,1,5.26-3.04,19.972,19.972,0,0,1,12.84,0,15.767,15.767,0,0,1,5.3,3.04,14.307,14.307,0,0,1,3.58,4.83,15.2,15.2,0,0,1,1.31,6.47v32.71a15.185,15.185,0,0,1-1.31,6.42,14.275,14.275,0,0,1-3.58,4.83,16.043,16.043,0,0,1-5.28,3.04,19.386,19.386,0,0,1-6.42,1.06,18.894,18.894,0,0,1-6.42-1.08,16.133,16.133,0,0,1-5.28-3.08,14.586,14.586,0,0,1-3.58-4.85,14.891,14.891,0,0,1-1.31-6.34V532.15Zm11.03,32.11a5.456,5.456,0,0,0,.39,2.05,5.165,5.165,0,0,0,1.12,1.72,5.47,5.47,0,0,0,4.06,1.66,5.658,5.658,0,0,0,2.28-.45,5.405,5.405,0,0,0,1.77-1.21,5.269,5.269,0,0,0,1.12-1.72,5.456,5.456,0,0,0,.39-2.05V532.75a5.434,5.434,0,0,0-1.57-3.82,5.8,5.8,0,0,0-1.77-1.21,5.7,5.7,0,0,0-4.44,0,5.74,5.74,0,0,0-1.77,1.21,5.2,5.2,0,0,0-1.16,1.75,5.42,5.42,0,0,0-.41,2.07v31.51Z" fill="#59595c"/>
        <path id="Path_128" data-name="Path 128" d="M467.2,519.44a1.815,1.815,0,0,1,.39-1.23,1.457,1.457,0,0,1,1.16-.45H483.1a17.67,17.67,0,0,1,6.49,1.16,15.119,15.119,0,0,1,5.04,3.19,14.174,14.174,0,0,1,3.25,4.85,15.778,15.778,0,0,1,1.16,6.1v4.48a9.784,9.784,0,0,1-.65,3.56,12.107,12.107,0,0,1-1.62,2.95,10.522,10.522,0,0,1-2.16,2.16,6.031,6.031,0,0,1-2.26,1.08,9.244,9.244,0,0,1,2.28,1.06,10.154,10.154,0,0,1,2.54,2.22,12.863,12.863,0,0,1,2.05,3.53,13.527,13.527,0,0,1,.84,5v5.56a13.393,13.393,0,0,1-1.29,5.9,14.722,14.722,0,0,1-3.45,4.61,15.7,15.7,0,0,1-4.98,3,16.627,16.627,0,0,1-5.84,1.06H468.72c-1.03,0-1.55-.56-1.55-1.68V519.44Zm10.61,8.83v14.7h5.17a7.214,7.214,0,0,0,1.7-.22,5.181,5.181,0,0,0,1.72-.75,4.577,4.577,0,0,0,1.36-1.49,4.722,4.722,0,0,0,.56-2.41v-4.4a5.237,5.237,0,0,0-1.49-4.12,5.4,5.4,0,0,0-3.73-1.31h-5.29Zm0,24.1v16.34h6.21a4.952,4.952,0,0,0,2.15-.45,4.411,4.411,0,0,0,1.57-1.21,5.369,5.369,0,0,0,.95-1.68,5.53,5.53,0,0,0,.32-1.87v-5.39a6.755,6.755,0,0,0-.43-2.54,5.278,5.278,0,0,0-1.16-1.79,4.789,4.789,0,0,0-1.68-1.06,5.725,5.725,0,0,0-1.98-.34h-5.95Z" fill="#59595c"/>
        <path id="Path_129" data-name="Path 129" d="M524.92,519.44a1.815,1.815,0,0,1,.39-1.23,1.457,1.457,0,0,1,1.16-.45h7.93a1.513,1.513,0,0,1,1.21.45,1.815,1.815,0,0,1,.39,1.23v49.27h13.79c.86,0,1.29.5,1.29,1.51v7.41q0,1.59-1.29,1.59H526.47c-1.03,0-1.55-.56-1.55-1.68Z" fill="#59595c"/>
        <path id="Path_130" data-name="Path 130" d="M556.64,519.44a1.773,1.773,0,0,1,.41-1.23,1.524,1.524,0,0,1,1.19-.45h7.89a1.513,1.513,0,0,1,1.21.45,1.815,1.815,0,0,1,.39,1.23v58.1q0,1.68-1.59,1.68h-7.89q-1.59,0-1.59-1.68v-58.1Z" fill="#59595c"/>
        <path id="Path_131" data-name="Path 131" d="M575.65,519.44a1.773,1.773,0,0,1,.41-1.23,1.524,1.524,0,0,1,1.19-.45h7.37a2.354,2.354,0,0,1,.56.09.292.292,0,0,1,.09.02.292.292,0,0,0,.09.02,2.374,2.374,0,0,1,.71.47,2.311,2.311,0,0,1,.54.86l12.54,34.18,1.08,4.7-.22-4.31-.47-34.35a1.585,1.585,0,0,1,.37-1.18,1.448,1.448,0,0,1,1.18-.5h7.46a1.708,1.708,0,0,1,1.03.35,1.491,1.491,0,0,1,.47,1.29v58.19a1.469,1.469,0,0,1-1.68,1.64h-7.5a2.5,2.5,0,0,1-1.19-.32,1.851,1.851,0,0,1-.84-1.1l-12.2-32.76-1.12-4.09.26,4.31.39,32.28c.06,1.12-.46,1.68-1.55,1.68h-7.37q-1.59,0-1.59-1.68V519.44Z" fill="#59595c"/>
        <path id="Path_132" data-name="Path 132" d="M639.82,543.53l13.4,33.88a1.205,1.205,0,0,1-.04,1.29,1.181,1.181,0,0,1-.99.52h-8.84a1.821,1.821,0,0,1-1.85-1.42L633.22,555l-4.18,7.5v15.04q0,1.68-1.59,1.68h-7.89q-1.59,0-1.59-1.68v-58.1a1.773,1.773,0,0,1,.41-1.23,1.524,1.524,0,0,1,1.19-.45h7.89a1.513,1.513,0,0,1,1.21.45,1.815,1.815,0,0,1,.39,1.23v22.41l11.77-22.97c.23-.55.5-.87.82-.97a4.11,4.11,0,0,1,1.21-.15h9.31a.739.739,0,0,1,.52.17.869.869,0,0,1,.22.45,1.548,1.548,0,0,1-.04.6,2.4,2.4,0,0,1-.26.62Z" fill="#59595c"/>
        <path id="Path_133" data-name="Path 133" d="M656.37,561.94a3.428,3.428,0,0,1,.28-1.62,1.307,1.307,0,0,1,1.23-.5h7.84c.66,0,1.08.14,1.27.41a2.553,2.553,0,0,1,.28,1.4v2.03a6.773,6.773,0,0,0,.54,2.85,5.1,5.1,0,0,0,1.38,1.85,5.274,5.274,0,0,0,1.83.99,6.417,6.417,0,0,0,1.85.3,5.8,5.8,0,0,0,3.88-1.49,5.12,5.12,0,0,0,1.23-1.7,5.366,5.366,0,0,0,.45-2.24,9.784,9.784,0,0,0-.41-2.93,8.018,8.018,0,0,0-1.34-2.5,20.168,20.168,0,0,0-2.39-2.52q-1.47-1.32-3.58-3-3.54-2.8-6.21-4.96a31.07,31.07,0,0,1-4.48-4.35,15.765,15.765,0,0,1-2.74-4.85,19.563,19.563,0,0,1-.93-6.44,16.717,16.717,0,0,1,.75-5.19,15.14,15.14,0,0,1,2-4.07,13.482,13.482,0,0,1,2.91-3.02,19.436,19.436,0,0,1,3.43-2.07,16.181,16.181,0,0,1,3.58-1.19,17.41,17.41,0,0,1,3.36-.37,17.67,17.67,0,0,1,6.49,1.16,15.02,15.02,0,0,1,5.09,3.28,14.73,14.73,0,0,1,3.32,5.09,17.8,17.8,0,0,1,1.19,6.64v2.59a1.924,1.924,0,0,1-.32,1.31,2.162,2.162,0,0,1-1.44.32h-7.63c-.58,0-.95-.11-1.12-.35a2.3,2.3,0,0,1-.26-1.29v-2.54a7.186,7.186,0,0,0-.34-2.2,5.565,5.565,0,0,0-.99-1.81,4.6,4.6,0,0,0-1.59-1.23,4.966,4.966,0,0,0-2.16-.45,5.838,5.838,0,0,0-1.79.3,4.5,4.5,0,0,0-1.68.99,5.276,5.276,0,0,0-1.23,1.77,6.477,6.477,0,0,0-.47,2.63,8.355,8.355,0,0,0,.45,2.87,8.512,8.512,0,0,0,1.29,2.31,13.614,13.614,0,0,0,2.03,2.07c.79.66,1.69,1.37,2.69,2.11q3.2,2.415,6.01,4.66a33.845,33.845,0,0,1,4.96,4.81,20.6,20.6,0,0,1,3.36,5.71,20.176,20.176,0,0,1,1.23,7.37,14.5,14.5,0,0,1-1.21,5.88,14.768,14.768,0,0,1-3.4,4.87,16.748,16.748,0,0,1-5.28,3.34,18.193,18.193,0,0,1-6.87,1.25,18,18,0,0,1-7.11-1.31,15.091,15.091,0,0,1-5.11-3.51,14.692,14.692,0,0,1-3.08-5.09,17.773,17.773,0,0,1-1.03-6.03v-2.34Z" fill="#59595c"/>
        <path id="Path_134" data-name="Path 134" d="M714.43,519.44a1.815,1.815,0,0,1,.39-1.23,1.457,1.457,0,0,1,1.16-.45h7.93a1.513,1.513,0,0,1,1.21.45,1.815,1.815,0,0,1,.39,1.23v23.02h11.64V519.44a1.843,1.843,0,0,1,.39-1.18,1.444,1.444,0,0,1,1.21-.5h7.97a1.708,1.708,0,0,1,1.03.35,1.491,1.491,0,0,1,.47,1.29v58.19a1.469,1.469,0,0,1-1.68,1.64h-7.8a1.429,1.429,0,0,1-1.59-1.64V552.98H725.51v24.57q0,1.68-1.59,1.68h-7.93c-1.03,0-1.55-.56-1.55-1.68V519.44Z" fill="#59595c"/>
        <path id="Path_135" data-name="Path 135" d="M766.63,564.22a5.6,5.6,0,0,0,.39,2.07,5.293,5.293,0,0,0,1.12,1.75,5.564,5.564,0,0,0,1.77,1.21,6.077,6.077,0,0,0,4.59,0,5.425,5.425,0,0,0,1.75-1.21,5.1,5.1,0,0,0,1.12-1.75,5.6,5.6,0,0,0,.39-2.07V519.48a2.018,2.018,0,0,1,.37-1.21,1.207,1.207,0,0,1,1.06-.52h8.15a1.246,1.246,0,0,1,1.1.54,2.086,2.086,0,0,1,.37,1.18v45.39a14.009,14.009,0,0,1-1.31,5.99,15.413,15.413,0,0,1-3.58,4.87,17.347,17.347,0,0,1-11.7,4.49,17.347,17.347,0,0,1-11.7-4.49,15.413,15.413,0,0,1-3.58-4.87,13.863,13.863,0,0,1-1.31-5.99V519.43a1.9,1.9,0,0,1,.37-1.21,1.026,1.026,0,0,1,.84-.47h8.53a1.067,1.067,0,0,1,1.03.47,2.551,2.551,0,0,1,.26,1.21v44.79Z" fill="#59595c"/>
        <path id="Path_136" data-name="Path 136" d="M796.15,519.44a1.815,1.815,0,0,1,.39-1.23,1.457,1.457,0,0,1,1.16-.45h14.35a17.67,17.67,0,0,1,6.49,1.16,15.118,15.118,0,0,1,5.04,3.19,14.175,14.175,0,0,1,3.25,4.85,15.778,15.778,0,0,1,1.16,6.1v4.48a9.783,9.783,0,0,1-.65,3.56,12.106,12.106,0,0,1-1.62,2.95,10.523,10.523,0,0,1-2.16,2.16,6.03,6.03,0,0,1-2.26,1.08,9.245,9.245,0,0,1,2.28,1.06,10.153,10.153,0,0,1,2.54,2.22,12.863,12.863,0,0,1,2.05,3.53,13.527,13.527,0,0,1,.84,5v5.56a13.393,13.393,0,0,1-1.29,5.9,14.722,14.722,0,0,1-3.45,4.61,15.7,15.7,0,0,1-4.98,3,16.627,16.627,0,0,1-5.84,1.06H797.7c-1.03,0-1.55-.56-1.55-1.68Zm10.61,8.83v14.7h5.17a7.214,7.214,0,0,0,1.7-.22,5.18,5.18,0,0,0,1.72-.75,4.577,4.577,0,0,0,1.36-1.49,4.722,4.722,0,0,0,.56-2.41v-4.4a5.237,5.237,0,0,0-1.49-4.12,5.394,5.394,0,0,0-3.73-1.31h-5.29Zm0,24.1v16.34h6.21a4.952,4.952,0,0,0,2.15-.45,4.412,4.412,0,0,0,1.57-1.21,5.37,5.37,0,0,0,.95-1.68,5.531,5.531,0,0,0,.32-1.87v-5.39a6.755,6.755,0,0,0-.43-2.54,5.278,5.278,0,0,0-1.16-1.79,4.789,4.789,0,0,0-1.68-1.06,5.725,5.725,0,0,0-1.98-.34h-5.95Z" fill="#59595c"/>
      </g>
    </g>
    <g id="Group_553" data-name="Group 553">
      <path id="Path_137" data-name="Path 137" d="M259.08,579.69l-8.12-8.12v17.27h21.38A44.2,44.2,0,0,1,259.08,579.69Z" fill="#393c7d"/>
      <path id="Path_138" data-name="Path 138" d="M363.27,500.31l8.5,8.5V491.15H350A44.456,44.456,0,0,1,363.27,500.31Z" fill="#393c7d"/>
      <path id="Path_139" data-name="Path 139" d="M352.75,510.83a29.665,29.665,0,0,0-41.93.08l-35.85,35.85L264.4,536.2l35.85-35.85a44.489,44.489,0,0,1,13.29-9.2H250.95v59.38l18.64,18.64a29.665,29.665,0,0,0,41.93-.08l25.29-25.29-20.12-20.12,10.56-10.56,30.68,30.68-35.85,35.85a44.49,44.49,0,0,1-13.29,9.2h62.98V529.86ZM317.69,543.8l-22.85,22.85-10.56-10.56,22.85-22.85Z" fill="#393c7d"/>
    </g>
  </g>
</svg>
    )
}
export default Logo
import { Box, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import { CaretCircleRight } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getJobsByType, translateField } from '../../../../../services/Helper';
import EnhancedMiniJobCard from '../../../abdelrahman/EnhancedMiniJobCard/EnhancedMiniJobCard';
import CategoryCard from '../../../yahya/components/category-card/CategoryCard';
import CareerLevelIcon from '../browse-jobs-by-section/CareerLevel.svg';
import JobTypeIcon from '../browse-jobs-by-section/JobType.svg';
import { useTranslation } from 'react-i18next';
import './ApplicantDashboardBrowse.scss';
import DashboardSection from '../../../abdelrahman/DashboardSection/DashboardSection';
import HeaderText from '../../../zeinnab/components/HeaderText/HeaderText';

const ApplicantDashboardBrowse = () => {
    const mdMatches = useMediaQuery('(max-width:1260px)');

    const navigate = useNavigate();
    const { t } = useTranslation();
    const type = 'RecentlyAdded';

    const careerTags = [
        { name: t('junior'), id: 3, type: 'careerLevels' },
        { name: t('mid_level'), id: 4, type: 'careerLevels' },
        { name: t('senior'), id: 5, type: 'careerLevels' },
        { name: t('manager'), id: 6, type: 'careerLevels' },
    ];

    const typeTags = [
        { name: t('full_time'), id: 1, type: 'jobTypes' },
        { name: t('part_time'), id: 2, type: 'jobTypes' },
        { name: t('freelance'), id: 3, type: 'jobTypes' },
        { name: t('intern'), id: 4, type: 'jobTypes' },
    ];

    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        getJobsByType('RecentlyAdded', 4, '', false).then((response) => {
            // setRecentlyAddedJobs(response.rows);
            setJobs(response.rows);
        });
    }, []);

    return (
        <>
            {mdMatches ? (
                <Box component="section" className="recently-added-jobs-section">
                    <Grid container marginTop={'80px'} direction={'row'} spacing={3}>
                        <Grid item container direction={'column'} xs={12} spacing={3}>
                            <Grid item>
                                <HeaderText title={t('browse_jobs')} fontSize={'20px'} fontWeight={'bold'} />
                            </Grid>
                            <Grid item>
                                <CategoryCard
                                    title={t('job_type')}
                                    icon={<img src={JobTypeIcon} width={26} />}
                                    // description={t("job_type_desc")}
                                    description={'Facere eos accusamus facere. Magni quia amet nisi voluptatibus molestiae harum. Ratione eveniet adipisci nihil ipsum cumque.'}
                                    tags={typeTags}
                                    ApplicantDashboardBrowse
                                />
                            </Grid>
                            <Grid item>
                                <CategoryCard
                                    title={t('career_level')}
                                    icon={<img src={CareerLevelIcon} width={26} />}
                                    // description={t("career_level_desc")}
                                    description={'Facere eos accusamus facere. Magni quia amet nisi voluptatibus molestiae harum. Ratione eveniet adipisci nihil ipsum cumque.'}
                                    tags={careerTags}
                                    ApplicantDashboardBrowse
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <DashboardSection type="RecentlyAdded" ApplicantDashboardBrowse />
                        </Grid>
                    </Grid>
                </Box>
            ) : (
                <Box component="section" className="recently-added-jobs-section" sx={{ marginTop: '80px' }}>
                    <Grid container marginTop={'80px'} marginBottom={'32px'} direction={'row'}>
                        <Grid item xs={4}>
                            <HeaderText text={t('browse_jobs')} fontSize={'20px'} fontWeight={'bold'} />
                        </Grid>
                        <Grid item xs={8} container direction={'row'} justifyContent="space-between" alignItems={'center'}>
                            <Grid item>
                                <HeaderText text={t(`${type}`)} fontSize={'20px'} fontWeight={'bold'} />
                            </Grid>
                            <Grid item>
                                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                    <Stack>
                                        <Link to={`/browsing-jobs?type=${type}`} className="featured-more">
                                            {t('see_more')}
                                        </Link>
                                    </Stack>
                                    <Stack>
                                        <CaretCircleRight size={16} />
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container direction={'row'} spacing={3}>
                        <Grid item xs={12} lg={4}>
                            <CategoryCard
                                title={t('job_type')}
                                icon={<img src={JobTypeIcon} width={26} />}
                                description={'Facere eos accusamus facere. Magni quia amet nisi voluptatibus molestiae harum. Ratione eveniet adipisci nihil ipsum cumque.'}
                                tags={typeTags}
                                ApplicantDashboardBrowse
                            />
                        </Grid>

                        <Grid item xs={12} lg={8} container spacing={3}>
                            {jobs?.slice(0, 2).map((job) => (
                                <Grid item xs={6}>
                                    <EnhancedMiniJobCard
                                        jobId={job?.id}
                                        title={translateField(job?.JobTitle, 'name')}
                                        company={job?.Recruiter?.company_name}
                                        logo={job?.Recruiter?.image}
                                        date={job?.created_at}
                                        noOfApplicants={job?.count}
                                        tags={[translateField(job?.WorkStyle, 'name'), translateField(job?.ExperienceType, 'name')]}
                                        location={`${translateField(job?.City, 'name')}, ${translateField(job?.Country, 'name')}`}
                                        onClick={() => navigate(`/jobs/${job?.id}`)}
                                        ApplicantDashboardBrowse
                                        isSaved={job?.is_saved}
                                        isApplied={job?.is_applied}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>

                    <Grid container paddingTop={'24px'} direction={'row'} spacing={3}>
                        <Grid item xs={12} lg={4}>
                            <CategoryCard
                                title={t('career_level')}
                                icon={<img src={CareerLevelIcon} width={26} />}
                                // description={t("career_level_desc")}
                                description={'Facere eos accusamus facere. Magni quia amet nisi voluptatibus molestiae harum. Ratione eveniet adipisci nihil ipsum cumque.'}
                                tags={careerTags}
                                ApplicantDashboardBrowse
                            />
                        </Grid>
                        <Grid item container spacing={3} xs={12} lg={8}>
                            {/* <Grid container columns={2}> */}
                            {jobs?.slice(2, 4).map((job) => (
                                <Grid item xs={6}>
                                    <EnhancedMiniJobCard
                                        jobId={job?.id}
                                        title={translateField(job?.JobTitle, 'name')}
                                        company={job?.Recruiter?.company_name}
                                        logo={job?.Recruiter?.image}
                                        date={job?.created_at}
                                        noOfApplicants={job?.count}
                                        tags={[translateField(job?.WorkStyle, 'name'), translateField(job?.ExperienceType, 'name')]}
                                        location={`${translateField(job?.City, 'name')}, ${translateField(job?.Country, 'name')}`}
                                        onClick={() => navigate(`/jobs/${job?.id}`)}
                                        ApplicantDashboardBrowse
                                        isSaved={job?.is_saved}
                                        isApplied={job?.is_applied}
                                    />
                                </Grid>
                            ))}
                            {/* </Grid> */}
                        </Grid>
                    </Grid>
                </Box>
            )}
        </>
    );
};

export default ApplicantDashboardBrowse;

import { Box, Grid, Typography } from "@mui/material";
import { Circle } from "phosphor-react";
import { useEffect, useState } from "react";
import DecisionButton from "../../../../components/decision-button/DecisionButton";
import MiniJobCard from "../../../../components/mini-job-card/MiniJobCard";
import {
  getCountry,
  getGovernorate,
  getWorkStyle,
  getWorkType,
  postNewJob,
  translateField,
} from "../../../../services/Helper";
import { CancelNextButtons } from "../../NewJob";
import "./ReviewJob.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import EnhancedMiniJobCard from "views/pages/abdelrahman/EnhancedMiniJobCard/EnhancedMiniJobCard";

const ReviewJob = ({
  control,
  register,
  setValue,
  getValues,
  errors,
  handleBack,
  handleNext,
  noOfApplicants,
  date,
  loading,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [active, setActive] = useState(true);
  const [country, setCountry] = useState(null);
  const [governorate, setGovernorate] = useState(null);
  const [city, setCity] = useState(null);
  const [workType, setWorkType] = useState(null);
  const [workStyle, setWorkStyle] = useState(null);

  // useEffect(() => {
  //     setValue("status", active ? 1 : 0);
  // }, [active])

  const company = useSelector((state) => state.profile.data?.recruiter);

  const activeTextColor = active ? "#fff" : "#3A3B81";
  const inactiveTextColor = active ? "#3A3B81" : "#fff";

  useEffect(() => {
    const country_id = getValues("country_id");

    if (country_id && country_id != "") {
      getCountry().then((data) => {
        if (data) {
          const tmpCountry = data.filter((item) => item.id == country_id);
          setCountry(tmpCountry?.length == 1 ? tmpCountry[0] : null);
        }
      });
    }
  }, [getValues("country_id")]);

  useEffect(() => {
    let isActive = getValues("status");
    console.log("STATUS", isActive);
    if (!isActive) {
      setValue("status", 0);
    }
    setActive(isActive == 1);
  }, []);

  useEffect(() => {
    const country_id = getValues("country_id");
    const city_id = getValues("city_id");

    if (country_id != "" && city_id != "") {
      getGovernorate(country_id).then((data) => {
        if (data) {
          const tmpCity = data.filter((item) => item.id == city_id);
          setCity(tmpCity?.length == 1 ? tmpCity[0] : null);
        }
      });
    }
  }, [getValues("country_id"), getValues("city_id")]);

  useEffect(() => {
    const experience_type_id = getValues("experience_type_id");
    if (experience_type_id != "") {
      getWorkType().then((data) => {
        if (data) {
          const tmpWorkType = data.filter(
            (item) => item.id == experience_type_id
          );
          setWorkType(tmpWorkType?.length == 1 ? tmpWorkType[0] : null);
        }
      });
    }
  }, [getValues("experience_type_id")]);

  useEffect(() => {
    const work_style_id = getValues("work_style_id");
    if (work_style_id != "") {
      getWorkStyle().then((data) => {
        if (data) {
          const tmpWorkStyle = data.filter((item) => item.id == work_style_id);
          setWorkStyle(tmpWorkStyle?.length == 1 ? tmpWorkStyle[0] : null);
        }
      });
    }
  }, [getValues("work_style_id")]);

  return (
    <Box component="div" className="review-job">
      <input type="hidden" {...register("status")} />
      <Grid container direction="column" spacing={1} md={10.5}>
        <Grid item marginBottom={1}>
          <Typography className="step-title">
            {t("review_job")}
          </Typography>
          <Typography className="step-description">
            {t("review_your_job")}
          </Typography>
        </Grid>
        <Grid item>
          <Grid container alignItems="center">
            <Grid item xs={12} lg={4}>
              <Typography className="step-subtitle">
                {t("job_status")}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={8}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <DecisionButton
                    active={!active}
                    onClick={() => {
                      setActive(false);
                      setValue("status", 0);
                    }}
                  >
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="center"
                      columnSpacing={1}
                    >
                      <Grid item>
                        <Typography
                          className="standart-text"
                          color={inactiveTextColor}
                        >
                          {t("inactive")}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Circle weight="fill" color="#919191" size={16} />
                      </Grid>
                    </Grid>
                  </DecisionButton>
                </Grid>
                <Grid item xs={6}>
                  <DecisionButton
                    active={active}
                    onClick={() => {
                      setActive(true);
                      setValue("status", 1);
                    }}
                  >
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="center"
                      columnSpacing={2}
                    >
                      <Grid item>
                        <Typography
                          className="standart-text"
                          color={activeTextColor}
                        >
                          {t("active")}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Circle weight="fill" color="#1E9F38" size={16} />
                      </Grid>
                    </Grid>
                  </DecisionButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={5}>
          <EnhancedMiniJobCard
            recruiter={true}
            title={translateField(getValues("job_title_id"), "name")}
            company={company?.company_name}
            logo={company?.image}
            date={date}
            noOfApplicants={noOfApplicants}
            tags={[
              translateField(workStyle, "name"),
              translateField(workType, "name"),
            ]}
            location={`${translateField(city, "name")}, ${translateField(
              country,
              "name"
            )}`}
            inactive={!active}
          />
          <Grid container justifyContent="end">
            <Grid item xs={12} lg={6}></Grid>
          </Grid>
        </Grid>
        <CancelNextButtons
          publish
          onBack={handleBack}
          publishText={t("publish")}
          backText={t("back_btn")}
          loading={loading}
        />
      </Grid>
    </Box>
  );
};
export default ReviewJob;

import React from 'react'
import { Box, Grid, Stack, Typography } from '@mui/material';
import "./SingleNewsPage.scss"
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getMonthYearDay, getSingleNews, translateField } from '../../services/Helper';
import { useSelector } from 'react-redux';

import { format, formatDistance, formatRelative, subDays } from 'date-fns'

const SingleNewsPage = () => {

    const { news_id } = useParams()
    const role = useSelector((state) => state.profile.role?.name);
    const [newsData, setNewsData] = useState()
    const [newsDate, setNewsDate] = useState()
    useEffect(() => {
        
        if (news_id)
            getSingleNews(role, news_id).then((res) => {
                
                
                setNewsData(res)
                setNewsDate(getMonthYearDay(new Date(newsData?.updated_at)))
            })

    }, [news_id])

    return (
        <>
            <Box className="single-news-wrapper">
                <Grid container>
                    {newsData?.image && (
                        <Grid item xs={12}>
                            <img src={newsData.image} alt="News" className='news-image' />
                        </Grid>)
                    }
                    <Grid item xs={12}>
                        <Stack
                            direction={"column"}
                            spacing={0.5}
                        >
                            <Box>
                                <Typography className="news-title">
                                    {translateField(newsData, "title")}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography className="news-date">
                                    {newsData && `
                                ${format(new Date(newsData?.updated_at), "dd")}
                                ${format(new Date(newsData?.updated_at), "MMMM")} 
                                ${format(new Date(newsData?.updated_at), "yyyy")}
                                -
                                ${format(new Date(newsData?.updated_at), "K")}:${format(new Date(newsData?.updated_at), "m")}
                                ${format(new Date(newsData?.updated_at), "b")}
                                `}
                                </Typography>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className="news-content">
                            {translateField(newsData, "message_property")}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default SingleNewsPage
import React, { useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import Logo from "../../../../assets/profile.png";
import { UsersThree } from "phosphor-react";
import { useDispatch, useSelector } from "react-redux";
import {
  getNextStep,
  getPrevStep,
  getStep,
} from "../../../../services/Stepper";
import { nextStep } from "../../../../state/actions/stepper/stepper";
import { stepperData } from "./stepper";
import { translateField } from "../../../../services/Helper";
import { submitCompany } from "../../../../state/actions/profile/profile";
import { useTranslation } from "react-i18next";
import Show from "./../../../../components/Show";
import Hide from "./../../../../components/Hide";

const ReviewAndSubmit = ({ handleEdit }) => {
  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const currentStepNo = useSelector((state) => state.stepper.currentStep);
  const finalStepNo = useSelector((state) => state.stepper.finalStep);
  const current = getStep(stepperData, currentStepNo);
  const recruiter = useSelector((state) => state.profile.data?.recruiter);

  const handleBack = () => {
    if (current.prev) {
      dispatch(nextStep({ currentStep: current.prev, finalStep: finalStepNo }));
    }
  };
  const handleNext = () => {
    setLoading(true);
    dispatch(submitCompany());
    if (current.next) {
      dispatch(nextStep({ currentStep: current.next, finalStep: finalStepNo }));
    }
    setLoading(false);
  };
  return (
    <>
      <Grid container direction="column">
        <Grid item container className="step-content review-info">
          <Grid item xs={12}>
            <Typography className="title">
              {" "}
              {t("review_title")}
            </Typography>
            <Typography className="text">
              {t("review_text")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid item container xs={12} className="info">
              {/* box for logo and name,location,friends in one row */}
              <Grid item container direction="row">
                <Grid item>
                  <img
                    src={recruiter?.image ? recruiter?.image : Logo}
                    className="logo-icon"
                  />
                </Grid>
                <Grid item className="logo-desc">
                  <Typography className="name">
                    {recruiter?.company_name}
                  </Typography>
                  <Typography className="location">
                    {translateField(recruiter?.City, "name")} ,
                    {translateField(recruiter?.City?.Governorate, "name")} ,
                    {translateField(
                      recruiter?.City?.Governorate?.Country,
                      "name"
                    )}
                  </Typography>
                  <Typography className="friends">
                    <UsersThree className="icon" />
                    <span>
                      {" "}
                      {translateField(recruiter?.CompanySize, "name")}
                    </span>
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {/* information  */}
                <Box sx={{ marginBottom: "10px" }}>
                  <Typography className="label">
                    {t("review_email")}
                  </Typography>
                  <Typography className="desc">
                    {recruiter?.work_email}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ marginBottom: "10px" }}>
                  <Typography className="label">
                    {t("review_number")}
                  </Typography>
                  <Typography className="desc">{recruiter?.mobile}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ marginBottom: "10px" }}>
                  <Typography className="label">
                    {t("review_industry")}
                  </Typography>
                  <Typography className="desc">
                    {translateField(recruiter?.CompanyIndustry, "name")}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ marginBottom: "10px" }}>
                  <Typography className="label">
                    {t("review_bio")}
                  </Typography>
                  <Typography className="desc">{recruiter?.bio}</Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              {current.prev && (
                <button className="back-btn" onClick={handleBack}>
                  {t("back_btn")}
                </button>
              )}
              <Show condition={handleEdit}>
                <button
                  type="submit"
                  className="next-btn"
                  onClick={handleEdit}
                  disabled={loading}
                  style={!loading ? { cursor: "pointer" } : {}}
                >
                  {t("submit_btn")}
                </button>
              </Show>
              <Hide condition={handleEdit}>
                <button
                  type="submit"
                  className="next-btn"
                  onClick={handleNext}
                  disabled={loading}
                  style={!loading ? { cursor: "pointer" } : {}}
                >
                  {t("submit_btn")}
                </button>
              </Hide>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ReviewAndSubmit;

import { RESET_PROCESS, START_PROCESS, STOP_PROCESS } from "./types";

export const startProcess = (process) => (dispatch) => {
  dispatch({
    type: START_PROCESS,
    payload: {
      process,
    },
  });
};

export const stopProcess = (process) => (dispatch) => {
  dispatch({
    type: STOP_PROCESS,
    payload: {
      process,
    },
  });
};

export const resetProcess = (process) => (dispatch) => {
    dispatch({
      type: RESET_PROCESS,
      payload: {
        process,
      },
    });
  };

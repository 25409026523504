import { Grid, Stack, useMediaQuery } from "@mui/material";
import Show from "components/Show";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authLogout } from "state/actions/auth/auth";
import Logo from "../../../../assets/Logo2SVG";
import { login } from "../../../../services/auth/Auth";
import { scrollToSection } from "../../../../services/Helper";
import { CHANGE_MODE } from "../../../../state/actions/mode/types";
import EnhancedButton from "../../yahya/components/enhanced-button/EnhancedButton";
import Links from "../../zeinnab/components/navbar/components/links/Links";
import MobileNavbar from "../../zeinnab/components/navbar/components/mobile-navbar/MobileNavbar";
import LanguageDropdown from "../LanguageDropdown/LanguageDropdown";

const GuestRecruiterContent = ({ currentSection, handleSelection }) => {
  const isMobile = useMediaQuery("(max-width:1200px)");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [openDrawer, setOpenDrawer] = useState(false);

  const loggedIn = useSelector((state) => state.auth?.token);

  useEffect(
    () => console.log("currentSectionRecruiter", currentSection),
    [currentSection]
  );

  const links = [
    {
      value: "home",
      label: "home",
      onClick: () => {
        window.isNavigating = true;
        setOpenDrawer(false);
        handleSelection(null, "home");
        if (window.location.pathname === "/") {
          scrollToSection("home");
        } else {
          navigate("/");
          setTimeout(() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }, 100);
        }
      },
    },
    {
      value: "how-it-works",
      label: "how_it_works",
      onClick: () => {
        window.isNavigating = true;
        setOpenDrawer(false);
        handleSelection(null, "how-it-works");
        if (window.location.pathname !== "/") {
          navigate("/");
          setTimeout(() => {
            scrollToSection("how-it-works");
          }, 100);
        } else {
          scrollToSection("how-it-works");
        }
      },
    },
    {
      value: "our-advantages",
      label: "our_advgs",
      onClick: () => {
        window.isNavigating = true;
        setOpenDrawer(false);
        handleSelection(null, "our-advantages");
        if (window.location.pathname !== "/") {
          navigate("/");
          setTimeout(() => {
            scrollToSection("our-advantages");
          }, 100);
        } else {
          scrollToSection("our-advantages");
        }
      },
    },
    {
      value: "our-clients",
      label: "clients",
      onClick: () => {
        window.isNavigating = true;
        setOpenDrawer(false);
        handleSelection(null, "our-clients");
        if (window.location.pathname !== "/") {
          navigate("/");
          setTimeout(() => {
            scrollToSection("our-clients");
          }, 100);
        } else {
          scrollToSection("our-clients");
        }
      },
    },
    {
      value: "download-app",
      label: "download_app",
      onClick: () => {
        window.isNavigating = true;
        setOpenDrawer(false);
        handleSelection(null, "download-app");
        if (window.location.pathname !== "/") {
          navigate("/");
          setTimeout(() => {
            scrollToSection("download-app");
          }, 100);
        } else {
          scrollToSection("download-app");
        }
      },
    },
    {
      value: "contact-enhanced",
      label: "contact_us",
      newPage: true,
      onClick: () => {
        window.isNavigating = true;
        setOpenDrawer(false);
        handleSelection(null, "contact-enhanced");
        navigate("/contact");
        setTimeout(() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }, 100);
      },
    },
  ];

  return (
    <>
      {!isMobile ? (
        <Grid container direction={"row"} alignItems={"center"}>
          <Grid item md={2}>
            <Logo />
          </Grid>
          <Grid item md={6.5}>
            <Stack>
              <Links
                links={links}
                orientation="horizontal"
                currentSection={currentSection}
                handleChange={handleSelection}
              />
            </Stack>
          </Grid>
          <Grid
            item
            md={3.5}
            container
            direction={"row"}
            spacing={3}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Show condition={!loggedIn}>
                <EnhancedButton
                  buttonText={t("find_a_job")}
                  variant={"outlined"}
                  padding="13.5px 33px"
                  onClick={() => {
                    console.log("guestApplicant");
                    handleSelection(null, "home");
                    dispatch({
                      type: CHANGE_MODE,
                      payload: { name: "guestApplicant" },
                    });
                  }}
                />
              </Show>
              <Show condition={loggedIn}>
                <EnhancedButton
                  buttonText={"Join Us"}
                  variant={"outlined"}
                  padding="13.5px 38px"
                  onClick={() => {
                    navigate("/join-us-reason");
                  }}
                />
              </Show>
            </Grid>
            <Grid item>
              <Show condition={loggedIn}>
                <EnhancedButton
                  buttonText={t("log_out")}
                  variant={"contained"}
                  padding={"16px 33px"}
                  onClick={() => {
                    dispatch(authLogout(navigate));
                  }}
                />
              </Show>
              <Show condition={!loggedIn}>
                <EnhancedButton
                  buttonText={t("login")}
                  variant={"contained"}
                  padding={"16px 33px"}
                  onClick={() => {
                    navigate("/login");
                  }}
                />
              </Show>
            </Grid>
            <Grid item>
              <LanguageDropdown />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <MobileNavbar
          links={links}
          currentSection={currentSection}
          handleChange={handleSelection}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
        />
      )}
    </>
  );
};

export default GuestRecruiterContent;

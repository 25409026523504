import { Grid, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { t } from "i18next";
import React from "react";
import CategoryCard from "../../../yahya/components/category-card/CategoryCard";
import "./BrowseJobsBySection.css";

import CareerLevelIcon from "./CareerLevel.svg";
import JobTypeIcon from "./JobType.svg";

const BrowseJobsBySection = () => {
  const mdMatches = useMediaQuery("(min-width:701px) and (max-width:1000px)");
  const smMatches = useMediaQuery("(max-width:700px)");

  const careerTags = [
    { name: t("junior"), id: 3, type: "careerLevels" },
    { name: t("mid_level"), id: 4, type: "careerLevels" },
    { name: t("senior"), id: 5, type: "careerLevels" },
    { name: t("manager"), id: 6, type: "careerLevels" },
  ];

  const typeTags = [
    { name: t("full_time"), id: 1, type: "jobTypes" },
    { name: t("part_time"), id: 2, type: "jobTypes" },
    { name: t("freelance"), id: 3, type: "jobTypes" },
    { name: t("intern"), id: 4, type: "jobTypes" },
  ];

  return (
    <Box
      component={"section"}
      className="section-browse-jobs"
      padding={smMatches ? "24px" : "0px"}
    >
      <Grid container justifyContent={"center"}>
        <Grid item xs={12} align={"center"}>
          <Typography className="title">
            {t("browse_jobs_by")}
          </Typography>
        </Grid>
        <Grid item container justifyContent={"center"} spacing={5} xs={12}>
          <Grid item lg={6}>
            <CategoryCard
              title={t("career_level")}
              icon={<img src={CareerLevelIcon} width={26} />}
              description={t("career_level_desc")}
              tags={careerTags}
            />
          </Grid>
          <Grid item lg={6}>
            <CategoryCard
              title={t("job_type")}
              icon={<img src={JobTypeIcon} width={26} />}
              description={t("job_type_desc")}
              tags={typeTags}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BrowseJobsBySection;

import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import MiniJobCard from "../../components/mini-job-card/MiniJobCard";
import Show from "../../components/Show";
import Permissions from "../../constants/permissions";
import {
  getCompanyProfile,
  getRecruiterJobs,
  perm,
  translateField,
} from "../../services/Helper";
import EnhancedMiniJobCard from "../pages/abdelrahman/EnhancedMiniJobCard/EnhancedMiniJobCard";
import "./CompanyProfile.scss";
import ProfileCard from "./components/company-profile-card/ProfileCard";

const CompanyProfile = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [profile, setProfile] = useState(null);
  const [profileLoaded, setProfileLoaded] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [jobsLoaded, setJobsLoaded] = useState(false);

  const { t, i18n } = useTranslation();

  const permissions = useSelector((state) => state.profile.role?.permissions);
  const role = useSelector((state) => state.profile.role);
  const companyId = useSelector((state) => state.profile?.data?.recruiter?.id);

  useEffect(() => {
    getCompanyProfile(
      params.company_id
        ? params.company_id == companyId
        : perm(permissions, Permissions.editCompanyProfile),
      role?.name,
      params.company_id
    ).then((data) => {
      setProfile(data.recruiter);
      setProfileLoaded(true);

      if (params.company_id && params.company_id != companyId) {
        setJobs(data.job_list.rows);
        setJobsLoaded(true);
      }
    });
    if (
      params.company_id
        ? params.company_id == companyId
        : perm(permissions, Permissions.editCompanyProfile)
    ) {
      getRecruiterJobs(1).then((data) => {
        setJobs(data.rows);
        setJobsLoaded(true);
      });
    }
  }, [params.company_id]);
  return (
    <Grid container direction="column" className="company-profile-page">
      <Helmet>
        <meta property="og:title" content={profile?.company_name} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="article" />
        <meta property="og:description" content={profile?.bio} />
        <meta property="og:image" content={profile?.image} />

        <meta property="twitter:title" content={profile?.company_name} />
        <meta property="twitter:description" content={profile?.bio} />
        <meta property="twitter:image" content={profile?.image} />
        <meta property="twitter:card" content="" />
      </Helmet>
      <Grid item>
        <Loader
          data={profileLoaded}
          width={"100%"}
          height={300}
          style={{ borderRadius: "1rem" }}
        >
          <ProfileCard
            name={profile?.company_name}
            logo={profile?.image}
            phone={profile?.mobile}
            email={profile?.work_email}
            location={`${translateField(
              profile?.City?.Governorate?.Country,
              "name"
            )}, ${translateField(
              profile?.City?.Governorate,
              "name"
            )}, ${translateField(profile?.City, "name")}`}
            description={profile?.bio}
            industry={translateField(profile?.CompanyIndustry, "name")}
            companySize={translateField(profile?.CompanySize, "name")}
            followed={profile?.is_followed}
            recruiterId={profile?.id}
          />
        </Loader>
      </Grid>
      <Loader
        data={jobsLoaded}
        width={"100%"}
        height={350}
        style={{ borderRadius: "1rem" }}
      >
        <Grid item>
          <Show condition={jobs.length > 0}>
            <Typography className="title">{t("jobs")}</Typography>
            <Grid container direction="column">
              <Grid item xs={12} container direction="row" spacing={2}>
                {jobs.map((job, index) => {
                  return (
                    <Grid item xs={12} md={6} lg={4} xl={4} className="mb-16">
                      <EnhancedMiniJobCard
                        title={translateField(job?.JobTitle, "name")}
                        company={job?.Recruiter?.company_name}
                        logo={job?.Recruiter?.image}
                        date={job?.created_at}
                        noOfApplicants={job?.count}
                        tags={[
                          translateField(job?.WorkStyle, "name"),
                          translateField(job?.ExperienceType, "name"),
                        ]}
                        location={`${translateField(
                          job?.City,
                          "name"
                        )}, ${translateField(job?.Country, "name")}`}
                        onClick={() => navigate(`/jobs/${job?.id}`)}
                        jobId={job?.id}
                        isSaved={job?.is_saved}
                        isApplied={job?.is_applied}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Show>
        </Grid>
      </Loader>
    </Grid>
  );
};

export default CompanyProfile;

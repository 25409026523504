import { Grid, Typography } from "@mui/material";
import { Checks, Buildings } from "phosphor-react";
import { useTranslation } from "react-i18next";
import DecisionButton from "../decision-button/DecisionButton";
import UserAvatar from "../user-avatar/UserAvatar";
import "./FeaturedCompany.scss";
import Show from "./../Show";
import { changeCompanyStatus } from "../../services/Helper";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const FeaturedCompany = ({ company, followedCompanies }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isFollowed, setIsFollowed] = useState();
  const [loading, setLoading] = useState(false);
  const role = useSelector((state) => state.profile.role?.name);

  const handleFollow = (e) => {
    e.stopPropagation();
    if (!loading) {
      setLoading(true);
      changeCompanyStatus(company.id, role).then((response) => {
        if(!followedCompanies) setIsFollowed((prev) => !prev);
      }).finally(() => {
        setLoading(false);
      });;
    }
  };
  useEffect(() => {
    setIsFollowed(company.is_followed);
  }, []);

  return (
    <Grid
      container
      direction={"column"}
      className="featured-company"
      onClick={() => {
        navigate(`/company/${company.id}`);
      }}
    >
      <Grid item container direction={"column"} alignItems="center">
        <Grid item>
          <Show condition={company.image}>
            <UserAvatar showAvatar size="large" avatar={company.image} />
          </Show>
          <Show condition={!company.image}>
            <Buildings size={80} />
          </Show>
        </Grid>
        <Grid item>
          <Typography className="company-name">
            {company.company_name}
          </Typography>
        </Grid>
        <Grid item>
          <Typography className="company-followers">
            {t("followers", { count: company.follower_count })}
          </Typography>
        </Grid>
      </Grid>

      <Grid item>
        <DecisionButton active={!isFollowed} onClick={handleFollow}>
          {isFollowed ? (
            <>
              <Checks className="followed-icon" />
              <Typography className="followed">
                {t("followed")}
              </Typography>
            </>
          ) : (
            <Typography className="follow">{t("follow")}</Typography>
          )}
        </DecisionButton>
      </Grid>
    </Grid>
  );
};
export default FeaturedCompany;

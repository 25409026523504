import {
  Accordion,
  AccordionDetails,
  AccordionSummary, ClickAwayListener, MenuItem,
  MenuList, Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { getDocumentDirection, translateField } from "../../../../services/Helper";
import "./EnhancedDropdown.scss";

const EnhancedDropdown = ({ options, onChange, error, selectedOption, role }) => {
  const [selected, setSelected] = useState(
    selectedOption ? selectedOption : options?.[0]
  );
  const [expanded, setExpanded] = useState(false);
  const documentDirection = getDocumentDirection()

  const handleChange = (index) => {
    setExpanded(false);
    setSelected(options[index]);
    onChange?.(options[index]);
  };
  useEffect(() => {
    console.log(role)

  }, [role])
  return (
    <ClickAwayListener onClickAway={() => setExpanded(false)} >

      <Accordion
        expanded={expanded}
        elevation={0}
        disableGutters
        className="enhanced-dropdown"
        onChange={() => setExpanded(!expanded)}
        sx={{ maxWidth: "149px", }}
      >
        <AccordionSummary
          disabled={role === "RECRUITER"}
          className="selected-dropdown-summary"
          expandIcon={role !== "RECRUITER" && <Typography className="dropdown-icon">▼</Typography>}
        >
          <Typography className="selected-text">
            {translateField(selected, "name")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="dropdown-options">
          <MenuList>
            {options.map(
              (option, index) =>
                option.id !== selected.id && (
                  <MenuItem onClick={() => handleChange(index)}>
                    <Typography className="option-text">
                      {translateField(option, "name")}
                    </Typography>
                  </MenuItem>
                )
            )}
          </MenuList>
        </AccordionDetails>
      </Accordion>
    </ClickAwayListener>

  );
};
export default EnhancedDropdown;

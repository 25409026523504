import { useState, useEffect } from "react";
import BrowsingPage from "../../components/browsing-page/BrowsingPage";
import { getJobs, scrollIntoView } from "../../services/Helper";
import Scroller from "../../components/Scroller";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

// const titles = {
//   Interests: "Recommended For You",
//   FollowedCompany: "By Companies You Follow",
//   RecentlyAddded: "Recently Added",
//   Saved: "Saved Jobs",
//   Applied: "Applied Jobs",
// };

const BrowsingJobsPage = () => {
  const [data, setData] = useState([]);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [page, setPage] = useState(1);
  const [type, setType] = useState("");
  const [ready, setReady] = useState(false);

  const { t, i18n } = useTranslation();

  const role = useSelector((state) => state.profile.role.name);

  const titles = {
    Interests: t("recommended_jobs"),
    FollowedCompany: t("followed_jobs"),
    RecentlyAdded: t("recent_jobs"),
    Saved: t("saved_jobs"),
    Applied: t("applied_jobs"),
  };

  const fetchPage = (jobType, pageNumber) => {
    setReady(false);
    
    if(role !== "RECRUITER"){
      getJobs(jobType, pageNumber).then((jobs) => {
        setData(jobs?.rows);
        setNumberOfPages(jobs?.meta?.totalNumberOfPages);
        setReady(true);
      });
    } else {
      setReady(true);
    }
    
  };

  useEffect(() => {
    let paramsString = window.location.href.split("?")[1];
    let searchParams = new URLSearchParams(paramsString);
    let jobType = searchParams.get("type");
    setType(jobType);
    fetchPage(jobType, 1);
  }, []);

  const changePage = (newPage) => {
    fetchPage(type, newPage);
    setPage(newPage);
    //scrollIntoView("browsing-page-top");
  };

  const scrollIntoJobs = () => {
    // document.getElementById("jobs_wrap").scrollTop = 0;
    // 
  }

  useEffect(() => {
    scrollIntoView("browsing-page-top");
  }, [ready]);

  return (
    <>
      {/* <Scroller id={"browsing-page-top"} /> */}
      <BrowsingPage
        data={data}
        showFilter={true}
        title={titles[type]}
        pagesManager={{
          page: page,
          setPage: changePage,
          numberOfPages: numberOfPages,
          onNext: scrollIntoJobs,
          onBack: scrollIntoJobs
        }}
        ready={ready}
      />
    </>
  );
};
export default BrowsingJobsPage;

import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import noDataAnimation from "../../../../../assets/lootie-files/no-data.json";
import DecisionButton from "../../../../../components/decision-button/DecisionButton";
import ErrorPage from "../../../../../components/error-page/ErrorPage";
import Hide from "../../../../../components/Hide";
import Loader from "../../../../../components/loader/Loader";
import Pagination from "../../../../../components/pagination/Pagination";
import Show from "../../../../../components/Show";
import {
  getRecruiterJobs,
  translateField
} from "../../../../../services/Helper";
import EnhancedMiniJobCard from "../../../abdelrahman/EnhancedMiniJobCard/EnhancedMiniJobCard";
import EnhancedButton from "../../../yahya/components/enhanced-button/EnhancedButton";
import StarterDialog from "./../../../../new-job/components/starter-dialog/StarterDialog";

// const noDataAnimation = React.lazy(() => import("../../../../../assets/lootie-files/no-data.json"));

const RecruiterJobs = () => {
  const { t } = useTranslation();
  const [active, setActive] = useState(1);
  const [jobs, setJobs] = useState([]);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [page, setPage] = useState(1);
  const [jobsLoaded, setJobsLoaded] = useState(false);
  const navigate = useNavigate();
  const activeTextColor = active ? "#fff" : "#3A3B81";
  const inactiveTextColor = active ? "#3A3B81" : "#fff";
  const role = useSelector((state) => state.profile?.role?.name);
  const isCvCreated = useSelector(
    (state) => state.profile?.data?.cv?.User?.is_cv_created
  );
  const isCompanyCreated = useSelector(
    (state) => state.profile?.data?.recruiter?.is_company_created
  );

  const notCompleted = isCvCreated == 0 || isCompanyCreated == 0;

  const fetchPage = (pageNumber) => {
    getRecruiterJobs(pageNumber, active, 15, role, notCompleted).then(
      (data) => {
        setJobs(data?.rows);
        setNumberOfPages(data?.meta?.totalNumberOfPages);
        setJobsLoaded(true);
      }
    );
  };

  const changePage = (newPage) => {
    fetchPage(newPage);
    setPage(newPage);
  };

  useEffect(() => {
    setJobsLoaded(false);
    fetchPage(1);
    setPage(1);
  }, [active]);

  const [noDataAnimation, setNoDataAnimation] = useState(null);
  useEffect(() => {
    import("../../../../../assets/lootie-files/no-data.json").then(data => setNoDataAnimation(data));
  }, [])

  return (
    <Box component={"section"} id="home" sx={{ marginTop: "80px" }}>
      <Stack spacing={5}>
        <Stack direction="row" justifyContent="space-between" >
          <Stack direction="row" spacing={2} sx={{ minWidth: "270px" }}>
            <DecisionButton
              active={active}
              rounded
              onClick={() => setActive(1)}
            >
              <Typography color={activeTextColor} className="standard-text ">
                {t("active_jobs")}
              </Typography>
            </DecisionButton>
            <DecisionButton
              active={!active}
              onClick={() => setActive(0)}
              rounded
            >
              <Typography color={inactiveTextColor} className="standard-text">
                {t("inactive_jobs")}
              </Typography>
            </DecisionButton>
          </Stack>
          <EnhancedButton
            buttonText={t("post_job")}
            padding={"10px 24px"}
            onClick={() => navigate("/new/job")}
          />
        </Stack>
        <Grid
          className="recruiter-jobs-container"
          container
          spacing={2}
          justifyContent="center"
        >
          <Loader
            data={jobsLoaded}
            width={"100%"}
            height={300}
            style={{ borderRadius: "1rem" }}
          >
            <Show condition={jobsLoaded && jobs.length > 0}>
              <Grid container spacing={2}>
                {jobs?.map((job) => {
                  console.log("job", job);
                  return (
                    <Grid item xs={12} lg={4}>
                      <EnhancedMiniJobCard
                        recruiter={true}
                        title={translateField(job?.JobTitle, "name")}
                        company={job?.Recruiter?.company_name}
                        logo={job?.Recruiter?.image}
                        date={job?.created_at}
                        noOfApplicants={job?.count}
                        tags={[
                          translateField(job?.WorkStyle, "name"),
                          translateField(job?.ExperienceType, "name"),
                        ]}
                        location={`${translateField(
                          job?.City,
                          "name"
                        )}, ${translateField(job?.Country, "name")}`}
                        onClick={() => navigate(`/jobs/${job?.id}`)}
                        jobId={job?.id}
                        inactive={!active}
                        // isSaved={job?.is_saved}
                        // isApplied={job?.is_applied}
                      />
                    </Grid>
                  );
                })}
              </Grid>
              {numberOfPages > 1 && <Grid container item xs={12} sm={6} md={5}>
                <Pagination
                  pagesManager={{
                    page: page,
                    setPage: changePage,
                    numberOfPages: numberOfPages,
                  }}
                />
              </Grid>}
            </Show>
          </Loader>
          {jobsLoaded && (
            <Hide condition={jobs.length >= 0}>
              <StarterDialog />
            </Hide>
          )}
        </Grid>

        <Show condition={jobsLoaded && jobs.length == 0}>
          <Grid item xs={12} justifyContent="center">
            <ErrorPage
              hideAction
              animationData={noDataAnimation}
              title={t("oops")}
              description={t("no_jobs")}
            />
          </Grid>
        </Show>
      </Stack>
    </Box>
  );
};

export default RecruiterJobs;

import { Box, Grid } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import emptyApplicants from '../../../../assets/lootie-files/3617-shopping-bag-error.json';
import ErrorPage from '../../../../components/error-page/ErrorPage';
import SeeAllLinks from "../../../../components/see-all-links/SeeAllLinks";
import { translateField } from '../../../../services/Helper';
import ApplicantsAvatar from './ApplicantsAvatar';

import Hide from '../../../../components/Hide';
import Show from '../../../../components/Show';
import "./ApplicantCard.scss";

const ApplicantCard = (props) => {

    const { t, i18 } = useTranslation();
    const { applicantsCount, applicantApplyJob, job_id } = props;
    const test = () => {
        applicantApplyJob.map((applicants) => {
            
        })
    }
    useEffect(() => {
        // test()
    }, []);
    const theme = useTheme()
    const isXs = useMediaQuery('(max-width:899px)')
    return (
        <Box className='applicant-card'>
            <Grid container sx={{ marginBottom: "1rem" }}>
                <Grid item xs={6} container>
                    <Grid item>
                        <span className='title'>{t("applicants")}</span>
                    </Grid>
                    <Grid item>
                        <span className='num'>{`(${applicantsCount})`}</span>
                    </Grid>
                </Grid>
                <Hide condition={applicantsCount == 0}>
                    <Grid item xs={6} container justifyContent={"flex-end"}>
                        <SeeAllLinks title={t("see-all")} bolded job_id={job_id} />
                    </Grid>
                </Hide>
            </Grid>
            <Show condition={applicantsCount === 0}>
                <ErrorPage
                    mini
                    description={t("no_applicants")}
                    action={() => {}}
                    animationData={emptyApplicants}
                />
            </Show>
            <Show condition={applicantsCount > 0}>
                {applicantApplyJob?.slice(0, 8).map((applicants) => {
                    return (
                        <ApplicantsAvatar
                            name={`${applicants.User?.first_name} ${applicants.User.second_name}`}
                            jobtitle={translateField(applicants.User?.JobTitle, 'name')}
                            image={applicants.User?.image}
                            job_id={job_id}
                            applicant_id={applicants?.User?.id}
                        />
                    )
                }
                )}
            </Show>
        </Box>
    )
}

export default ApplicantCard
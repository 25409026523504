import { Avatar, Box, Grid, IconButton, Paper, Stack, Typography } from '@mui/material';
import { Check, Plus } from 'phosphor-react';
import React, { useState } from 'react';
import { shareJob } from './../../../services/Helper';

const UserCard = ({ image, name, email, isExternal, job_id }) => {
    const [isInvited, setIsInvited] = useState()

    const inviteUser = () => {
        shareJob(job_id, [email]).then((res) => {
            console.log(res)
            setIsInvited(true)
        })
    }

    return (
        <>
            <Paper elevation={0} sx={{
                backgroundColor: isInvited ? "#3A3B811A" : "rgba(58, 59, 129, 0)",
                borderRadius: "8px"
            }}>
                <Grid container direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ zIndex: "3", padding: "8px 16px" }}>
                    <Grid item xs={8} justifyContent={"flex-start"} container direction={"row"} columnSpacing={1}>
                        <Grid item >
                            <Avatar
                                sx={{
                                    height: "50px",
                                    width: "50px",
                                }}
                                src={image}
                                variant={"circular"}
                            />
                        </Grid>
                        <Grid item >
                            <Box>
                                <Stack direction={"column"}>
                                    <Typography>{name}</Typography>
                                    <Typography>{email}</Typography>
                                </Stack>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={1}>
                        {isInvited ?
                            <IconButton sx={{ backgroundColor: "#3A3B81" }}>
                                <Check size={20} color="#FFFFFF" weight="bold" />
                            </IconButton>
                            :
                            <IconButton sx={{ backgroundColor: "#9191911A " }} onClick={inviteUser}>
                                <Plus size={20} color="#919191" />
                            </IconButton>
                        }
                    </Grid>
                </Grid>
            </Paper>
            {/* <UserAvatar showAvatar showName showTitle name={isExternal ? "External User" : name} title={email} image={image && image} /> */}
        </>
    )
}

export default UserCard

import { useState, useEffect } from "react";
import { Grid, Typography, FormGroup, Button } from "@mui/material";
import {
  At,
  Phone,
  Browser,
  FacebookLogo,
  LinkedinLogo,
  TwitterLogo,
  GithubLogo,
  GitlabLogo,
  DribbbleLogo,
  PinterestLogo,
  BehanceLogo,
} from "phosphor-react";
import IconInput from "../../../components/IconInput";
import MultipleInput from "../../../components/MultipleInput";
import AddButton from "../../../components/AddButton";
import { useDispatch, useSelector } from "react-redux";
import { getNextStep, getPrevStep, getStep } from "../../../services/Stepper";
import { nextStep } from "../../../state/actions/stepper/stepper";
import { stepperData } from "./stepper";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import {
  addContactPortfolio,
  editContactPortfolio,
} from "../../../state/actions/profile/profile";

const ContantInfoPortfolio = () => {
  const { t, i18n } = useTranslation();
  const user = useSelector((state) => state?.profile?.data?.cv?.User);
  let CvSocialMedia = useSelector((state) => {
    let socialMedia = state?.profile?.data?.cv?.User?.CvSocialMedia;
    if (Array.isArray(socialMedia)) return socialMedia;
    else return [];
  });
  let CvPortfolios = useSelector((state) => {
    let portfolios = state?.profile?.data?.cv?.User?.CvPortfolios;
    if (Array.isArray(portfolios)) return portfolios;
    else return [];
  });
  let mobile = useSelector((state) => {
    let mobile = state?.profile?.data?.cv?.User?.mobile;
    if (mobile) return mobile;
    else return "";
  });
  let work_email = useSelector((state) => {
    let work_email = state?.profile?.data?.cv?.User?.work_email;
    if (work_email) return work_email;
    else return "";
  });
  let email = useSelector((state) => {
    let email = state?.profile?.data?.cv?.User?.email;
    if (email) return email;
    else return "";
  });

  const schema = yup
    .object({
      phone: yup
        .string()
        .matches(
          // /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
          /^\+[0-9]{1,3}[0-9]{4,14}(?:x.+)?$/,
          {
            message: t("phone_format"),
            excludeEmptyString: true,
          }
        )
        .notRequired()
        .nullable(),
      email: yup
        .string()
        .email(t("email_format"))
        .required(t("required_input")),
      socialMedia: yup
        .array()
        .of(
          yup.object().shape({
            type: yup.object(),
            url: yup
              .string()
              .matches(
                /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
                t("url_format")
              )
              .required(t("required_input")),
          })
        )
        .notRequired(),
      portfolio: yup
        .array()
        .of(
          yup.object().shape({
            url: yup
              .string()
              .matches(
                /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
                t("url_format")
              )
              .required(t("required_input")),
          })
        )
        .notRequired(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    trigger,
    control,
  } = useForm({
    reValidateMode: "onChange",
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      email: email,
      phone: user?.mobile,
    },
  });

  const {
    fields: socialMediaFields,
    append: appendSocialMediaField,
    remove: removeSocialMediaField,
  } = useFieldArray({
    name: "socialMedia",
    control,
  });

  const {
    fields: portfolioFields,
    append: appendPortfolioField,
    remove: removePortfolioField,
  } = useFieldArray({
    name: "portfolio",
    control,
  });

  const onSubmit = async (data) => {
    setLoading(true);
    let socialData = [];
    let portfolioData = [];

    data.socialMedia?.forEach((element) => {
      socialData.push({
        name:
          element.type.displayName.replace("Logo", "") === "Browser"
            ? "Others"
            : element.type.displayName.replace("Logo", ""),
        link: element.url,
      });
    });

    data.portfolio?.forEach((element) => {
      portfolioData.push({
        name:
          element.type.displayName.replace("Logo", "") === "Browser"
            ? "Others"
            : element.type.displayName.replace("Logo", ""),
        link: element.url,
      });
    });

    let contactPortfolioData = {
      work_email: data.email,
      ...(data.phone && { mobile: data.phone }),
      ...(socialData.length > 0 && { social: socialData }),
      ...(portfolioData.length > 0 && { portfolio: portfolioData }),
    };

    if (finalStepNo > currentStepNo) {
      await dispatch(editContactPortfolio(contactPortfolioData)).finally(() => {
        setLoading(false);
      });
    } else {
      await dispatch(addContactPortfolio(contactPortfolioData)).finally(() => {
        setLoading(false);
      });
    }

    handleNext();
  };

  const dispatch = useDispatch();
  const currentStepNo = useSelector((state) => state.stepper.currentStep);
  const finalStepNo = useSelector((state) => state.stepper.finalStep);
  const current = getStep(stepperData, currentStepNo);

  const [socialIndices, setSocialIndices] = useState([]);
  const [socialCounter, setSocialCounter] = useState(0);
  const [portfolioIndices, setPortfolioIndices] = useState([]);
  const [portfolioCounter, setPortfolioCounter] = useState(0);

  const [selectedSocialTypes, setSelectedSocialTypes] = useState([]);
  const [selectedPortfolioTypes, setSelectedPortfolioTypes] = useState([]);
  const [loading, setLoading] = useState(false);

  const addSocialMedia = (type) => {
    setSocialIndices((prevIndexes) => [
      ...prevIndexes,
      { index: socialCounter, type: type },
    ]);
    setSocialCounter((prevCounter) => prevCounter + 1);
  };

  const removeSocialMediaType = (index) => {
    let socialMediaName = getValues("socialMedia")[
      index
    ].type.displayName.replace("Logo", "");
    let selectedSocialMedia = [...selectedSocialTypes];
    let socialMediaTypeIndex = selectedSocialMedia.indexOf(socialMediaName);

    //let socialMediaFieldValues = [...getValues("socialMedia")];

    if (socialMediaTypeIndex > -1) {
      selectedSocialMedia.splice(socialMediaTypeIndex, 1);
      //socialMediaFieldValues.splice(socialMediaTypeIndex, 1);
      setSelectedSocialTypes(selectedSocialMedia);
      //setValue("socialMedia", socialMediaFieldValues);
      //trigger("socialMedia");
    }

    // setSocialIndices((prevIndexes) => [
    //   ...prevIndexes.filter((item) => item.index !== index),
    // ]);
    // setSocialCounter((prevCounter) => prevCounter - 1);
  };

  const addPortfolio = () => {
    setPortfolioIndices((prevIndexes) => [...prevIndexes, portfolioCounter]);
    setPortfolioCounter((prevCounter) => prevCounter + 1);
  };

  const removePortfolioType = (index) => {
    let portfolioName = getValues("portfolio")[index].type.displayName.replace(
      "Logo",
      ""
    );
    let selectedPortfolio = [...selectedPortfolioTypes];
    let portfolioTypeIndex = selectedPortfolio.indexOf(portfolioName);

    //let socialMediaFieldValues = [...getValues("socialMedia")];

    if (portfolioTypeIndex > -1) {
      selectedPortfolio.splice(portfolioTypeIndex, 1);
      //socialMediaFieldValues.splice(socialMediaTypeIndex, 1);
      setSelectedPortfolioTypes(selectedPortfolio);
      //setValue("socialMedia", socialMediaFieldValues);
      //trigger("socialMedia");
    }
  };

  const handleBack = () => {
    if (current.prev) {
      dispatch(nextStep({ currentStep: current.prev, finalStep: finalStepNo }));
    }
  };
  const handleNext = () => {
    //onSubmit(handleSubmit);
    if (current.next) {
      dispatch(nextStep({ currentStep: current.next, finalStep: finalStepNo }));
    }
  };

  useEffect(() => {
    if (finalStepNo > currentStepNo) {
      let socialMediaFields = [];
      let portfolioFields = [];
      let selectedSocialMedia = [...selectedSocialTypes];
      let selectedPortfolio = [...selectedPortfolioTypes];

      CvSocialMedia.forEach((value) => {
        if (value.name === "Facebook") {
          appendSocialMediaField({ type: FacebookLogo, url: value.link });
          socialMediaFields.push({ type: FacebookLogo, url: value.link });
          selectedSocialMedia.push("Facebook");
        } else if (value.name === "Twitter") {
          appendSocialMediaField({ type: TwitterLogo, url: value.link });
          socialMediaFields.push({ type: TwitterLogo, url: value.link });
          selectedSocialMedia.push("Twitter");
        } else if (value.name === "Linkedin") {
          appendSocialMediaField({ type: LinkedinLogo, url: value.link });
          socialMediaFields.push({ type: LinkedinLogo, url: value.link });
          selectedSocialMedia.push("Linkedin");
        } else {
          appendSocialMediaField({ type: Browser, url: value.link });
          socialMediaFields.push({ type: Browser, url: value.link });
        }
      });

      CvPortfolios.forEach((value) => {
        // GithubLogo,
        // GitlabLogo,
        // DribbbleLogo,
        // PinterestLogo,
        // BehanceLogo,

        if (value.name === "Github") {
          appendPortfolioField({ type: GithubLogo, url: value.link });
          portfolioFields.push({ type: GithubLogo, url: value.link });
          selectedPortfolio.push("Github");
        } else if (value.name === "Gitlab") {
          appendPortfolioField({ type: GitlabLogo, url: value.link });
          portfolioFields.push({ type: GitlabLogo, url: value.link });
          selectedPortfolio.push("Gitlab");
        } else if (value.name === "Dribbble") {
          appendPortfolioField({ type: DribbbleLogo, url: value.link });
          portfolioFields.push({ type: DribbbleLogo, url: value.link });
          selectedPortfolio.push("Dribbble");
        } else if (value.name === "Pinterest") {
          appendPortfolioField({ type: PinterestLogo, url: value.link });
          portfolioFields.push({ type: PinterestLogo, url: value.link });
          selectedPortfolio.push("Pinterest");
        } else if (value.name === "Behance") {
          appendPortfolioField({ type: BehanceLogo, url: value.link });
          portfolioFields.push({ type: BehanceLogo, url: value.link });
          selectedPortfolio.push("Behance");
        } else {
          appendPortfolioField({ type: Browser, url: value.link });
          portfolioFields.push({ type: Browser, url: value.link });
        }
      });

      setSelectedSocialTypes(selectedSocialMedia);
      setSelectedPortfolioTypes(selectedPortfolio);
      setValue("socialMedia", socialMediaFields);
      setValue("phone", mobile);
      setValue("email", work_email);
    }
  }, []);

  return (
    <Grid container direction="column">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid item className="step-content contact-info-portfolio">
          <Typography className="title">{t("contact_title")}</Typography>
          <Typography className="text">
            {t("contactl_text")}
          </Typography>
          <FormGroup className="mb16">
            <Typography className="type">
              {t("contact_title_contactInfo")}
            </Typography>
            <IconInput
              label={t("contact_phone_optional")}
              id="phone-number"
              placeholder={t("contact_phone")}
              type="text"
              icon={Phone}
              className="mb16"
              register={register}
              fieldName="phone"
              error={errors.phone}
            />
            <IconInput
              label={t("work_email")}
              id="email"
              placeholder={t("email_placeholder")}
              type="email"
              icon={At}
              className="mb16"
              register={register}
              fieldName="email"
              error={errors.email}
            />

            <Typography className="type">
              {t("contact_title_social")}
            </Typography>
            {socialMediaFields.map((item, i) => (
              <MultipleInput
                id={"social-media" + i}
                fieldName={"socialMedia." + i}
                register={register}
                error={errors.socialMedia?.[i]?.url}
                placeholder={t("contact_account")}
                type="text"
                icon={item.type}
                removeType={removeSocialMediaType}
                remove={removeSocialMediaField}
                className="mb16"
              />
            ))}
            <AddButton
              type={t("add_btn_account")}
              onClick={appendSocialMediaField}
              menu={"social"}
              selectedSocialTypes={selectedSocialTypes}
              setSelectedSocialTypes={setSelectedSocialTypes}
            />

            <Typography className="type">
              {t("contact_title_portfolio")}
            </Typography>
            {portfolioFields.map((item, i) => (
              <MultipleInput
                id={"portfolio" + i}
                fieldName={"portfolio." + i}
                register={register}
                error={errors.portfolio?.[i]?.url}
                placeholder={t("contact_portfolio_link")}
                type="text"
                icon={item.type}
                remove={removePortfolioField}
                removeType={removePortfolioType}
                className="mb16"
              />
            ))}
            <AddButton
              type={t("add_btn_portfolio")}
              onClick={appendPortfolioField}
              menu={"portfolio"}
              selectedPortfolioTypes={selectedPortfolioTypes}
              setSelectedPortfolioTypes={setSelectedPortfolioTypes}
            />
          </FormGroup>
        </Grid>
        <Grid container justifyContent="flex-end">
          {current.prev && (
            <button className="back-btn" onClick={handleBack}>
              {t("back_btn")}
            </button>
          )}
          <button className="next-btn" type="submit" disabled={loading} style={!loading ? { cursor: "pointer" } : {}}>
            {t("next_btn")}
          </button>
        </Grid>
      </form>
    </Grid>
  );
};
export default ContantInfoPortfolio;

import { Box, Drawer, Grid, IconButton, ListItem, ListItemButton, ListItemIcon } from '@mui/material';
import { Stack } from '@mui/system';
import { BellSimple, Circle, List } from 'phosphor-react';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../../../../assets/Logo2SVG';
import TabIcon from '../../../../../assets/Logo5SVG';
import Show from '../../../../../components/Show';
import DrawerComponent from '../../../zeinnab/components/navbar/components/mobile-navbar/components/drawer/Drawer';
import ProfileDropdown from '../../../zeinnab/components/navbar/components/profile-dropdown/ProfileDropdown';
import ApplicantNavigationButtons from '../../ApplicantContent/navigation-buttons/ApplicantNavigationButtons';
import RecruiterNavigationButtons from '../navigation-buttons/RecruiterNavigationButtons';

const AuthorizedMobileNavbar = ({ openDrawer, setOpenDrawer }) => {
    // navigator
    const navigate = useNavigate();

    // redux
    const newNotification = useSelector((state) => state.newNotification.new);
    const mode = useSelector((state) => state.mode.name);

    return (
        <Grid container>
            <Grid item xs={6}>
                <Link to="/">
                    <TabIcon />
                </Link>
            </Grid>
            <Grid item xs={6}>
                <Stack direction={"row"} justifyContent="flex-end">
                    <IconButton className="menu-btn" onClick={() => setOpenDrawer(true)}>
                        <List />
                    </IconButton>
                    <Drawer
                        open={openDrawer}
                        onClose={() => setOpenDrawer(false)}
                        anchor="top"
                        PaperProps={{
                            sx: {
                                height: "100%"
                            }
                        }}
                        className="drawer"
                        transitionDuration={200}
                    >
                        <Stack direction={"row"} justifyContent={"space-between"} marginBottom={5}>
                            <Logo />
                            <Box>
                                <IconButton
                                    id="notification-button"
                                    onClick={() => {
                                        navigate("/notifications");
                                        setOpenDrawer(false)
                                    }}
                                    sx={{
                                        position: "relative",
                                        color: "#3A3B81",
                                        background: "#3A3B811A",
                                        borderRadius: "8px",
                                        "&:hover": {
                                            background: "#3A3B811A"
                                        }
                                    }}
                                >
                                    <BellSimple />
                                    <Show condition={newNotification}>
                                        <Circle color="red" size={8} weight="fill" style={{ position: "absolute", top: "5px", right: "5px" }} />
                                    </Show>
                                </IconButton>
                                <Box component={"span"} id="notification-button-side"></Box>
                            </Box>
                        </Stack>
                        <ProfileDropdown usedInMobile setOpenDrawer={setOpenDrawer} />
                    </Drawer>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Stack direction={"row"} justifyContent={"center"} width={"100%"}>
                    <Show condition={mode == "recruiter"}>
                        <RecruiterNavigationButtons />
                    </Show>
                    <Show condition={mode == "applicant"}>
                        <ApplicantNavigationButtons />
                    </Show>
                </Stack>
            </Grid>
        </Grid>
    );
};

export default AuthorizedMobileNavbar;
import { Box, Typography, useMediaQuery } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getStaticPages, translateField } from "../../../../services/Helper";
import HeroSection from "../../yahya/components/hero-section/HeroSection";
import "./EnhancedTermsPage.scss";

const EnhancedTermsPage = () => {
  const mdMatches = useMediaQuery("(min-width:701px) and (max-width:1000px)");
  const smMatches = useMediaQuery("(max-width:700px)");

  const [terms, setTerms] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    const handleGetTerms = async () => {
      return await getStaticPages("Terms");
    };

    handleGetTerms().then((data) => {
      setTerms(data.length > 0 ? data[0] : null);
    });
  }, []);

  return (
    <Box >
      <HeroSection small>
        <Typography className={"hero-section-title colored"}>
          {t("terms_title")}
        </Typography>
        <Typography className={"hero-section-description"}>
          {t("terms_desc")}
        </Typography>
      </HeroSection>
      <Box
        className="terms-page"
        component="div"
        dangerouslySetInnerHTML={{ __html: translateField(terms, "value") }}
      >
        {/* <Typography className="terms-text">
          {translateField(terms, "value")}
        </Typography> */}
      </Box>
    </Box>
  );
};

export default EnhancedTermsPage;

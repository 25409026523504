import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { translateField } from "../../../../../services/Helper";
import InformationCard from "../../../abdelrahman/InformationCard/InformationCard";
import "./InformationCardSection.css";

const InformationCardSection = ({
  backColored,
  title,
  data,
  cardWithoutNumber,
}) => {
  const mdMatches = useMediaQuery("(min-width:701px) and (max-width:1000px)");
  const smMatches = useMediaQuery("(min-width:390px) and (max-width:1200px)");
  return (
    <Box
      component={"section"}
      className="section-information-card"
      sx={{ backgroundColor: backColored ? "#C2C2FF1A" : "#fff", display: "hidden" }}
      paddingX={smMatches ? "24px" : "0px"}
      paddingY={mdMatches ? "30px" : smMatches ? "24px" : "60px"}
    >
      <Grid container align={"center"} justifyContent={"center"}>
        <Grid item xs={12}>
          <Typography className="title">{title}</Typography>
        </Grid>
        <Grid
          item
          xs={12}

          // columnSpacing={{ lg: 2.26, xl: 2.5 }}
          // justifyContent={{
          //   md: "space-evenly",
          //   lg: "space-between",
          //   xl: "space-beteween",
          // }}
          paddingX={14.8}
        // sx={{ maxWidth: { lg: "1048px" } }}
        >
          <Grid container spacing={2} >
            {data.map((card, index) => {
              return (
                <Grid item xs={12} lg={4} >
                  <InformationCard
                    asset={<img width={100} height={80} src={card.image} />}
                    // number={index + 1}
                    number={cardWithoutNumber ? null : index + 1}
                    title={card.title}
                    description={card.description}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Box >
  );
};

export default InformationCardSection;

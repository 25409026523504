import { Grid, Stack, useMediaQuery } from '@mui/material';
import "./HeroSection.scss";


import { Box } from '@mui/material';
import { useRef, useState } from 'react';

const HeroSection = ({ children, floatingCards, small, heroSectionCards, leftCard, rightCard }) => {
    const mdMatches = useMediaQuery('(min-width:1200px)');

    const [cardsState, setCardsState] = useState(false)
    const anchorRef = useRef()
    return (
        <Stack
            className={`${small ? "small-hero-section" : "hero-section"}`}
            justifyContent={"space-around"}
            alignItems={"center"}
            direction={"column"}
            ref={anchorRef}
            sx={{
                position: "relative"
            }}
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "75px",
                    right: "18px",
                }}>
                {rightCard}
            </Box>
            <Stack
                direction={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                className={"hero-section-text-wrapper"}
                spacing={2}

            >
                <Box>
                    {children}
                </Box>
                {mdMatches && <>
                    <Box
                        sx={{
                            position: "absolute",
                            bottom: "-45px",
                        }}
                    >
                        {heroSectionCards &&
                            <Grid container justifyContent={"space-between"} columnSpacing={4}>
                                {heroSectionCards?.map((card) => {
                                    return <Grid
                                        item
                                        md={12 / heroSectionCards.length}
                                        lg={12 / heroSectionCards.length}
                                    >{card}
                                    </Grid>
                                })}
                            </Grid>
                        }
                    </Box>
                </>}
                <Box sx={{
                    position: "absolute",
                    top: mdMatches ? "155px" : "420px",
                    left: "18px"
                }}>
                    {leftCard}
                </Box>
            </Stack>
        </Stack>
    )
}

export default HeroSection

import { Helmet } from "react-helmet";
import LoadingBar from "react-redux-loading-bar";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import ReduxToastr from "react-redux-toastr/lib/ReduxToastr";
import Main from "./Main";
import "./scss/style.scss";
//import './i18n';
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
// import ChangeLang from "./components/ChangeLang/ChangeLang"


const App = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = useSelector(state => state.languageHandler.current_lang)

  useEffect(() => {
    let langCode = currentLanguage?.split("-")[0];
    // i18n.changeLanguage(currentLanguage)
    if (langCode === "ar") {
      document.dir = "rtl";
    } else {
      document.dir = "ltr";
    }
  }, [i18n.language])

  return (
    <div className="App">
      <Helmet>
        <title>JOBLINK</title>
      </Helmet>
      {/* <h1>{t("common.required_input")}</h1> */}
      {/* <ChangeLang /> */}
      <LoadingBar direction={i18n.language == "ar" ? "rtl" : "ltr"} style={{ position: "fixed", top: "0", zIndex: '1000' }} />
      <ReduxToastr
        timeOut={2500}
        newestOnTop={false}
        preventDuplicates
        position={i18n.language == "ar" ? "top-left" : "top-right"}
        getState={(state) => state.toastr} // This is the default
        transitionIn="bounceIn"
        transitionOut="bounceOut"
        progressBar
        closeOnToastrClick
      />
      <Main />
    </div>
  );
};

export default App;

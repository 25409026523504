import { Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { translateField } from "../../../../services/Helper";
import "./TypeSwitch.scss";

const TypeSwitch = ({ options, onChange }) => {
  const [value, setValue] = useState(0);

  return (
    <Tabs
      value={value}
      onChange={(e, newValue) => {
        setValue(newValue);
        onChange?.(newValue);
      }}
      variant="fullWidth"
      indicatorColor=""
    >
      {options.map((option, index) => (
        <Tab
          className={`type-option${index === 0
              ? " first-element"
              : index === options.length - 1
                ? " last-element"
                : " middle-element"
            }${index === value ? " active" : ""}`}
          label={translateField(option, "name")}
        />
      ))}
    </Tabs>
  );
};
export default TypeSwitch;

import { LANGUAGE_CHANGED, LANGUAGE_READY } from "state/actions/languages-handler/types";

let initialState = {
    current_lang: null,
    ready: false,
    code: "en",
    locale: "us",
    fallback_lang: "en-us"
}

export const languageHandlerReducer = (state = initialState, action) => {
    console.log(action)
    switch (action.type) {
        case LANGUAGE_CHANGED:
            return { ...state, fallback_lang: `${action.payload.code}-${action.payload.locale}`, current_lang: action.payload.code + "-" + action.payload.locale, ...action.payload };
        case LANGUAGE_READY:
            return { ...state, ready: true };
        default:
            return state;
    }
}

export default languageHandlerReducer;
import { FILE_TYPES, IMAGE_TYPES, MONTHS } from "../constants/constants";
import i18n from "../i18n";
import { dashboardRoutes } from "../routes/dashboard";
import { Api } from "./Api";

export const searchInArray = (arr, string) => {
  return arr?.find((item) => item == string);
};

export const searchInArrayWithKey = (arr, key, search) => {
  return arr?.find((item) => item[key] == search);
};

export const searchInArrayOfObjsById = (arr, id) => {
  return arr?.find((item) => item.id == id);
};

export const searchInArrayOfObjsByName = (arr, name) => {
  return arr?.find((item) => item.name == name);
};

export const getPermissionsArray = (string) => {
  if (string instanceof Array) {
    return string;
  }
  let permissions = string;
  permissions = permissions?.replace("[", "");
  permissions = permissions?.replace("]", "");
  permissions = permissions?.replaceAll("'", "");
  permissions = permissions?.split(",");
  return permissions;
};

export const scrollToSection = (section) => {
  const element = document.getElementById(section);
  window.scrollTo({
    top: element.offsetTop - document.getElementById("navbar").clientHeight,
    behavior: "smooth",
  });
  setTimeout(() => {
    window.isNavigating = false;
  }, 1000);
};

export const getEducationDegree = async () => {
  const result = await Api.get("/api/v1/account/model?model=EducationDegree");
  return result;
};

export const getGrade = async () => {
  const result = await Api.get("/api/v1/account/model?model=Grade");
  return result;
};

export const getSeniorityLevel = async () => {
  const result = await Api.get("/api/v1/account/model?model=CareerLevel");
  return result;
};

export const getWorkType = async () => {
  const result = await Api.get("/api/v1/account/model?model=ExperienceType");
  return result;
};

export const getCompanyIndustry = async () => {
  const result = await Api.get("/api/v1/account/model?model=CompanyIndustry");
  return result;
};
export const getJobCategory = async () => {
  const result = await Api.get("/api/v1/account/model?model=JobCategory");
  return result;
};
export const getHiringTime = async () => {
  const result = await Api.get("/api/v1/account/model?model=HiringTime");
  return result;
};

export const getCountry = async () => {
  const result = await Api.get(
    "/api/v1/account/model?model=Country&countryStatus=1"
  );
  return result;
};

export const getGovernorate = async (countryId, isAll = false) => {
  const result = await Api.get(
    `/api/v1/account/model?model=Governorate&governorateStatus=1&country=${countryId}&is_all=${
      isAll ? 1 : 0
    }`
  );
  // if (!isAll)
  //     return result?.filter((item) => item.is_all == 0 || item.is_all == undefined)
  // else
  return result;
};

export const getGovernorateWithAll = async (countryId) => {
  const result = await Api.get(
    `/api/v1/account/model?model=Governorate&country=${countryId}`
  );
  return result;
};

export const getCity = async (governorateId) => {
  const result = await Api.get(
    `/api/v1/account/model?model=City&governorate_id=${governorateId}`
  );
  return result;
};

export const getYearsOfExperience = async () => {
  const result = await Api.get("/api/v1/account/model?model=YearsOfExperience");
  return result;
};

export const getCompanySize = async () => {
  const result = await Api.get("/api/v1/account/model?model=CompanySize");
  return result;
};

export const getRecruiterPosition = async () => {
  const result = await Api.get("/api/v1/account/model?model=RecruiterPosition");
  return result;
};

export const getJobTitle = async () => {
  const result = await Api.get("/api/v1/account/model?model=JobTitle");
  return result;
};

export const getNationality = async () => {
  const result = await Api.get("/api/v1/account/model?model=Nationality");
  return result;
};

export const getMilitary = async () => {
  const result = await Api.get("/api/v1/account/model?model=Military");
  return result;
};

export const getMarital = async () => {
  const result = await Api.get("/api/v1/account/model?model=Marital");
  return result;
};

export const getWorkStyle = async () => {
  const result = await Api.get("/api/v1/account/model?model=WorkStyle");
  return result;
};

export const getSalaryType = async () => {
  const result = await Api.get("/api/v1/account/model?model=Salary");
  return result;
};

export const getJobs = async (type, pageNumber, limit = 10) => {
  const result = await Api.get(
    `/api/v1/users/job/list?limit=${limit}&pageNumber=${pageNumber}&type=${type}`
  );
  return result;
};

export const getMyJobs = async (status, pageNumber, limit) => {
  const result = await Api.get(
    `/api/v1/recruiters/job/list?status=${status}&pageNumber=${pageNumber}&limit=${limit}`
  );
  return result;
};
export const getJob = async (id, applicant = true) => {
  if (applicant) {
    return await Api.get(`/api/v1/users/job/view?job_id=${id}`);
  } else {
    return await Api.get(`/api/v1/recruiters/job/view?job_id=${id}`);
  }
};

export const getProfile = async () => {
  const result = await Api.get(
    `/api/v1/account/read-my-profile?model=All&type=REGISTERD_USER`
  );
  return result;
};

export const createAndAddToCategory = async (applicant_id, category) => {
  let url = `/api/v1/recruiters/cv-bank/add-applicant`;

  const result = await Api.post(url, {
    category_name: category,
    applicant_id,
  });
  return result;
};
export const addToCvBank = async (applicant_id, categories) => {
  if (categories.length <= 0) {
    return { rows: [] };
  }
  let url = `/api/v1/recruiters/cv-bank/add-applicant`;

  const result = await Api.post(url, {
    applicant_id,
    category_id_list: categories,
  });
  return result;
};

export const searchApplicant = async (filters, pageNumber, type) => {
  let url = `/api/v1/account/search/applicant?`;

  if (filters.search.trim().length > 0) {
    url += `name=${filters.search}`;
  }

  filters.seniorityLevels.map((value) => {
    url += `&career_level[]=${value}`;
  });
  filters.nationalities.map((value) => {
    url += `&nationality[]=${value}`;
  });
  filters.degrees.map((value) => {
    url += `&degree[]=${value}`;
  });
  filters.grades.map((value) => {
    url += `&grade[]=${value}`;
  });
  filters.countries.map((value) => {
    url += `&country[]=${value}`;
  });
  filters.governorates.map((value) => {
    url += `&governorate[]=${value}`;
  });
  filters.cities.map((value) => {
    url += `&city[]=${value}`;
  });

  url += `&pageNumber=${pageNumber}`;

  let headers = {};
  if (type != "GUEST_USER") {
    headers.type = type;
  }

  const result = await Api.get(url, {
    headers: headers,
  });
  return result;
};

export const searchRecruiter = async (filters, pageNumber, type) => {
  let url = `/api/v1/account/search/recruiter?`;

  if (filters.search.trim().length > 0) {
    url += `company_name=${filters.search}`;
  }

  filters.companyIndustries.map((value) => {
    url += `&company_industry[]=${value}`;
  });
  filters.countries.map((value) => {
    url += `&country[]=${value}`;
  });
  filters.governorates.map((value) => {
    url += `&governorate[]=${value}`;
  });
  filters.cities.map((value) => {
    url += `&city[]=${value}`;
  });

  url += `&pageNumber=${pageNumber}`;

  let headers = {};
  if (type != "GUEST_USER") {
    headers.type = type;
  }

  const result = await Api.get(url, {
    headers: headers,
  });
  return result;
};

export const searchJob = async (filters, pageNumber, type) => {
  let url = `/api/v1/account/search/job?`;

  if (filters.search.trim().length > 0) {
    url += `job_title=${filters.search}`;
  }

  filters.jobIndustries.map((value) => {
    url += `&job_category[]=${value}`;
  });
  filters.careerLevels.map((value) => {
    url += `&career_level[]=${value}`;
  });
  filters.salaries.map((value) => {
    url += `&salary[]=${value}`;
  });
  filters.jobTypes.map((value) => {
    url += `&work_style[]=${value}`;
  });
  filters.countries.map((value) => {
    url += `&country[]=${value}`;
  });

  filters?.hiring_time?.map((value) => {
    url += `&hiring_time[]=${value}`;
  });
  filters.governorates.map((value) => {
    url += `&governorate[]=${value}`;
  });
  filters.cities.map((value) => {
    url += `&city[]=${value}`;
  });

  if (filters.max) {
    url += `&max_salary=${filters.max}`;
  }

  if (filters.min) {
    url += `&min_salary=${filters.min}`;
  }

  url += `&pageNumber=${pageNumber}`;

  let headers = {};
  if (type != "GUEST_USER") {
    headers.type = type;
  }

  const result = await Api.get(url, {
    headers: headers,
  });
  return result;
};

export const getCompanyProfile = async (isRecruiter, role, recruiterId) => {
  let result = null;
  if (isRecruiter) {
    result = await Api.get(
      `/api/v1/account/read-my-profile?model=All&type=RECRUITER`
    );
  } else {
    result = await Api.get(
      `/api/v1/account/profile?recruiter_id=${recruiterId}`,
      {
        headers: {
          ...(role === "APPLICANT" && { type: "APPLICANT" }),
        },
      }
    );
  }
  return result;
};

export const getRecruiterJobs = async (
  pageNumber,
  status = 1,
  limit = 15,
  role,
  notCompleted
) => {
  let params = {};

  if (role === "GUEST_USER" || role === "REGISTERD_USER" || notCompleted) {
    params.stopRequest = true;
  }
  const result = await Api.get(
    `/api/v1/recruiters/job/list?status=${status}&limit=${limit}&pageNumber=${pageNumber}`,
    {
      params: params,
    }
  );
  return result;
};

export const getAppliedApplicants = async (
  job_id,
  pageNumber,
  filter_by = null,
  limit = 15
) => {
  const result = await Api.get(
    `/api/v1/recruiters/job/applicant_applied_list?pageNumber=${pageNumber}${
      filter_by ? `&filter_by=${filter_by}` : ``
    }&limit=${limit}&job_id=${job_id}`
  );
  return result;
};

export const checkErrorInValidation = (props) => {
  let isError = true;
  if (props.error === undefined) {
    isError = false;
  } else if (Object.keys(props.error).length == 0) {
    isError = false;
  } else if (props.error[props.fieldName] !== undefined) {
    isError = true;
  } else {
    isError = false;
  }

  return isError;
};

export const getMonthYear = (date) => {
  try {
    const month = date ? date.getMonth() + 1 : null;
    const year = date ? date.getFullYear() : null;
    return {
      month,
      year,
    };
  } catch (error) {
    return null;
  }
};

export const getMonthYearDay = (date) => {
  try {
    const month = date.getUTCMonth() + 1;
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();
    return {
      day,
      month,
      year,
    };
  } catch (error) {
    return null;
  }
};

export const reformatDate = (obj) => {
  return obj ? obj.year + "-" + obj.month + "-" + obj.day : null;
};

export const translateField = (object, key) => {
  try {
    return object?.[key + "_" + i18n?.language?.split("-")[0]];
  } catch (error) {
    return null;
  }
};

export const getMonthName = (id) => {
  let monthId = parseInt(id);
  monthId -= 1;
  return MONTHS[monthId];
};
export const scrollIntoView = (id) => {
  document?.getElementById(id)?.scrollIntoView({
    behavior: "smooth",
  });
};

export const getJobTitles = async (searchTerm) => {
  return Api.get(
    `/api/v1/users/model?model=JobTitle&filter=${searchTerm.substring(0, 45)}`
  );
};

export const getCurrencies = async () => {
  return Api.get(`/api/v1/account/model?model=Currency`);
};

export const validateFileType = (pFileType) => {
  return FILE_TYPES?.find((fileType) => fileType == pFileType);
};

export const validateImageType = (pFileType) => {
  return IMAGE_TYPES?.find((fileType) => fileType == pFileType);
};

export const getFirstStepNotCompleted = (obj, stepper = "cv") => {
  let step = Object.keys(obj).find((key, index) => {
    if (obj[key] == 0) {
      if (stepper == "cv" && index !== 3) {
        return key;
      } else if (stepper == "recruiter") {
        return key;
      }
    }
  });

  let array = Object.keys(obj);
  return array.indexOf(step) + 1;
};

export const getPageHeaderByPath = (path) => {
  const route = dashboardRoutes.find((route) => {
    if (route.parent == null) {
      return route.path === path;
    }
    return `${route.parent}${route.path}` === path;
  });

  return route ? route?.pageHeader : null;
};

export const getRouteObjectByPath = (path) => {
  const route = dashboardRoutes.find((route) => {
    if (route.parent == null) {
      return route.path === path;
    }
    return `${route.parent}${route.path}` === path;
  });

  return route;
};

export const getRoutePath = (location, params) => {
  const { pathname } = location;

  if (!Object.keys(params).length) {
    return pathname; // we don't need to replace anything
  }

  let path = pathname;
  Object.entries(params).forEach(([paramName, paramValue]) => {
    if (paramValue) {
      path = path.replace(paramValue, `:${paramName}`);
    }
  });
  return path;
};

export const permAnd = (arr, perm1, perm2) => {
  return searchInArray(arr, perm1) && searchInArray(arr, perm2);
};

export const permOr = (arr, perm1, perm2) => {
  return searchInArray(arr, perm1) || searchInArray(arr, perm2);
};

export const perm = (arr, perm) => {
  return searchInArray(arr, perm);
};

export const getRouteByPath = (routes, path) => {
  return routes?.find((route) => {
    if (route.path == "/") {
      return `${route.path}` == path;
    } else if (route.parent == "/") {
      return `${route.parent}${route.path}` == path;
    } else {
      return `${route.parent}/${route.path}` == path;
    }
  });
};

// export const getJob = async (job_id) => {
//     const result = await Api.get(`/api/v1/recruiters/job/view?job_id=${job_id}`);
//
//     return result
// }

// export const  getJob = async () =>{
//     const result = await Api.get('/api/v1/recruiters/job/view?job_id=177');
//
//      return result
//  }

export const deleteJob = async (job_id) => {
  await Api.delete(`/api/v1/recruiters/job/delete?job_id=${job_id}`);
};

export const deleteDocument = async (type, file) => {
  await Api.delete(
    `/api/v1/recruiters/doc?doc_type=${type}${
      file ? `&commercial_id=${file}` : ""
    }`,
    {
      headers: {
        type: "RECRUITER",
      },
    }
  );
};

export const applyJob = async (job_id, role) => {
  let params = {};
  if (role === "GUEST_USER") {
    params.openPopup = true;
  }
  return await Api.put(
    "/api/v1/users/job",
    {
      job_id: job_id,
      type: "ApplyJob",
    },
    { params: params }
  );
};
export const saveJob = async (job_id, role) => {
  let params = {};
  if (role === "GUEST_USER") {
    params.openPopup = true;
  }

  return await Api.put(
    "/api/v1/users/job",
    {
      job_id: job_id,
      type: "SaveJob",
    },
    { params: params }
  );
};
export const getJobsByType = async (type, limit, role, notCompleted) => {
  let params = {};
  console.log("LOGGED IN USER TYPE", role);
  if (
    role === "GUEST_USER" ||
    role === "REGISTERD_USER" ||
    notCompleted ||
    role === "RECRUITER"
  ) {
    params.stopRequest = true;
  }
  const result = await Api.get(
    `/api/v1/users/job/list?limit=${limit}&pageNumber=1&type=${type}`,
    { params: params }
  );
  return result;
};

export const getFeaturedCompanies = async (
  role,
  notCompleted,
  limit = 10,
  from
) => {
  console.log("THIS REQUEST IS FROM", from, role, notCompleted);
  let params = {};
  if (role === "GUEST_USER" || role === "REGISTERD_USER" || notCompleted) {
    params.stopRequest = true;
  }
  const result = await Api.get(
    `/api/v1/users/connection/featured?limit=${limit}`,
    { params: params }
  );
  return result;
};

export const getFollowedCompanies = async (pageNumber, limit = 10) => {
  const result = await Api.get(
    `/api/v1/users/connection?limit=${limit}&pageNumber=${pageNumber}`
  );
  return result;
};

export const fetchMyProfile = async (
  role = "REGISTERD_USER",
  model = "All"
) => {
  const response = await Api.get(
    `/api/v1/account/read-my-profile?type=${role}&model=${model}`
  );
  return response;
};

export const fetchAppliedApplicant = async (applicant_id, job_id) => {
  const response = await Api.get(
    `/api/v1/recruiters/get_applicant?applicant_id=${applicant_id}&job_id=${job_id}`
  );
  return response;
};

export const applyActionOnJob = async (applicant_id, action_number) => {
  const response = await Api.put(
    `/api/v1/recruiters/action/applicant_applied_job`,
    {
      applicant_applied_job_id: applicant_id,
      status_id: action_number,
    }
  );
  return response;
};

/**
 * @typedef {Object} Job
 * @property {string} name
 */

/**
 * Posts new.
 * @param {Job} data
 * @returns {Promise<import("s").AxiosResponse<Job>>}
 */
export const postNewJob = async (data) => {
  if (!data.external_apply_website) {
    delete data.external_apply_website;
  }
  data.job_title_id = data.job_title_id.id;
  if (data.salary_id == "1") {
    delete data.salary_from;
    delete data.salary_to;
  } else if (data.salary_id !== "2") {
    delete data.salary_to;
  }
  const response = await Api.post(`/api/v1/recruiters/job/create`, data);
  return response;
};

export const editJob = async (data, applied) => {
  if (!applied) {
    data.job_title_id = data.job_title_id.id;
    if (data.salary_id == "1") {
      delete data.salary_from;
      delete data.salary_to;
    } else if (data.salary_id !== "2") {
      delete data.salary_to;
    }
  }

  const response = await Api.put(`/api/v1/recruiters/job/edit`, data);
  return response;
};

export const shareJob = async (id, links) => {
  const response = await Api.post("/api/v1/recruiters/job/invite-by-mail", {
    job_id: id,
    mail_list: links,
  });
  return response;
};

export const addDeviceToken = async (data, type, notCompleted) => {
  let params = {};
  if (type === "GUEST_USER" || type === "REGISTERD_USER" || notCompleted) {
    params.stopRequest = true;
  }
  const response = await Api.post(`/api/v1/account/device_token`, data, {
    params: params,
    headers: {
      type,
    },
  });
  return response;
};

export const getNotificationList = async (type, limit) => {
  const response = await Api.get(`/api/v1/account/notification`, {
    params: {
      limit: limit,
      pageNumber: 1,
    },
    headers: {
      type,
    },
  });
  return response;
};
export const getNewsList = async (type, limit) => {
  const response = await Api.get(`/api/v1/account/news`, {
    params: {
      limit: limit,
      pageNumber: 1,
    },
    headers: {
      type,
    },
  });
  return response;
};
export const readSingleNews = async (type, news_id) => {
  const response = await Api.get(`/api/v1/account/news/read`, {
    params: {
      news_id: news_id,
      isAll: 0,
    },
    headers: {
      type,
    },
  });
  return response;
};

export const getSingleNews = async (type, news_id) => {
  return await Api.get(
    `/api/v1/account/news?limit=1&pageNumber=1&news_id=${news_id}`,
    {
      headers: {
        type: type,
      },
    }
  );
};

export const readSingleNotification = async (type, notification_id) => {
  const response = await Api.get(`/api/v1/account/notification/read`, {
    params: {
      notification_id: notification_id,
      isAll: 0,
    },
    headers: {
      type,
    },
  });
  return response;
};

export const readAllNotifications = async (type, limit) => {
  const response = await Api.get(`/api/v1/account/notification/read`, {
    params: {
      isAll: 1,
      pageNumber: 1,
      limit,
    },
    headers: {
      type,
    },
  });
  return response;
};

export const readAllNews = async (type, limit) => {
  const response = await Api.get(`/api/v1/account/news/read`, {
    params: {
      isAll: 1,
      pageNumber: 1,
      limit,
    },
    headers: {
      type,
    },
  });
  return response;
};
export const changeCompanyStatus = (recruiter_id, role) => {
  let params = {};
  if (role === "GUEST_USER") {
    params.openPopup = true;
  }
  return Api.put(
    "/api/v1/users/connection",
    { recruiter_id },
    {
      params: params,
    }
  );
};

export const getNotificationPermission = () => {
  navigator.permissions
    .query({ name: "notifications" })
    .then((response) => {
      return response.state === "granted" ? true : false;
    })
    .catch((error) => {});
};
export const askUserForNotificationPermission = () => {
  if (Notification.permission === "granted") {
    return "permission granted";
  } else {
    Notification.requestPermission().then((permission) => {
      return permission;
    });
  }
};

export const getUnreadNotificationsCount = (type, notCompleted) => {
  let params = {};
  if (type === "GUEST_USER" || type === "REGISTERD_USER" || notCompleted) {
    params.stopRequest = true;
  }
  const response = Api.get("/api/v1/account/notification/un-read-count", {
    params: params,
    headers: {
      type,
    },
  });
  return response;
};

export const getDocumentDirection = () => {
  const textAlignment = document.dir === "rtl" ? "right" : "left";

  return textAlignment;
};

export const capitalizeWord = (string) => {
  if (string) {
    const firstLetter = string[0]?.toUpperCase();
    return firstLetter + string?.slice(1);
  }
};

export const getCategory = () => {
  const response = Api.get("/api/v1/recruiters/cv-bank/category");
  return response;
};

export const addCategory = (newCategory) => {
  const response = Api.post("/api/v1/recruiters/cv-bank/category", {
    category_name: newCategory,
  });
  return response;
};

export const editCategory = (newCategory, idCategory) => {
  const response = Api.put("/api/v1/recruiters/cv-bank/category", {
    category_name: newCategory,
    category_id: idCategory,
  });
  return response;
};

export const deleteCategory = (idCategory) => {
  const response = Api.delete(
    `/api/v1/recruiters/cv-bank/category?category_id=${idCategory}`
  );
  return response;
};

export const getApplicantByCategory = (idCategory) => {
  const response = Api.get(
    `/api/v1/recruiters/cv-bank/get-applicants-by-category?category_id=44`
  );
};

export const getApplicantToCvBank = (idCategory) => {
  const response = Api.get(
    `/api/v1/recruiters/cv-bank/get-applicants-by-category?category_id=${idCategory}`
  );
  return response;
};

export const getReadProfileApplicant = (applicant_id, role = "RECRUITER") => {
  const response = Api.get(
    `/api/v1/account/profile?applicant_id=${applicant_id}`,
    {
      headers: {
        ...(role === "RECRUITER" && { type: "RECRUITER" }),
      },
    }
  );
  return response;
};

export const inviteToJobByEmail = async (job_id, applicant_id) => {
  let data = {
    job_id,
    applicant_id,
  };
  return await Api.post(`/api/v1/recruiters/job/invite-applicant`, data);
};

export const getCategories = async () =>
  await Api.get(`/api/v1/recruiters/cv-bank/category`);

export const addCatagory = async (category_name) =>
  await Api.post(`/api/v1/recruiters/cv-bank/category`, { category_name });

export const deletApplicantFromCvBank = async (cvBankId) => {
  const response = await Api.delete(
    `/api/v1/recruiters/cv-bank/delete-applicant?cv_bank_id=${cvBankId}`
  );
  return response;
};

export const getFollowers = async (limit, pageNumber) =>
  await Api.get(
    `/api/v1/recruiters/connection?limit=${limit}&pageNumber=${pageNumber}`
  );

export const changeDefaultRole = async (default_role) => {
  return await Api.put(`/api/v1/account/change-default-role`, {
    default_role,
  });
};

export const downloadCV = async (applicant_id) =>
  await Api.get(`/api/v1/account/cv/download?applicant_id=${applicant_id}`);

export const getStaticPages = async (key) =>
  await Api.get(`/api/v1/account/static-pages?key=${key}`);

export const contactSupport = async (messageToSupport, email, name) => {
  let data = {
    message: messageToSupport,
    email,
    name,
  };
  return await Api.post(`/api/v1/account/support/message`, data);
};

export const deleteMyAccount = async () => {
  const lang = "ar";
  const result = await Api.delete(`/api/v1/account/delete/me?role=Me`, {
    headers: {
      type: "APPLICANT",
    },
  });
  return result;
};

export const getCurrency = async () => {
  return await Api.get("/api/v1/account/model?model=Currency");
};

export const searchForApplicantByNameOrEmail = (searchTerm) => {
  return Api.get("/api/v1/recruiters/search/applicant", {
    params: { limit: "10", pageNumber: 1, searchTerm },
  });
};

export const getTranslationFiles = (language_id) => {
  return Api.get(
    `/api/v1/account/language-file?side=web&language=${language_id}`
  );
};

export const getLanguageDropdown = () => {
  return Api.get("/api/v1/account/languages-list");
};

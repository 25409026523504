import { Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';



const HeaderText = (props) => {
  const useStyles = makeStyles({
    typography: (props) => ({
      '& .MuiTypography-root': {
        color: '#3A3B81',
        fontFamily: "Urbanist",
        fontSize: props.fontSize,
        fontWeight: props.fontWeight
      }
    }),
  });

  const classes = useStyles(props);

  const { text, icon } = props;

  return (

    <Stack direction="row">
      {icon && (
        <Stack>
          {icon}
        </Stack>

      )}
      <Stack className={classes.typography}>
        <Typography >
          {text}
        </Typography>
      </Stack>
    </Stack>
  )
}

export default HeaderText
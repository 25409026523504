import { Grid, Paper, Typography } from "@mui/material";
import EnhancedButton from "../../../yahya/components/enhanced-button/EnhancedButton";
import "./ContactCard.css";

const ContactCard = ({ title, info, text, icon, iconDirection, onClick }) => {
  return (
    <Paper className="contact-card" elevation={0} sx={{ height: "100%" }} component={Grid} alignItems={"center"} container rowSpacing={0}>
      <Grid item xs={12}>
        <Typography className="title">{title}</Typography>
        <Typography className="info">{info}</Typography>
      </Grid>
      {/* <Grid item xs={12}>
        </Grid> */}
      <Grid item xs={12} alignSelf={"flex-end"} mt={1}>
        {iconDirection === "iconLeft" && (
          <EnhancedButton buttonText={text} iconLeft={icon} onClick={onClick} />
        )}
        {iconDirection === "iconRight" && (
          <EnhancedButton buttonText={text} iconRight={icon} onClick={onClick} />
        )}
      </Grid>
    </Paper>
  );
};

export default ContactCard;

import EnglishFlag from '../assets/EnglishFlag.png';
import ArabicFlag from '../assets/flags/ArabicFlag.png';


export const LANGS = {

    EN:{
        FLAG: EnglishFlag
    },
    AR:{
        FLAG: ArabicFlag
    }
} 
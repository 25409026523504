import { CLOSE_SIDEBAR, OPEN_SIDEBAR, TOGGLE_SIDEBAR } from "../../actions/sidebar/types";

const initialState = {
    opened: false
}
export const sidebarReducer = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_SIDEBAR:
            return { ...state, opened: true }
        case CLOSE_SIDEBAR:
            return { ...state, opened: false }
        case TOGGLE_SIDEBAR:
            return {
                ...state,
                opened: !state.opened
            }
        default:
            return state
    }

}
import { APPLICANT_ROLE, RECRUITER_ROLE } from "../../../constants/permissions";
import { Api } from "../../../services/Api";
import {
  changeDefaultRole,
  getMonthYear,
  getMonthYearDay,
  reformatDate,
} from "../../../services/Helper";
import { stopProcess } from "../process/process";

import {
  FETCH_PROFILE,
  DELETE_PROFILE,
  ASSIGN_ROLE,
  ADD_SKILL,
  DELETE_SKILL,
  EDIT_SKILL,
  UPDATE_PROFILE,
  UPDATE_STEP_FINISHED,
  ADD_CONTACTPORTFOLIO,
  EDIT_CONTACTPORTFOLIO,
  ADD_GENERALINFO,
  DELETE_PROFILE_IMAGE,
  LOAD_PROFILE,
  CHANGE_DEFAULT_ROLE,
} from "./types";

//Action Creator
export const fetchProfile =
  (role = "REGISTERD_USER", model = "All") =>
  async (dispatch) => {
    const response = await Api.get(
      `/api/v1/account/read-my-profile?type=${role}&model=${model}`
    );
    return dispatch({ type: FETCH_PROFILE, payload: response });
  };

export const fetchProfileAndAssignRole =
  (navigate, role = "REGISTERD_USER", model = "All") =>
  async (dispatch) => {
    const response = await Api.get(
      `/api/v1/account/read-my-profile?type=${role}&model=${model}`
    );
    console.log("READ MY PROFILE RES", response);
    dispatch({ type: FETCH_PROFILE, payload: response });
    
    const roles = response.role;
    if (roles?.length == 0) {
      dispatch({ type: ASSIGN_ROLE, payload: "GUEST_USER" });
      navigate("/");
    } else if (roles?.length == 1) {
      dispatch({ type: ASSIGN_ROLE, payload: roles[0] });
      navigate(roles[0].redirect, { state: { switched: true } });
    } else if (roles?.length > 1) {
      const default_role = response.azure_account?.default_role;
      const default_role_object = roles.find(
        (item) => item.name === default_role
      );
      dispatch({ type: ASSIGN_ROLE, payload: default_role_object });
      navigate(default_role_object.redirect, { state: { switched: true } });
    }

    dispatch(stopProcess("login"));
  };

export const deleteProfile = () => (dispatch) => {
  dispatch({ type: DELETE_PROFILE, payload: null });
};

export const assignRole =
  (pRole = null) =>
  (dispatch, getState) => {
    dispatch({ type: ASSIGN_ROLE, payload: pRole });
  };

export const addEducation = (data) => async (dispatch) => {
  const fromDate = getMonthYear(data.from_date);
  const toDate = getMonthYear(data.to_date);
  let sendData = {
    university_name: data.university_name,
    study_field: data.study_field,
    education_degree_id: data.education_degree_id,
    grade_id: data.grade_id,
    from_year: fromDate?.year,
    from_month: fromDate?.month,
    to_year: toDate?.year,
    to_month: toDate?.month,
  };

  if (!fromDate.month || !fromDate.year) {
    delete sendData.from_month;
    delete sendData.from_year;
  }

  if (!toDate.month || !toDate.year) {
    delete sendData.to_month;
    delete sendData.to_year;
  }

  const response = await Api.post(
    "/api/v1/users/cv_step3_eduication",
    sendData
  );
  dispatch({ type: UPDATE_PROFILE, payload: { User: response?.User } });
};

export const updateEducation = (data) => async (dispatch) => {
  const fromDate = getMonthYear(data.from_date);
  const toDate = getMonthYear(data.to_date);
  let sendData = {
    education_id: data.id,
    university_name: data.university_name,
    study_field: data.study_field,
    education_degree_id: data.education_degree_id,
    grade_id: data.grade_id,
    from_year: fromDate?.year,
    from_month: fromDate?.month,
    to_year: toDate?.year,
    to_month: toDate?.month,
  };

  if (!fromDate.month || !fromDate.year) {
    delete sendData.from_month;
    delete sendData.from_year;
  }

  if (!toDate.month || !toDate.year) {
    delete sendData.to_month;
    delete sendData.to_year;
  }
  const response = await Api.put("/api/v1/users/cv_step3_eduication", sendData);
  dispatch({ type: UPDATE_PROFILE, payload: { User: response?.User } });
};

export const deleteEducation = (education_id) => async (dispatch) => {
  const response = await Api.delete(
    `/api/v1/users/cv_step3_eduication?education_id=${education_id}`
  );
  dispatch({ type: UPDATE_PROFILE, payload: { User: response?.User } });
};

export const addSkill = (data) => async (dispatch) => {
  const response = await Api.post("/api/v1/users/cv_step5", data);
  dispatch({ type: UPDATE_PROFILE, payload: { User: response.User } });
};
export const editSkill = (data) => async (dispatch) => {
  const response = await Api.put("/api/v1/users/cv_step5", data);
  dispatch({ type: UPDATE_PROFILE, payload: { User: response.User } });
};

export const deleteSkill = (skillId, skillType) => async (dispatch) => {
  const response = await Api.delete(
    `/api/v1/users/cv_step5?type=${skillType}&id=${skillId}`
  );
  dispatch({ type: UPDATE_PROFILE, payload: { User: response.User } });
};

export const goToNextStep = () => async (dispatch) => {
  const response = await Api.put("/api/v1/users/cv_next_step");
  dispatch({
    type: UPDATE_STEP_FINISHED,
    payload: { step_finished: response?.step_finished },
  });
};

export const addExperience = (data) => async (dispatch) => {
  const fromDate = getMonthYear(data.from);
  const toDate = getMonthYear(data.to);
  let sendData = {
    job_title: data.job_title,
    experience_type_id: data.experience_type_id,
    job_category_id: data.job_category_id,
    company_name: data.company_name,
    career_level_id: data.career_level_id,
    to_current: data.to_current ? 1 : 0,
  };
  if (data.description !== "") {
    sendData.description = data.description;
  }
  if (fromDate) {
    sendData.from_month = fromDate.month;
    sendData.from_year = fromDate.year;
  }
  if (!data.to_current) {
    sendData.to_month = toDate.month;
    sendData.to_year = toDate.year;
  }
  if (data.job_title_id !== null && data.job_title_id !== undefined)
    sendData.job_title_id = data.job_title_id;

  const response = await Api.post(
    "/api/v1/users/cv_step4_experience",
    sendData
  );
  dispatch({ type: UPDATE_PROFILE, payload: { User: response?.User } });
};

export const updateExperience = (data) => async (dispatch) => {
  const fromDate = getMonthYear(data.from);
  const toDate = getMonthYear(data.to);
  let sendData = {
    experience_id: data.id,
    job_title: data.job_title,
    experience_type_id: data.experience_type_id,
    job_category_id: data.job_category_id,
    company_name: data.company_name,
    career_level_id: data.career_level_id,
    to_current: data.to_current ? 1 : 0,
  };
  if (fromDate) {
    sendData.from_month = fromDate.month;
    sendData.from_year = fromDate.year;
  }
  if (
    data.description !== "" &&
    data.description !== null &&
    data.description !== undefined
  ) {
    sendData.description = data.description;
  }
  if (!data.to_current) {
    sendData.to_month = toDate.month;
    sendData.to_year = toDate.year;
  }
  if (data.job_title_id !== null && data.job_title_id !== undefined) {
    sendData.job_title_id = data.job_title_id;
  }

  const response = await Api.put("/api/v1/users/cv_step4_experience", sendData);
  dispatch({ type: UPDATE_PROFILE, payload: { User: response?.User } });
};

export const deleteExperience = (experience_id) => async (dispatch) => {
  const response = await Api.delete(
    `/api/v1/users/cv_step4_experience?experience_id=${experience_id}`
  );
  dispatch({ type: UPDATE_PROFILE, payload: { User: response?.User } });
};
export const addContactPortfolio = (data) => async (dispatch) => {
  const response = await Api.post("/api/v1/users/cv_step2", data);
  dispatch({ type: UPDATE_PROFILE, payload: { User: response?.User } });
};

export const editContactPortfolio = (data) => async (dispatch) => {
  const response = await Api.put("/api/v1/users/cv_step2", data);
  dispatch({ type: UPDATE_PROFILE, payload: { User: response?.User } });
};

export const addCompanyContactInfo = (data) => async (dispatch) => {
  const response = await Api.post("/api/v1/recruiters/step1", data);
  dispatch({
    type: UPDATE_PROFILE,
    payload: {
      recruiter: response?.Recruiter,
      role: response?.role,
    },
  });
  if (response?.role?.length == 1) {
    dispatch(assignRole(response?.role?.[0]));
  }
};

export const updateCompanyContactInfo = (data) => async (dispatch) => {
  const response = await Api.put("/api/v1/recruiters/step1", data);
  dispatch({
    type: UPDATE_PROFILE,
    payload: { recruiter: response?.Recruiter },
  });
  if (response?.role?.length == 1) {
    dispatch(assignRole(response?.role?.[0]));
  }
};

export const addCompanyInfo = (data) => async (dispatch) => {
  delete data.country_id;
  delete data.governorate_id;
  const response = await Api.post("/api/v1/recruiters/step2", data, {
    headers: {
      type: "RECRUITER",
    },
  });
  dispatch({
    type: UPDATE_PROFILE,
    payload: { recruiter: response?.Recruiter },
  });
};

export const updateCompanyInfo = (data) => async (dispatch) => {
  delete data.country_id;
  delete data.governorate_id;
  const response = await Api.put("/api/v1/recruiters/step2", data, {
    headers: {
      type: "RECRUITER",
    },
  });
  dispatch({
    type: UPDATE_PROFILE,
    payload: { recruiter: response?.Recruiter },
  });
};

export const submitCompany = () => async (dispatch) => {
  const response = await Api.put("/api/v1/recruiters/submit");
  dispatch({
    type: UPDATE_PROFILE,
    payload: { recruiter: response?.Recruiter },
  });
  dispatch(assignRole(RECRUITER_ROLE));
};

export const addGeneralInfo = (data, gender) => async (dispatch) => {
  let nats = [];
  let sendData = new FormData();

  data.nationality_id?.map((data) => {
    sendData.append(`nationality_id[]`, data.id);
    nats.push(data.id);
  });
  sendData.append("first_name", data.first_name);
  sendData.append("second_name", data.second_name);
  sendData.append("job_title", data.job_title);
  sendData.append("bio", data.bio);
  sendData.append("gender_id", gender);
  sendData.append("city_id", data.city_id);
  if (data.military_id) sendData.append("military_id", data.military_id);
  sendData.append("marital_id", data.marital_id);
  if (data?.career_level_id)
    sendData.append("career_level_id", data.career_level_id);
  // sendData.append("image", data.image)

  if (data.birth_of_date)
    sendData.append("birth_of_date", data.birth_of_date?.toISOString?.());

  if (data.currentImgFile && data.currentImgFile !== undefined) {
    sendData.append("image", data.currentImgFile, data.currentImgFile.name);
  }

  if (data.job_title_id !== null && data.job_title_id !== undefined) {
    sendData.append("job_title_id", data.job_title_id);
  }

  const response = await Api.post("/api/v1/users/cv_step1", sendData);
  dispatch({ type: FETCH_PROFILE, payload: response });
  if (response?.role?.length == 1) {
    dispatch(assignRole(response?.role?.[0]));
  }
};
export const updateGeneralInfo = (data, gender) => async (dispatch) => {
  let nats = [];

  let sendData = new FormData();

  data.nationality_id?.map((data) => {
    sendData.append(`nationality_id[]`, data.id);
    nats.push(data.id);
  });

  sendData.append("first_name", data.first_name);
  sendData.append("second_name", data.second_name);
  sendData.append("job_title", data.job_title);
  sendData.append("bio", data.bio);
  sendData.append("gender_id", gender);
  sendData.append("city_id", data.city_id);
  if (data?.career_level_id)
    sendData.append("career_level_id", data?.career_level_id);

  if (data.military_id) sendData.append("military_id", data.military_id);
  sendData.append("marital_id", data.marital_id);

  if (data.birth_of_date) {
    sendData.append("birth_of_date", data.birth_of_date);
  }
  if (data.currentImgFile && data.currentImgFile !== undefined) {
    sendData.append("image", data.currentImgFile, data.currentImgFile.name);
  }

  if (data.job_title_id !== null && data.job_title_id !== undefined) {
    sendData.append("job_title_id", data.job_title_id);
  }
  const response = await Api.put("/api/v1/users/cv_step1", sendData);
  dispatch({ type: FETCH_PROFILE, payload: response });
  if (response?.role?.length == 1) {
    dispatch(assignRole(response?.role?.[0]));
  }
};

export const addInterests = (data) => async (dispatch) => {
  const response = await Api.post("/api/v1/users/cv_step6", data);
  dispatch({ type: UPDATE_PROFILE, payload: { User: response?.User } });
  dispatch(assignRole(APPLICANT_ROLE));
};

export const editInterests = (data) => async (dispatch) => {
  const response = await Api.put("/api/v1/users/cv_step6", data);
  dispatch({ type: UPDATE_PROFILE, payload: { User: response?.User } });
  dispatch(assignRole(APPLICANT_ROLE));
};

export const deleteProfileImage = () => async (dispatch) => {
  await Api.delete("/api/v1/users/profile_image");
  dispatch({ type: DELETE_PROFILE_IMAGE, payload: null });
};

export const loadProfileFromRedux = () => (dispatch) => {
  dispatch({ type: LOAD_PROFILE });
};

export const changeDefaultRoleSettings = (role) => async (dispatch) => {
  const data = await changeDefaultRole(role);
  dispatch({ type: CHANGE_DEFAULT_ROLE, payload: data });
};

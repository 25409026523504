import { Stack, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

import { t } from "i18next";
import ApplyIcon from "./components/assets/Apply.svg";
import FindTheJobIcon from "./components/assets/FindTheJob.svg";
import JoinUsIcon from "./components/assets/JoinUs.svg";
import DownloadTheAppSection from "./components/download-the-app-section/DownloadTheAppSection";
import HowDoesWorkSection from "./components/how-does-work-section/HowDoesWorkSection";
import OurAdvantagesSection from "./components/our-advantages-section/OurAdvantagesSection";
import OurClientsSection from "./components/out-clients-section/OurClientsSection";
import RecruiterHeroSection from "./components/recruiter-hero-section/RecruiterHeroSection";
import "./RecruiterLandingPage.css";

const GuestRecruiterLandingPage = () => {
  const mdMatches = useMediaQuery("(min-width:701px) and (max-width:1300px)");
  const smMatches = useMediaQuery("(max-width:700px)");
  const data = [
    {
      number: 1,
      image: JoinUsIcon,
      title: t("join_us"),
      description:t("recruiter_join_us_desc")
    },
    {
      number: 1,
      image: FindTheJobIcon,
      title: t("post_your-jobs"),
      description:t("post_your_jobs_desc")
    },
    {
      number: 1,
      image: ApplyIcon,
      title: t("find_the_right_fit"),
      description:t("find_candidate_desc")
    },
  ];

  return (
    <Box  mb={0}>
      <RecruiterHeroSection />
      <Stack direction={"column"} spacing={6}>
        <HowDoesWorkSection data={data} />
        <OurAdvantagesSection backColored={true} />
        <OurClientsSection />
        <DownloadTheAppSection />
      </Stack>
    </Box>
  );
};

export default GuestRecruiterLandingPage;

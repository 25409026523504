import { Divider, IconButton, ListItemIcon, MenuItem, MenuList, Paper, Typography, Stack, Menu, Button, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { BellSimple, Circle, EnvelopeOpen } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Show from '../../../../../../../components/Show';
import { readSingleNotification, translateField } from '../../../../../../../services/Helper';
import { OPENED } from '../../../../../../../state/actions/notification/types';
import { getNotifications, readAllNotifications } from '../../../../../../../state/actions/notifications/notifications';
import EnhancedDropdownElement from './enhanced-dropdown-element/EnhancedDropdownElement';

import "./EnhancedNotificationsDropdown.css";

const EnhancedNotificationsDropdown = () => {

    // navigator
    const navigate = useNavigate();

    // translation
    const { t, i18n } = useTranslation();

    // state
    const [anchorEl, setAnchorEl] = useState(null);

    // redux
    const dispatch = useDispatch();
    const role = useSelector((state) => state.profile?.role?.name);
    let notifications = useSelector((state) => state.notifications.data);
    const dataLoaded = useSelector((state) => state.notifications.loaded);
    const limit = useSelector((state) => state.notifications.limit);
    const newNotification = useSelector((state) => state.newNotification.new);

    notifications = notifications?.slice(0, 4);

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        const target = document.getElementById("notification-button-side");
        setAnchorEl(target);
        dispatch({ type: OPENED });
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const handleOneNotifyClick = async (id, redirect) => {
        await readSingleNotification(role, id);
        navigate(redirect);
        handleClose();
    }

    const handleReadAll = () => {
        dispatch(readAllNotifications());
    }

    const handleDropdownItemClick = (route) => {
        navigate(route);
        handleClose();
    }

    useEffect(() => {
        if (open) dispatch(getNotifications(limit))
    }, [open])
    return (
        <>
            <Box>
                <IconButton
                    id="notification-button"
                    aria-controls={open ? 'notification-dropdown' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    sx={{
                        position: "relative",
                        color: "#3A3B81",
                        background: "#3A3B811A",
                        borderRadius: "8px",
                        "&:hover": {
                            background: "#3A3B811A"
                        }
                    }}
                >
                    <BellSimple />
                    <Show condition={newNotification}>
                        <Circle color="red" size={8} weight="fill" style={{ position: "absolute", top: "5px", right: "5px" }} />
                    </Show>
                </IconButton>
                <Box component={"span"} id="notification-button-side"></Box>
            </Box>
            <Menu
                id="notification-dropdown"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                disableScrollLock={true}
            >
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} marginBottom={2}>
                    <Typography className="menu-title">{t("notifications")}</Typography>
                    <IconButton
                        className="read-all-btn"
                        onClick={() => handleReadAll()}
                    >
                        <EnvelopeOpen size={18} style={{
                            marginRight: i18n.language == "en" ? "8px" : "0px",
                            marginLeft: i18n.language == "en" ? "0px" : "8px",
                        }} />
                        <Typography>{t("mark-all-as-read")}</Typography>
                    </IconButton>
                </Stack>
                <Stack>
                    {notifications?.map((item) => (
                        <>
                            <EnhancedDropdownElement
                                active={item.status == 0 ? true : false}
                                name={() => {
                                    if (item.User)
                                        return item.User.first_name + " " + item.User.second_name;
                                    else
                                        return item.Recruiter.company_name;
                                }}
                                image={item.User ? item.User.image : item.Recruiter.image}
                                message={translateField(item, "message_property")}
                                date={item?.created_at}
                                onClick={() => handleOneNotifyClick(item?.id, item.redirect)}
                            />
                        </>
                    ))}
                </Stack>
                <Stack direction={"row"} justifyContent={"center"}>
                    <Button className="see-all-btn" onClick={() => handleDropdownItemClick("/notifications")}>
                        {t("see-all")}
                    </Button>
                </Stack>
            </Menu>
        </>
    );
};

export default EnhancedNotificationsDropdown;
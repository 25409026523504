import { useSelector } from "react-redux";
import Show from "../../../components/Show";
import ApplicantLandingPage from "./ApplicantLandingPage";
import GuestApplicantLandingPage from "./GuestApplicantLandingPage";
import GuestRecruiterLandingPage from "./GuestRecruiterLandingPage";
import RecruiterLandingPage from "./RecruiterLandingPage";

const LandingPage = () => {
  const mode = useSelector((state) => state.mode.name);

  return (
    <>
      <Show condition={mode === "guestApplicant"}>
        <GuestApplicantLandingPage />
      </Show>
      <Show condition={mode === "guestRecruiter"}>
        <GuestRecruiterLandingPage />
      </Show>
      <Show condition={mode === "applicant"}>
        <ApplicantLandingPage />
      </Show>
      <Show condition={mode === "recruiter"}>
        <RecruiterLandingPage />
      </Show>
    </>
  );
};

export default LandingPage;

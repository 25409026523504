import {
  Box,
  Grid,
  InputBase,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Fuse from "fuse.js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getStaticPages, translateField } from "../../../../../services/Helper";
import TypeSwitch from "../../../abdelrahman/TypeSwitch/TypeSwitch";
import HeroSection from "../../components/hero-section/HeroSection";
import EnhancedButton from "./../../components/enhanced-button/EnhancedButton";
import EnhancedFaq from "./../../components/enhanced-faq/EnhancedFaq";
import "./HelpCenter.scss";

const HelpCenter = () => {
  const mdMatches = useMediaQuery("(min-width:701px) and (max-width:1000px)");
  const smMatches = useMediaQuery("(max-width:700px)");

  const [faqs, setFaqs] = useState([]);
  const [filteredFaqs, setFilteredFaqs] = useState([]);
  const [type, setType] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const handleGetFaqs = async () => {
      if (type === 1) {
        return await getStaticPages("FAQsRecruiter");
      } else {
        return await getStaticPages("FAQS");
      }
    };

    handleGetFaqs().then((data) => {
      setFaqs(data);
      setFilteredFaqs(data);
    });
  }, [type]);

  const handleSearch = () => {
    if (
      searchTerm?.trim === "" ||
      searchTerm === "" ||
      searchTerm == undefined ||
      searchTerm == null
    ) {
      setFilteredFaqs(faqs);
    } else {
      const options = {
        includeScore: true,
        keys: [`name_${i18n.language}`, `value_${i18n.language}`],
      };
      const fuse = new Fuse(faqs, options);
      const result = fuse.search(searchTerm);

      const temp = result.map((one) => one.item);
      setFilteredFaqs(temp);
    }
  };

  const switchOptions = [
    {
      name_en: "Applicant Questions",
      name_ar: "أسئلة المتقدمين",
      id: 0,
    },
    {
      name_en: "Recruiter Questions",
      name_ar: "أسئلة اصحاب العمل",
      id: 1,
    },
  ];

  const renderFaqs = () => {
    return filteredFaqs.map((faq) => {
      return (
        <Grid item xs={12}>
          <EnhancedFaq
            question={translateField(faq, "name")}
            answer={translateField(faq, "value")}
          />
        </Grid>
      );
    });
  };

  return (
    <Stack
      direction={"column"}
      spacing={10}
      justifyContent={"center"}
      alignItems={"center"}
      
    >
      <HeroSection small={true}>
        <Box align={"center"}>
          <Typography className={"hero-section-title colored"}>
            {t("help_center")}
          </Typography>
          <Typography className={"hero-section-description"}>
            {t("help_center_desc")}
          </Typography>
        </Box>
      </HeroSection>
      <Stack
        direction={"column"}
        alignItems={"stretch"}
        justifyContent={"center"}
        spacing={1}
        sx={{
          width: { md: "100%", lg: "84.8%" },
        }}
      >
        <Grid
          container
          columnSpacing={1}
          rowSpacing={2}
          sx={{ width: "100%" }}
          justifyContent={"space-between"}
        >
          <Grid item xs={12} mb={2}>
            <Typography
              className="colored-text-urbanist"
              align="center"
              sx={{
                font: "normal normal bold 30px/37px Urbanist",
              }}
            >
              {t("frequently_asked_question")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TypeSwitch options={switchOptions} onChange={setType} />
          </Grid>
          <Grid item xs={12} container columnSpacing={1}>
            <Grid item xs={9} sm={true}>
              <InputBase
                fullWidth
                placeholder={t("search")}
                onChange={(e) => setSearchTerm(e.target.value)}
                sx={{
                  border: " 1px solid #E4E4E4",
                  borderRadius: "8px",
                  paddingLeft: "24px",
                  paddingRight: "24px",
                  minHeight: "50px",
                }}
              />
            </Grid>
            <Grid item xs={3} md={2}>
              <EnhancedButton
                buttonText={t("search")}
                variant={"contained"}
                padding={"16px 40px"}
                onClick={handleSearch}
              />
            </Grid>
          </Grid>

          {renderFaqs()}
        </Grid>
      </Stack>
    </Stack>
  );
};

export default HelpCenter;

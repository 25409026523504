import { Skeleton } from '@mui/material';
import React from 'react';
import Show from '../Show';

const Loader = ({ data, children, height, width, style }) => {
    return (
        <>
            <Show condition={!data}>
                <Skeleton variant='rectangular' animation="wave" width={width} height={height} sx={{ marginBottom: 1 }} style={style} />
            </Show>
            <Show condition={data}>
                {children}
            </Show>
        </>
    );
};

export default Loader;
import { Grid, Stack, useMediaQuery } from "@mui/material";
import { t } from "i18next";
import React from "react";
import { useNavigate } from "react-router-dom";
import Hide from "../../../../../../../components/Hide";
import EnhancedButton from "../../../../../yahya/components/enhanced-button/EnhancedButton";
import HeaderText from "../../../../components/HeaderText/HeaderText";
import SubtitleText from "../../../../components/sub-title-text/SubtitleText";
import IllustrationIcon from "./Illustration.svg";


const ContactUsSection = () => {
  const smMatches = useMediaQuery("(max-width:899px)");
  const navigate = useNavigate();

  return (
    <Grid
      container
      direction={"row"}
      justifyContent={"space-between"}
      sx={{
        width: "100%"
      }}
    >
      <Grid item md={5}>
        <Stack direction={"column"} >
          <Stack>
            <HeaderText
              text={t("joblinks")}
              fontSize={"40px"}
              fontWeight={"800"}
            />
            {/* <HeaderText
              text={ }
              fontSize={"25px"}
              fontWeight={"600 "}
            /> */}
          </Stack>
          <Stack sx={{ marginTop: "24px", marginBottom: "24px" }}>
            <SubtitleText
              title={`${t("recruiter_joblink_desc")} ${t("recruiter_about_us_desc")} `}
            />
          </Stack>
          {smMatches ? (
            <Stack direction={"row"} spacing={3}>
              <Stack>
                <EnhancedButton
                  buttonText={t("contact_us")}
                  padding={"16px 40px"}
                />
              </Stack>
              <Stack>
                <EnhancedButton
                  buttonText={t("know_more")}
                  padding={"16px 40px"}
                  variant={"outlined"}
                />
              </Stack>
            </Stack>
          ) : (
            <Stack sx={{ width: "158px" }}>
              <EnhancedButton
                onClick={() => navigate("/contact")}
                buttonText={t("contact_us")}
                padding={"16px 40px"}
              />
            </Stack>
          )}
        </Stack>
      </Grid>
      <Grid item md={4.8}>
        <Hide condition={smMatches}>
          <Stack>
            <img src={IllustrationIcon} />
          </Stack>
        </Hide>
      </Grid>

    </Grid>
  );
};

export default ContactUsSection;

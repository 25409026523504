import { B2C_LOGIN_STARTED, B2C_LOGIN_FINISHED, B2C_LOGIN_COMPLETED, RESET } from "../../actions/b2c/types";

let initialState = {
    loading: false,
    finished: false,
}

export const b2cReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case B2C_LOGIN_STARTED:
            return {
                ...state,
                loading: true,
                finished: false
            };
        case B2C_LOGIN_FINISHED:
            return {
                ...state,
                loading: false,
                finished: true
            };
        case B2C_LOGIN_COMPLETED:
            return {
                ...state,
                loading: false,
                finished: false,
            };
        case RESET:
            return {
                ...state,
                loading: false,
                finished: false
            };
        default:
            return state;
    }
}

export default b2cReducer;

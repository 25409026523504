import { Chip, Typography } from "@mui/material";
import "./EnhancedJobTag.scss";

const EnhancedJobTag = ({ tag, hovered }) => {
  return (
    <Chip
      className={`job-tag${hovered ? " hovered" : ""}`}
      label={
        <Typography className={`tag-text${hovered ? " hovered" : ""}`}>
          {tag}
        </Typography>
      }
    />
  );
};
export default EnhancedJobTag;

import React, { useState } from "react";

import { Tab, Tabs, Stack } from "@mui/material";
import "./Links.css";
import { useTranslation } from "react-i18next";

const Links = ({ links, orientation, mobileClass, currentSection, handleChange }) => {
  // translation
  const { t } = useTranslation();

  return (
    <Stack
      alignItems={mobileClass ? "flex-start" : "center"}
      className="links-container"
    >
      <Tabs
        orientation={orientation}
        value={currentSection}
        onChange={handleChange}
        // className="links"
        className={mobileClass ? "mobile-links" : "links"}
        TabIndicatorProps={{ children: <span /> }}
        sx={{
          width: "100%"
        }}
      >
        {links.map((link, index) => {
          return (
            <Tab
              onClick={link.onClick}
              label={t(link.label)}
              value={link.value}
              disableRipple
              className={`link-tab ${link.newPage ? "blue-color" : ""}`}
            />
          );
        })}
      </Tabs>
    </Stack>
  );
};

export default Links;

import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { Provider } from "react-redux";
import { store } from "./state/store";

import SkeletonRedirecting from "components/SkeletonRedirecting";
import { Suspense } from "react";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('../firebase-messaging-sw.js')
    .then(function (registration) {
      // 
    }).catch(function (err) {
      // 
    });
}

ReactDOM.render(
  <Provider store={store}>

    <I18nextProvider i18n={i18n}>
      <Suspense fallback={<SkeletonRedirecting />}>
        {/* <LanguageInstaller> */}
        <App />
        {/* </LanguageInstaller> */}
      </Suspense>
    </I18nextProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
//Native CSS
import "./ApplicantProfile.scss";

//Material UI imports
import { Box, Grid } from "@mui/material";

//component re-usable content
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ApplicantProfileViewHeader from "../../components/applicant-profile-view-header/ApplicantProfileViewHeader";
import Loader from "../../components/loader/Loader";
import ProfileContent from "./components/profile-content/ProfileContent";
import ProfileInfo from "./components/profile-info/ProfileInfo";

const ApplicantProfile = ({ userData }) => {
  const role = useSelector((state) => state.profile.role.name);
  const rehydrated = useSelector((state) => state._persist.rehydrated);
  const [showAddAndInviteProfile, setShowAddAndInviteProfile] = useState(false);

  useEffect(() => {
    setShowAddAndInviteProfile(
      role === "GUEST_USER" ? false : role === "RECRUITER" ? true : false
    );
  }, [rehydrated]);

  return (
    <Grid
      className="applicant-profile"
      component="main"
      direction="row"
      container
      justifyContent={"space-between"}
      mt={"32px"}
    >
      <ApplicantProfileViewHeader userData={userData} />
      <Grid
        item
        component="section"
        className="profile-info-section"
        xs={12}
        sm={12}
        md={3.2}
        lg={3.2}
        xl={3.2}
      >
        <Box className="applicant-profile-card">
          <Loader
            data={userData}
            width={"100%"}
            height={600}
            style={{ borderRadius: "1rem" }}
          >
            <ProfileInfo
              hideSaveIcon={true}
              showAddAndInviteProfile={showAddAndInviteProfile}
              userData={userData}
            />
          </Loader>
        </Box>
      </Grid>
      <Grid item component="section" xs={12} sm={12} md={8.5} lg={8.5} xl={8.5}>
        <Loader
          data={userData}
          width={"100%"}
          height={600}
          style={{ borderRadius: "1rem" }}
        >
          <ProfileContent
            showAddAndInviteProfile={showAddAndInviteProfile}
            hideSaveIcon={true}
            userData={userData}
          />
        </Loader>
      </Grid>
    </Grid>
  );
};
export default ApplicantProfile;

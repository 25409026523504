import { Box, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React from 'react'
import "./IconText.scss"

const IconText = ({ icon, text, className, iconRight, variant ,searchPage }) => {
    return (
        <Box
            className="icon-text-wrapper"
        >
            {iconRight ?
                <>
                    <Typography
                        mr={6}
                        component="span"
                        className={` ${className ? className : "icon-text-subtitle"} + ${variant === "colored" && "colored"}`}
                    >{text}</Typography>
                    <Box sx={{color: searchPage ? "#3A3B81" : ""}}>
                        {icon}
                    </Box>

                </> :
                <>
                    <Box sx={{color: searchPage ? "#3A3B81" : ""}}>
                        {icon}
                    </Box>
                    <Typography
                        ml={0.5}
                        mr={0.5}
                        component="span"
                        className={` ${className ? className : "icon-text-subtitle"} + ${variant === "colored" && "colored"}`}
                    >{text}</Typography>
                </>
            }
        </Box >
    )
}

export default IconText
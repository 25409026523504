import React from 'react';
import { Stack  } from '@mui/material';
import "./CompanyTag.css"

const CompanyTag = ({ icon, text ,ltrChangesInSlider}) => {
    return (
        <Stack direction="row" alignItems={"center"}   className={ltrChangesInSlider ? "card-tag slider-rtl-changes" : "card-tag"}>
            <Stack >{icon}</Stack>
            <Stack sx={{ whiteSpace: "nowrap" }} className="text">{text}</Stack>
        </Stack>
       
    )
}

export default CompanyTag
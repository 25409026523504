import { Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import JLSelect from '../../../../components/JLSelect';
import SingleAutocomplete from '../../../../components/single-autocomplete/SingleAutocomplete';
import { getJobCategory, getJobTitles, getSeniorityLevel, getWorkType, getYearsOfExperience } from '../../../../services/Helper';
import { CancelNextButtons } from '../../NewJob';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getHiringTime } from './../../../../services/Helper';
import './BasicDetails.css';
import RTL from 'components/RTL';

const BasicDetails = ({ control, errors, handleBack, handleNext, getValues, pJobTitles, disabled, loading }) => {
    const { t } = useTranslation();

    const [yearsOfExperience, setYearsOfExperience] = useState([]);
    const [seniorityLevel, setSeniorityLevel] = useState([]);
    const [workType, setWorkType] = useState([]);
    const [jobCategory, setJobCategory] = useState([]);
    const [hiringTime, setHiringTime] = useState([]);
    const [jobTitles, setJobTitles] = useState([]);

    const { job_id } = useParams();

    useEffect(() => {
        getYearsOfExperience().then((data) => setYearsOfExperience(data));
        getSeniorityLevel().then((data) => setSeniorityLevel(data));
        getWorkType().then((data) => setWorkType(data));
        getJobCategory().then((data) => setJobCategory(data));
        getHiringTime().then((data) => setHiringTime(data));
        return () => {
            setHiringTime(null);
            setYearsOfExperience(null);
            setSeniorityLevel(null);
            setWorkType(null);
            setJobCategory(null);
        };
    }, []);

    const onJobTitleChange = (value) => {
        if (value?.length > 2) {
            getJobTitles(value).then((data) => setJobTitles(data ? data : []));
        }
    };

    return (
        <>
            <Grid container direction="column" spacing={1}>
                <Grid item marginBottom={1}>
                    <Typography className="step-title">{t('basic_details')}</Typography>
                </Grid>
                <Grid item>
                    <RTL>
                        <SingleAutocomplete
                            control={control}
                            fieldName={'job_title_id'}
                            label={t('job_title')}
                            placeholder={t('enter_job_title')}
                            options={jobTitles}
                            onChange={(input) => onJobTitleChange(input)}
                            error={errors.job_title_id}
                            disabled={disabled}
                            edit={job_id !== undefined}
                        />
                    </RTL>
                </Grid>
                <Grid item>
                    <JLSelect
                        control={control}
                        fieldName="years_of_experience_id"
                        label={t('years_of_experience')}
                        selectLabel={t('years_of_experience')}
                        className="new-job-input"
                        childs={yearsOfExperience}
                        error={errors.years_of_experience_id}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item>
                    <JLSelect
                        control={control}
                        fieldName="hiring_time_id"
                        label={t('hiring_time')}
                        selectLabel={t('hiring_time')}
                        className="new-job-input"
                        childs={hiringTime}
                        error={errors.hiringTime}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item>
                    <JLSelect
                        control={control}
                        fieldName="career_level_id"
                        label={t('seneority_level_label')}
                        selectLabel={t('seneority_level_label')}
                        className="new-job-input"
                        childs={seniorityLevel}
                        error={errors.career_level_id}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item>
                    <JLSelect
                        control={control}
                        fieldName="experience_type_id"
                        label={t('work_type_label')}
                        selectLabel={t('work_type_label')}
                        className="new-job-input"
                        childs={workType}
                        error={errors.experience_type_id}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item>
                    <JLSelect
                        control={control}
                        fieldName="job_category_id"
                        label={t('job_category')}
                        selectLabel={t('industry')}
                        className="new-job-input"
                        childs={jobCategory}
                        error={errors.job_category_id}
                        disabled={disabled}
                    />
                </Grid>
                <CancelNextButtons cancel onCancel={handleBack} onNext={handleNext} cancelText={t('cancel')} nextText={t('next_btn')} loading={loading} />
            </Grid>
        </>
    );
};
export default BasicDetails;

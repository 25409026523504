const Card = ({ children, single, bg }) => {
    let className = '';
    if (single) {
        className = 'card full-rounded'
    } else if (!single && bg == 'dark') {
        className = 'card card-desc'
    }
    else if (!single && bg != 'dark') {
        className = 'card card-form'
    }
    return (
        <div className={className}>
            {children}
        </div>
    )
}
export default Card;
import React, { useEffect } from 'react'
import Skills from './../pages/applicant-submit/Skills';
import { Helmet } from 'react-helmet';
import Experience from './../pages/applicant-submit/Experience';
import Education from './../pages/applicant-submit/Education';
import GeneralInfo from './../pages/applicant-submit/GeneralInfo';
import ContactInfoPortfolio from "../pages/applicant-submit/ContactInfoPortfolio"
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import AuthStepper from './../../components/auth-stepper/Stepper';
import { stepperData } from "./stepper"
import { useSelector } from 'react-redux';
import { useState } from 'react';

import "./ApplicantProfileEdit.scss"
import { useNavigate } from 'react-router-dom';


const ApplicantProfileEdit = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const stepsFromApi = useSelector((state) => {
        let steps = state.profile.data?.cv?.User?.UserSteps;
        return steps && steps.length > 0 ? steps[0] : {};
    });
    const currentStep = useSelector((state) => state.stepper.currentStep);
    const [stepperDataState, setStepperDataState] = useState(stepperData);

    useEffect(() => {
        let tempStepperData = stepperData;
        Object.keys(stepsFromApi).map((key, index) => {
            if (index !== 5) {
                tempStepperData[index].completed = stepsFromApi[key]
            }
        })
        setStepperDataState(tempStepperData);
        
    }, [stepsFromApi])

    const title = "Edit Profile"
    const renderStepContent = () => {
        switch (currentStep) {
            case 1:
                return (
                    <>
                        <Helmet>
                            <title>{title} - {t("general_info")}</title>
                        </Helmet>
                        <GeneralInfo />
                    </>
                );
            case 2:
                return (
                    <>
                        <Helmet>
                            <title>{title} - {t("contact_and_portfolio")}</title>
                        </Helmet>
                        <ContactInfoPortfolio />
                    </>
                );
            case 3:
                return (
                    <>
                        <Helmet>
                            <title>{title} - {t("education")}</title>
                        </Helmet>
                        <Education />
                    </>
                );
            case 4:
                return (
                    <>
                        <Helmet>
                            <title>{title} - {t("experience")}</title>
                        </Helmet>
                        <Experience />
                    </>
                );
            case 5:
                return (
                    <>
                        <Helmet>
                            <title>{title} - {t("skills")}</title>
                        </Helmet>
                        <Skills lastStep={true} handleSubmit={() => navigate("/my-profile")} />
                    </>
                );
            default:
                break;
        }
    }
    return (
        <Box className="edit-profile common-dark-stepper">
            <AuthStepper
                data={stepperDataState}
                finalStep={4}
                startStep={0}
                renderStepContent={renderStepContent}
                label={"test"}
                hideHeader={true}
                stepsFromApi={stepsFromApi}
                pass={true}
                hideLogoutLang={true}
            />
        </Box>

    )
}

export default ApplicantProfileEdit


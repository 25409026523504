import { AppStoreLogo, FacebookLogo, GooglePlayLogo, InstagramLogo, LinkedinLogo, TwitterLogo } from 'phosphor-react';
import { ReactComponent as AppGalleryLogo } from '../../../../assets/App Gallery.svg';
import { ReactComponent as JobLinksLogo } from '../../../../assets/WhiteLogo.svg';

export const socialMedia = [
    { url: 'https://linkedin.com', icon: <LinkedinLogo weight="fill" size={19} /> },
    { url: 'https://facebook.com', icon: <FacebookLogo weight="fill" size={19} /> },
    { url: 'https://instagram.com', icon: <InstagramLogo weight="fill" size={19} /> },
    { url: 'https://twitter.com', icon: <TwitterLogo weight="fill" size={19} /> },
];

export const appStores = [
    {
        recruiterUrl: 'https://play.google.com/store/apps/details?id=com.joblinksrecruiter.app.digitalroots',
        applicantUrl: 'https://play.google.com/store/apps/details?id=com.joblink.app',
        icon: <GooglePlayLogo size={19} />,
    },
    {
        recruiterUrl: 'https://apps.apple.com/us/app/job-links-recruiter/id1640982525',
        applicantUrl: 'https://apps.apple.com/us/app/job-links/id1604374545',
        icon: <AppStoreLogo size={19} />,
    },
    {
        recruiterUrl: 'https://appgallery.cloud.huawei.com/ag/n/app/C107072649?',
        applicantUrl: 'https://appgallery.cloud.huawei.com/ag/n/app/C106194891?',
        icon: <AppGalleryLogo size={19} />,
    },
];

export const description = {
    name_en:
        'Job Links Hub help job seekers create professional cv and search for suitable jobs that match their skills and experience and help recruiters to post their jobs and reach more applicants easily. ',
    name_ar:
        'يساعد Job Links Hub الباحثين عن عمل في إنشاء سيرة ذاتية احترافية والبحث عن وظائف مناسبة تتناسب مع مهاراتهم وخبراتهم وتساعد مسؤولي التوظيف على نشر وظائفهم والوصول إلى المزيد من المتقدمين بسهولة. ',
};

export const sections = [
    { name_en: 'About', name_ar: 'تعرف علينا', id: 1, to: '/about' },
    { name_en: 'Terms & Conditions', name_ar: 'الشروط والاحكام', id: 2, to: '/terms' },
    { name_en: 'Privacy Policy', name_ar: 'سياسة الخصوصية', id: 3, to: '/privacy' },
    { name_en: 'Help Center', name_ar: 'مركز المساعدة', id: 4, to: '/help' },
    { name_en: 'Contact Us', name_ar: 'تواصل معنا', id: 5, to: '/contact' },
];

export const logo = <JobLinksLogo />;

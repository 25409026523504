import { Button, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ApplicationStatus from "../../../../components/application-status/ApplicationStatus";
import "./FilterStatus.scss";

const FilterStatus = ({ changeFilter }) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [filterStatus, setFilterStatus] = useState(null);

  const filters = ["", t("pending_status"), t("shortlisted_status"), t("refused_status")];

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleFilter = (status) => {
    changeFilter(status, 1);
    setFilterStatus(status);
    handleClose();
  }
  return (
    <div>
      <Button
        className="filter-btn"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        { filterStatus ? t("filter_by", { filter: filters[filterStatus] }) : t("filtered")}
      </Button>
      <Menu
        id="basic-menu"
        disableScrollLock={true}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem sx={filterStatus === 1 && { background: "#fafafa" }} onClick={() => handleFilter(filterStatus === 1 ? null : 1)}>
          <ApplicationStatus status="Pending" />
        </MenuItem>
        <MenuItem sx={filterStatus === 2 && { background: "#fafafa" }} onClick={() => handleFilter(filterStatus === 2 ? null : 2)}>
          <ApplicationStatus status="Shortlisted" />
        </MenuItem>
        <MenuItem sx={filterStatus === 3 && { background: "#fafafa" }} onClick={() => handleFilter(filterStatus === 3 ? null : 3)}>
          <ApplicationStatus status="Refused" />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default FilterStatus;

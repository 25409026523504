import {
  Button,
  Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, Typography
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import Show from "../Show";
import "./Modals.scss";

const Modals = (props) => {
  const {
    open,
    handleClose,
    title,
    subtitle,
    content,
    disagree,
    agree,
    type,
    handleAgree,
    loading,
  } = props;
  const colors = {
    delete: "#ffffff",
    apply: "#ffffff",
    confirmation: "#ffffff",
    unfollow: "#3a3b81",
  };
  const backgrounds = {
    delete: "#3a3b81",
    apply: "#3a3b81",
    confirmation: "#3a3b81",
    unfollow: "rgba(58, 59, 129, 0.1)",
  };

  const navigate = useNavigate();
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableScrollLock={true}
        className="dialog"
        sx={{

          "& .MuiPaper-root": {
            borderRadius: "16px",
            padding:"18px",
            backgroundColor: type === "delete" ? "#ffffff" : "#f7f7f7",
            maxWidth: "400px",
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            color: type === "delete" ? "#3a3b81" : "rgba(0, 0, 0, 0.87)",
            fontWeight: type === "delete" ? "bold" : "400",
            fontFamily: "Urbanist",
            fontSize: "20px",
          }}
        >
          {title}
          <Show condition={type === "delete"}>
            <Typography
              component={"span"}
              color="red"
              sx={{
                fontFamily: "Urbanist",
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              {" "}
              {subtitle}
              {" ?"}
            </Typography>
          </Show>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className="disagree-btn">
            {disagree}
          </Button>
          <Button
            onClick={() => {
              handleAgree();
              handleClose();
            }}
            className="agree-btn"
            disabled={loading}
            sx={{
              color: colors[type],
              background: backgrounds[type],
              "&:hover": {
                background: backgrounds[type],
              },
            }}
          >
            {/* {loading ? <CircularProgress size={20} /> : agree} */}
            {agree}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Modals;

import { SEARCH_TOGGLE, SEARCH_SET, SEARCH_UNSET } from "../../actions/search/types"

let initialState = {
    active: false
}

export const searchReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_TOGGLE:
            return { ...state, active: !state.active };
        case SEARCH_SET:
            return { ...state, active: true };
        case SEARCH_UNSET:
            return { ...state, active: false };
        default:
            return state;
    }
}

export default searchReducer;

import { FormControl, FormHelperText, MenuItem, Select, useMediaQuery } from '@mui/material';
import { CaretDown } from 'phosphor-react';
import { Controller } from 'react-hook-form';
import { translateField } from '../services/Helper';
import Hide from './Hide';

const JLSelect = (props) => {
    console.log(props.childs);
    const matched = useMediaQuery('(max-width:900px)');
    return (
        <>
            <FormControl variant="standard" fullWidth className={['standart-form-controller-select', props?.className].join(' ')}>
                <Hide condition={matched && props?.label === ' '}>
                    <label className="label">
                        {props?.label}
                        {!matched && props?.label === ' ' ? <span>&nbsp;</span> : null}
                    </label>
                </Hide>
                <Controller
                    defaultValue=""
                    control={props?.control}
                    name={props?.fieldName}
                    render={({ field }) => {
                        return (
                            <Select
                                disabled={props.disabled}
                                MenuProps={{
                                    disableScrollLock: false,
                                }}
                                value={field.value}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                className="select"
                                variant="outlined"
                                error={props.error}
                                IconComponent={CaretDown}
                                onChange={(data) => {
                                    field.onChange(data);
                                    props?.onChange?.(data);
                                }}
                            >
                                <MenuItem style={props?.optional ? {} : { display: 'none' }} key={-1} value="">
                                    {props?.selectLabel}{' '}
                                </MenuItem>
                                {props?.childs &&
                                    props?.childs?.map((child) => (
                                        <MenuItem key={child.id} value={child.id}>
                                            {typeof child === 'object' ? translateField(child, 'name') : child}
                                        </MenuItem>
                                    ))}
                            </Select>
                        );
                    }}
                />
                {props.error && <FormHelperText style={{ color: 'red' }}>{props.error.message}</FormHelperText>}
            </FormControl>
        </>
    );
};
export default JLSelect;

/* eslint-disable react/react-in-jsx-scope */
import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import ApplicantProfileViewHeader from "../../components/applicant-profile-view-header/ApplicantProfileViewHeader";
import Loader from "../../components/loader/Loader";
import { getReadProfileApplicant } from "../../services/Helper";
import ProfileContent from "../applicant-profile/components/profile-content/ProfileContent";
import ProfileInfo from "../applicant-profile/components/profile-info/ProfileInfo";

import "./ApplicantCvProfile.scss";

const ApplicantCvProfile = () => {
  const role = useSelector((state) => state.profile.role.name);
  const rehydrated = useSelector((state) => state._persist.rehydrated);
  const { applicant_id } = useParams();
  const [showAddAndInviteProfile, setShowAddAndInviteProfile] = useState(false);
  useEffect(() => {
    setShowAddAndInviteProfile(
      role === "GUEST_USER" ? false : role === "RECRUITER" ? true : false
    );
  }, [rehydrated]);

  const [userData, setUserData] = useState();
  const [cvBankState, setCvBankState] = useState();
  const [fetchTrigger, setFetchTrigger] = useState(true);
  const location = useLocation();
  useEffect(() => {
    getReadProfileApplicant(applicant_id, role)
      .then((response) => {
        console.log("resss", response)
        setCvBankState(response.cv_bank);
        setUserData({ User: response });
      })
      .catch((error) => { });
  }, [fetchTrigger, location]);

  return (
    <Grid
      className="applicant-profile"
      component="main"
      direction="row"
      container
      justifyContent={"space-between"}
      mt={"32px"}
    >
      <ApplicantProfileViewHeader userData={userData?.User} />
      <Grid
        item
        component="section"
        className="profile-info-section"
        xs={12}
        sm={12}
        md={3}
        lg={4}
        xl={3}
      >
        <Box className="applicant-profile-card">
          <Loader
            data={userData}
            width={"100%"}
            height={600}
            style={{ borderRadius: "1rem" }}
          >
            <ProfileInfo
              userData={userData}
              hideSaveIcon={true}
              cvBank={{ cvBankState, setCvBankState }}
              showAddAndInviteProfile={showAddAndInviteProfile}
              fetchTrigger={fetchTrigger}
              setFetchTrigger={setFetchTrigger}
            />
          </Loader>
        </Box>
      </Grid>
      <Grid item component="section" xs={12} sm={12} md={8} lg={7} xl={8}>
        <Loader
          data={userData}
          width={"100%"}
          height={600}
          style={{ borderRadius: "1rem" }}
        >
          <ProfileContent
            userData={userData}
            showAddAndInviteProfile={showAddAndInviteProfile}
            cvBank={{ cvBankState, setCvBankState }}
            fetchTrigger={fetchTrigger}
            setFetchTrigger={setFetchTrigger}
          />
        </Loader>
      </Grid>
    </Grid>
  );
};

export default ApplicantCvProfile;

import React, { useEffect, useState } from "react";
import AuthPageWrapper from "../../../components/auth-page-wrapper/AuthPageWrapper";
// import OtpInput from "react-otp-input";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Box, Grid, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import VerifyOTP from "../../../components/VerifyOTP";
import { submitOTP, verifyEmail } from "services/AuthHelper";
import { toastr } from "react-redux-toastr";

const EmailVerification = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const onSubmit = async (data) => {
    const postData = {
      email: searchParams.get("email"),
      otp: data.otp,
      type: "VERIFY_EMAIL",
    };
    try {
      const submitOTPResult = await submitOTP(
        postData.email,
        postData.otp,
        postData.type
      );
      // const verifyEmailResult = await verifyEmail(submitOTPResult.token);
      toastr.success(submitOTPResult.message);
      navigate("/login");
    } catch (error) {
      toastr.error(error.response?.data?.error?.message);
      // navigate("/login");
    }
  };

  useEffect(() => {
    if (!searchParams.get("email")) navigate("/login");
  }, []);
  return (
    <AuthPageWrapper single={true}>
      <Grid
        className="signin-form"
        container
        item
        xs={12}
        justifyContent="center"
        alignItems="center"
      >
        <VerifyOTP title={"Enter Email Verification OTP"} onSubmit={onSubmit} />
      </Grid>
    </AuthPageWrapper>
  );
};

export default EmailVerification;

import axios from "axios";
import jwt from "jwt-decode";
import { toastr } from "react-redux-toastr";
import { AUTH_LOGIN_SUCCESS } from "state/actions/auth/types";
import { store } from "../state/store";
import { Api } from "./Api";

const { getState } = store;

export const login = async (email, password) => {
  const result = await axios.post(
    `${process.env.REACT_APP_AUTH_URL}/login`,
    {
      email,
      password,
    }
  );
  return await result.data;
};

export const refreshToken = async (refreshToken) => {
  let token = await getToken(getState().auth?.token);
  
  const result = await axios.post(
    `${process.env.REACT_APP_AUTH_URL}/refresh-token`,
    {
      refreshToken,
    },
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return await result.data;
};

export const signup = async (firstName, lastName, email, password) => {
  const result = await axios.post(
    `${process.env.REACT_APP_AUTH_URL}/signup`,
    {
      // firstName,
      // lastName,
      email,
      // username: email,
      password,
    }
  );
  return await result.data;
};

export const sendOTP = async (email, type) => {
  const result = await axios.post(
    `${process.env.REACT_APP_AUTH_URL}/send-otp`,
    {
      email,
    }
  );
  console.log("OTP RES", result);
  return await result.data;
};

export const submitOTP = async (email, otp, type) => {
  const result = await axios.post(
    `${process.env.REACT_APP_AUTH_URL}/submit-otp`,
    {
      email,
      otp,
      isReset: type === "RESET_PASSWORD"
    }
  );
  
  return await result.data;
};

export const verifyEmail = async (token, email) => {
  const result = await axios.put(
    `${process.env.REACT_APP_AUTH_URL}/send-otp`,
    {
      email,
    }
  );
  return await result.data;
};

export const resetPassword = async (newPassword, confirmPassword, email) => {
  let token = await getToken(getState().auth?.token);

  const result = await axios.put(
    `${process.env.REACT_APP_AUTH_URL}/reset-password`,
    {
      password: newPassword,
      confirmPassword,
      email
    },
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return await result.data;
};

export const changeEmail = async (email, token) => {
  const result = await axios.post(
    `${process.env.REACT_APP_AUTH_URL}/change-email`,
    {
      email,
    },
    {
      params: {
        realm: "joblinkshub",
        clientId: "73aba933-e8c0-469c-a226-4012f3d032d2",
        clientName: "joblinkshub",
      },
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return await result.data;
};

export const submitChangeEmail = async (token) => {
  const result = await Api.put(
    "/api/v1/account/change-email",
    {},
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return await result.data;
};

export const getToken = async (token) => {
  try {
    const payload = jwt(token);
    if (payload.exp * 1000 < Date.now()) {
      const storedRefreshToken = store.getState().auth.refreshToken;
      if (storedRefreshToken) {
        const result = await refreshToken(storedRefreshToken);
        store.dispatch({ type: AUTH_LOGIN_SUCCESS, payload: result });
        token = result.access_token;
      }
    }
  } catch (error) {
    token = null;
  }

  return token;
};

export const getEmailFromToken = (token) => {
  try {
    const payload = jwt(token);
    return payload.email;
  } catch (error) {
    console.log(error.message);
  }
  return null;
};

import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import MiniJobCard from "../../../../components/mini-job-card/MiniJobCard";
import Pagination from "../../../../components/pagination/Pagination";
import "./List.css";
import { getRecruiterJobs, translateField } from "../../../../services/Helper";
// import noDataAnimation from "../../../../assets/lootie-files/no-data.json";
import ErrorPage from "../../../../components/error-page/ErrorPage";
import Show from "../../../../components/Show";
import Hide from "../../../../components/Hide";
import { useTranslation } from "react-i18next";
import Loader from "../../../../components/loader/Loader";
import { useSelector } from "react-redux";

// const noDataAnimation = React.lazy(() => import("../../../../assets/lootie-files/no-data.json"));

const List = ({ data, active }) => {
  const matches = useMediaQuery('(max-width:700px)');

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [jobs, setJobs] = useState([]);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [page, setPage] = useState(1);
  const [jobsLoaded, setJobsLoaded] = useState(false);
  const activeText = active ? t("active_jobs") : t("inactive_jobs");

  const role = useSelector((state) => state.profile?.role?.name);
  const isCvCreated = useSelector((state) => state.profile?.data?.cv?.User?.is_cv_created);
  const isCompanyCreated = useSelector((state) => state.profile?.data?.recruiter?.is_company_created);

  const notCompleted = (isCvCreated == 0 || isCompanyCreated == 0)



  const [noDataAnimation, setNoDataAnimation] = useState(null);
  useEffect(() => {
    import("assets/lootie-files/no-data.json").then(data => setNoDataAnimation(data));
  }, [])



  const fetchPage = (pageNumber) => {
    getRecruiterJobs(pageNumber, active, 15, role, notCompleted).then((data) => {
      setJobs(data?.rows);
      setNumberOfPages(data?.meta?.totalNumberOfPages);
      setJobsLoaded(true);
    });
  };

  const changePage = (newPage) => {
    fetchPage(newPage);
    setPage(newPage);
  };

  useEffect(() => {
    setJobsLoaded(false);
    fetchPage(1);
    setPage(1);
  }, [active]);

  const fillData = () => {
    return jobs.map((value, index) => (
      <MiniJobCard
        selectable
        onClick={() => navigate(`/jobs/${value?.id}`)}
        noOfApplicants={value?.count}
        logo={value?.Recruiter?.image}
        date={value?.created_at}
        location={`${translateField(value?.Country, "name")}, ${translateField(value?.Governorate, "name")}, ${translateField(value?.City, "name")}`}
        jobTitle={translateField(value?.JobTitle, "name")}
        type={translateField(value?.ExperienceType, "name")}
        active={value?.status}
        url={`https://${window.location.host}/jobs/${value?.id}`}
        showActive
      />
    ));
  };
  return (
    <>
      <Grid className="recruiter-jobs-container" container spacing={2} justifyContent="center">
        <Loader data={jobsLoaded} width={"100%"} height={300} style={{ borderRadius: "1rem" }}>
          <Show condition={jobsLoaded && jobs.length > 0}>
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <Typography sx={{ color: "#919191" }} className="standard-text">{activeText}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Box className="all-jobs-container">
                  {fillData()}
                </Box>
              </Grid>
            </Grid>
            {numberOfPages > 1 && <Grid container item xs={12} sm={6} md={5}>
              <Pagination
                pagesManager={{
                  page: page,
                  setPage: changePage,
                  numberOfPages: numberOfPages,
                }}
              />
            </Grid>}
          </Show>
        </Loader>
        <Show condition={jobsLoaded && jobs.length == 0}>
          <Grid item xs={12} justifyContent="center">
            <ErrorPage
              hideAction
              animationData={noDataAnimation}
              title={t("oops")}
              description={t("no_jobs")}
            />
          </Grid>
        </Show>
      </Grid>
    </>
  );
};
export default List;

import "./Sidebar.scss";
import {
  Stack,
  useMediaQuery,
} from "@mui/material";
import SidebarElement from "./sidebar-element/SidebarElement";
import { useState } from "react";
import { sidebarElements } from "./sideBarElements";
import { useNavigate } from "react-router-dom";
import DeleteAccountModal from "../modals/delete-account-modal/DeleteAccountModal";

const Sidebar = ({ setContent }) => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState("account-settings");
  const [open, setOpen] = useState(false);
  const matched = useMediaQuery("(max-width:900px)");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };
  return (
    <>
    <Stack
      direction={"column"}
      sx={{
        height: "100%"
      }}
    >
      {sidebarElements.map((element, index) => {
        return (
          element.end ? <Stack
            direction={"row"}
            alignItems="flex-end"
            sx={{
              height: "100%",
            }}
          >
            <SidebarElement
              id={element.id}
              icon={<element.icon />}
              title={element.title}
              onClick={() => {
                if (!element.end) {
                  if (!matched) {
                    setSelected(element.id);
                    setContent(element.id);

                  } else {
                    navigate(`/${element.id}`);

                  }
                }
                if(element.end){
                  handleClickOpen()
                }
              }}
              active={element.id === selected && !matched}
              end={element.end}
            />
          </Stack> :
            <SidebarElement
              id={element.id}
              icon={<element.icon />}
              title={element.title}
              onClick={() => {
                if (!element.end) {
                  if (!matched) {
                    setSelected(element.id);
                    setContent(element.id);
                  } else {
                    navigate(`/${element.id}`);
                  }
                }
                if(element.end){
                  handleClickOpen()
                }
              }}
              active={element.id === selected && !matched}
              end={element.end}
            />
        );
      })}
    </Stack>
    <DeleteAccountModal  open={open} handleClose={handleClose}/>
    </>

  );
};
export default Sidebar;

import { Button } from "@mui/material";
import "./DecisionButton.scss";

const DecisionButton = (props) => {
  const { children, active, onClick, rounded, disabled, submit } = props;

  return (
    <Button
      disableElevation
      className="decision-button"
      onClick={onClick}
      type={submit ? "submit" : "button"}
      disabled={disabled}
      sx={{
        backgroundColor: active ? "#3A3B81" : "#9191911A",
        borderRadius: rounded ? "20px" : "8px",
        "&:hover": {
          backgroundColor: active ? "#3A3B81" : "#9191911A",
        },
      }}
    >
      {children}
    </Button>
  );
};
export default DecisionButton;

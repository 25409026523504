import { Box, Divider, Grid, useMediaQuery } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import AccountSettings from "../settings/components/content/account-settings/AccountSettings";
import "./AccountSettingsPage.scss";

const AccountSettingsPage = () => {
  const mdMatches = useMediaQuery("(min-width:701px) and (max-width:1300px)");
  const smMatches = useMediaQuery("(max-width:700px)");

  return (
    <>
      <Grid container className="settings-container" direction="row" >
        <AccountSettings />
      </Grid>
    </>
  );
};

export default AccountSettingsPage;

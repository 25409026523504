import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { Briefcase, Buildings, Plus, TextAlignLeft } from "phosphor-react";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { getStep } from "../../../services/Stepper";
import { nextStep } from "../../../state/actions/stepper/stepper";
import EditBox from "./education-experience/EditBox";
import InfoBox from "./education-experience/ExperienceInfoBox";
import SaveBox from "./education-experience/SaveBox";
import { stepperData } from "./stepper";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

// import { MobileDatePicker } from "@mui/lab";
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { useTranslation } from "react-i18next";
import IconInput from "../../../components/IconInput";
import JLSelect from "../../../components/JLSelect";
import {
  getJobCategory,
  getJobTitles,
  getSeniorityLevel,
  getWorkType,
  scrollIntoView,
  searchInArrayOfObjsById,
} from "../../../services/Helper";
import {
  addExperience,
  deleteExperience,
  goToNextStep,
  updateExperience,
} from "../../../state/actions/profile/profile";
import Scroller from "./../../../components/Scroller";

import { translateField } from "../../../services/Helper";
const Experience = () => {
  const { t, i18n } = useTranslation();

  const requiredMessage = t("required_input");
  const futureDateMessage = t("future_date_all");
  const lessThanToDate = t("less_than_to_date");
  const invalideDateMessage = t("invalid_date");
  const schema = yup
    .object({
      job_title: yup
        .string()
        .required(requiredMessage)
        .matches(
          /[a-zA-Z\u0600-\u06FF][\w\[\]`!@#$%\^&*()={}:;<>+'-]*/,
          t("contain_alphapet")
        )
        .min(3, t("min_input", { number: 3 }))
        .max(45, t("max_input", { number: 45 })),
      experience_type_id: yup.string().required(requiredMessage),
      job_category_id: yup.string().required(requiredMessage),
      description: yup
        .string()
        .max(500, t("max_input", { number: 500 }))
        .nullable(),
      company_name: yup
        .string()
        .required(requiredMessage)
        .min(3, t("min_input", { number: 3 }))
        .max(30, t("max_input", { number: 30 }))
        .matches(/^[1-9]*.*[a-zA-Z].*\w?/, t("only_alphapet")),
      career_level_id: yup.string().required(requiredMessage),
      from: yup.date().when("to", (to, schema) => {
        if (
          Object.prototype.toString.call(to) === "[object Date]" &&
          !isNaN(to.getTime())
        ) {
          let tToDate = new Date(to?.getTime());
          tToDate?.setDate(to?.getDate() - 1);
          return schema
            .nullable()
            .max(tToDate, lessThanToDate)
            .required(requiredMessage)
            .typeError(invalideDateMessage);
        } else {
          return schema
            .nullable()
            .max(new Date(), futureDateMessage)
            .required(requiredMessage)
            .typeError(invalideDateMessage);
        }
      }),
      to: yup.date().when("to_current", {
        is: false,
        then: yup
          .date()
          .nullable()
          .max(new Date(), futureDateMessage)
          .required()
          .typeError(invalideDateMessage),
        otherwise: yup.date().nullable().typeError(invalideDateMessage),
      }),
    })
    .required();
  const {
    control,
    register,
    reset,
    setValue,
    formState: { errors },
    setError,
    handleSubmit,
    getValues,
  } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      job_title: "",
      experience_type_id: "",
      job_category_id: "",
      company_name: "",
      career_level_id: "",
      description: "",
      from: null,
      to: null,
      to_current: false,
    },
  });
  const anchorRef = useRef(null);
  const onSubmit = async (data) => {
    setLoading(true);
    if (getValues("job_title"))
      if (isAdding) {
        await dispatch(addExperience(data)).finally(() => {
          setIsAdding(false);
          setLoading(false);
        });
      } else {
        if (currentExperience) {
          data.id = currentExperience?.id;
          await dispatch(updateExperience(data)).finally(() => {
            setIsEditing(false);
            setLoading(false);
          });
        }
        setIsEditing(false);
        setLoading(false);
      }
  };

  const [isAdding, setIsAdding] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [seniorityLevel, setSeniorityLevel] = useState(null);
  const [workType, setWorkType] = useState(null);
  const [companyIndustry, setCompanyIndustry] = useState(null);
  const [currentExperience, setCurrentExperience] = useState(null);
  const [showAddBtn, setShowAddBtn] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const stepFinished = useSelector(
    (state) => state.profile.data?.cv?.User?.step_finished
  );
  const currentStepNo = useSelector((state) => state.stepper.currentStep);
  const finalStepNo = useSelector((state) => state.stepper.finalStep);
  const experiences = useSelector(
    (state) => state.profile.data?.cv?.User?.CvExperiences
  );
  const current = getStep(stepperData, currentStepNo);

  const [toCurrent, setToCurrent] = useState(false);
  const [jobTitleChanged, setJobTitleChanged] = useState(false);
  //real-time-search
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState(searchTerm);
  const [jobTitles, setJobTitles] = useState([]);
  const [openJobTitlesMenu, setOpenJobTitlesMenu] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => setSearchTerm(debouncedTerm), 250);
    return () => clearTimeout(timer);
  }, [debouncedTerm]);
  useEffect(() => {
    if (searchTerm.length <= 45)
      if (searchTerm !== "") {
        handleJobTitleChange(searchTerm);
      } else {
        setDebouncedTerm("");
        setSearchTerm("");
      }
  }, [searchTerm]);

  useEffect(async () => {
    const sl = await getSeniorityLevel();
    const wt = await getWorkType();
    const ci = await getJobCategory();
    setSeniorityLevel(sl);
    setWorkType(wt);
    setCompanyIndustry(ci);
    return () => {
      setSeniorityLevel(null);
      setWorkType(null);
      setCompanyIndustry(null);
    };
  }, []);

  useEffect(() => {
    if (
      experiences == null ||
      experiences == undefined ||
      experiences?.length == 0
    ) {
      reset();
      setShowAddBtn(false);
      setIsAdding(true);
    } else {
      setShowAddBtn(true);
      setIsAdding(false);
    }
  }, [experiences?.length]);

  const handleJobTitleChange = (searchTerm) => {
    getJobTitles(searchTerm).then((res) => {
      setJobTitles(res);
      setOpenJobTitlesMenu(true);
    });
  };
  const handleBack = () => {
    if (current?.prev) {
      dispatch(
        nextStep({ currentStep: current?.prev, finalStep: finalStepNo })
      );
    }
  };
  const handleNext = async () => {
    setLoading(true);
    if (current.next) {
      if (current.no > stepFinished) {
        await dispatch(goToNextStep());
      }
      dispatch(nextStep({ currentStep: current.next, finalStep: finalStepNo }));
    }
    setLoading(false);
  };
  const handleAdd = () => {
    reset();
    setIsAdding(true);
    setIsEditing(false);
  };
  const handleOpenEdit = (id) => {
    const exp = searchInArrayOfObjsById(experiences, id);
    setCurrentExperience(exp);
    if (exp.job_title === null) {
      setValue("job_title_id", exp.job_title_id);
    }
    setValue(
      "job_title",
      exp.job_title ? exp.job_title : translateField(exp.JobTitle, "name")
    );
    setValue("experience_type_id", exp.experience_type_id);
    setValue("job_category_id", exp.job_category_id);

    setValue("company_name", exp.company_name);
    setValue("career_level_id", exp.career_level_id);
    setValue("description", exp.description);
    setValue(
      "from",
      exp?.from_year
        ? new Date(exp?.from_month + "/01/" + exp?.from_year)
        : null
    );
    setValue(
      "to",
      exp?.to_year ? new Date(exp?.to_month + "/01/" + exp?.to_year) : null
    );
    setValue("to_current", exp.to_current);
    setIsAdding(false);
    setIsEditing(true);
    setJobTitleChanged(false);
    scrollIntoView("experience");
  };
  const handleDelete = async () => {
    setLoading(true);
    if (currentExperience) {
      await dispatch(deleteExperience(currentExperience?.id)).finally(() => {
        setIsEditing(false);
        setLoading(false);
      });
    }
    setLoading(false);
  };
  const handleCloseEdit = () => {
    setIsAdding(false);
    setIsEditing(false);
  };
  const handleSearchResult = () => {
    if (jobTitles?.length > 1) {
      return jobTitles?.map((job) => {
        return (
          <MenuItem
            onClick={() => {
              setValue("job_title", translateField(job, "name"));
              setValue("job_title_id", job.id);
              setOpenJobTitlesMenu(false);
            }}
            key={job.id}
          >
            {translateField(job, "name")}
          </MenuItem>
        );
      });
    } else {
      if (
        !jobTitleChanged &&
        currentExperience &&
        currentExperience?.JobTitle
      ) {
        setValue("job_title_id", currentExperience?.JobTitle?.id);
      } else {
        setValue("job_title_id", null);
      }

      return <MenuItem>there is no result</MenuItem>;
    }
  };

  const renderForm = () => {
    return (
      <>
        <Grid item xs={12} md={6}>
          <IconInput
            label={t("job_title")}
            id="job_title"
            placeholder={t("enter_job_title")}
            type="text"
            icon={Briefcase}
            className="w100"
            fieldName="job_title"
            register={register}
            error={errors.job_title}
            onChange={(e) => {
              setDebouncedTerm(e.target.value);
              setJobTitleChanged(true);
            }}
          />
          <Box component="div" ref={anchorRef} sx={{}}></Box>
          <Menu
            open={openJobTitlesMenu}
            anchorEl={anchorRef.current}
            disableScrollLock={true}
            disableAutoFocus
            disableAutoFocusItem
            autoFocus={false}
            sx={{
              maxHeight: 200,
              marginTop: "4px",
            }}
          >
            <ClickAwayListener
              onClickAway={() => {
                setOpenJobTitlesMenu(false);
                setDebouncedTerm("");
                setSearchTerm("");
              }}
            >
              <MenuList
                sx={{
                  minWidth: anchorRef?.current?.offsetWidth - 10,
                  paddingTop: 0,
                  backgroundColor: "none",
                }}
              >
                {handleSearchResult()}
              </MenuList>
            </ClickAwayListener>
          </Menu>
        </Grid>
        <Grid item xs={12} md={6}>
          <IconInput
            label={t("company")}
            id="company"
            placeholder={t("enter_company")}
            type="text"
            icon={Buildings}
            className="w100"
            fieldName="company_name"
            register={register}
            error={errors.company_name}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <JLSelect
            label={t("seneority_level_label")}
            selectLabel={t("seneority_level")}
            control={control}
            childs={seniorityLevel}
            fieldName="career_level_id"
            error={errors.career_level_id}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <JLSelect
            label={t("work_type_label")}
            selectLabel={t("work_type")}
            control={control}
            childs={workType}
            fieldName="experience_type_id"
            error={errors.experience_type_id}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <JLSelect
            label={t("industry")}
            selectLabel={t("industry")}
            control={control}
            childs={companyIndustry}
            fieldName="job_category_id"
            error={errors.job_category_id}
          />
        </Grid>
        <Grid item xs={12} md={6}></Grid>
        <Grid item xs={12} md={6}>
          <Grid item className="datepicker-wrapper mb16">
            <Typography className="label" component="span">
              {t("from_date")}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack className="datepicker">
                <Controller
                  defaultValue={null}
                  control={control}
                  name="from"
                  render={({ field }) => (
                    <MobileDatePicker
                      disableCloseOnSelect={false}
                      views={["year", "month"]}
                      value={field.value}
                      InputAdornmentProps={{
                        position: "start",
                      }}
                      InputProps={{
                        error: errors.from,
                      }}
                      onChange={(date) => field.onChange(date)}
                      renderInput={(params) => {
                        params.inputProps.placeholder = `${t("from_date")}`;
                        return (
                          <TextField
                            className="input"
                            {...params}
                            sx={{
                              backgroundColor: "white",
                              borderRadius: "0.5rem",
                            }}
                          />
                        );
                      }}
                    />
                  )}
                />
              </Stack>
              {errors.from && (
                <FormHelperText style={{ color: "red" }}>
                  {errors.from.message}
                </FormHelperText>
              )}
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid item className="datepicker-wrapper mb16">
            <Typography className="label" component="span">
              {t("to_date")}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack className="datepicker">
                <Controller
                  defaultValue={null}
                  control={control}
                  name="to"
                  render={({ field }) => {
                    return (
                      <MobileDatePicker
                        disableCloseOnSelect={false}
                        views={["year", "month"]}
                        disabled={toCurrent}
                        value={field.value}
                        InputAdornmentProps={{
                          position: "start",
                        }}
                        InputProps={{
                          error: errors.to,
                        }}
                        onChange={(date) => field.onChange(date)}
                        renderInput={(params) => {
                          params.inputProps.placeholder = `${t("to_date")}`;
                          return (
                            <TextField
                              className={
                                getValues("to_current")
                                  ? "input bg-gray"
                                  : "input"
                              }
                              sx={{
                                backgroundColor: "white",
                                borderRadius: "0.5rem",
                              }}
                              {...params}
                            />
                          );
                        }}
                      />
                    );
                  }}
                />
              </Stack>
              {errors.to && (
                <FormHelperText style={{ color: "red" }}>
                  {errors.to.message}
                </FormHelperText>
              )}
            </LocalizationProvider>
          </Grid>
          <FormGroup className="checkbox">
            <FormControlLabel
              label={t("currently_work_checkbox")}
              control={
                <Controller
                  name="to_current"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      checked={getValues("to_current")}
                      value={field.value}
                      onChange={(e) => {
                        console.log("e.target.checked", e.target.checked);
                        // field.onChange(e.target.checked);
                        setToCurrent(e.target.checked);
                        setValue("to_current", e.target.checked);
                        setValue("to", null);
                      }}
                    />
                  )}
                />
              }
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <IconInput
            label={t("experience_desc")}
            placeholder={t("bio_placeholder")}
            icon={TextAlignLeft}
            type="textarea"
            className="w100"
            multiline
            fieldName="description"
            register={register}
            error={errors.description}
          />
        </Grid>
      </>
    );
  };
  return (
    <Grid container direction="column">
      <Grid item container className="step-content applicant-experience">
        <Grid item>
          <Typography className="title">{t("experience_title")}</Typography>
          <Scroller id="experience" />
          <Typography className="text">{t("experience_text")}</Typography>
        </Grid>
        {isAdding && (
          <SaveBox
            title={t("experience_save_title")}
            handleSubmit={handleSubmit(onSubmit)}
            loading={loading}
          >
            {renderForm()}
          </SaveBox>
        )}
        {isEditing && (
          <EditBox
            title={t("experience_edit_title")}
            handleSubmit={handleSubmit(onSubmit)}
            handleCloseEdit={handleCloseEdit}
            handleDelete={handleDelete}
            loading={loading}
          >
            {renderForm()}
          </EditBox>
        )}

        {experiences?.map((exp) => (
          <InfoBox data={exp} handleEdit={() => handleOpenEdit(exp.id)} />
        ))}
        <Grid container item>
          {showAddBtn && (
            <Button
              className="add-btn"
              startIcon={<Plus />}
              fullWidth
              onClick={handleAdd}
            >
              {t("add_btn_experience")}
            </Button>
          )}
        </Grid>
        <Grid item container justifyContent="end">
          {current?.prev && (
            <button className="back-btn" onClick={handleBack}>
              {t("back_btn")}
            </button>
          )}
          <button
            className="next-btn"
            onClick={handleNext}
            disabled={loading}
            style={!loading ? { cursor: "pointer" } : {}}
          >
            {t("next_btn")}
          </button>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Experience;

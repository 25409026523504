import profileImage from "../assets/profile.png";
import { store } from "../state/store";
import { translateField } from "./Helper";
export const getCurrentUser = (pRole) => {
    const currentRole = pRole;
    let user = {};
    let avatar, name, first_name, second_name, title;

    const applicant = store.getState().profile.data?.cv?.User;
    const recruiter = store.getState().profile.data?.recruiter;

    switch (currentRole) {
        case "APPLICANT":
            avatar = applicant?.image;
            first_name = applicant?.first_name;
            second_name = applicant?.second_name;
            title = applicant?.job_title;
            if (!title) {
                title = translateField(applicant?.JobTitle, "name");;
            }
            user.avatar = avatar ? avatar : profileImage;
            user.name = first_name + " " + second_name;
            user.title = title;
            break;
        case "RECRUITER":
            avatar = recruiter?.image;
            name = recruiter?.company_name;
            title = translateField(recruiter?.CompanyIndustry, "name");
            user.avatar = avatar ? avatar : profileImage;
            user.name = name;
            user.title = title;
            break;
        default:
            break;
    }
    return user;
}
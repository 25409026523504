import { Box, Grid, useMediaQuery } from "@mui/material";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FeaturedSection from "../../../../../components/featured-section/FeaturedSection";
import Loader from "../../../../../components/loader/Loader";
import { getJobsByType, translateField } from "../../../../../services/Helper";
import EnhancedMiniJobCard from "../../../abdelrahman/EnhancedMiniJobCard/EnhancedMiniJobCard";
import "./RecentlyAddedJobsSection.css";
import { useSelector } from "react-redux";

const RecentlyAddedJobsSection = () => {
  const mdMatches = useMediaQuery("(min-width:701px) and (max-width:1000px)");
  const smMatches = useMediaQuery("(max-width:700px)");
  const [recentlyAddedJobs, setRecentlyAddedJobs] = useState([]);
  const [jobsLoaded, setJobsLoaded] = useState(false);
  const navigate = useNavigate();

  const role = useSelector((state) => state.profile.role?.name);
  const token = useSelector((state) => state.auth?.token);

  useEffect(() => {
    console.log("RECENTLY ADDED SECTION", role);
    
    getJobsByType("RecentlyAdded", 3, "", (role === "GUEST_USER" && token) || (role === "RECRUITER")).then((response) => {
      setRecentlyAddedJobs(response.rows);
      setJobsLoaded(true);
    });
  }, []);

  return (
    <Box
      component="section"
      className="recently-added-jobs-section"
      // padding={!smMatches ? "24px" : "0px"}
      sx={{ width: "100%" }}
    >
      <FeaturedSection title={t("recently_added")} url={"/browsing-jobs?type=RecentlyAdded"}>
        <Grid container spacing={0} marginTop={1}>
          <Loader
            data={jobsLoaded}
            width={"100%"}
            height={260}
            style={{ borderRadius: "1rem" }}
          >
            <Grid container spacing={3}>
              {recentlyAddedJobs?.map((job) => (
                <Grid item xs={12} md={6} lg={4} xl={4}>
                  <EnhancedMiniJobCard
                    jobId={job?.id}
                    title={translateField(job?.JobTitle, "name")}
                    logo={job?.Recruiter?.image}
                    company={job?.Recruiter?.company_name}
                    date={job?.created_at}
                    noOfApplicants={job?.count}
                    tags={[
                      translateField(job?.WorkStyle, "name"),
                      translateField(job?.ExperienceType, "name"),
                    ]}
                    location={`${translateField(
                      job?.City,
                      "name"
                    )}, ${translateField(job?.Country, "name")}`}
                    onClick={() => navigate(`/jobs/${job?.id}`)}
                    isSaved={job?.is_saved}
                    isApplied={job?.is_applied}
                  />
                </Grid>
              ))}
            </Grid>
          </Loader>
        </Grid>
      </FeaturedSection>
    </Box>
  );
};

export default RecentlyAddedJobsSection;

import { IconButton } from "@mui/material";
import "./EnhancedIconButton.scss";

const EnhancedIconButton = ({ icon, active, onClick, variant }) => {
  return (
    <IconButton onClick={onClick} className={`enhanced-icon-button${active ? " active" : ""} ${variant == "gray" && "gray"}`}>
      {icon}
    </IconButton>
  );
};
export default EnhancedIconButton;

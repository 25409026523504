const Permissions = {
    createCV: 'create-cv',
    createCompany: 'create-company',
    chooseJoinReason: 'choose-join-reason',
    viewHome: 'view-home',
    viewAllJobs: 'view-all-jobs',
    viewGuestBanner: 'view-guest-banner',
    viewUserBanner: 'view-user-banner',
    viewFeaturedJobs: 'view-featured-jobs',
    viewInterestsJobs: 'view-interests-jobs',
    viewFeaturedCompanies: 'view-featured-companies',
    viewRecentlyAddedJobs: 'view-recently-added-jobs',
    viewFromFollowedJobs: 'view-from-followed-jobs',
    viewJob: 'view-job',
    editJob: 'edit-job',
    createJob: 'create-job',
    deleteJob: 'delete-job',
    viewJobApplicants: 'view-job-applicants',
    canApplyJob: 'can-apply-job',
    canSaveJob: 'can-save-job',
    canBrowsingJobs: 'can-browsing-jobs',
    viewNotifications: 'view-notifications',
    canSwitchRole: 'can-switch-role',
    viewProfile: 'view-profile',
    viewApplicantProfile: 'view-applicant-profile',
    editApplicantProfile: 'edit-applicant-profile',
    viewCompanyProfile: 'view-company-profile',
    editCompanyProfile: 'edit-company-profile',
    viewFollowedCompanies: 'view-followed-companies',
    viewcandidatesBank: 'view-candidates-bank',
    viewFollowers: 'view-followers',
    viewSettings: 'view-settings',
    viewStatusInJobDetails: 'view-status-in-job-details'
}

export const INITAL_ROLE = {
    name: "GUEST_USER",
    permissions: [
        Permissions.viewHome,
        Permissions.viewGuestBanner,
        Permissions.viewFeaturedJobs,
        Permissions.viewFeaturedCompanies,
        Permissions.viewRecentlyAddedJobs,
        Permissions.viewJob,
        Permissions.canBrowsingJobs
    ]
}

export const APPLICANT_ROLE = {
    name: 'APPLICANT',
    permissions: [
        'create-cv',
        'view-home',
        'view-user-banner',
        'view-interests-jobs',
        'view-featured-companies',
        'view-recently-added-jobs',
        'view-from-followed-jobs',
        'view-job',
        'can-apply-job',
        'can-save-job',
        'can-browsing-jobs',
        'view-notifications',
        'can-switch-role',
        'view-profile',
        'view-applicant-profile',
        'edit-applicant-profile',
        'view-followed-companies',
        'view-settings',
        'view-status-in-job-details',
        'create-company'
    ],
}

export const RECRUITER_ROLE = {
    name: 'RECRUITER',
    permissions: [
        'create-company',
        'view-home',
        'view-all-jobs',
        'view-job',
        'edit-job',
        'create-job',
        'delete-job',
        'view-job-applicants',
        'view-notifications',
        'can-switch-role',
        'view-profile',
        'view-company-profile',
        'edit-company-profile',
        'view-candidates-bank',
        'view-followers',
        'view-settings',
        'create-cv'
    ]
}

export default Permissions;
import React from "react";
import { Avatar, Grid, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import "./RecruiterLandingPage.css";
import RecruiterHeroSection from "./components/recruiter-hero-section/RecruiterHeroSection";
import RecruiterJobs from "./components/recruiter-jobs/RecruiterJobs";
const RecruiterLandingPage = () => {
  const mdMatches = useMediaQuery("(min-width:701px) and (max-width:1300px)");
  const smMatches = useMediaQuery("(max-width:700px)");

  return (
    <Box >
      <RecruiterHeroSection />
      <RecruiterJobs />
    </Box>
  );
};

export default RecruiterLandingPage;

import { Dialog, Grid, InputBase, Paper, Typography } from '@mui/material';
import Fuse from 'fuse.js';
import { t } from 'i18next';
import { Files } from 'phosphor-react';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import EnhancedButton from '../../../views/pages/yahya/components/enhanced-button/EnhancedButton';
import { addToCvBank } from './../../../services/Helper';
import Category from './Category';
import useAddToCvBank from './useAddToCvBank';

const AddToCvBankDialog = ({ open, setOpen, applicant_id, cvBank }) => {
  const { data, firstCategoryCreation, handleSubmit, control, setValue, onSubmit, closeTrigger } = useAddToCvBank({ applicant_id });

  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState(data);
  const [searchTerm, setSearchTerm] = useState('');

  const handleClose = () => {
    setOpen(false);
  };
  const handleAddToCategories = () => {
    addToCvBank(applicant_id, categories).then((res) => {
      handleClose();
    });
  };
  const handleSearch = () => {
    if (searchTerm?.trim === '' || searchTerm === '' || searchTerm == undefined || searchTerm == null) {
      setFilteredCategories(data);
    } else {
      const options = {
        includeScore: true,
        keys: ['name'],
      };
      const fuse = new Fuse(data, options);
      const result = fuse.search(searchTerm);

      const temp = result.map((one) => one.item);
      setFilteredCategories(temp);
    }
  };
  useEffect(() => {
    if (closeTrigger) handleClose();
  }, [closeTrigger]);
  useEffect(() => {
    setFilteredCategories(data);
  }, [data]);
  const renderNoCategories = () => {
    return (
      <>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container rowSpacing={3}>
            <Grid item xs={12} container direction={'row'} columnSpacing={1} alignItems={'flex-start'}>
              <Grid item>
                <Files size={24} weight={'fill'} color={'#3A3B81'} />
              </Grid>
              <Grid item xs={11}>
                <Typography sx={{ font: 'normal normal bold 20px/24px Urbanist' }}>{t('add_to_candidates_bank')}</Typography>
                <Typography
                  sx={{
                    font: 'normal normal medium 16px/19px Urbanist',
                    color: '#838383',
                  }}
                >
                  {t('no_categories_modal')}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="category_name"
                control={control}
                render={({ field: { onChange, onBlur, value, name, ref } }) => {
                  return (
                    <InputBase
                      fullWidth
                      onChange={onChange}
                      value={value}
                      placeholder={t('enter_category_name')}
                      sx={{
                        backgroundColor: '#9191911A',
                        color: 'black',
                        borderRadius: '4px',
                        padding: '8px',
                      }}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} container columnSpacing={2} justifyContent={'flex-end'}>
              <Grid item>
                {' '}
                <EnhancedButton buttonText={t('cancel')} variant={'outlined'} padding={'8.5px 20px'} onClick={handleClose} />{' '}
              </Grid>
              <Grid item>
                {' '}
                <EnhancedButton buttonText={t('create_category_and_add')} padding={'10.5px 12px'} type="submit" />{' '}
              </Grid>
            </Grid>
          </Grid>
        </form>
      </>
    );
  };
  const renderCategories = () => {
    return (
      <Grid container rowSpacing={1}>
        <Grid item xs={12} container direction={'row'} columnSpacing={1} alignItems={'flex-start'}>
          <Grid item>
            <Files size={24} weight={'fill'} color={'#3A3B81'} />
          </Grid>
          <Grid item xs={11}>
            <Typography sx={{ font: 'normal normal bold 20px/24px Urbanist' }}>{cvBank.isAdded ? t('edit-cv-bank') : t('add_to_cv_bank')}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} container columnSpacing={2}>
          <Grid item xs={10}>
            <InputBase
              placeholder={t('search')}
              sx={{
                border: ' 1px solid #E4E4E4',
                borderRadius: '8px',
                height: '40px',
                paddingX: '10px',
                width: '100%',
              }}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Grid>
          <Grid item xs={2}>
            <EnhancedButton buttonText={t('search')} padding={'11px 24px'} onClick={handleSearch} />
          </Grid>
        </Grid>
        {filteredCategories?.map((category) => {
          return (
            <Grid item xs={12}>
              <Category
                name={category.name}
                categories={categories}
                category_id={category.id}
                applicant_id={applicant_id}
                added={categories?.includes(category.id) ? true : false}
                setCategories={setCategories}
              />
            </Grid>
          );
        })}
        <Grid item xs={12} container columnSpacing={2} justifyContent={'flex-end'}>
          <Grid item>
            {' '}
            <EnhancedButton buttonText={t('cancel')} variant={'outlined'} padding={'8.5px 20px'} onClick={handleClose} />{' '}
          </Grid>
          <Grid item>
            {' '}
            <EnhancedButton buttonText={t('done')} padding={'10.5px 12px'} onClick={handleAddToCategories} />{' '}
          </Grid>
        </Grid>
      </Grid>
    );
  };
  useEffect(() => {
    console.log(categories);
  }, [categories]);
  useEffect(() => {
    if (cvBank?.cvBankCategories?.length > 0) setCategories(cvBank?.cvBankCategories?.map((category) => category?.CvBankCategory?.id));
    console.log({ categories: cvBank?.cvBankCategories });
  }, []);
  return (
    <Dialog open={open}>
      <Paper sx={{ padding: '24px', minWidth: '100%', overflow: 'hidden' }}>{data?.length < 1 ? renderNoCategories() : renderCategories()}</Paper>
    </Dialog>
  );
};

export default AddToCvBankDialog;

import { Divider, Grid, Link, Stack, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { t } from 'i18next';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getDocumentDirection } from './../../../../../services/Helper';
import Apple from "./Apple.svg";
import "./DownloadTheAppSection.css";
import Google from "./Google.svg";
import Huawei from "./Huawei.svg";
// const PhoneLeft = React.lazy(() => import("./PhoneLeft.svg"));
// const PhoneRight = React.lazy(() => import("./PhoneRight.svg"));

const DownloadTheAppSection = () => {
    const [size, setSize] = useState([0, 0]);
    const useWindowSize = () => {
        useLayoutEffect(() => {
            function updateSize() {
                setSize([window.innerWidth, window.innerHeight]);
            }
            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
    }
    const documentDirection = getDocumentDirection()
    console.log(useWindowSize())
    const mdMatches = useMediaQuery('(min-width:701px) and (max-width:1000px)');
    const smMatches = useMediaQuery('(max-width:700px)');
    const md2Matches = useMediaQuery('(max-width:1000px)');
    const md3Matches = useMediaQuery('(max-width:1200px)');

    const mode = useSelector((state) => state.mode.name);

    const googleStoreLink = mode === "guestApplicant" ? "https://play.google.com/store/apps/details?id=com.joblink.app" : ""
    const appStoreLink = mode === "guestApplicant" ? "https://apps.apple.com/us/app/job-links/id1604374545" : "https://apps.apple.com/us/app/job-links-recruiter/id1640982525"
    const appGalleryLink = mode === "guestApplicant" ? "https://appgallery.cloud.huawei.com/ag/n/app/C106194891?" : ""

    const [PhoneLeft, setPhoneLeft] = useState(null);
    useEffect(() => {
        import("./PhoneLeft.svg").then(data => { setPhoneLeft(data.default); });

    }, [])

    const [PhoneRight, setPhoneRight] = useState(null);
    useEffect(() => {
        import("./PhoneRight.svg").then(data => setPhoneRight(data.default));
    }, [])

    return (
        <Box id="download-app" component={"section"} className="section-download-the-app" padding={smMatches ? "0px" : "0px"}>
            <Grid container alignItems={"center"} justifyContent={"center"} sx={{ minHeight: "486px" }}>
                {!md3Matches &&
                    <Grid className={"img-container"} item xs={6} md={6} lg={3.5} sx={{ order: md3Matches && 2, maxHeight: "fit-content" }}>
                        <img className="phone-left" src={documentDirection == "right" ? PhoneRight : PhoneLeft} height={"486px"} />
                    </Grid>
                }
                <Grid item xs={12} md={12} lg={5} sx={{ order: md3Matches && 1, justifyContent: "center", zIndex: 2 }}>
                    <Stack
                        direction={"column"}
                        sx={{ zIndex: "12", height: "100%" }}
                        spacing={2}
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        <Box>
                            <Typography className="title">{t("download_app")}</Typography>
                        </Box>
                        <Box>
                            <Typography className="content" sx={{ whiteSpace: "pre-line", textAlign: "center" }}>
                                {t("download_app_desc")}
                            </Typography></Box>
                        <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}>
                            <Divider orientation={"vertical"} sx={{ height: "70px", width: "1px", border: "1px solid #EEEEEE" }} flexItem />
                        </Stack>
                        <Grid
                            item
                            container
                            justifyContent={{
                                lg: "space-evenly",
                                md: "space-evenly",
                                sm: "space-evenly",
                                xs: "space-evenly",
                            }}
                            spacing={2}
                            className="stores-wrap"
                            sx={{ width: "100%" }}
                        >
                            <Grid item xs={6} sm={4} md={4} lg={4.5}>
                                <Link href={googleStoreLink}>
                                    <img src={Google} height={"46px"} />
                                </Link>
                            </Grid>
                            <Grid item xs={6} sm={4} md={4} lg={4.5}>
                                <Link href={appStoreLink}>
                                    <img src={Apple} height={"46px"} />
                                </Link>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={4.5}>
                                <Link href={appGalleryLink}>
                                    <img src={Huawei} height={"46px"} />
                                </Link>
                            </Grid>
                        </Grid>
                    </Stack>
                </Grid>
                {!md3Matches &&
                    <Grid className={"img-container"} item xs={6} md={6} lg={3.5} sx={{ order: md3Matches && 3, maxHeight: "fit-content" }}>
                        <img className="phone-right" src={documentDirection == "right" ? PhoneLeft : PhoneRight} height={"486px"} />
                    </Grid>
                }
            </Grid>
        </Box>
    );
};

export default DownloadTheAppSection;
import { Box, Grid, IconButton } from "@mui/material";
import { PencilSimple } from "phosphor-react";
import { useTranslation } from "react-i18next";
import { LOCALES_MONTHS, MONTHS } from "../../../../constants/constants";
import { getMonthName, translateField } from "../../../../services/Helper";

const InfoBox = (props) => {
    const data = props?.data;
    const { t, i18n } = useTranslation();




    let to_monthToRender = ""


    let from_monthToRender = ""
    const renderFromDate = () => {
        LOCALES_MONTHS.map((month) => {
            if (month.name_en === getMonthName(data?.from_month))
                from_monthToRender = translateField(month, "name")
            if (month.name_en === getMonthName(data?.to_month))
                to_monthToRender = translateField(month, "name")
        })
        let fromDate = '';
        if (data.from_month && data.from_year) {
            fromDate = from_monthToRender + " " + data?.from_year + " - ";
        } else {
            fromDate = i18n.language === "ar" ? "الي" : "To"
        }

        return fromDate;
    }
    return (
        <Grid container item className="grey-box">
            <Grid item xs={12}>
                <Grid item container justifyContent="space-between" alignItems="center">
                    <Box component="div" className="date">
                        {renderFromDate()} {to_monthToRender} {data?.to_year}
                    </Box>
                    <IconButton size="small" onClick={props.handleEdit} className="pencil">
                        <PencilSimple />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Box component="span" className="info">
                    {translateField(data.EducationDegree, "name")}{"  "}{t("in")}{"  "}{data?.study_field}, {data?.university_name}
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box component="span" className="grade">
                    {translateField(data.Grade, "name")}
                </Box>
            </Grid>
        </Grid>
    )
}
export default InfoBox;
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { t } from 'i18next'
import { CaretDown } from 'phosphor-react'
import React from 'react'

const OneQuestion = ({ expanded, handleChange, panel, questionSummary, questionDetails }) => {
    return (
        <Accordion
            expanded={expanded === panel}
            onChange={handleChange(panel)}
            sx={{
                backgroundColor: "transparent",
                border: "solid 1px rgba(145, 145, 145, 0.1)",
                boxShadow: "none",
            }}
        >
            <AccordionSummary
                className="accordion-expand"
                expandIcon={<CaretDown />}
            >
                <Typography className="faq-summary">{questionSummary}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography className="faq-subtitle">
                    {t("faq-answer")}:
                </Typography>
                <Typography className='faq-answer'>
                    {questionDetails}
                </Typography>
            </AccordionDetails>
        </Accordion>
    )
}

export default OneQuestion
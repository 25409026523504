import { Box, Grid, IconButton, Typography, useMediaQuery } from '@mui/material';
import { Binoculars, BookmarkSimple, ClipboardText } from 'phosphor-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { matchRoutes, useNavigate } from 'react-router-dom';

const ApplicantNavigationButtons = () => {
    const isMobile = useMediaQuery("(max-width:1200px)");
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const applicantContentPaths = {
        exploreJobs: {
            patterns: [
                { path: "/" },
            ]
        },
        appliedJobs: {
            patterns: [
                { path: "applied-jobs" }
            ]
        },
        savedJobs: {
            patterns: [
                { path: "saved-jobs" }
            ]
        }

    };



    const checkCurrentPath = (patterns) => {
        const exist = matchRoutes(patterns, location)

        return exist ? true : false;
    }

    return (
        <>
            <IconButton
                className={["nav-button", checkCurrentPath(applicantContentPaths.exploreJobs.patterns) ? "active" : ""].join(" ")}
                onClick={() => navigate("/")}
            >
                <Grid
                    container
                    className={[isMobile ? "navigation-button" : ""].join(" ")}
                    justifyContent={"center"}
                    alignItems={"center"}>
                    <Grid item xs={12} md={"auto"}>
                        <Box className={[!isMobile ? "icon" : ""].join(" ")}>
                            <Binoculars weight="fill" />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={"auto"}>
                        <Box width={"8px"}></Box>
                    </Grid>
                    <Grid item xs={12} md={"auto"}>
                        <Typography>{t("explore-jobs")}</Typography>
                    </Grid>
                </Grid>
            </IconButton>
            <IconButton
                className={["nav-button", checkCurrentPath(applicantContentPaths.appliedJobs.patterns) ? "active" : ""].join(" ")}
                onClick={() => navigate("/applied-jobs")}
            >
                <Grid
                    container
                    className={[isMobile ? "navigation-button" : ""].join(" ")}
                    justifyContent={"center"}
                    alignItems={"center"}>
                    <Grid item xs={12} md={"auto"}>
                        <Box className={[!isMobile ? "icon" : ""].join(" ")}>
                            <ClipboardText weight="fill" />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={"auto"}>
                        <Box width={"8px"}></Box>
                    </Grid>
                    <Grid item xs={12} md={"auto"}>
                        <Typography>{t("applied_jobs")}</Typography>
                    </Grid>
                </Grid>
            </IconButton>
            <IconButton
                className={["nav-button", checkCurrentPath(applicantContentPaths.savedJobs.patterns) ? "active" : ""].join(" ")}
                onClick={() => navigate("/saved-jobs")}
            >
                <Grid
                    container
                    className={[isMobile ? "navigation-button" : ""].join(" ")}
                    justifyContent={"center"}
                    alignItems={"center"}>
                    <Grid item xs={12} md={"auto"}>
                        <Box className={[!isMobile ? "icon" : ""].join(" ")}>
                            <BookmarkSimple weight="fill" />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={"auto"}>
                        <Box width={"8px"}></Box>
                    </Grid>
                    <Grid item xs={12} md={"auto"}>
                        <Typography>{t("saved_jobs")}</Typography>
                    </Grid>
                </Grid>
            </IconButton>
        </>
    );
};

export default ApplicantNavigationButtons;
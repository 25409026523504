import { useState } from 'react'

const useShareJobPost = () => {

    const [open, setOpen] = useState(false)

    return (
        {
            open,
            setOpen,
            
        }
    )
}

export default useShareJobPost

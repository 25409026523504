import { Grid } from "@mui/material";
import AuthPageWrapper from "components/auth-page-wrapper/AuthPageWrapper";
import VerifyOTP from "components/VerifyOTP";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useNavigate, useSearchParams } from "react-router-dom";
import { submitOTP } from "services/AuthHelper";
import { authSubmitOTP } from "state/actions/auth/auth";

const ChangeEmailVerification = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const onSubmit = async (data) => {
    const postData = {
      email: searchParams.get("email"),
      otp: data.otp,
      type: "CHANGE_EMAIL",
    };
    try {
      dispatch(
        authSubmitOTP(
          navigate,
          postData.email,
          postData.otp,
          postData.type,
          "/change-email"
        )
      );
    } catch (error) {
      toastr.error(error.response?.data?.error?.message);
      // navigate("/login");
    }
  };

  useEffect(() => {
    if (!searchParams.get("email")) navigate("/login");
  }, []);
  return (
    <AuthPageWrapper single={true}>
      <Grid
        className="signin-form"
        container
        item
        xs={12}
        justifyContent="center"
        alignItems="center"
      >
        <VerifyOTP title={"Enter OTP to change email"} onSubmit={onSubmit} />
      </Grid>
    </AuthPageWrapper>
  );
};

export default ChangeEmailVerification;

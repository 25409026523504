import React from 'react';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import './SeeAllLinks.scss'

const SeeAllLinks = (props) => {
  const { title, bolded, job_id } = props;
  return (
    <Box className='see-all-links'>
      <Link className={bolded ? 'link bold' : 'link'} to={`/jobs/${job_id}/applicants`} >{title}</Link>
    </Box >
  )
}

export default SeeAllLinks
import {
  RESET_PROCESS,
  START_PROCESS,
  STOP_PROCESS,
} from "state/actions/process/types";

let initialState = {
  login: {
    started: false,
    finished: false,
  },
};

export const processReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case START_PROCESS:
      return {
        ...state,
        [payload.process]: {
          ...state[payload.process],
          started: true,
          finished: false,
        },
      };
    case STOP_PROCESS:
      return {
        ...state,
        [payload.process]: {
          ...state[payload.process],
          started: true,
          finished: true,
        },
      };
    case RESET_PROCESS:
      return {
        ...state,
        [payload.process]: {
          ...state[payload.process],
          started: false,
          finished: false,
        },
      };
    default:
      return state;
  }
};

export default processReducer;

import axios from "axios";
import { hideLoading, showLoading } from "react-redux-loading-bar";
import { toastr } from "react-redux-toastr";
import { authLogout, authRefreshToken } from "state/actions/auth/auth";
import { STATUS_CODES } from "../constants/constants";
import i18n from "../i18n";
import { finishLoading, startLoading } from "../state/actions/loading/loading";
import { OPEN_LOGIN_MODAL } from "../state/actions/login-modal/types";
import { deleteProfile } from "../state/actions/profile/profile";
import { store } from "../state/store";
import { acquireToken, logout } from "./auth/Auth";
import { getToken } from "./AuthHelper";

const { dispatch, getState } = store;

export const Api = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.REACT_APP_BACKEND_URL,
  // headers: { type: getState()?.profile?.role?.name }
});

Api.defaults.params = {};
Api.interceptors.request.use(
  async (config) => {
    if (config.urlFlag) {
      config.baseURL = "https://dry-plains-26650.herokuapp.com";
    }

    // dispatch(startLoading());
    let token = await getToken(getState().auth?.token);
    if (token) config.headers["Authorization"] = "Bearer " + token;
    if (config.params["openPopup"] == true) {
      store.dispatch({ type: OPEN_LOGIN_MODAL });
      throw new axios.Cancel("Request need to be canceled");
    }
    if (config.params["stopRequest"] == true) {
      throw new axios.Cancel("Request need to be canceled");
    }

    config.params["lang"] = i18n.language;
    dispatch(showLoading());
    return config;
  },
  (error) => {
    // dispatch(finishLoading());
    dispatch(hideLoading());
    toastr.error(error);
    return Promise.reject(error);
  }
);

Api.interceptors.response.use(
  (response) => {
    // dispatch(finishLoading());
    dispatch(hideLoading());
    const statusCode = response.data.statusCode;
    switch (statusCode) {
      case STATUS_CODES.SUCCESS:
        if (response.data.message) toastr.success(response.data.message);
        break;
      case STATUS_CODES.NOT_FOUND:
        window.location = "/404"
        break;
      default:
        break;
    }
    return response?.data?.data;
  },
  (error) => {
    // dispatch(finishLoading());
    dispatch(hideLoading());
    const statusCode = error?.response?.data?.error?.status;
    const message = error?.response?.data?.error?.message;
    switch (statusCode) {
      case STATUS_CODES.UNAUTHORIZED:
        store.dispatch(deleteProfile());
        store.dispatch(authLogout());
        break;
      case STATUS_CODES.NOT_FOUND:
        if (message && message !== "null") toastr.error(message);
        window.location = "/404";
        break;
      case STATUS_CODES.BAD_REQUEST:
        if (message && message !== "null") toastr.error(message);
        break;
      case STATUS_CODES.BLOCKED:
        if (message && message !== "null") toastr.error(message);
        // store.dispatch(deleteProfile());
        // store.dispatch(authLogout());
        break;
      default:
        if (message && message !== "null") toastr.error(message);
        break;
    }
    return Promise.reject(error);
  }
);

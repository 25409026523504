import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormHelperText,
  Grid,
  Rating,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Flag, Hash, Plus, X } from "phosphor-react";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import IconInput from "../../../components/IconInput";
import JLSelect from "../../../components/JLSelect";
import { RTLProvider } from "../../../components/RTLProvider";
import Show from "../../../components/Show";
import { getYearsOfExperience } from "../../../services/Helper";
import { getStep } from "../../../services/Stepper";
import {
  addSkill,
  deleteSkill,
  editSkill,
  goToNextStep,
} from "../../../state/actions/profile/profile";
import { nextStep } from "../../../state/actions/stepper/stepper";
import Hide from "./../../../components/Hide";
import EditBox from "./skills/EditBox";
import SaveBox from "./skills/SaveBox";
import { stepperData } from "./stepper";

const Skills = ({ lastStep, handleSubmit }) => {
  const { t, i18n } = useTranslation();
  const theme = createTheme({
    direction: i18n.language == "ar" ? "rtl" : "ltr", // Both here and <body dir="rtl">
  });
  const matches = useMediaQuery("(min-width:1400px)");
  const dispatch = useDispatch();
  const currentStepNo = useSelector((state) => state.stepper.currentStep);
  const finalStepNo = useSelector((state) => state.stepper.finalStep);
  const current = getStep(stepperData, currentStepNo);
  const stepFinished = useSelector(
    (state) => state.profile.data?.cv?.User?.step_finished
  );
  const skills = useSelector(
    (state) => state.profile?.data?.cv?.User?.CvCareerSkills
  );
  const langs = useSelector(
    (state) => state.profile?.data?.cv?.User?.CvLanguageSkills
  );
  const motherTongueChecker =
    langs?.filter((lang) => lang.mother_tongue).length > 0 ? true : false;
  // component State
  const [isAddingSkill, setIsAddingSkill] = useState(true);
  const [isEditingSkill, setIsEditingSkill] = useState(false);
  const [isAddingLang, setIsAddingLang] = useState(true);
  const [isEditingLang, setIsEditingLang] = useState(false);
  const [currentSkill, setCurrentSkill] = useState(null);
  const [yearsOfExperience, setYearsOfExperience] = useState();
  const [currentLang, setCurrentLang] = useState(null);
  const [checkedMotherTonuge, setCheckedMotherTongue] = useState(false);
  const [showAddBtnSkills, setShowAddBtnSkills] = useState(false);
  const [showAddBtnLangs, setShowAddBtnLangs] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (skills == null || skills == undefined || skills?.length == 0) {
      setShowAddBtnSkills(false);
      setIsAddingSkill(true);
    } else {
      setShowAddBtnSkills(true);
      setIsAddingSkill(false);
    }
  }, [skills?.length]);

  useEffect(() => {
    if (langs == null || langs == undefined || langs?.length == 0) {
      setShowAddBtnLangs(false);
      setIsAddingLang(true);
    } else {
      setShowAddBtnLangs(true);
      setIsAddingLang(false);
    }
  }, [langs?.length]);
  //Form & Validation Schema
  const skillSchema = yup
    .object({
      skillName: yup
        .string()
        .required(t("required_input"))
        .min(3, t("min_input", { number: 3 }))
        .max(100, t("max_input", { number: 100 }))
        .trim(t("required_input"))
        .matches(/^[a-zA-Z\u0600-\u06FF]+.?/, t("contain_alphapet")),
      proficiency: yup
        .number(t("required_input"))
        .oneOf([1, 2, 3, 4, 5], t("required_input"))
        .required(t("required_input"))
        .typeError(t("required_input")),
    })
    .required();
  const langSchema = yup
    .object({
      languageName: yup
        .string()
        .required(t("required_input"))
        .min(3, t("min_input", { number: 3 }))
        .max(30, t("max_input", { number: 30 }))
        .trim(t("required_input"))
        .matches(/^[a-zA-Z\u0600-\u06FF]+.?/, t("contain_alphapet")),
      langProficiencyRating: yup
        .number()
        .nullable()
        .when("motherTongue", (motherTongue, schema) => {
          if (!motherTongue) {
            return yup
              .number(t("required_input"))
              .oneOf([1, 2, 3, 4, 5], t("required_input"))
              .required(t("required_input"))
              .typeError(t("required_input"));
          }
        }),
      motherTongue: yup.boolean(),
    })
    .required();
  const {
    register: skillRegister,
    setValue: setSkillValue,
    reset: resetSkills,
    formState: { errors: skillErrors },
    control,
    handleSubmit: skillSubmitHandler,
  } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
    resolver: yupResolver(skillSchema),
  });
  const {
    register: langsRegister,
    reset: resetLangs,
    setValue: setLangvalue,
    getValues: getLangvalues,
    formState: { errors: langsErrors },
    control: languageControl,
    clearErrors,
    handleSubmit: langsSubmitHandler,
  } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
    resolver: yupResolver(langSchema),
  });
  const onSubmitSkill = async (event) => {
    setLoading(true);
    // rearrange data in action
    let dataToSend = {
      type: "career",
      name: event.skillName,
      proficiency: event.proficiency,
    };
    if (event.years_of_experience_id) {
      dataToSend.years_of_experience_id = event.years_of_experience_id;
    }
    await dispatch(addSkill(dataToSend))
      .then(() => {
        resetSkills();
        setIsAddingSkill(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onSubmitEditingSkill = async (event) => {
    setLoading(true);
    let dataToSend = {
      type: "career",
      name: event.skillName,
      proficiency: event.proficiency,
    };
    if (event.years_of_experience_id) {
      dataToSend.years_of_experience_id = event.years_of_experience_id;
    }
    if (currentSkill.id) {
      dataToSend.id = currentSkill.id;
    }
    await dispatch(editSkill(dataToSend))
      .then(() => {
        setIsEditingSkill(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onSubmitEditingLang = async (event) => {
    setLoading(true);
    await dispatch(
      editSkill({
        type: "language",
        name: event.languageName,
        proficiency: event.motherTongue ? 5 : event.langProficiencyRating,
        id: currentSkill.id,
        mother_tongue: event.motherTongue ? 1 : 0,
      })
    )
      .then(() => {
        setIsEditingLang(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onSubmitLang = async (event) => {
    setLoading(true);
    await dispatch(
      addSkill({
        type: "language",
        name: event.languageName,
        proficiency: event.motherTongue ? 5 : event.langProficiencyRating,
        mother_tongue: event.motherTongue ? 1 : 0,
      })
    )
      .then(() => {
        resetLangs();
        setIsAddingLang(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleClick = (skill, type) => {
    if (type === "career") {
      setIsEditingSkill(true);
      setSkillValue("skillName", skill.name);
      setSkillValue("proficiency", skill.proficiency);
      if (skill.years_of_experience_id)
        setSkillValue("years_of_experience_id", skill.years_of_experience_id);
      else setSkillValue("years_of_experience_id", "");
      setCurrentSkill(skill);
      setIsAddingSkill(false);
    } else {
      if (skill.mother_tongue) {
        setCheckedMotherTongue(true);
      } else {
        setCheckedMotherTongue(false);
      }

      setCurrentLang(skill);
      setIsEditingLang(true);
      setLangvalue("languageName", skill.name);
      setLangvalue("langProficiencyRating", skill.proficiency);
      setCurrentSkill(skill);
      setIsAddingLang(false);
    }
  };
  const handleSkillDelete = (skill, type) => {
    setLoading(true);

    if (type === "career") {
      dispatch(deleteSkill(skill.id, "career"))
        .then(() => {
          setIsEditingSkill(false);
          resetSkills();
          setSkillValue("years_of_experience_id", "");
          setSkillValue("proficiency", "");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      //setCheckedMotherTongue(false)
      if (skill.mother_tongue === 1) {
        setCheckedMotherTongue(false);
        setLangvalue("motherTongue", false);
      }
      setLangvalue("langProficiencyRating", null);
      dispatch(deleteSkill(skill.id, "language"))
        .then(() => {
          setIsEditingLang(false);
          resetLangs();
          // setLangvalue("langProficiencyRating", "")
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const handleCloseEditSkill = () => {
    setCurrentSkill(null);
    resetSkills();
    setIsEditingSkill(false);
  };
  const handleCloseEditLang = () => {
    setCheckedMotherTongue(false);
    setIsEditingLang(false);
  };
  const handleBack = () => {
    if (current?.prev) {
      dispatch(
        nextStep({ currentStep: current?.prev, finalStep: finalStepNo })
      );
    }
  };
  const handleNext = async () => {
    if (current.next) {
      if (current.no > stepFinished) {
        await dispatch(goToNextStep());
      }
      dispatch(nextStep({ currentStep: current.next, finalStep: finalStepNo }));
    }
  };
  const renderSkillsForm = () => {
    return (
      <Grid item container direction="row" spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={matches ? 6 : 12} xl={4}>
          <IconInput
            label={t("skill_name")}
            placeholder={t("skill_name")}
            icon={Hash}
            className="mb16 w100"
            fieldName="skillName"
            register={skillRegister}
            error={skillErrors?.skillName}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={matches ? 6 : 12} xl={4.5}>
          <JLSelect
            fieldName="years_of_experience_id"
            label={t("skill_yearsOfexp")}
            selectLabel={t("skill_yearsOfexp")}
            control={control}
            childs={yearsOfExperience}
            onChange={(e) => {
              setSkillValue("years_of_experience_id", e.target.value);
            }}
            optional={true}
          ></JLSelect>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          lg={3.5}
          xl={3}
          className="skill-input-rating"
        >
          <Typography className="label" component="div" sx={{}}>
            {t("proficiency")}
          </Typography>
          <Controller
            control={control}
            name="proficiency"
            defaultValue={null}
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <>
                  <ThemeProvider theme={theme}>
                    <RTLProvider>
                      <Rating {...field} size="large" />
                    </RTLProvider>
                  </ThemeProvider>
                </>
              );
            }}
          />
          {skillErrors.proficiency && (
            <FormHelperText style={{ color: "red" }}>
              {skillErrors.proficiency.message}
            </FormHelperText>
          )}
        </Grid>
      </Grid>
    );
  };
  const renderLangsForm = () => {
    return (
      <Grid item container spacing={1} direction="row">
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <IconInput
            label={t("skill_language")}
            placeholder={t("skill_language")}
            icon={Flag}
            className="mb16 w100"
            fieldName="languageName"
            register={langsRegister}
            error={langsErrors.languageName}
          />
        </Grid>
        <Grid item xs={12} md={6} className="skill-input-rating">
          {!checkedMotherTonuge && (
            <>
              <Typography className="label">{t("proficiency")}</Typography>
              <Controller
                control={languageControl}
                name="langProficiencyRating"
                defaultValue={checkedMotherTonuge ? 5 : null}
                rules={{ required: true }}
                render={({ field }) => {
                  return (
                    <>
                      <ThemeProvider theme={theme}>
                        <RTLProvider>
                          <Rating {...field} size="large" />
                        </RTLProvider>
                      </ThemeProvider>
                    </>
                  );
                }}
              />
            </>
          )}
          {langsErrors.langProficiencyRating && !checkedMotherTonuge && (
            <FormHelperText style={{ color: "red" }}>
              {langsErrors.langProficiencyRating.message}
            </FormHelperText>
          )}
        </Grid>
        {currentSkill?.mother_tongue && !isAddingLang ? (
          <Grid item xs={true}>
            <Box alignConten="center">
              <Controller
                control={languageControl}
                name="motherTongue"
                render={({ field }) => {
                  return (
                    <Checkbox
                      onChange={(e) => {
                        setCheckedMotherTongue((prev) => !prev);
                        if (e.target.checked) {
                          setLangvalue("langProficiencyRating", 0);
                        }
                        field.onChange(e.target.checked);
                      }}
                      defaultChecked={motherTongueChecker}
                      checked={field.value}
                      sx={{
                        color: "#3a3b81",
                        "&.Mui-checked": {
                          color: "#3a3b81",
                        },
                      }}
                    />
                  );
                }}
              />
              <label> {t("mother_tongue")}</label>
            </Box>
          </Grid>
        ) : (
          !motherTongueChecker && (
            <Grid item xs={true}>
              <Box className="mother-tongue-wrapper">
                <Controller
                  control={languageControl}
                  name="motherTongue"
                  render={({ field }) => {
                    return (
                      <Checkbox
                        sx={{
                          color: "#3a3b81",
                          "&.Mui-checked": {
                            color: "#3a3b81",
                          },
                        }}
                        color="secondary"
                        onChange={(e) => {
                          setCheckedMotherTongue((prev) => !prev);
                          if (motherTongueChecker) {
                            setLangvalue("langProficiencyRating", 0);
                          }
                          field.onChange(e.target.checked);
                        }}
                        defaultChecked={motherTongueChecker}
                        checked={field.value}
                      />
                    );
                  }}
                />
                <label className="mother-tongue"> {t("mother_tongue")}</label>
              </Box>
            </Grid>
          )
        )}
      </Grid>
    );
  };
  useEffect(() => {
    getYearsOfExperience().then((years) => {
      setYearsOfExperience(years);
    });
  }, []);
  useEffect(() => {
    clearErrors();
  }, [checkedMotherTonuge]);
  return (
    <Grid container direction="row" className="step-content skills">
      <Grid item xs={12}>
        <Typography className="title">{t("skills_title")}</Typography>
        <Typography className="text">{t("skills_text")}</Typography>
        <Typography className="subtitle" component="span">
          {t("skills_title_carrer")}
        </Typography>
        <Box className="skills-chips-wrapper mb16">
          {skills?.map((skill, index) => {
            return (
              <Chip
                key={index++}
                className={`choice-chips ${
                  isEditingSkill && currentSkill === skill && "clicked-chip"
                }`}
                label={skill.name}
                onClick={() => handleClick(skill, "career")}
                onDelete={() => handleSkillDelete(skill, "career")}
                deleteIcon={<X color="white" size={18} />}
                disabled={loading}
              />
            );
          })}
        </Box>
        {isAddingSkill && (
          <SaveBox
            title={t("skill_add_title")}
            handleSubmit={skillSubmitHandler(onSubmitSkill)}
            loading={loading}
          >
            {renderSkillsForm()}
          </SaveBox>
        )}
        {isEditingSkill && (
          <EditBox
            title={t("skills_edit_title")}
            handleSubmit={skillSubmitHandler(onSubmitEditingSkill)}
            handleCloseEdit={handleCloseEditSkill}
            handleDelete={() => handleSkillDelete(currentSkill, "career")}
            loading={loading}
          >
            {renderSkillsForm()}
          </EditBox>
        )}

        {showAddBtnSkills && (
          <Button
            fullWidth
            className="add-btn"
            disabled={loading}
            onClick={() => {
              resetSkills();
              setIsEditingSkill(false);
              setIsAddingSkill(true);
            }}
          >
            {" "}
            <Plus />
            {t("add_skill")}
          </Button>
        )}

        <Typography mt={2} className="subtitle mb16" component="span">
          {t("skill_subtitle_lang")}
        </Typography>
        <Box className="skills-chips-wrapper mb16">
          {langs?.map((lang, index) => {
            return (
              <Chip
                key={index++}
                className={`choice-chips ${
                  isEditingLang && currentLang === lang && "clicked-chip"
                }`}
                label={lang.name}
                onClick={() => handleClick(lang, "lang")}
                onDelete={() => handleSkillDelete(lang)}
                deleteIcon={<X color="white" size={18} />}
                disabled={loading}
              />
            );
          })}
        </Box>
        {isAddingLang && (
          <SaveBox
            title={t("add_new_lang")}
            handleSubmit={langsSubmitHandler(onSubmitLang)}
            loading={loading}
          >
            {renderLangsForm()}
          </SaveBox>
        )}
        {isEditingLang && (
          <EditBox
            title={t("language_edit")}
            handleSubmit={langsSubmitHandler(onSubmitEditingLang)}
            handleCloseEdit={handleCloseEditLang}
            handleDelete={() => handleSkillDelete(currentSkill, "language")}
            loading={loading}
          >
            {renderLangsForm()}
          </EditBox>
        )}

        {showAddBtnLangs && (
          <Button
            fullWidth
            className="add-btn"
            disabled={loading}
            onClick={() => {
              setCheckedMotherTongue(false);
              resetLangs();
              setIsEditingLang(false);
              setIsAddingLang(true);
            }}
          >
            {" "}
            <Plus />
            {t("add_language_skill")}
          </Button>
        )}

        <Grid mt={1} container justifyContent="flex-end">
          {current?.prev && (
            <button className="back-btn" onClick={handleBack}>
              {t("back_btn")}
            </button>
          )}
          <Hide condition={lastStep}>
            <button
              className="next-btn"
              onClick={handleNext}
              disabled={loading}
              style={!loading ? { cursor: "pointer" } : {}}
            >
              {t("next_btn")}
            </button>
          </Hide>
          <Show condition={lastStep}>
            <button
              className="next-btn"
              onClick={handleSubmit}
              disabled={loading}
              style={!loading ? { cursor: "pointer" } : {}}
            >
              {/* Submit */}
              {/* {t("next_btn")} */}
              {t("submit_btn")}
            </button>
          </Show>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Skills;

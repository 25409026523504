import { Box, Grid, Icon, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { Checks, DotsThreeVertical, X } from 'phosphor-react';
import { useEffect, useState } from 'react';
import { applyActionOnJob } from '../../../services/Helper';
import EnhancedButton from '../../../views/pages/yahya/components/enhanced-button/EnhancedButton';
import Hide from './../../Hide';
import IconText from './../../icon-with-text/IconText';

const EnhancedRefuseAndShortlist = ({ job_name, applicant_applied_job_id, status_id }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [applicationState, setApplicationState] = useState(status_id)
    const handleRefuse = () => {
        applyActionOnJob(applicant_applied_job_id, 3).then((response) => {
            setApplicationState(3)
        }).catch((response) => {
            setApplicationState(1)
        })
        handleClose()
    }

    const handleShortlist = () => {
        applyActionOnJob(applicant_applied_job_id, 2).then((response) => {
            setApplicationState(2)
        }).catch((response) => {
            setApplicationState(1)
        })
        handleClose()
    }
    const handleOpenMenu = (e) => {
        setAnchorEl(e.currentTarget);
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    const renderMenu = () => {
        return <Menu
            disableAutoFocus
            disableScrollLock={true}
            disablePortal
            disableAutoFocusItem
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'right',
                horizontal: 'right',
            }}
        >
            <Hide condition={applicationState === 3}>
                <MenuItem onClick={handleRefuse}>
                    <IconText
                        iconRight={true}
                        className="refuse-text"
                        icon={<X color="red" size={19} />}
                        text={t("refuse")}
                    />
                </MenuItem>
            </Hide>
            <Hide condition={applicationState === 2}>
                <MenuItem onClick={handleShortlist}>
                    <IconText
                        iconRight={true}
                        className="shortlist-text"
                        icon={<Checks color="green" size={19} />}
                        text={t("shortlist")}
                    />
                </MenuItem>
            </Hide>
        </Menu>
    }
    useEffect(() => {
        setApplicationState(status_id)
    }, [status_id])
    return (
        <Grid container justifyContent={{ xs: "center", md: "flex-end" }} alignItems={"center"} columnSpacing={1} rowSpacing={2} mb={2}>
            <Grid item xs={12} sm={12} md={"auto"} lg={"auto"} align={"center"}>
                <Typography sx={{ font: { xs: "normal normal bold 14px/17px Urbanist", sm: "normal normal bold 20px/24px Urbanist" } }}>
                    {t("applied_to")}: <Typography variant={"span"} sx={{ font: "inherit", color: "#3A3B81" }}>{job_name}</Typography>
                </Typography>
            </Grid>
            <Hide condition={applicationState !== 1}>
                <Grid item xs={"auto"} md={"auto"}><EnhancedButton buttonText={t("refuse")} variant={"outlined"} padding={"8.5px 23.5px"} onClick={handleRefuse} /> </Grid>
                <Grid item xs={"auto"} md={"auto"}><EnhancedButton buttonText={t("shortlist")} padding={"10.5px 20.5px"} onClick={handleShortlist} /> </Grid>
            </Hide>
            <Hide condition={applicationState === 1}>
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                >
                    <Box item>
                        {applicationState === 3 && (
                            <Typography sx={{ color: "red" }}>
                                <Icon>
                                    <X size={16} color="red" />
                                </Icon>
                                {t("refused_status")}
                            </Typography>
                        )}
                        {applicationState === 2 && (
                            <Typography sx={{ color: "green" }}>
                                <Icon>
                                    <Checks size={16} color="green" />
                                </Icon>
                                {t("shortlist")}
                            </Typography>
                        )}
                    </Box>
                    <Box>
                        <IconButton
                            onClick={handleOpenMenu}
                            sx={{ padding: "10px", borderRadius: "8px", backgroundColor: " #9191911A " }}>
                            <DotsThreeVertical color={"#3a3b81"} size={24} weight={"bold"} />
                        </IconButton>
                    </Box>
                </Stack>
            </Hide>
            {renderMenu()}
        </Grid>
    )
}

export default EnhancedRefuseAndShortlist

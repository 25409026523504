import {
    GET_APPLICANT_LIST,
    DELETE_APPLICANT
} from './types'

import {getApplicantToCvBank ,deletApplicantFromCvBank} from '../../../services/Helper';


export const getApplicantList = (idCategory) => async(dispatch) => {

    const data = await getApplicantToCvBank(idCategory)
    const payload = {
        data: data.rows
    }
    dispatch({ type:  GET_APPLICANT_LIST, payload })
}

export const deleteApplicantFromList = (cvBankId,idCategory) => async (dispatch) => {
    await deletApplicantFromCvBank(cvBankId);
    const data = await getApplicantToCvBank(idCategory);
    const payload = {
        data: data.rows
    }
    dispatch({type: DELETE_APPLICANT , payload})
}
import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import React from "react";
import "./EnhancedButton.scss";

const EnhancedButton = ({
  buttonText,
  variant,
  iconLeft,
  iconRight,
  padding,
  onClick,
  type,
  loading,
  noHover,
}) => {
  const buttonClasses = `${variant === "outlined" ? "outlined" : "contained"}`;

  return (
    <>
      <Stack className="enhanced-btn" direction={"row"}>
        {iconRight || iconLeft ? (
          <>
            <Button
              className={buttonClasses}
              variant={variant}
              startIcon={iconLeft && iconLeft}
              endIcon={iconRight && iconRight}
              sx={{
                minHeight: "50px",
                "& .MuiButton-endIcon": {
                  position: "absolute",
                  right: "23px",
                },
                "& .MuiButton-startIcon": {
                  position: "absolute",
                  left: "23px",
                },
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
              type={type && type}
              onClick={onClick}
            >
              {loading ? (
                <CircularProgress size={20} />
              ) : (
                <Typography>{buttonText}</Typography>
              )}
            </Button>
          </>
        ) : (
          <>
            <Button
              className={`${buttonClasses}${noHover ? " no-hover" : ""}`}
              variant={variant}
              sx={{
                padding: `${padding}`,
              }}
              onClick={onClick}
              type={type && type}
              disableRipple
            >
              {loading ? (
                <CircularProgress size={20} sx={{ color: "#3a3b81" }} />
              ) : (
                buttonText
              )}
            </Button>
          </>
        )}
      </Stack>
    </>
  );
};

export default EnhancedButton;

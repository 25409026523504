import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { CaretDown } from 'phosphor-react';
import React from 'react';
import "./EnhancedFaq.scss";

const EnhancedFaq = ({ question, answer }) => {
    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <Accordion
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
            className="enhanced-faq-wrapper"
        >
            <AccordionSummary
                className="faq-question"
                expandIcon={<CaretDown weight="fill" color={"#3A3B81"} size={15} />}
            >
                <Typography className="question" sx={{ flexShrink: 0 }}>
                    {question}
                </Typography>
            </AccordionSummary>
            <AccordionDetails className="faq-answer">
                <Typography className="answer">
                    {answer}
                </Typography>
            </AccordionDetails>
        </Accordion>
    )
}

export default EnhancedFaq
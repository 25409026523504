
import HeaderText from "./components/HeaderText/HeaderText";
import companyLogo from '../../../assets/companyImage.png';
import CompanyCard from "./components/company-card/CompanyCard";
import ContactCard from "./components/contact-card/ContactCard";
import { Phone, ArrowRight } from 'phosphor-react';
import ComponentSlider from "./components/slider/Slider";
import { Stack } from "@mui/material";
import Clients from "./components/our-clients/Clients";
import Navbar from "./components/navbar/Navbar";
import About from "./views/about/About";


const companyCardItems = [

        <CompanyCard
            image={companyLogo}
            companyName="Uber"
            companyIndustry="Transportation"
            companyFollowers="2.5M followers"
            companyUser="1000 - 5000"
            companyLocation="Cairo, Egypt"
        />,

        <CompanyCard
            image={companyLogo}
            companyName="Uber"
            companyIndustry="Transportation"
            companyFollowers="2.5M followers"
            companyUser="1000 - 5000"
            companyLocation="Cairo, Egypt"
        />,
        <CompanyCard
            image={companyLogo}
            companyName="Uber"
            companyIndustry="Transportation"
            companyFollowers="2.5M followers"
            companyUser="1000 - 5000"
            companyLocation="Cairo, Egypt"
        />,
        <CompanyCard
            image={companyLogo}
            companyName="Uber"
            companyIndustry="Transportation"
            companyFollowers="2.5M followers"
            companyUser="1000 - 5000"
            companyLocation="Cairo, Egypt"
        />
   
];

const clientsItems = [
    <Stack direction="row">
        <Clients />
        <Clients />
        <Clients />
        <Clients />
        <Clients />
        <Clients />
        <Clients />
        <Clients />
        <Clients />
        <Clients />
        <Clients />
    </Stack>,
    <Stack direction="row">
        <Clients />
        <Clients />
        <Clients />
        <Clients />
        <Clients />
        <Clients />
        <Clients />
        <Clients />
        <Clients />
        <Clients />
        <Clients />
    </Stack>,
    <Stack direction="row">
        <Clients />
        <Clients />
        <Clients />
        <Clients />
        <Clients />
        <Clients />
        <Clients />
        <Clients />
        <Clients />
        <Clients />
        <Clients />
    </Stack>
]

const links = [
    { value: "1", label: "Home" },
    { value: "2", label: "About" },
    { value: "3", label: "How it Works" },
    { value: "4", label: "Browse Jobs" },
    { value: "5", label: "Download the App" },
    { value: "6", label: "Contact Us" , newPage:true},

]

let options = [
    { name_en: "Test", name_ar: "Test", id: 1 },
    { name_en: "Test2", name_ar: "Test2", id: 2 },
    { name_en: "Test3", name_ar: "Test3", id: 3 },

];

const Zeinab = () => {
    return (
        <Stack >
            {/* <Stack>
                <Navbar links={links} options={options} />
            </Stack>
            <HeaderText text={"JobLinks Hub"} fontSize={40} fontWeight={"bold"} />
            <CompanyCard
                image={companyLogo}
                companyName="Uber"
                companyIndustry="Transportation"
                companyFollowers="2.5M followers"
                companyUser="1000 - 5000"
                companyLocation="Cairo, Egypt"
            />
            <Stack sx={{ mt: "10px" }}>
                <ContactCard
                    title="Contact Sales"
                    text="+20 123 1254 236"
                    icon={<Phone size={19} weight="fill" />}
                    iconLeft
                    info="Facere eos accusamus facere. Magni quia amet nisi voluptatibus molestiae harum. Ratione eveniet adipisci nihil ipsum cumque"
                />
            </Stack>
            <Stack sx={{ mt: "10px" }}>
                <ContactCard
                    title="Contact Customer Support"
                    text="Contact Now"
                    icon={<ArrowRight size={19} />}
                    iconRight
                    info="Facere eos accusamus facere. Magni quia amet nisi voluptatibus molestiae harum. Ratione eveniet adipisci nihil ipsum cumque"
                />
            </Stack> */}
            <Stack sx={{ mt: "30px" }}>
               
                <ComponentSlider items={companyCardItems} />
                
            </Stack>
            {/* <Stack>
                <Slider items={clientsItems} />
            </Stack> */}
            {/* <About /> */}
        </Stack>
    )
}

export default Zeinab;
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputBase,
  Stack,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import { CaretLeft } from "phosphor-react";
import React from "react";
import IconText from "../../../../../components/icon-with-text/IconText";
import CheckText from "../../checkbox-with-text/CheckText";
import "./ResponsiveFilter.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  RESPONSIVE_FILTER_TOGGLE,
  RESPONSIVE_FILTER_UNSET,
} from "../../../../../state/actions/filter/types";
import { useState, useEffect } from "react";
import { use } from "i18next";
import { useForm, Controller, useWatch } from "react-hook-form";
import Fuse from "fuse.js";
import _, { map } from "underscore";
import { useTranslation } from "react-i18next";
import {
  capitalizeWord,
  getCity,
  getCompanyIndustry,
  getCountry,
  getEducationDegree,
  getGovernorate,
  getGrade,
  getJobCategory,
  getNationality,
  getSalaryType,
  getSeniorityLevel,
  getWorkType,
  searchApplicant,
  searchJob,
  searchRecruiter,
  translateField,
} from "../../../../../services/Helper";

const ResponsiveFilter = ({
  setResponsiveFilterState,
  setData,
  page,
  setNumberOfPages,
  control,
  getValues,
  reset,
}) => {
  const [seniorityLevels, setSeniorityLevels] = useState([]);
  const [nationalities, setNationalities] = useState([]);
  const [nationalitiesSearch, setNationalitiesSearch] = useState([]);
  const [countries, setCountries] = useState([]);
  const [countriesSearch, setCountriesSearch] = useState([]);
  const [governorates, setGovernorates] = useState([]);
  const [governoratesSearch, setGovernoratesSearch] = useState([]);
  const [cities, setCities] = useState([]);
  const [citiesSearch, setCitiesSearch] = useState([]);
  const [degrees, setDegrees] = useState([]);
  const [grades, setGrades] = useState([]);
  const [jobTypes, setJobTypes] = useState([]);
  const [salaries, setSalaries] = useState([]);
  const [jobIndustries, setJobIndustries] = useState([]);
  const [jobIndustriesSearch, setJobIndustriesSearch] = useState([]);
  const [companyIndustries, setCompanyIndustries] = useState([]);
  const [companyIndustriesSearch, setCompanyIndustriesSearch] = useState([]);
  const [refresh, setRefresh] = useState([]);

  const { t, i18n } = useTranslation();

  const [filters, setFilters] = useState([
    {
      label: t("seneority_level_label"),
      options: seniorityLevels,
      actionBtns: true,
      id: "seniorityLevels",
      filter_type: ["people"],
    },
    {
      label: t("nationality_placeholder"),
      searchInput: t("nationality_placeholder"),
      options: nationalitiesSearch,
      actionBtns: true,
      id: "nationalities",
      filter_type: ["people"],
    },
    {
      label: t("degree_field"),
      options: degrees,
      actionBtns: true,
      id: "degrees",
      filter_type: ["people"],
    },
    {
      label: t("grade_field"),
      options: grades,
      actionBtns: true,
      id: "grades",
      filter_type: ["people"],
    },
    {
      label: t("job_type"),
      options: jobTypes,
      actionBtns: true,
      id: "jobTypes",
      filter_type: ["jobs"],
    },
    {
      label: t("salary"),
      options: salaries,
      searchInput: ["min", "max"],
      actionBtns: true,
      id: "salaries",
      filter_type: ["jobs"],
    },
    {
      label: t("career_level"),
      options: seniorityLevels,
      actionBtns: true,
      id: "careerLevels",
      filter_type: ["jobs"],
    },
    {
      label: t("job_industry"),
      searchInput: t("job_industry"),
      options: jobIndustriesSearch,
      actionBtns: true,
      id: "jobIndustries",
      filter_type: ["jobs"],
    },
    {
      label: t("industry"),
      searchInput: t("industry"),
      options: companyIndustriesSearch,
      actionBtns: true,
      id: "companyIndustries",
      filter_type: ["companies"],
    },
    {
      label: t("country"),
      searchInput: t("country"),
      options: countriesSearch,
      actionBtns: true,
      id: "countries",
      filter_type: ["jobs", "people", "companies"],
    },
    {
      label: t("governrate"),
      searchInput: t("governrate"),
      options: governoratesSearch,
      actionBtns: true,
      id: "governorates",
      filter_type: ["jobs", "people", "companies"],
    },
    {
      label: t("city"),
      searchInput: t("city"),
      options: citiesSearch,
      actionBtns: true,
      id: "cities",
      filter_type: ["jobs", "people", "companies"],
    },
  ]);

  const activeFilter = useSelector((state) => state?.filter?.activeFilter);
  const dispatch = useDispatch();

  const filtersData = useSelector((state) => state.filter);

  useEffect(() => {
    async function fetchData() {
      await getEducationDegree().then((degrees) => {
        setDegrees(degrees);
        updateFilterOptions("degrees", degrees);
      });
      await getGrade().then((grades) => {
        setGrades(grades);
        updateFilterOptions("grades", grades);
      });
      await getNationality().then((nationalities) => {
        setNationalities(nationalities);
        setNationalitiesSearch(nationalities);
        updateFilterOptions("nationalities", nationalities);
      });
      await getSeniorityLevel().then((seniorityLevels) => {
        
        setSeniorityLevels(seniorityLevels);
        updateFilterOptions("seniorityLevels", seniorityLevels);
        updateFilterOptions("careerLevels", seniorityLevels);
      });
      await getWorkType().then((jobTypes) => {
        setJobTypes(jobTypes);
        updateFilterOptions("jobTypes", jobTypes);
      });
      await getSalaryType().then((salaries) => {
        setSalaries(salaries);
        updateFilterOptions("salaries", salaries);
      });
      await getJobCategory().then((jobIndustries) => {
        setJobIndustries(jobIndustries);
        setJobIndustriesSearch(jobIndustries);
        updateFilterOptions("jobIndustries", jobIndustries);
      });
      await getCompanyIndustry().then((companyIndustries) => {
        setCompanyIndustries(companyIndustries);
        setCompanyIndustriesSearch(companyIndustries);
        updateFilterOptions("companyIndustries", companyIndustries);
      });
      await getCountry().then((countries) => {
        setCountries(countries);
        setCountriesSearch(countries);
        updateFilterOptions("countries", countries);
      });

      //getGovernorate.then((governorates) => setGovernorate(governorates));
      //getCity.then((cities) => setGovernorate(cities));
    }

    fetchData();
  }, []);

  const updateFilterOptions = (filterName, options) => {
    let filtersTemp = [...filters];
    let filterIndex = _.findLastIndex(filtersTemp, {
      id: filterName,
    });
    
    filtersTemp[filterIndex].options = options;
    setFilters(filtersTemp);
  };

  const checkDisabled = (id) => {
    if (id === "governorates" && filtersData.countries.length <= 0) {
      return true;
    } else if (id === "cities" && filtersData.governorates.length <= 0) {
      return true;
    } else {
      return false;
    }
  };

  const fuzzySearch = (arr, term) => {
    let currentLang = i18n.language.split("-")[0];
    const fuse = new Fuse(arr, {
      keys: [`name_${currentLang}`],
    });
    let result = fuse.search(term);
    let filtered_result = result.length > 0 ? _.pluck(result, "item") : arr;
    return filtered_result;
  };

  const searchFilter = (type, filter) => {
    let result = [];
    switch (type) {
      case "nationalities":
        result = fuzzySearch(nationalities, filter.target.value);
        setNationalitiesSearch(result);
        break;
      case "countries":
        result = fuzzySearch(countries, filter.target.value);
        setCountriesSearch(result);
        break;
      case "governorates":
        result = fuzzySearch(governorates, filter.target.value);
        setGovernoratesSearch(result);
        break;
      case "cities":
        result = fuzzySearch(cities, filter.target.value);
        setCitiesSearch(result);
        break;
      case "jobIndustries":
        result = fuzzySearch(jobIndustries, filter.target.value);
        setJobIndustriesSearch(result);
        break;
      case "companyIndustries":
        result = fuzzySearch(companyIndustries, filter.target.value);
        setCompanyIndustriesSearch(result);
        break;
      default:
        break;
    }

    let filtersTemp = [...filters];
    let filterIndex = _.findLastIndex(filtersTemp, {
      id: type,
    });
    
    filtersTemp[filterIndex].options = result;
    setFilters(filtersTemp);
  };

  useEffect(() => {
    if (filtersData.search.trim().length > 0) {
      switch (activeFilter) {
        case "people":
          searchApplicant(filtersData, page).then((data) => {
            setData(data.rows ? data.rows : []);
            setNumberOfPages(data.meta ? data.meta.totalNumberOfPages : 0);
          });
          break;
        case "jobs":
          searchJob(filtersData, page).then((data) => {
            setData(data.rows ? data.rows : []);
            setNumberOfPages(data.meta ? data.meta.totalNumberOfPages : 0);
          });
          break;
        case "companies":
          searchRecruiter(filtersData, page).then((data) => {
            setData(data.rows ? data.rows : []);
            setNumberOfPages(data.meta ? data.meta.totalNumberOfPages : 0);
          });
          break;
        default:
          break;
      }
    }
  }, [filtersData, page]);

  useEffect(() => {
    const getGovernorates = async () => {
      let governorates = [];

      if (filtersData.countries.length > 0) {
        
        filtersData.countries.map(async (value) => {
          
          governorates.push(...(await getGovernorate(value)));
        });

        
        setGovernorates(governorates);
        setGovernoratesSearch(governorates);
        updateFilterOptions("governorates", governorates);
      }
    };
    getGovernorates();
  }, [filtersData.countries]);

  useEffect(() => {
    const getCities = async () => {
      let cities = [];

      if (filtersData.governorates.length > 0) {
        
        filtersData.governorates.map(async (value) => {
          
          cities.push(...(await getCity(value)));
        });
        
        setCities(cities);
        setCitiesSearch(cities);
        updateFilterOptions("cities", cities);
      }
    };
    getCities();
  }, [filtersData.governorates]);

  const renderFilters = () => {
    return (
      <Stack
        direction={"column"}
        spacing={1}
        divider={<Divider flexItem />}
        sx={{
          width: "100%",
        }}
      >
        {filters.map((filter) => {
          return (filter.filter_type.includes(activeFilter) && !checkDisabled(filter.id)) ? (
            <Grid container>
              <Grid item xs={12}>
                <Typography className="filter-title ">
                  {filter.label}
                </Typography>
              </Grid>
              {Array.isArray(filter.searchInput) ? (
                <>
                  <Grid item xs={12}>
                    <Stack
                      direction={"column"}
                      justifyContent="center"
                      alignItems={"center"}
                      spacing={1}
                    >
                      {filter.options.map((option) => {
                        return (
                          <Controller
                            control={control}
                            name={`${filter.id}.${option.id}`}
                            render={({ field }) => {
                              return (
                                <CheckText
                                  text={translateField(option, "name")}
                                  field={field}
                                  id={option.id}
                                />
                              );
                            }}
                          />
                        );
                      })}
                    </Stack>
                  </Grid>
                  {filter.searchInput.map((searchInput) => {
                    return (
                      <Controller
                        control={control}
                        name={searchInput}
                        render={({ field }) => {
                          return (
                            <InputBase
                              mt={1}
                              mb={1}
                              className="responsive-filter-search-input"
                              type="text"
                              placeholder={
                                searchInput.charAt(0).toUpperCase() +
                                searchInput.slice(1)
                              }
                              //autoFocus
                              value={field.value}
                              onChange={(e) => {
                                
                                searchFilter(searchInput, e);
                                field.onChange(e);
                              }}
                            />
                          );
                        }}
                      />
                    );
                  })}
                </>
              ) : (
                <>
                  <Grid item xs={12}>
                    {filter.searchInput && (
                      <Controller
                        control={control}
                        name={`${filter.id}_search`}
                        render={({ field }) => {
                          return (
                            <InputBase
                              mt={1}
                              mb={1}
                              className="responsive-filter-search-input"
                              autoFocus
                              type="text"
                              value={field.value}
                              placeholder={filter.searchInput}
                              onChange={(e) => {
                                
                                searchFilter(filter.id, e);
                                field.onChange(e);
                              }}
                            />
                          );
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Stack
                      direction={"column"}
                      justifyContent="center"
                      alignItems={"center"}
                    >
                      {filter.options.map((option) => {
                        return (
                          <Controller
                            control={control}
                            name={`${filter.id}.${option.id}`}
                            render={({ field }) => {
                              return (
                                <CheckText
                                  text={translateField(option, "name")}
                                  field={field}
                                  id={option.id}
                                />
                              );
                            }}
                          />
                        );
                      })}
                    </Stack>
                  </Grid>
                </>
              )}
            </Grid>
          ) : (
            null);
        })}
      </Stack>
    );
  };

  useEffect(() => {
    setFilters([...filters]);
  }, [activeFilter]);

  return (
    <Stack
      direction={"column"}
      spacing={2}
      justifyContent="flex-start"
      alignItems={"flex-start"}
      sx={{
        width: "100%",
      }}
    >
      <Stack
        direction={"row"}
        alignItems="center"
        onClick={() => {
          dispatch({ type: RESPONSIVE_FILTER_UNSET });
        }}
      >
        <CaretLeft size={20} weight="bold" />
        <Typography className="back-text">Back</Typography>
      </Stack>
      <Typography className="filter-header">Filter Result</Typography>
      {renderFilters()}
      <Stack
        direction={"row"}
        spacing={1}
        justifyContent="flex-end"
        sx={{ width: "100%" }}
      >
        <Button
          className="btn reset-filter"
          onClick={() => {
            let toReset = {};
            
            for (const [key, value] of Object.entries(getValues())) {
              //

              if (
                !key.includes("search") &&
                !key.includes("max") &&
                !key.includes("min")
              ) {
                toReset[key] = [];
                reset({ ...getValues(), [key]: [] });
                dispatch({
                  type: `${key.toUpperCase()}_FILTER`,
                  payload: [],
                });
              }
            }
          }}
        >
          Reset
        </Button>
        <Button
          className="btn btn-dark apply-filter"
          onClick={() => {
            
            for (const [key, value] of Object.entries(getValues())) {
              

              if (
                !key.includes("_search") &&
                !key.includes("max") &&
                !key.includes("min")
              ) {
                let filterValues = Object.keys(value).filter(function (k) {
                  return value[k] === true;
                });
                if (key !== "countries") {
                  filterValues = filterValues.map(Number);
                }
                
                dispatch({
                  type: `${key.toUpperCase()}_FILTER`,
                  payload: filterValues,
                });
              }
            }

            dispatch({ type: RESPONSIVE_FILTER_UNSET });
          }}
        >
          Apply Filters
        </Button>
      </Stack>
    </Stack>
  );
};

export default ResponsiveFilter;
import React, { useState } from "react";
import { Box, Grid, Paper, Typography, ToggleButton, ToggleButtonGroup, Button, Divider } from "@mui/material";
import { MagnifyingGlass, Cards } from "phosphor-react"
import AuthPageWrapper from "../../../components/auth-page-wrapper/AuthPageWrapper";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Show from "../../../components/Show";
import { logout } from "../../../services/auth/Auth";
import ChangeLang from "../../../components/ChangeLang/ChangeLang";
import { useDispatch, useSelector } from "react-redux";
import { authLogout } from "state/actions/auth/auth";


const JoinUsReason = () => {
    const { t, i18n } = useTranslation();

    const [view, setView] = useState('list');
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const profile = useSelector((state) => state.profile.data)

    const handleChange = (event, nextView) => {
        setView(nextView);
    };
    const nextClick = () => {
        switch (view) {
            case 'find-job':
                navigate('/applicant-submit', { state: { switched: true } })
                break;
            case 'post-job':
                navigate('/recruiter-submit', { state: { switched: true } });
                break;
            default:
                break;
        }
    }
    return (
        <>
            <Grid
                container
                spacing={1}
                position={"absolute"}
                top={"15px"}
                width={"fit-content"}
                right={i18n.language == "ar" ? "15px" : "unset"}
                left={i18n.language == "ar" ? "unset" : "15px"}
            >
                <Show condition={profile}>
                    <Grid item>
                        <Button
                            style={{ zIndex: "200" }}
                            className="btn btn-home"
                            onClick={() => {
                                dispatch(authLogout(navigate))
                            }}
                        >
                            {t("log_out")}
                        </Button>
                    </Grid>
                </Show>
                <Grid item>
                    <Divider light orientation="vertical" style={{ zIndex: "200" }} />
                </Grid>
                <Grid item>
                    <ChangeLang />
                </Grid>
            </Grid>
            <AuthPageWrapper single={true}>
                <Grid item align="center">
                    <Box component="div" className="single-auth-card join-us-reason">
                        <Grid container alignItems="center" justifyContent="center" rowSpacing={2}>
                            <Grid item xs={12}>
                                <Typography className="title mb24">joblink</Typography>
                                <Typography className="desc" >{t("joinus_desc")}</Typography>
                            </Grid>
                            <Grid item xs={10} container direction="column">
                                <ToggleButtonGroup
                                    onChange={handleChange}
                                    orientation="vertical"
                                    exclusive
                                    value={view}
                                    className="toggle-btns"
                                >
                                    <ToggleButton value="find-job" fullWidth size="medium">
                                        <MagnifyingGlass size={18} />
                                        {t("joinus_find_job")}
                                    </ToggleButton>
                                    <ToggleButton value="post-job" fullWidth size="medium">
                                        <Cards size={18} />
                                        {t("joinus_post_job")}
                                    </ToggleButton>




                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item xs={10}>
                                <Button className="btn btn-dark" fullWidth onClick={() => nextClick()}>
                                    {t("next_btn")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </AuthPageWrapper>
        </>
    );
};

export default JoinUsReason;

import {
    ENABLE_ADD,
    DISABLE_ADD,
    TOGGLE_ADD
} from './../../actions/add-to-category/types';

let initialState = {
    isAddDisabled: true
}

export const AddToCategoryReducer = (state = initialState, action) => {
    switch (action) {
        case ENABLE_ADD:
            return {
                isAddDisabled: false
            }
        case DISABLE_ADD: return {
            isAddDisabled: true
        }
        case TOGGLE_ADD: return {
            isAddDisabled: !state.isAddDisabled
        }
        default:
            return state
    }
}
import {
  Autocomplete,
  Box,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { CaretDown } from "phosphor-react";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { translateField } from "../../services/Helper";
import Show from "../Show";
import "./SingleAutocomplete.css";

const SingleAutocomplete = ({
  control,
  label,
  placeholder,
  onChange,
  options,
  fieldName,
  error,
  disabled,
  edit,
}) => {
  const [open, setOpen] = useState(false);

  

  return (
    <Box className="single-autocomplete">
      <Grid container direction="row" spacing={0.5}>
        <Grid item xs={12}>
          <Typography className="label">{label}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name={fieldName}
            render={({ field }) => {
              
              return (
                <Autocomplete
                  disabled={disabled}
                  multiple={false}
                  open={open}
                  onOpen={() => setOpen(true)}
                  onClose={() => setOpen(false)}
                  f
                  onChange={(e, newValue) => {
                    
                    field.onChange(newValue);
                  }}
                  onInputChange={(e, newValue) => onChange(newValue)}
                  getOptionLabel={(option) =>
                    option && translateField(option, "name")
                  }
                  filterOptions={(x) => x}
                  popupIcon={<CaretDown color="#000" />}
                  options={options}
                  value={
                    edit
                      ? field.value
                        ? field.value
                        : {
                            id: -1,
                            name_ar: "جاري التحميل",
                            name_en: "Loading",
                          }
                      : field.value
                  }
                  defaultValue={field.value}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        error={error ? true : false}
                        //label="filterSelectedOptions"
                        placeholder={placeholder}
                      />
                    );
                  }}
                />
              );
            }}
          />
          <Show condition={error}>
            <FormHelperText style={{ color: "red" }}>
              {error?.message}
            </FormHelperText>
          </Show>
        </Grid>
      </Grid>
    </Box>
  );
};
export default SingleAutocomplete;

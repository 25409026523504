import {
    Box,
    Button,
    Divider,
    Grid,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
  } from "@mui/material";
  import { Cards, MagnifyingGlass, User } from "phosphor-react";
  import { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { useNavigate } from "react-router-dom";
  import AuthPageWrapper from "../../../components/auth-page-wrapper/AuthPageWrapper";
  import {
    addDeviceToken,
    searchInArray,
    searchInArrayOfObjsByName,
  } from "../../../services/Helper";
  import {
    assignRole,
    deleteProfile,
    fetchProfile,
  } from "../../../state/actions/profile/profile";
  import Permissions from "../../../constants/permissions";
  import { useTranslation } from "react-i18next";
  import { store } from "../../../state/store";
  import { deleteFirebaseToken, requestForToken } from "../../../firebase";
  import { acquireToken, logout } from "../../../services/auth/Auth";
  import Show from "../../../components/Show";
  import ChangeLang from "../../../components/ChangeLang/ChangeLang";
  import { authLogout } from "state/actions/auth/auth";
  
  const LoginAs = () => {
    const { t, i18n } = useTranslation();
  
    const dispatch = useDispatch();
    const profile = useSelector((state) => state.profile?.data);
    const roles = useSelector((state) => state.profile?.data?.role);
    const loginCompleted = useSelector((state) => state.profile?.loginCompleted);
    const isCvCreated = useSelector(
      (state) => state.profile?.data?.cv?.User?.is_cv_created
    );
    const isCompanyCreated = useSelector(
      (state) => state.profile?.data?.recruiter?.is_company_created
    );
    const [role, setRole] = useState(null);
    const navigate = useNavigate();
  
    const notCompleted = isCvCreated == 0 || isCompanyCreated == 0;
  
    const handleChange = (event, selectedRole) => {
      setRole(selectedRole);
    };
    const nextClick = async () => {
      const tRoles = store.getState().profile.data?.role;
      const result = searchInArrayOfObjsByName(tRoles, role);
      if (result) {
        await dispatch(fetchProfile(result.name));
        dispatch(assignRole(result));
  
        // if (Notification.permission !== "granted") {
  
        //     Notification.requestPermission().then(async (permission) => {
        //         if (permission == "granted") {
        //             const token = await requestForToken();
        //             addDeviceToken({ device_token: token }, result?.name, notCompleted)
        //         }
  
        //     })
        // } else {
        //     const token = await requestForToken();
        //     addDeviceToken({ device_token: token }, result?.name, notCompleted)
        // }
  
        navigate(result.redirect);
      }
    };
  
    useEffect(() => {
      if (loginCompleted) {
        navigate("/");
      }
    }, [loginCompleted]);
  
    useEffect(() => {
      acquireToken().then((token) => {
        if (!token) navigate("/");
      });
    }, []);
  
    return (
      <>
        <Grid
          container
          spacing={1}
          position={"absolute"}
          top={"15px"}
          width={"fit-content"}
          right={i18n.language == "ar" ? "15px" : "unset"}
          left={i18n.language == "ar" ? "unset" : "15px"}
        >
          <Show condition={profile}>
            <Grid item>
              <Button
                style={{ zIndex: "200" }}
                className="btn btn-home"
                onClick={() => {
                  dispatch(deleteProfile());
                  dispatch(authLogout(navigate));
                }}
              >
                {t("log_out")}
              </Button>
            </Grid>
          </Show>
          <Grid item>
            <Divider light orientation="vertical" style={{ zIndex: "200" }} />
          </Grid>
          <Grid item>
            <ChangeLang />
          </Grid>
        </Grid>
        <AuthPageWrapper single={true}>
          <Grid item align="center">
            <Box component="div" className="single-auth-card join-us-reason">
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                rowSpacing={2}
              >
                <Grid item xs={12}>
                  <Typography className="title mb24">joblink</Typography>
                  <Typography className="desc">
                    {t("login_as_title")}
                  </Typography>
                </Grid>
                <Grid item xs={10} container direction="column">
                  <ToggleButtonGroup
                    onChange={handleChange}
                    orientation="vertical"
                    exclusive
                    value={role}
                    className="toggle-btns"
                  >
                    {roles?.map((role) => (
                      <ToggleButton value={role.name} fullWidth size="medium">
                        <User size={18} />
                        {role.name}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={10}>
                  <Button
                    className="btn btn-dark"
                    fullWidth
                    onClick={() => nextClick()}
                  >
                    {t("next_btn")}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </AuthPageWrapper>
      </>
    );
  };
  export default LoginAs;
  
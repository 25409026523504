import React, { useState } from "react";
import { Grid, Stack, Drawer, Button, IconButton } from "@mui/material";
import TabIcon from "../../../../../../../assets/Logo5SVG";
import EnhancedButton from "../../../../../yahya/components/enhanced-button/EnhancedButton";
import DrawerComponent from "./components/drawer/Drawer";
import { List } from "phosphor-react";
import "./MobileNavbar.css";

const MobileNavbar = ({ links, currentSection, handleChange, openDrawer, setOpenDrawer }) => {
  return (
    <Grid container>
      <Grid item xs={6}>
        <TabIcon />
      </Grid>
      <Grid item xs={6}>
        <Stack direction={"row"} justifyContent="flex-end">
          <IconButton className="menu-btn" onClick={() => setOpenDrawer(true)}>
            <List />
          </IconButton>
          <Drawer
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
            anchor="top"
            className="drawer"
          >
            <DrawerComponent
              links={links}
              currentSection={currentSection}
              handleChange={handleChange}
            />
          </Drawer>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default MobileNavbar;

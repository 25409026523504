import { Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import { Checks } from 'phosphor-react';
import { useTranslation } from "react-i18next";
import DecisionButton from "../../decision-button/DecisionButton";
import UserAvatar from "../../user-avatar/UserAvatar";

const FollowedCard = ({ avatar, companySize, name, followed, onFollow, recruiterId, loading ,showUserThreeicon }) => {
  const { t } = useTranslation();
  const [is_followed, setIsFollowed] = useState(followed)
  useEffect(() => {

    // setIsFollowed(followed)
  }, [])
  return (
    <Grid container direction="row" className="followed-card" >
      <Grid item xs={9.75}

      >
        <UserAvatar
          recruiterId={recruiterId}
          showAvatar
          showName
          showTitle
          isCompany
          name={name}
          title={companySize}
          avatar={avatar}
          size="medium"
          rounded
          bolded
          showUserThreeicon
        />
      </Grid>
      <Grid
        item
        xs={2.25}
        container
        justifyContent={"flex-end"}
        alignItems={"center"}
      >
        <DecisionButton active={!followed} onClick={onFollow} disabled={loading}>

          {followed ? (

            <Stack direction="row" alignItems="center" gap={1}>
              <Checks color="green" size={19} />
              <Typography className="followed-text" >
                {t("followed")}
              </Typography>
            </Stack>

          ) : (
            
            <Typography className="follow-text">
              {t("follow")}
            </Typography>
          )
          }

        </DecisionButton>
      </Grid>
    </Grid>
  );
};

export default FollowedCard;

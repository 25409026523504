
import { NEW_NOTIFICATION, OPENED } from './../../actions/notification/types';
let initialState = {
    new: false
}

export const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case NEW_NOTIFICATION:
            return { ...state, new: true };
        case OPENED: {
            return { ...state, new: false };
        }
        default:
            return state;
    }
}

export default notificationReducer;

/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { useState } from "react";

import {
  Divider, Paper,
  Stack, useMediaQuery
} from "@mui/material";

//component re-usable content
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { downloadCV } from "../../../../services/Helper";
import ProfileOtherLinks from "../profile-other-links/ProfileOtherLinks";
import { translateField } from "./../../../../services/Helper";
import ProfileBasicDetails from "./../profile-basic-details/ProfileBasicDetails";
import ProfileContactInfo from "./../profile-contact-info/ProfileContactInfo";

const ProfileInfo = ({
  userData,
  hideSaveIcon,
  showAddAndInviteProfile,
  cvBank,
  fetchTrigger,
  setFetchTrigger,
}) => {
  const matched = useMediaQuery("(max-width:900px)");
  const role = useSelector((state) => state.profile.role?.name);
  const permissions = useSelector((state) => state.profile.role?.permissions);
  const [isApplicant, setIsApplicant] = useState(() => {
    if (role === "APPLICANT") return true;
    else return false;
  });
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();

  const handleDownloadCV = async (applicant_id) => {
    setLoading(true);
    const link = await downloadCV(applicant_id);
    setLoading(false);
    var element = document.createElement("a");
    element.setAttribute("href", link);
    element.setAttribute("target", "_blank");

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const handleTitlePropsUserAvatar = () => {
    if (userData?.User?.CareerLevel?.name_en === null) {
      return translateField(userData?.User?.JobTitle, "name");
    } else if (userData?.User?.JobTitle?.name_en === null) {
      return translateField(userData?.User?.CareerLevel, "name");
    } else {
      return `${translateField(
        userData?.User?.CareerLevel,
        "name"
      )} ${translateField(userData?.User?.JobTitle, "name")}`;
    }
  };

  return (
    <>
      <Paper className="profile-info" elevation={0} sx={{ border: "1px solid #9191914D", borderRadius: "16px", boxShadow: "0px" }}>
        <Stack
          mb={10}
          direction="column"
          spacing={2}
          divider={<Divider orientation="horizontal" flexItem />}
        >
      
          <ProfileBasicDetails user={userData?.User} />
          <ProfileContactInfo
            user={userData?.User}
            email={userData?.User?.work_email}
            phone={userData?.User?.mobile ? userData?.User?.mobile : null}
          />

          {(userData?.User?.CvSocialMedia?.length > 0 ||
            userData?.User?.CvPortfolios?.length > 0) && (
              <ProfileOtherLinks user={userData?.User} />
            )}
        </Stack>
      </Paper>
    </>
  );
};

export default ProfileInfo;

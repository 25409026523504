import { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  Autocomplete,
  Chip,
  TextField,
  Grid,
  IconButton,
  FormHelperText,
} from "@mui/material";
import { CaretDown, Plus, X } from "phosphor-react";
import React from "react";
import { getGovernorate, getGovernorateWithAll, searchInArrayOfObjsById } from "../services/Helper";
import { translateField } from "../services/Helper";
import { getCountry } from "../services/Helper"
import _ from 'underscore';
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";


const LocationInput = (props) => {
  const { t, i18n } = useTranslation();

  const [choices, setChoices] = useState(props.defaultValue);
  const [governorates, setGovernorates] = useState([]);
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState();
  const [defaultGovers, setDefaultGovers] = useState();
  const [countryOpen, setCountryOpen] = useState(false);
  const [governorateOpen, setGovernorateOpen] = useState(false);

  const checkAllGovernorates = (governorateChoices, field) => {
    let isAllGovernorates = _.where(governorateChoices, { is_all: 1 });
    let isAllCountries = _.pluck(isAllGovernorates, "country");

    if (isAllGovernorates.length > 0) {
      let filteredGovernorateChoices = _.reject(governorateChoices, (governorate) => !governorate.is_all && isAllCountries.includes(governorate.country));
      let filteredGovernorates = _.reject(governorates, (governorate) => isAllCountries.includes(governorate.country));
      let filteredCountries = _.reject(props.countries, (country) => isAllCountries.includes(country.id));
      
      setChoices(filteredGovernorateChoices);
      setGovernorates(filteredGovernorates);
      // field.onChange(filteredGovernorateChoices);
      props.setValue(props?.fieldName, filteredGovernorateChoices);
      props.setCountries(filteredCountries);
      setCountries(filteredCountries);
      setCountry(null);
      props.setValue("country", null);
    }
    else {
      setChoices(governorateChoices);
      props.setValue(props?.fieldName, governorateChoices);
      // field.onChange(governorateChoices);
    }

    // props.trigger(props?.fieldName);
  }

  const retrieveAllCountries = (option) => {
    
    if (option.is_all === 1) {
      let currentChoices = _.reject(choices, (choice) => choice.id == option.id || choice.is_all !== 1);
      
      getCountry().then((data) => {
        let newCountries = _.reject(data, (country) => currentChoices?.filter(choice => choice.country === country.id).length > 0)
        props.setCountries(newCountries);
        setCountries(newCountries);
        
        
        // props?.setValue("country_id", searchInArrayOfObjsById(newCountries, option.country)?.id)
      })
    }
  }



  useEffect(() => {
    let stopped = false;
    
    let isAllGovernorates = _.where(props?.defaultValue, { is_all: 1 });
    let isAllCountries = _.pluck(isAllGovernorates, "country");
    
    
    if (isAllGovernorates.length > 0) {
      const filteredCountries = _.reject(props?.countries, (country) => isAllCountries.includes(country.id));
      
      

      setCountries(filteredCountries);

    } else {
      setCountries(props?.countries);
    }
  }, [props?.defaultValue, props?.countries])

  return (
    <FormControl
      variant="standard"
      className={["standart-form-controller", props.className].join(" ")}
    >
      {props.label && (
        <InputLabel focused={false} htmlFor={props.id}>
          {props.label}
        </InputLabel>
      )}
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Controller
            control={props?.control}
            name="country"
            render={({ field }) => {
              return <Autocomplete
                open={countryOpen}
                onOpen={() => setCountryOpen(true)}
                onClose={() => setCountryOpen(false)}
                popupIcon={<CaretDown />}
                id={props.id}
                disableClearable
                options={countries}
                value={field.value}
                getOptionLabel={(option) => translateField(option, "name")}
                // inputValue={country ? translateField(country, "name") : ''}
                className="autocomplete-field"
                error={props.error.country}
                onBlur={(event) => {
                  // props.trigger("country");
                  
                  
                  
                  props.setError?.({ ...props.error, country: choices.length < 1 ? (country ? false : true) : false });
                }}
                onChange={(event, newValue) => {
                  field.onChange(newValue);
                  
                  // props.setValue("country", translateField(newValue, "name"));
                  setCountry(newValue);
                  getGovernorateWithAll(newValue.id).then((governorates) => {
                    
                    setGovernorates(governorates);
                  });
                  props.setError?.({ ...props.error, country: false });
                  // props.trigger(props?.fieldName);
                }}
                renderInput={(params) => {
                  
                  return (
                    <TextField
                      {...params}
                      error={props.error.country}
                      //label="filterSelectedOptions"
                      placeholder={t("select_placeholder")}
                    />
                  )
                }}
              />
            }}
          />


          {props.error.country && (
            <FormHelperText style={{ color: "red" }}>
              {t("required_input")}
            </FormHelperText>
          )}
        </Grid>
        <Grid item md={6} xs={12}>
          <Controller
            control={props?.control}
            name="governorates"
            render={({ field }) => {
              return <Autocomplete
                multiple
                open={governorateOpen}
                onOpen={() => setGovernorateOpen(true)}
                onClose={() => setGovernorateOpen(false)}
                id={props.id}
                popupIcon={choices.length > 0 ? "" : <CaretDown />}
                options={governorates}
                value={field.value}
                getOptionLabel={(option) => translateField(option, "name")}
                filterSelectedOptions
                className="autocomplete-field"
                error={props.error.governorates && country}
                onBlur={(event) => {
                  
                  props.setError?.({ ...props.error, governorates: choices.length < 1 });
                  // props.trigger(props?.fieldName);
                }}
                onChange={(event, newValue) => {
                  
                  field.onChange(newValue);
                  checkAllGovernorates(newValue, field);
                  props.setError?.({ ...props.error, governorates: newValue.length < 1 });
                }}
                renderTags={(tagValue, getTagProps) => {
                  return tagValue.map((option, index) => {
                    if (index === tagValue.length - 1) {
                      return (
                        <>
                          <Chip
                            {...getTagProps({ index })}
                            onDelete={(e) => {
                              retrieveAllCountries(option);
                              
                              getTagProps({ index }).onDelete();
                              // props.trigger(props?.fieldName);
                            }}
                            label={translateField(option, "name")}
                            className="choice-chips"
                            deleteIcon={<X className="chip-icon" />}
                          />
                          <IconButton
                            onClick={() => {
                              
                              setGovernorateOpen(true);
                            }}
                          >
                            <Plus />
                          </IconButton>
                        </>
                      );
                    }
                    return (
                      <Chip
                        {...getTagProps({ index })}
                        label={translateField(option, "name")}
                        className="choice-chips"
                        deleteIcon={<X className="chip-icon" />}
                        onDelete={(e) => {
                          retrieveAllCountries(option);
                          
                          getTagProps({ index }).onDelete();
                          // props.trigger(props?.fieldName);
                        }}
                      />
                    );
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={props.error.governorates && country}
                    //label="filterSelectedOptions"
                    placeholder={t("governorate_placeholder")}
                  />
                )}
              />
            }}
          />


          {(props.error.governorates && country) && (
            <FormHelperText style={{ color: "red" }}>
              {t("required_selection")}
            </FormHelperText>
          )}
        </Grid>
      </Grid>
    </FormControl>
  );
};

export default LocationInput;

import {
  END_OTP_VERIFICATION,
  START_OTP_VERIFICATION,
  SUBMIT_OTP_TOKEN,
} from "./types";

export const startOTPVerification = (title, email, type) => (dispatch) => {
  dispatch({
    type: START_OTP_VERIFICATION,
    payload: {
      title,
      email,
      type,
    },
  });
};

export const submitOTPToken = () => (dispatch) => {
  dispatch({
    type: SUBMIT_OTP_TOKEN,
  });
};

export const endOTPVerification = () => (dispatch) => {
  dispatch({
    type: END_OTP_VERIFICATION,
  });
};

import { Grid, Typography } from "@mui/material";
import {
  FacebookLogo, LinkedinLogo, LinkSimple, TwitterLogo, WhatsappLogo
} from "phosphor-react";
import { useTranslation } from "react-i18next";
import { toastr } from "react-redux-toastr";
import {
  FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton
} from "react-share";
import "./ShareJob.scss";

const ShareJob = (props) => {
  const { url, type } = props;
  const { t, i18n } = useTranslation();
  return (
    <Grid
      container
      direction={"row"}
      justifyContent="space-between"
      onClick={(event) => event.stopPropagation()}
    >
      <Grid item>
        <Typography className="share-text">
          {t("share" + (type === "Company" ? "_company" : ""))}
        </Typography>
      </Grid>
      <Grid
        item
        onClick={() => {
          navigator.clipboard.writeText(url);
          toastr.info(t("clipboard-copy"));
        }}
      >
        <LinkSimple className="share-icon" />
      </Grid>
      <Grid item>
        <FacebookShareButton url={url} hashtag={"#joblink"}>
          <FacebookLogo className="share-icon" />
        </FacebookShareButton>
      </Grid>
      <Grid item>
        <TwitterShareButton
          url={url}
          hashtags={["joblink", "recruitment", "jobs"]}
        >
          <TwitterLogo className="share-icon" />
        </TwitterShareButton>
      </Grid>
      <Grid item>
        <LinkedinShareButton url={url}>
          <LinkedinLogo className="share-icon" />
        </LinkedinShareButton>
      </Grid>
      <Grid item>
        <WhatsappShareButton url={url}>
          <WhatsappLogo className="share-icon" />
        </WhatsappShareButton>
      </Grid>
    </Grid>
  );
};
export default ShareJob;

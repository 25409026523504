import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getFollowedCompanies } from "../../services/Helper";
import Companies from "./components/Companies/Companies";
import Pagination from "../../components/pagination/Pagination";
import "./FollowedCompanies.css";
import { useNavigate } from "react-router-dom";
import Show from "../../components/Show";
import { Box, Typography, useMediaQuery } from "@mui/material";

const FollowedCompanies = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [companies, setCompanies] = useState([]);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [page, setPage] = useState(1);
  const [ready, setReady] = useState(false);

  const mdMatches = useMediaQuery("(min-width:701px) and (max-width:1300px)");
  const smMatches = useMediaQuery("(max-width:700px)");

  const fetchPage = (pageNumber) => {
    getFollowedCompanies(pageNumber).then((companies) => {
      setCompanies(companies ? companies?.rows : []);
      setNumberOfPages(companies?.meta?.totalNumberOfPages);
      setReady(true);
    });
  };

  useEffect(() => {
    fetchPage(1);
  }, [companies]);

  const changePage = (newPage) => {
    fetchPage(newPage);
    setPage(newPage);
  };

  return (
    <Box >
      <Typography className="settings-page-title">
        {" "}
        {t("companies_followed")}
      </Typography>
      <Companies
        companies={companies}
        loaded={ready}
        emptySectionData={{
          description: t("followed_companies_empty"),
          actionText: t("back_to_home"),
          action: () => {
            navigate("/");
          },
        }}
        followedCompanies={true}
      />
      <Show condition={ready && numberOfPages > 1}>
        <Pagination
          pagesManager={{
            page: page,
            setPage: changePage,
            numberOfPages: numberOfPages,
          }}
        />
      </Show>
    </Box>
  );
};
export default FollowedCompanies;

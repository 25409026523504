import { APPLICANT_PROFILE_EDIT_STEPPER } from "../../constants/constants";

export const stepperData = [
    {
        no: 1,
        title_en: "General Info",
        title_ar:"معلومات عامة",
        name: APPLICANT_PROFILE_EDIT_STEPPER.GENERAL_INFO,
        next: 2,
        prev: false,
    },
    {
        no: 2,
        title_en: "Contact Info & Portfolio",
        title_ar:"معلومات الاتصال والمحفظة",
        name: APPLICANT_PROFILE_EDIT_STEPPER.CONTACT_INFO_PORTFOLIO,
        next: 3,
        prev: 1,
    },
    {
        no: 3,
        title_en: "Education",
        title_ar:"تعليم",
        name: APPLICANT_PROFILE_EDIT_STEPPER.EDUCATION,
        next: 4,
        prev: 2,
    },
    {
        no: 4,
        title_en: "Experience",
        title_ar:"خبرة",
        name: APPLICANT_PROFILE_EDIT_STEPPER.EXPERIENCE,
        next: 5,
        prev: 3,
    },
    {
        no: 5,
        title_en: "Skills",
        title_ar:"مهارات",
        name: APPLICANT_PROFILE_EDIT_STEPPER.SKILLS,
        next: false,
        prev: 4,
    },
];
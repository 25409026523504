import { SET_NEWS, OPEN_NEWS } from "../../actions/news/types";

let initialState = {
    limit: 11,
    data: [],
    new: false,
    count: 0,
    loaded: false
}

export const newsReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_NEWS:
            return {
                ...state,
                data: payload.data,
                limit: payload.limit,
                total: payload.total,
                new: payload.new,
                count: payload.count,
                loaded: true
            };
        case OPEN_NEWS:
            return {
                ...state,
                new: payload.new
            };
        default:
            return state;
    }
}

export default newsReducer;

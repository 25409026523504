import "./Jobs.css";

import { Files } from "phosphor-react";
import ErrorPage from "../../components/error-page/ErrorPage";
import NotificationsDropdown from "../../containers/dashboard/header/components/notifications-dropdown/NotificationsDropdown";
// import emptyPage from "../../assets/empty-page.json";
import { Box, Button, Grid, Typography } from "@mui/material";
import RoleSwitcher from "../../containers/dashboard/header/components/role-switcher/RoleSwitcher";
import DecisionButton from "../../components/decision-button/DecisionButton";
import React, { useState } from "react";
import List from "./components/list/List";
import { Outlet } from "react-router-dom";
import { useTranslation } from 'react-i18next';

// const emptyPage = React.lazy(() => import("../../assets/empty-page.json"));


const Jobs = () => {
  const [active, setActive] = useState(1);
  const activeTextColor = active ? "#fff" : "#3A3B81";
  const inactiveTextColor = active ? "#3A3B81" : "#fff";
  const { t, i18n } = useTranslation();
  return (
    <Box component="div" className="all-jobs" paddingY={2}>
      <Grid container direction="row" rowSpacing={5} justifyContent="center">
        <Grid item xs={12}>
          <Grid container direction="row" columnSpacing={1}>
            <Grid item xs={5} md={2}>
              <DecisionButton
                active={active}
                rounded
                onClick={() => setActive(1)}
              >
                <Typography color={activeTextColor} className="standard-text ">
                  {t("active_jobs")}
                </Typography>
              </DecisionButton>
            </Grid>
            <Grid item xs={5} md={2}>
              <DecisionButton
                active={!active}
                rounded
                onClick={() => setActive(0)}
              >
                <Typography color={inactiveTextColor} className="standard-text">
                  {t("inactive_jobs")}
                </Typography>
              </DecisionButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <List active={active} />
        </Grid>
      </Grid>
      {/* <ErrorPage
                hideAction
                title="Oops No Jobs.."
                description="Please add new jobs to view here"
                actionText={"Return To Home Page"}
                action={() => { 
                animationData={emptyPage}
            /> */}
    </Box>
  );
};
export default Jobs;

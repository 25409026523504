/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-key */
import { InputBase, Stack } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Fuse from "fuse.js";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import _ from "underscore";
import Hide from "../../../../components/Hide";
import {
  capitalizeWord,
  getCity,
  getCompanyIndustry,
  getCountry,
  getEducationDegree,
  getGovernorate,
  getGrade,
  getJobCategory,
  getNationality,
  getSalaryType,
  getSeniorityLevel,
  getWorkType, searchApplicant,
  searchJob,
  searchRecruiter,
  translateField
} from "../../../../services/Helper";
import { getHiringTime } from './../../../../services/Helper';
import CheckText from "./../checkbox-with-text/CheckText";
import "./Filter.scss";
import OneFilter from "./one-filter/OneFilter";
const Filter = ({ setData, page, setNumberOfPages, setReady }) => {
  const [seniorityLevels, setSeniorityLevels] = useState([]);
  const [nationalities, setNationalities] = useState([]);
  const [nationalitiesSearch, setNationalitiesSearch] = useState([]);
  const [countries, setCountries] = useState([]);
  const [countriesSearch, setCountriesSearch] = useState([]);
  const [governorates, setGovernorates] = useState([]);
  const [governoratesSearch, setGovernoratesSearch] = useState([]);
  const [cities, setCities] = useState([]);
  const [citiesSearch, setCitiesSearch] = useState([]);
  const [degrees, setDegrees] = useState([]);
  const [grades, setGrades] = useState([]);
  const [jobTypes, setJobTypes] = useState([]);
  const [salaries, setSalaries] = useState([]);
  const [hiringTime, setHiringTime] = useState([])
  const [jobIndustries, setJobIndustries] = useState([]);
  const [jobIndustriesSearch, setJobIndustriesSearch] = useState([]);
  const [companyIndustries, setCompanyIndustries] = useState([]);
  const [companyIndustriesSearch, setCompanyIndustriesSearch] = useState([]);
  const [filterTypeStatus, setFilterTypeStatus] = useState(false);
  const { t, i18n } = useTranslation();
  //const storedFilters = useSelector((state) => state.filter);
  const {
    control,
    getValues,
    reset,
    setFocus,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    function fetchData() {
      getEducationDegree().then((degrees) => {
        setDegrees(degrees);
        updateFilterOptions("degrees", degrees);
      });
      getGrade().then((grades) => {
        setGrades(grades);
        updateFilterOptions("grades", grades);
      });
      getNationality().then((nationalities) => {
        setNationalities(nationalities);
        setNationalitiesSearch(nationalities);
        updateFilterOptions("nationalities", nationalities);
      });
      getSeniorityLevel().then((seniorityLevels) => {
        setSeniorityLevels(seniorityLevels);
        updateFilterOptions("seniorityLevels", seniorityLevels);
        updateFilterOptions("careerLevels", seniorityLevels);
      });
      getWorkType().then((jobTypes) => {
        setJobTypes(jobTypes);
        updateFilterOptions("jobTypes", jobTypes);
      });
      getSalaryType().then((salaries) => {
        setSalaries(salaries);
        updateFilterOptions("salaries", salaries);
      });
      getJobCategory().then((jobIndustries) => {
        setJobIndustries(jobIndustries);
        setJobIndustriesSearch(jobIndustries);
        updateFilterOptions("jobIndustries", jobIndustries);
      });
      getCompanyIndustry().then((companyIndustries) => {
        setCompanyIndustries(companyIndustries);
        setCompanyIndustriesSearch(companyIndustries);
        updateFilterOptions("companyIndustries", companyIndustries);
      });
      getCountry().then((countries) => {
        setCountries(countries);
        setCountriesSearch(countries);
        updateFilterOptions("countries", countries);
      });

      getHiringTime().then((data) => {
        setHiringTime(data)
        updateFilterOptions("hiring_time", data);
      })
    }

    fetchData();
  }, []);
  const updateFilterOptions = (filterName, options) => {
    let filtersTemp = [...filters];
    let filterIndex = _.findLastIndex(filtersTemp, {
      id: filterName,
    });

    if (filtersTemp[filterIndex]) {
      filtersTemp[filterIndex].options = options;
    }
    setFilters(filtersTemp);
  };
  const fuzzySearch = (arr, term) => {
    let currentLang = i18n.language.split("-")[0];
    const fuse = new Fuse(arr, {
      keys: [`name_${currentLang}`],
    });
    let result = fuse.search(term);
    let filtered_result = result?.length > 0 ? _.pluck(result, "item") : arr;
    return filtered_result;
  };
  const searchFilter = (type, filter) => {
    let result = [];
    switch (type) {
      case "nationalities":
        result = fuzzySearch(nationalities, filter.target.value);
        setNationalitiesSearch(result);
        break;
      case "countries":
        result = fuzzySearch(countries, filter.target.value);
        setCountriesSearch(result);
        break;
      case "governorates":
        result = fuzzySearch(governorates, filter.target.value);
        setGovernoratesSearch(result);
        break;
      case "cities":
        result = fuzzySearch(cities, filter.target.value);
        setCitiesSearch(result);
        break;
      case "jobIndustries":
        result = fuzzySearch(jobIndustries, filter.target.value);
        setJobIndustriesSearch(result);
        break;
      case "companyIndustries":
        result = fuzzySearch(companyIndustries, filter.target.value);
        setCompanyIndustriesSearch(result);
        break;
      default:
        break;
    }

    let filtersTemp = [...filters];
    let filterIndex = _.findLastIndex(filtersTemp, {
      id: type,
    });

    if (filtersTemp[filterIndex]) {
      filtersTemp[filterIndex].options = result;
    }

    setFilters(filtersTemp);
  };
  const [filters, setFilters] = useState([
    {
      label: "seneority_level_label",
      options: seniorityLevels,
      actionBtns: true,
      id: "seniorityLevels",
      filter_type: ["people"],
    },

    {
      label: "nationality_placeholder",
      searchInput: "nationality_placeholder",
      options: nationalitiesSearch,
      actionBtns: true,
      id: "nationalities",
      filter_type: ["people"],
    },
    {
      label: "degree_field",
      options: degrees,
      actionBtns: true,
      id: "degrees",
      filter_type: ["people"],
    },
    {
      label: "grade_field",
      options: grades,
      actionBtns: true,
      id: "grades",
      filter_type: ["people"],
    },
    {
      label: "career_level",
      options: seniorityLevels,
      actionBtns: true,
      id: "careerLevels",
      filter_type: ["jobs"],
    },
    {
      label: "job_type",
      options: jobTypes,
      actionBtns: true,
      id: "jobTypes",
      filter_type: ["jobs"],
    },
    {
      label: "industry",
      searchInput: "industry",
      options: companyIndustriesSearch,
      actionBtns: true,
      id: "companyIndustries",
      filter_type: ["companies"],
    },
    {
      label: "country",
      searchInput: "country",
      options: countriesSearch,
      actionBtns: true,
      id: "countries",
      filter_type: ["jobs", "people", "companies"],
    },
    {
      label: "governrate",
      searchInput: "governrate",
      options: governoratesSearch,
      actionBtns: true,
      id: "governorates",
      filter_type: ["jobs", "people", "companies"],
    },
    {
      label: "city",
      searchInput: "city",
      options: citiesSearch,
      actionBtns: true,
      id: "cities",
      filter_type: ["jobs", "people", "companies"],
    },
    {
      label: "job_industry",
      searchInput: "job_industry",
      options: jobIndustriesSearch,
      actionBtns: true,
      id: "jobIndustries",
      filter_type: ["jobs"],
    },
    {
      label: "salary",
      options: salaries,
      searchInput: ["min", "max"],
      actionBtns: true,
      id: "salaries",
      filter_type: ["jobs"],
    },
    {
      label: "hiring_time",
      options: hiringTime,
      actionBtns: true,
      id: "hiring_time",
      filter_type: ["jobs"],
    },
  ]);
  const dispatch = useDispatch();
  const handleSearchTypeChange = (e) => {
    dispatch({ type: e.target.value.toUpperCase() });
    setFilterTypeStatus(true);

    const url = new URL(window.location);
    url.searchParams.set("type", e.target.value);
    window.history.pushState(null, "", url.toString());
  };
  const filterType = useSelector((state) => state.filter.activeFilter);
  const activeFilter = useSelector((state) => state?.filter?.activeFilter);
  const filtersData = useSelector((state) => state.filter);
  const role = useSelector((state) => state?.profile?.role?.name);
  useEffect(() => {
    // if (filtersData.search.trim().length > 0) {
    //scrollIntoView("searching-page-top");
    console.log(
      "FILTERS DATA CHANGE",
      activeFilter,
      filtersData.activeFilter,
      filtersData
    );
    setReady(false);
    const url = new URL(window.location);
    Array.from(Object.entries(filtersData)).forEach(([id, value]) => {
      //
      if (value) {
        if (id === "activeFilter") {
          url.searchParams.set("type", value);
        } else {
          if (Array.isArray(value)) {
            if (value?.length > 0) {
              url.searchParams.set(id, value);
            }
          } else {
            url.searchParams.set(id, value);
          }
        }
      }
      if (Array.isArray(value)) {
        let newValue = [...value];
        value.forEach((element) => {
          if (element !== undefined) {
            newValue[element] = true;
          }
        });
        setValue(id, newValue);
      } else {
        setValue(id, value);
      }
    });
    window.history.pushState(null, "", url.toString());
    switch (filtersData.activeFilter) {
      case "people":
        searchApplicant(filtersData, page, role).then((data) => {
          setData(data.rows ? data.rows : []);
          setNumberOfPages(data.meta ? data.meta.totalNumberOfPages : 0);
          setReady(true);
        });
        break;
      case "jobs":
        searchJob(filtersData, page, role).then((data) => {
          setData(data.rows ? data.rows : []);
          setNumberOfPages(data.meta ? data.meta.totalNumberOfPages : 0);
          setReady(true);
        });
        break;
      case "companies":
        searchRecruiter(filtersData, page, role).then((data) => {
          setData(data.rows ? data.rows : []);
          setNumberOfPages(data.meta ? data.meta.totalNumberOfPages : 0);
          setReady(true);
        });
        break;
      default:
        window.location = "/404";
        break;
    }
    // }
  }, [filtersData, page]);
  useEffect(() => {
    const getGovernorates = async () => {
      let governorates = [];

      if (filtersData?.countries?.length > 0) {
        filtersData?.countries?.map((value) => {
          getGovernorate(value).then((govs) => {
            governorates.push(...govs);
          });
          // governorates.push(...(await ));
        });

        setGovernorates(governorates);
        setGovernoratesSearch(governorates);
        updateFilterOptions("governorates", governorates);
      }
    };
    getGovernorates();
  }, [filtersData?.countries?.length]);
  useEffect(() => {
    const getCities = async () => {
      let cities = [];
      if (filtersData?.governorates?.length > 0) {
        filtersData.governorates.map(async (value) => {
          cities.push(...(await getCity(value)));
        });

        setCities(cities);
        setCitiesSearch(cities);
        updateFilterOptions("cities", cities);
      }
    };
    getCities();
  }, [filtersData?.governorates?.length]);
  useEffect(() => {
    if (role == "RECRUITER") dispatch({ type: "PEOPLE" });
  }, [role]);
  useEffect(() => {
    setFilters([...filters]);
  }, [activeFilter]);
  const checkDisabled = (id) => {
    if (id === "governorates" && filtersData?.countries?.length <= 0) {
      return true;
    } else if (id === "cities" && filtersData?.governorates?.length <= 0) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <Stack direction={"row"} spacing={1}>
      <Hide condition={role == "RECRUITER"}>
        <OneFilter
          label={t(`filter_${capitalizeWord(filterType)}`)}
          active={true}
          controlCloseMenu
          isRadioBtns={true}
        >
          <FormControl>
            <RadioGroup
              defaultValue={filterType}
              name="radio-buttons-group"
              onChange={handleSearchTypeChange}
            >
              <FormControlLabel
                sx={{ marginLeft: "unset", marginRight: "unset" }}
                value={"people"}
                control={
                  <Radio
                    size="medium"
                    className="radio-btn-dark"
                    sx={{ paddingY: "2px", paddingX: "5px" }}
                  />
                }
                label={t("radio_filter_people")}
                labelPlacement={"end"}
              />
              <FormControlLabel
                sx={{ marginLeft: "unset", marginRight: "unset" }}
                value={"jobs"}
                control={
                  <Radio
                    size="medium"
                    className="radio-btn-dark"
                    sx={{ paddingY: "2px", paddingX: "5px" }}
                  />
                }
                label={t("radio_filter_jobs")}
                labelPlacement={"end"}
              />
              <FormControlLabel
                sx={{ marginLeft: "unset", marginRight: "unset" }}
                value={"companies"}
                control={
                  <Radio
                    size="medium"
                    className="radio-btn-dark"
                    sx={{ paddingY: "2px", paddingX: "5px" }}
                  />
                }
                label={t("radio_filter_companies")}
                labelPlacement={"end"}
              />
            </RadioGroup>
          </FormControl>
        </OneFilter>
      </Hide>
      {filters.map((filter) => {
        return filter.filter_type.includes(activeFilter) ? (
          <OneFilter
            label={t(filter.label)}
            showActionBtns={filter.actionBtns}
            disabled={checkDisabled(filter.id)}
            control={getValues}
            id={filter.id}
            reset={reset}
            isRadioBtns={false}
            active={filtersData?.[filter.id]?.length > 0}
          >
            {Array.isArray(filter.searchInput) ? (
              <>
                {filter.searchInput.map((searchInput) => {
                  return (
                    <Controller
                      control={control}
                      name={searchInput.split(".")[1]}
                      render={({ field }) => {
                        return (
                          <InputBase
                            mt={1}
                            mb={1}
                            className="responsive-filter-search-input"
                            type="text"
                            placeholder={
                              t(searchInput)
                              // searchInput.charAt(0).toUpperCase() +
                              // searchInput.slice(1)
                            }
                            //autoFocus
                            value={field.value}
                            onChange={(e) => {
                              //searchFilter(searchInput, e);
                              field.onChange(e);
                              setFocus("max");
                            }}
                          />
                        );
                      }}
                    />
                  );
                })}
              </>
            ) : (
              filter.searchInput && (
                <Controller
                  control={control}
                  name={`${filter.id}_search`}
                  render={({ field }) => {
                    return (
                      <InputBase
                        className="one-filter-search-input"
                        autoFocus
                        type="text"
                        value={field.value}
                        placeholder={t(filter.searchInput)}
                        onChange={(e) => {
                          searchFilter(filter.id, e);
                          field.onChange(e);
                          setFocus(`${filter.id}_search`);
                        }}
                      />
                    );
                  }}
                />
              )
            )}
            <Stack>
              {filter?.options?.map((option, index) => (
                <Controller
                  control={control}
                  name={`${filter.id}.${option.id}`}
                  render={({ field }) => {
                    return (
                      <CheckText
                        name={filter.id}
                        text={translateField(option, "name")}
                        field={field}
                        value={field.value}
                        id={option.id}
                        innnerOnChange={field.onChange}
                      />
                    );
                  }}
                />
              ))}
            </Stack>
          </OneFilter>
        ) : (
          <></>
        );
      })}
    </Stack>
  );
};

export default Filter; 
import { Box, CardMedia, Paper, Stack, Typography, Grid } from "@mui/material";
import { Buildings, Checks, MapPin, User, UsersThree } from "phosphor-react";
import { useState } from "react";
import Show from "../../../../../components/Show";
import EnhancedButton from "../../../yahya/components/enhanced-button/EnhancedButton";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CompanyTag from "../../../../../components/company-tag/CompanyTag";
import { changeCompanyStatus } from "../../../../../services/Helper";
import "./CompanyCard.css";

// const CompanyTag = ({ icon, text }) => {
//   return (
//     <Stack direction="row" className="company-tag" alignItems={"center"} spacing={0.5}>
//       <Stack>{icon}</Stack>
//       <Stack sx={{ whiteSpace: "nowrap" }}>{text}</Stack>
//     </Stack>
//   );
// };

const CompanyCard = ({
  image,
  companyName,
  companyIndustry,
  companyFollowers,
  companyUser,
  companyLocation,
  companyId,
  onClick,
  selected,
  redirect,
  hovered,
  ltrChangesInSlider,
  isFollowed,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const role = useSelector((state) => state.profile.role);

  const isLoggedIn = useSelector((state) => state.profile.loginCompleted);

  const [hover, setHover] = useState(false);
  const [followed, setFollowed] = useState(isFollowed);

  const handleClick = () => {
    onClick();
  };

  const followCompany = (e) => {
    e.stopPropagation();
    if (isLoggedIn) {
      changeCompanyStatus(companyId, role);
      setFollowed(true);
      console.log("Following");
    } else {
      dispatch({ type: "OPEN_LOGIN_MODAL" });
    }
  };
  const navigateToCompany = () => {
    navigate(`company/${companyId}`);
  };
  return (
    <Paper
      className={hovered ? "company-card hovered" : "company-card"}
      elevation={0}
      // elevation={hover ? 3 : 0}
      // onMouseOver={() => setHover(true)}
      // onMouseOut={() => setHover(false)}
      onClick={handleClick}
      sx={{
        boxShadow: !redirect && selected ? "0px 0px 30px #0000001A" : "",
      }}
    >
      <Box
      // onClick={navigateToCompany}
      >
        <Show condition={image}>
          <CardMedia component="img" width="60" height="60" image={image} />
        </Show>
        <Show condition={!image}>
          <Buildings size={60} />
        </Show>
        <Stack sx={{ mt: "5px", mb: "4px" }}>
          <Typography className="company-name">{companyName}</Typography>
        </Stack>
        <Typography className="company-industry-info">
          {companyIndustry}
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent={"center"}
          spacing={1}
          className={ltrChangesInSlider ? "slider-rtl-changes" : ""}
        >
          <Grid item>
            <Stack
              direction="row"
              alignItems={"center"}
              className={ltrChangesInSlider ? "slider-rtl-changes" : ""}
            >
              <Stack className="company-card-icon">
                <User width={"16px"} height={"16px"} />
              </Stack>
              <Stack>
                <Typography className="company-industry-info">
                  {companyFollowers}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item>
            <Stack
              direction="row"
              alignItems={"center"}
              className={ltrChangesInSlider ? "slider-rtl-changes" : ""}
            >
              <Stack className="company-card-icon">
                <UsersThree width={"16px"} height={"16px"} />
              </Stack>
              <Stack>
                <Typography className="company-industry-info">
                  {companyUser}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <Stack
          direction="row"
          className="company-chips"
          justifyContent={"center"}
          sx={{ mt: "8px" }}
          spacing={2}
        >
          <Stack>
            <CompanyTag
              icon={<MapPin />}
              text={companyLocation}
              ltrChangesInSlider
            />
          </Stack>
        </Stack>
      </Box>
      <Stack sx={{ marginTop: "19px" }} direction="column">
        {followed ? (
          <>
            <Stack direction={i18n.language === "en" ? "row" : "row-reverse"} spacing={1} justifyContent={"center"} sx={{ paddingY: "10px" }}>
              <Checks size={20} color={"#3A3B81"} weight={"fill"} />
              <Typography
                color={"#3A3B81"}
                sx={{ font: "normal normal 600 16px/19px Urbanist" }}
              >
                {t("followed")}
              </Typography>
            </Stack>
          </>
        ) : (
          <EnhancedButton
            buttonText={t("follow")}
            variant={"contained"}
            padding={"10px 0"}
            onClick={followCompany}
          />
        )}
      </Stack>

      {/* <Stack sx={{ marginTop: "19px" }}>
        <EnhancedButton
          buttonText={t("follow")}
          variant={"contained"}
          padding={"10px 0"}
          onClick={followCompany}
        />
      </Stack> */}
    </Paper>
  );
};

export default CompanyCard;

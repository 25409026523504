/* eslint-disable react/jsx-key */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { Box, Grid, Typography, useMediaQuery } from '@mui/material'

import moment from "moment"
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ProfileInformation from '../../../../components/profile-information/ProfileInformation'
import RatingBox from '../../../../components/rating-box/RatingBox'
import Show from '../../../../components/Show'
import Permissions from '../../../../constants/permissions'
import { perm, translateField } from '../../../../services/Helper'
import EditProfile from '../edit-profile/EditProfile'
import ProfileExperience from '../profile-experience/ProfileExperience'


const ProfileContent = ({ userData, hideActionBtns, showAddAndInviteProfile, cvBank, fetchTrigger, setFetchTrigger }) => {


    const [isOwnProfile, setIsOwnProfile] = useState(false);

    const matched = useMediaQuery("(min-width:900px)")
    const permissions = useSelector((state) => state.profile.role?.permissions);
    const user_id = useSelector((state) => state.profile.data?.cv?.User?.id);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        setIsOwnProfile(user_id === userData?.User?.id)
    }, [userData])
    useEffect(() => {

    }, [userData.User?.cv_bank])
    return (
        <Grid container wrap="wrap" marginTop={"24px"}>
            {/* <Show condition={matched && !hideActionBtns}>
                <Show condition={isOwnProfile && perm(permissions, Permissions.editApplicantProfile)}>
                    <EditProfile />
                </Show>
            </Show> */}
            <Grid item xs={12}>
                <ProfileInformation
                    showTitle
                    title={t("bio")}
                >
                    <Typography className="profile-exp-text">
                        {userData?.User?.bio}
                    </Typography>
                </ProfileInformation>
                {userData?.User?.CvExperiences?.map((exp, index) => {
                    return <ProfileInformation
                        showTitle={index === 0 ? true : false}
                        title={t("work_experience")}
                    >
                        <ProfileExperience
                            startDate={`${moment().month(exp?.from_month - 1).format("M")} ${exp?.from_year}`}
                            from_year={exp?.from_year}
                            to_year={exp?.to_year}
                            from_month={moment().month(exp?.from_month - 1).format("M")}
                            to_month={moment().month(exp?.to_month - 1).format("M")}
                            endDate={exp?.to_year ? `${moment().month(exp?.to_month - 1).format("M")} ${exp?.to_year}` : "Present"}
                            expTitle={exp?.JobTitle ? translateField(exp?.JobTitle, "name") : exp?.job_title}
                            expPlace={exp?.company_name}
                            companyType={translateField(exp?.JobCategory, "name")}
                            workStyle={translateField(exp?.ExperienceType, "name")}
                            expInfo={exp?.description}
                            careerLevel={translateField(exp?.CareerLevel, "name")}
                        />
                    </ProfileInformation>
                })}
                {userData?.User?.CvEducations?.map((edu, index) => {
                    return <ProfileInformation
                        showTitle={index == 0}
                        title={t("education")}
                    >
                        <ProfileExperience
                            education
                            startDate={`${moment().month(edu?.from_month - 1).format("M")} ${edu?.from_year}`}
                            from_year={edu?.from_year}
                            to_year={edu?.to_year}
                            from_month={moment().month(edu?.from_month - 1).format("M")}
                            to_month={moment().month(edu?.to_month - 1).format("M")}
                            endDate={edu?.to_year ? `${moment().month(edu?.to_month - 1).format("M")} ${edu?.to_year}` : "Present"}
                            expTitle={translateField(edu?.EducationDegree, "name")}
                            expType={edu?.study_field}
                            expPlace={edu?.university_name}
                            degree={translateField(edu?.Grade, "name")}
                        />
                    </ProfileInformation>
                })}
            </Grid>
            <Box sx={{
                display: "flex",
                flexWrap: "wrap",
                rowGap: "1rem",
                columnGap: "1rem"
            }}
            >
                <Typography component="span" className="applicant-profile-title" mt={2} mb={2} >{t("skills")}</Typography>
                {userData?.User?.CvCareerSkills?.map((skill) => {
                    return <RatingBox
                        title={skill?.name}
                        value={skill?.proficiency}
                        yearsOfExp={translateField(skill?.YearsOfExperience, "name")}
                    />
                })}
            </Box>
            <Typography className="applicant-profile-title" mt={2} mb={2} >{t("languages")}</Typography>
            <Box
                sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    rowGap: "1rem",
                    columnGap: "1rem"
                }}
            >
                {userData?.User?.CvLanguageSkills?.map((lang) => {
                    return <RatingBox
                        title={lang?.name}
                        value={lang?.proficiency}
                    />
                })}
            </Box>
        </Grid>
    )
}

export default ProfileContent
import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from '../../../assets/Logo2SVG';
import { requestForToken } from "../../../firebase";
import { fetchMyProfile } from "../../../services/Helper";
import { completeB2cLogin } from "../../../state/actions/b2c/b2c";
import { assignRole } from "../../../state/actions/profile/profile";
import { FETCH_PROFILE } from "../../../state/actions/profile/types";
import "./Redirecting.css";

const Redirecting = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const b2cLogin = useSelector((state) => state.b2cLogin);
    const isCvCreated = useSelector((state) => state.profile?.data?.cv?.User?.is_cv_created);
    const isCompanyCreated = useSelector((state) => state.profile?.data?.recruiter?.is_company_created);

    const notCompleted = (isCvCreated == 0 || isCompanyCreated == 0)

    useEffect(() => {
        const fetchAll = async () => {
            let result = null;
            result = requestForToken();

            const data = await fetchMyProfile();

            dispatch({ type: FETCH_PROFILE, payload: data });
            const roles = data?.role;
            if (roles?.length == 0) {
                navigate('/');
            } else if (roles?.length == 1) {
                dispatch(assignRole(roles[0]));
                navigate(roles[0]?.redirect, { state: { switched: true } });
            } else if (roles?.length > 1) {
                const default_role = data?.azure_account?.default_role;
                const default_role_object = roles.find((item) => item.name === default_role);
                dispatch(assignRole(default_role_object));
                navigate(roles[0]?.redirect, { state: { switched: true } });
            }
        }

        if (b2cLogin.finished) {
            fetchAll().then((res) => {
                dispatch(completeB2cLogin())
            })
        }

    }, [b2cLogin.loading, b2cLogin.finished])

    return (
        <>
            <Box className="redirect-page">
                <Box className="animation">
                    {/* <ColoredLogo fontSize={"60px"} /> */}
                    <Logo />
                    <Typography style={{ textAlign: "center" }} marginTop={2} >
                        Redirecting ...
                    </Typography>
                </Box>
            </Box>
        </>
    )
}
export default Redirecting;
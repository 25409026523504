import { Box, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchMyProfile } from "../../services/Helper";
import ApplicantProfile from "../applicant-profile/ApplicantProfile";

const ApplicantProfilePage = () => {
  const permissions = useSelector((state) => state.profile.role?.permissions);
  const role = useSelector((state) => state.profile.role?.name);
  const [userData, setUserData] = useState();
  const mdMatches = useMediaQuery("(min-width:701px) and (max-width:1300px)");
  const smMatches = useMediaQuery("(max-width:700px)");

  useEffect(() => {
    fetchMyProfile(role).then((response) => {
      console.log("fetchMyProfile", response);
      let result = { ...response.cv, ...response.cv.User };
      setUserData(result);
    });
  }, []);

  return (
    <Box >
      <ApplicantProfile userData={userData} />
    </Box>
  );
};

export default ApplicantProfilePage;

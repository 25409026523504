import { Box, Button, Divider, Grid, IconButton, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { BookmarkSimple, Checks, Circle, PencilSimple, ShareNetwork, Trash } from 'phosphor-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Modals from '../../components/modals/Modals';
import Permissions from '../../constants/permissions';
import { applyJob, changeCompanyStatus, deleteJob, perm, permOr, saveJob, translateField } from '../../services/Helper';
import EnhancedShareJobPost from '../../views/job-details/enhanced-share-job-post/EnhancedShareJobPost';
import EnhancedJobTag from '../../views/pages/abdelrahman/EnhancedJobTag/EnhancedJobTag';
import EnhancedButton from '../../views/pages/yahya/components/enhanced-button/EnhancedButton';
import TitlesAndDescreption from '.././titles-and-descreption/TitlesAndDescreption';
import ApplicationStatus from '../application-status/ApplicationStatus';
import Hide from '../Hide';
import Show from '../Show';
import useShareJobPost from './../../views/job-details/enhanced-share-job-post/useShareJobPost';
import FollowedCard from './components/FollowedCard';
import './JobDetailsCard.scss';
import { useDispatch, useSelector } from 'react-redux';

const JobDetailsCard = ({
  external_apply_website,
  hiringTime,
  jobId,
  status,
  jobTitle,
  experienceType,
  date,
  location,
  careerLevel,
  yearsOfEx,
  jobCategory,
  slaryRange,
  currency,
  jobDescreption,
  jobRequirement,
  showStatus,
  workStyle,
  showBottomShare,
  showJobStatusInBrowsingAppliedPage,
  companyName,
  companyLogo,
  followed,
  companySize,
  type,
  isApplied,
  isSaved,
  noOfApplicants,
  ApplicantApplyJobs,
  //followHandler,
  jobStatus,
  viewDetails,
  recruiterId,
  fetchJob,
  stickyApply,
}) => {
  console.log(external_apply_website);
  const { t } = useTranslation();
  const urlLocation = useLocation();
  console.log('URLL', window.location.href);
  const { open, setOpen } = useShareJobPost();
  const navigate = useNavigate();
  const params = useParams();
  // const [is_followed, setIsFollowed] = useState(followed);
  const [apply, setApply] = useState(true);

  console.log('apply', apply);
  const [save, setSave] = useState(isSaved);
  // const [jobStatus, setJobStatus] = useState()
  const [openShare, setOpenShare] = useState(false);
  const [openDeleteModals, setOpenDeleteModals] = useState(false);
  const [openApplyModals, setOpenApplyModals] = useState(false);
  const [loading, setLoading] = useState(false);

  const [containerEnd, setContainerEnd] = useState();

  const toggleDivider = useMediaQuery('(max-width:890px)');
  const matches = useMediaQuery('(max-width:900px)');
  const mdMatches = useMediaQuery('(min-width:701px) and (max-width:1300px)');

  const actionBtnsMatches = useMediaQuery('(min-width:1200px) and (max-width:1536px)');
  const permissions = useSelector((state) => state.profile.role?.permissions);
  const role = useSelector((state) => state.profile.role?.name);

  const isLoggedIn = useSelector((state) => state.profile.loginCompleted);

  const dispatch = useDispatch();

  useEffect(() => {
    setApply(isApplied);
    setSave(isSaved);
    // if (ApplicantApplyJobs?.length > 0) {
    //   setJobStatus(ApplicantApplyJobs[0].JobStatus);
    // }
  }, [jobId]);

  const followHandler = () => {
    if (!loading) {
      setLoading(true);
      changeCompanyStatus(recruiterId, role)
        .then(async (res) => {
          await fetchJob?.(jobId);
          // setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  console.log(hiringTime);

  const toggleApply = (e) => {
    e.stopPropagation();
    if (isLoggedIn) {
      applyJob(jobId, role);
      setApply(false);
    } else {
      dispatch({ type: 'OPEN_LOGIN_MODAL' });
    }
  };

  const getDaysAgo = (date) => {
    const date1 = new Date(date);
    const date2 = new Date();
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };
  const daysAgo = getDaysAgo(date);

  const handleClickApply = async () => {
    if (external_apply_website) {
      window.open(external_apply_website);
      return;
    }
    setLoading(true);
    const data = await applyJob(jobId, role);
    setLoading(false);
    setApply(data?.is_applied);
  };
  const handleClickSave = async () => {
    setLoading(true);
    const data = await saveJob(jobId, role);
    setLoading(false);
    setSave(data?.is_saved);
  };

  //share dialog
  const handleClickOpenShare = () => {
    setOpen(true);
    console.log('sharing', open);
  };

  const handleCloseShare = () => {
    setOpen(false);
  };
  //Modals
  const handleClickOpenDeleteModals = () => {
    setOpenDeleteModals(true);
  };

  const handleCloseDeleteModals = (value) => {
    setOpenDeleteModals(false);
  };
  const handleClickOpenApplyModals = () => {
    if (!apply) {
      if (perm(permissions, Permissions.viewGuestBanner)) {
        handleClickApply();
      } else setOpenApplyModals(true);
    }
  };

  const handleCloseApplyModals = (value) => {
    setOpenApplyModals(false);
  };

  const viewDetailsClickHandler = () => {
    navigate(`/jobs/${jobId}`);
  };
  useEffect(() => {
    const jobDetailsCardContainer = document.getElementById('job-details-card');
    console.log({
      jobDetailsCardContainer,
      actionBtnsMatches,
    });

    setContainerEnd(jobDetailsCardContainer.clientLeft + (actionBtnsMatches ? 32 : 0));
  }, [document.getElementById('job-details-card')?.clientLeft]);
  return (
    <Box sx={{ padding: matches ? '1rem' : ' 2.125rem 2rem 2rem 2rem' }} className="details-card" id={'job-details-card'}>
      <Grid container direction="row">
        <Grid container item xs={7}>
          <Grid item>
            <Show condition={perm(permissions, Permissions.editJob)}>
              <Circle className={status ? 'active-icon' : 'inactive-icon'} />
            </Show>
          </Grid>
          <Grid item>
            <Typography className="job-name">{jobTitle}</Typography>
            <Grid item>
              <Box className="publish-time" component="div">
                {daysAgo === 0 ? t('today') : t('days', { count: daysAgo })}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Hide condition={viewDetails}>
          <Grid item container justifyContent="flex-end" xs={5}>
            <Show condition={perm(permissions, Permissions.editJob)}>
              <IconButton className="icons edit-icon" onClick={() => navigate(`/jobs/${jobId}/edit`)}>
                <PencilSimple />
              </IconButton>
            </Show>
            <Show condition={perm(permissions, Permissions.deleteJob)}>
              <IconButton className="icons del-icon" onClick={handleClickOpenDeleteModals}>
                <Trash />
              </IconButton>
            </Show>
            <Hide condition={showBottomShare}>
              <IconButton className="icons" onClick={handleClickOpenShare}>
                <ShareNetwork />
              </IconButton>
            </Hide>
          </Grid>
        </Hide>
        <Show condition={viewDetails}>
          <Grid item container xs={5} justifyContent="flex-end" sx={{ maxHeight: '40px' }}>
            <EnhancedButton onClick={viewDetailsClickHandler} buttonText={t('view-full-details')} padding={'10px 20px'} />
          </Grid>
        </Show>
      </Grid>
      {/*from yahya , chips  */}
      <Grid container direction="row" sx={{ marginTop: '1.0625rem' }} spacing={2}>
        <Grid item>
          <EnhancedJobTag tag={experienceType} />
        </Grid>
        <Grid item>
          <EnhancedJobTag tag={workStyle} />
        </Grid>
        <Grid item>
          <EnhancedJobTag tag={careerLevel} />
        </Grid>
        <Grid item>
          <EnhancedJobTag tag={t('applicants', { count: noOfApplicants })} bold />
        </Grid>
      </Grid>
      {/*  */}
      <Hide condition={permOr(permissions, Permissions.createJob, Permissions.editJob)}>
        <Box>
          <FollowedCard
            recruiterId={recruiterId}
            followed={followed}
            avatar={companyLogo}
            name={companyName}
            onFollow={followHandler}
            companySize={companySize}
            loading={loading}
            showUserThreeicon
          />
        </Box>
      </Hide>
      {/*  */}
      <Grid container direction="row">
        {/* first grid in row */}
        <Grid item xs={12} md={5} container direction="column">
          <Grid item>
            <Grid item>
              <TitlesAndDescreption title={t('job_category')} descreption={jobCategory} />
            </Grid>
            <TitlesAndDescreption title={t('location')} descreption={location} />
          </Grid>
          <Grid item>
            <TitlesAndDescreption title={t('years_of_experience')} descreption={yearsOfEx} />
          </Grid>
        </Grid>
        {/* divider  */}
        <Divider
          orientation="vertical"
          flexItem
          className="divider"
          sx={{
            marginRight: '2rem',
            display: toggleDivider ? 'none' : 'block',
          }}
        />
        {/*second grid in row  */}
        <Grid item xs={12} md={6} container direction="column">
          <Grid item>
            <TitlesAndDescreption title={t('hiring_time')} descreption={translateField(hiringTime, 'name')} />
          </Grid>
          <Grid item>
            <TitlesAndDescreption title={t('salary_range')} descreption={`${slaryRange}${currency ? ` ${currency}` : ''}`} />
          </Grid>
        </Grid>
        {/* ending of row grid*/}
      </Grid>
      {/*  */}
      <Grid item xs={12}>
        <TitlesAndDescreption title={t('job_desc')} descreption={jobDescreption} />
      </Grid>
      <Grid xs={12}>
        <TitlesAndDescreption title={t('job_requirments')} descreption={jobRequirement} />
      </Grid>
      {/* ------------------------------ browsing Pages------------------------------  */}
      <Grid>
        <Show condition={showStatus && urlLocation.pathname === '/applied-jobs'}>
          <Grid item xs={6}>
            <Box className="status-title" component="div">
              {t('status')}
            </Box>
            <Box component="div">
              <ApplicationStatus status={jobStatus} />
            </Box>
          </Grid>
        </Show>
      </Grid>

      <Hide condition={false}>
        <Grid
          container
          direction="row"
          className="browsing-page-footer"
          justifyContent="flex-end"
          sx={
            stickyApply && {
              position: 'absolute',
              right: `${containerEnd-23}px`,
              bottom: '-130px',
              zIndex: 122,
            }
          }
        >
          <Grid item xs={7.8} container alignItems="center">
            <Show condition={showJobStatusInBrowsingAppliedPage}>
              <Grid item xs={6}>
                <ApplicationStatus status={jobStatus} />
              </Grid>
            </Show>
            <Grid item xs={showJobStatusInBrowsingAppliedPage ? 6 : 12} container justifyContent="flex-end">
              <Box className="puplished-and-saved">
                <Show condition={showBottomShare}>
                  <Box component="div">
                    <IconButton className="shared" onClick={() => handleClickOpenShare()} sx={{ backgroundColor: '#FFF' }}>
                      <ShareNetwork />
                    </IconButton>
                  </Box>
                </Show>
                <Show condition={permOr(permissions, Permissions.viewGuestBanner, Permissions.canSaveJob)}>
                  <Box component="div">
                    <IconButton className="save" onClick={handleClickSave} disabled={loading} sx={{ backgroundColor: '#FFF' }}>
                      <BookmarkSimple weight={save ? 'fill' : null} />
                    </IconButton>
                  </Box>
                </Show>
                <Show condition={permOr(permissions, Permissions.viewGuestBanner, Permissions.canApplyJob)}>
                  {apply ? (
                    <>
                      <Button onClick={toggleApply} className="cancel-button">
                        {t('cancel')}
                      </Button>
                      {/* <EnhancedButton
                      onClick={toggleApply}
                      className="cancel-button"
                      padding={"10px 110px"}
                      buttonText={t("cancel")}
                    /> */}
                    </>
                  ) : (
                    <EnhancedButton onClick={handleClickOpenApplyModals} className={apply ? 'applied' : 'not-applied'} padding={'10px 110px'} buttonText={t('apply')} />
                  )}
                </Show>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Hide>
      {/* ------------------------------ MODALS------------------------------ */}
      <EnhancedShareJobPost open={open} setOpen={setOpen} url={window.location.href} job_id={jobId} />
      <Modals
        open={openDeleteModals}
        handleClose={handleCloseDeleteModals}
        loading={loading}
        handleAgree={() => {
          setLoading(true);
          deleteJob(params.job_id)
            .then((res) => {
              navigate('/');
            })
            .finally(() => {
              setLoading(false);
            });
        }}
        title={t('sure_delete_job')}
        // content={t("if_you_delete_job")}
        subtitle={jobTitle}
        disagree={t('cancel')}
        agree={t('delete')}
        type="delete"
      />
      <Modals
        open={openApplyModals}
        handleClose={handleCloseApplyModals}
        loading={loading}
        handleAgree={!apply && handleClickApply}
        title={t('sure_apply_job')}
        content={t('if_you_apply_job')}
        disagree={t('cancel')}
        agree={t('yes_apply')}
        type="confirmation"
      />
    </Box>
  );
};

export default JobDetailsCard;

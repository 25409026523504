import { Grid, useMediaQuery } from '@mui/material';
import { t } from "i18next";
import { EyeClosed, Target } from 'phosphor-react';
import React from 'react';
import InformationCard from '../../../../../abdelrahman/InformationCard/InformationCard';



const data = [
    {
        number: <EyeClosed width={"39px"} height={"39px"} />,
        // image:EyeClosed,
        title: t('our_vission'),
        //description: "Facere eos accusamus facere. Magni quia amet nisi voluptatibus molestiae harum. Ratione eveniet adipisci nihil ipsum cumque. Nisi et omnis incidunt modi ipsam. Aperiam officiis corrupti maxime"
        description: t("landing_page_desc")
    },
    {
        number: <Target width={"39px"} height={"39px"} />,
        // image:EyeClosed,
        title: t('our_mission'),
        //description: "Facere eos accusamus facere. Magni quia amet nisi voluptatibus molestiae harum. Ratione eveniet adipisci nihil ipsum cumque. Nisi et omnis incidunt modi ipsam. Aperiam officiis corrupti maxime"
        description: t("landing_page_desc")

    },

]

const OurVissionMission = () => {
    const smMatch = useMediaQuery('(max-width:"890px")')
    return (
        <Grid
            container
            direction={"row"}
            // justifyContent={"space-between"}
            spacing={3}
            sx={{
                width: "100%",
                marginTop:"85px"
            }}
        >
            <Grid item xs={12} md={6} >
                <InformationCard
                    number={<EyeClosed width={"49px"} height={"49px"} />}
                    title={t('our_vission')}
                    description={t("our_vision_desc")}
                    bgGray
                    aboutSectionInfo
                />
            </Grid>
            <Grid item xs={12} md={6} >
                <InformationCard
                    number={<Target width={"49px"} height={"49px"} weight="fill" />}
                    title={t('our_mission')}
                    description={t("our_mission_desc")}
                    bgGray
                    aboutSectionInfo
                />
            </Grid>
        </Grid>
    )
}

export default OurVissionMission
import { Grid, IconButton, Typography } from '@mui/material';
import { Check, Plus } from 'phosphor-react';
import React from 'react';

const Category = ({ name, category_id, added, applicant_id, setCategories, categories }) => {
    const handleAddToCvBank = () => {
        setCategories((prev) => [...prev, category_id])
    }
    const handleRemoveFromCvBank = () => {
        let indexofCategory = categories?.indexOf(category_id)
        setCategories((prev) => {
            return prev?.filter((category, index) => {
                return (index !== indexofCategory)
            })
        });
    }
    return (
        <Grid container
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{ width: "100%", borderRadius: "8px", backgroundColor: added ? "#3A3B811A" : "white", padding: "9.5px 16px" }}
        >
            <Grid item xs={6}>
                <Typography>{name}</Typography>
            </Grid>
            <Grid item xs={1}>
                <IconButton onClick={added ? handleRemoveFromCvBank : handleAddToCvBank} sx={{ borderRadius: "50%", backgroundColor: added ? "#3A3B81" : "#9191911A" }}>
                    {added ? <Check color={"white"} size={24} /> : <Plus size={24} />}
                </IconButton>
            </Grid>
        </Grid>
    )
}

export default Category

/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import {
  Box, Button, ClickAwayListener, Divider, IconButton, Paper, Popper, Stack, Typography
} from "@mui/material";
import { t } from "i18next";
import { CaretDown } from "phosphor-react";
import { useEffect, useState } from "react";
import { getDocumentDirection } from "../../../../../services/Helper";
import "./OneFilter.scss";
import { useDispatch, useSelector } from "react-redux";

const OneFilter = ({
  label,
  children,
  active,
  showActionBtns,
  disabled,
  control,
  id,
  reset,
  controlCloseMenu,
  closed,
  setClosed,
  isRadioBtns,
  setFilterTypeStatus,
  applied,
}) => {
  const textDir = getDocumentDirection();
  const [anchorEl, setAnchorEl] = useState();
  const [menuState, setMenuState] = useState(false);
  const activeFilter = useSelector((state) => state?.filter?.activeFilter);
  const dispatch = useDispatch();

  useEffect(() => {
    setMenuState(false);
  }, [activeFilter]);

  const FilterDropDown = (props) => {
    const { anchorEl, open } = props;
    return (
      <ClickAwayListener
        onClickAway={() => {
          setMenuState(false);
          if (!isRadioBtns) {
            // setFilterTypeStatus(false)
          }
        }}
      >
        <Popper
          anchorEl={anchorEl}
          open={open}
          disableAutoFocus={true}
          placement={textDir === "left" ? "bottom-start" : "bottom-end"}
          sx={{
            zIndex: "4123",
          }}
          // style={{ minWidth: "300px" }}
        >
          <Paper className="one-filter-dropdown">
            <Stack divider={<Divider />} spacing={1}>
              <Box className="one-filter-dropdown-content">
                {props.children}
              </Box>
              {showActionBtns && (
                <Box>
                  <Stack direction={"row"} spacing={1}>
                    <Button
                      className="btn reset-filter"
                      onClick={() => {
                        const url = new URL(window.location);
                        if (id === "salaries") {
                          reset({ ...control(), max: "", min: "", [id]: [] });
                          dispatch({
                            type: `MAX_FILTER`,
                            payload: null,
                          });
                          dispatch({
                            type: `MIN_FILTER`,
                            payload: null,
                          });
                          url.searchParams.delete('max');
                          url.searchParams.delete('min');
                        } else if (id === "countries") {
                          reset({
                            ...control(),
                            ["governorates"]: [],
                            ["cities"]: [],
                            [id]: [],
                          });
                          dispatch({
                            type: `${"governorates".toUpperCase()}_FILTER`,
                            payload: [],
                          });
                          dispatch({
                            type: `${"cities".toUpperCase()}_FILTER`,
                            payload: [],
                          });
                          url.searchParams.delete('governorates');
                          url.searchParams.delete('cities');
                        } else if (id === "governorates") {
                          reset({ ...control(), ["cities"]: [], [id]: [] });
                          dispatch({
                            type: `${"cities".toUpperCase()}_FILTER`,
                            payload: [],
                          });
                          url.searchParams.delete('cities');
                        } else {
                          reset({ ...control(), [id]: [] });
                        }

                        dispatch({
                          type: `${id.toUpperCase()}_FILTER`,
                          payload: [],
                        });
                        url.searchParams.delete(id);
                        window.history.pushState(null, "", url.toString());
                      }}
                    >
                      {t("reset")}
                    </Button>
                    <Button
                      className="btn btn-dark apply-filter"
                      onClick={() => {
                        const url = new URL(window.location);

                        if (id === "salaries") {
                          
                          if (control()["max"]) {
                            dispatch({
                              type: `MAX_FILTER`,
                              payload: control()["max"],
                            });

                            url.searchParams.set("max", control()["max"]);
                          }
                          if (control()["min"]) {
                            dispatch({
                              type: `MIN_FILTER`,
                              payload: control()["min"],
                            });

                            url.searchParams.set("min", control()["min"]);
                          }
                        }
                        
                        let filterValues = Object.keys(control()[id]).filter(
                          function (k) {
                            return control()[id][k] === true;
                          }
                        );
                        if (id !== "countries") {
                          filterValues = filterValues.map(Number);
                        }
                        
                        
                        dispatch({
                          type: `${id.toUpperCase()}_FILTER`,
                          payload: filterValues,
                        });

                        
                        url.searchParams.set(id, filterValues);
                        window.history.pushState(null, "", url.toString());

                        setMenuState(false);
                      }}
                    >
                      {t("apply_filter")}
                    </Button>
                  </Stack>
                </Box>
              )}
            </Stack>
          </Paper>
        </Popper>
      </ClickAwayListener>
    );
  };
  const renderFilterDropDown = (dropDownItems) => {
    if (dropDownItems)
      return (
        <>
          <FilterDropDown anchorEl={anchorEl} open={menuState}>
            {dropDownItems}
          </FilterDropDown>
        </>
      );
  };
  const handleFilterClick = (event) => {
    
    setAnchorEl(event.currentTarget);
    setMenuState((prev) => !prev);
  };
  return (
    <Box className="one-filter-warpper">
      <IconButton
        className={`one-filter ${
          active && !disabled ? "one-filter-active" : null
        }`}
        onClick={handleFilterClick}
        disabled={disabled}
      >
        <Typography
          className={`filter-label ${
            active && !disabled ? "one-filter-active-label" : null
          } `}
        >
          {label}
        </Typography>
        <CaretDown
          size={16}
          weight="fill"
          color={active && !disabled ? "white" : "gray"}
        />
      </IconButton>
      <Box>{renderFilterDropDown(children)}</Box>
    </Box>
  );
};

export default OneFilter;

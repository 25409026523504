import {
    CHANGE_MODE
  } from "../../actions/mode/types";
  
  let initialState = {
    name: "guestApplicant"
  };
  
  export const modeReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case CHANGE_MODE:
        return {
          ...state,
          ...payload,
        };
      default:
        return state;
    }
  };
  
  export default modeReducer;
  
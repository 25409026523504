import { Box, Typography, Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { translateField } from "../../../../../../../services/Helper";
import "./CategoryTag.scss";

const CategoryTag = ({ tag }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Box
      component={Button}
      onClick={async () => {
        await dispatch({
          type: `SEARCH_FILTER`,
          payload: "",
        });
        await dispatch({
          type: `${tag.id.toUpperCase}_FILTER`,
          payload: tag.id,
        });
        navigate(`/search?type=jobs&${tag.type}=${tag.id}`);
      }}
      disableRipple
      className="tag-wrapper"
      textAlign={"center"}
    >
      <Typography className="tag-name">{tag.name}</Typography>
    </Box>
  );
};

export default CategoryTag;

import { Grid, Typography } from "@mui/material";
import { CaretLeft, CaretRight } from "phosphor-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DecisionButton from "../decision-button/DecisionButton";
import Show from "../Show";
import "./Pagination.scss";

const Pagination = (props) => {
  const { pagesManager, changeMdLg } = props;

  const [page, setPage] = useState(1);

  const { t, i18n } = useTranslation();

  const handleNext = () => {
    if (page < pagesManager?.numberOfPages) {
      pagesManager?.setPage?.(page + 1);
      setPage(page + 1);
      pagesManager.onNext();
    }
  };

  const handleBack = () => {
    if (page > 1) {
      pagesManager?.setPage?.(page - 1);
      setPage(page - 1);
      pagesManager.onBack();
    }
  };

  useEffect(() => {
    setPage(pagesManager?.page);
  }, [pagesManager?.page]);

  return (
    <Grid
      container
      direction={"row"}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item sx={{ marginBottom: "5px" }} md={changeMdLg ? 6 : 0} lg={changeMdLg ? 4.5 : 0}>
        <DecisionButton onClick={handleBack} disabled={page <= 1}>
          <>
            <Grid container direction={"row"} spacing={1} alignItems="center">
              <Grid item>
                <Show condition={i18n.language == "ar"}>
                  <CaretRight />
                </Show>
                <Show condition={i18n.language !== "ar"}>
                  <CaretLeft />
                </Show>
              </Grid>
              <Grid item>
                <Typography className="change-page-text">
                  {t("back")}
                </Typography>
              </Grid>
            </Grid>
          </>
        </DecisionButton>
      </Grid>
      <Grid
        item
        container
        className="page-number"
        alignItems="center"
        justifyContent="center"
        sx={{ marginBottom: "5px" }}
        md={changeMdLg ? 6 : 0} lg={changeMdLg ? 2 : 0}
      >
        <Typography>{page}</Typography>
      </Grid>
      <Grid item md={changeMdLg ? 6 : 0} lg={changeMdLg ? 4.5 : 0}>
        <DecisionButton onClick={handleNext} disabled={page >= pagesManager?.numberOfPages}>
          <>
            <Grid container direction={"row"} spacing={1} justifyContent="flex-end" alignItems={"center"}>
              <Grid item >
                <Typography className="change-page-text" >
                  {t("next")}
                </Typography>
              </Grid>
              <Grid item>
                <Show condition={i18n.language == "ar"}>
                  <CaretLeft />
                </Show>
                <Show condition={i18n.language !== "ar"}>
                  <CaretRight />
                </Show>
              </Grid>
            </Grid>
          </>
        </DecisionButton>
      </Grid>
    </Grid>
  );
};
export default Pagination;

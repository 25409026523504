import {
    GET_APPLICANT_LIST,
    DELETE_APPLICANT
} from '../../actions/applicantList/types'

let initialState = {
    data:[]
}

export const ApplicantListReducer = (state = initialState , action) => {
    const { type, payload } = action;
    switch (type) {
        case  GET_APPLICANT_LIST:
            return {
                ...state,
                data: payload.data,
            }
        case DELETE_APPLICANT:
            return{
                ...state,
                data: payload.data,
            }
        default:
            return state
    }

}
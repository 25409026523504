import "./ProfileExperience.scss"

import { Box, Grid, Stack, Typography } from '@mui/material'
import { Briefcase, Buildings, CalendarBlank, GraduationCap } from "phosphor-react"
import { useTranslation } from 'react-i18next'
import IconText from '../../../../components/icon-with-text/IconText'
import { LOCALES_MONTHS } from './../../../../constants/constants'
import { translateField } from './../../../../services/Helper'
const ProfileExperience = ({
    startDate,
    from_year,
    to_year,
    endDate,
    expTitle,
    expType,
    expPlace,
    companyType,
    workStyle,
    expInfo,
    education,
    degree,
    from_month,
    careerLevel,
    to_month,
    searchPage
}) => {
    const { t, i18n } = useTranslation();
    let from_monthToRender = ""
    let to_monthToRender = ""
    LOCALES_MONTHS.map((month) => {
        if (month.name_en === from_month)
            from_monthToRender = translateField(month, "name")
        if (month.name_en === to_month)
            to_monthToRender = translateField(month, "name")
    })

    return (
        <Grid
            container
            direction="row"
            spacing={1.1}
        >
            <Grid item xs={12}>
                <IconText
                    searchPage
                    icon={<CalendarBlank weight="light" size={20} />}
                    text={`
                    ${from_year ? from_monthToRender + " " + from_year + "-" : i18n.language === "ar" ? "الي" : "To"}
                    ${to_year ? to_monthToRender + " " + to_year : i18n.language === "ar" ? "حاليا" : "Present"}`} />
            </Grid>
            <Grid item xs={12}>
                {education ?
                    (<Typography component="span" className="profile-exp-text">
                        {expTitle} {i18n.language === "ar" ? "في" : "in"} <Typography className="profile-exp-company-title" component="span">{expType}</Typography>
                    </Typography>)
                    :
                    (<Typography component="span" className="profile-exp-text">
                        {careerLevel}  {expTitle}  {i18n.language === "ar" ? "في" : "at"} <Typography className="profile-exp-company-title" component="span">{expPlace}</Typography>
                    </Typography>)
                }
            </Grid>
            <Grid item xs={12}>
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    columnGap={1}
                >
                    <Box>
                        {education ?
                            (<IconText searchPage icon={<Buildings weight="light" size={20} />} text={`${expPlace} `} />) :
                            (<IconText searchPage icon={<Briefcase weight="light" size={20} />} text={`${workStyle} `} />)}
                    </Box>
                    <Box>
                        {education ?
                            (<IconText searchPage icon={<GraduationCap weight="light" size={20} />} text={`${degree} `} />) :
                            (<IconText searchPage icon={<Buildings weight="light" size={20} />} text={`${companyType} `} />)}
                    </Box>
                </Stack>
            </Grid>
            <Grid item>
                <Typography className="profile-exp-text">
                    {expInfo}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default ProfileExperience
import { yupResolver } from "@hookform/resolvers/yup";
// import { MobileDatePicker } from '@mui/lab';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  Button,
  FormHelperText,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Buildings, Plus, Student } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import IconInput from "../../../components/IconInput";
import JLSelect from "../../../components/JLSelect";
import Scroller from "../../../components/Scroller";
import {
  getEducationDegree,
  getGrade,
  scrollIntoView,
  searchInArrayOfObjsById,
} from "../../../services/Helper";
import { getStep } from "../../../services/Stepper";
import {
  addEducation,
  deleteEducation,
  goToNextStep,
  updateEducation,
} from "../../../state/actions/profile/profile";
import { nextStep } from "../../../state/actions/stepper/stepper";
import EditBox from "./education-experience/EditBox";
import InfoBox from "./education-experience/EducationInfoBox";
import SaveBox from "./education-experience/SaveBox";
import { stepperData } from "./stepper";

const Education = () => {
  const { t, i18n } = useTranslation();

  const [degrees, setDegrees] = useState(null);
  const [grades, setGrades] = useState(null);
  const [isAdding, setIsAdding] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [currentEdu, setCurrentEdu] = useState(null);
  const [showAddBtn, setShowAddBtn] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const stepFinished = useSelector(
    (state) => state.profile.data?.cv?.User?.step_finished
  );
  const educations = useSelector(
    (state) => state.profile.data?.cv?.User?.CvEducations
  );
  const currentStepNo = useSelector((state) => state.stepper.currentStep);
  const finalStepNo = useSelector((state) => state.stepper.finalStep);

  const current = getStep(stepperData, currentStepNo);

  const requiredMessage = t("required_input");
  const invalideDateMessage = t("invalid_date");
  const futureDateMessage = t("future_date_all");
  const moreThanFromDate = t("more_than_from_date");
  const schema = yup
    .object({
      study_field: yup
        .string()
        .min(3, t("min_input", { number: 3 }))
        .required(requiredMessage)
        .matches(/^[aA-zZ\s\u0600-\u06FF]+$/, t("only_alphapet")),
      university_name: yup
        .string()
        .required(requiredMessage)
        .matches(
          /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/,
          t("only_alphapet")
        )
        .min(3, t("min_input", { number: 3 }))
        .max(50, t("max_input", { number: 50 })),
      education_degree_id: yup.string().required(requiredMessage),
      grade_id: yup.string().required(requiredMessage),
      from_date: yup
        .date()
        .nullable()
        .max(new Date(), futureDateMessage)
        .typeError(invalideDateMessage),
      to_date: yup.date().when("from_date", (from_date, schema) => {
        let tFromDate = new Date(from_date?.getTime());
        tFromDate?.setDate(from_date?.getDate() + 1);
        if (
          Object.prototype.toString.call(from_date) === "[object Date]" &&
          !isNaN(from_date.getTime())
        ) {
          return schema
            .min(tFromDate, moreThanFromDate)
            .nullable()
            .required(requiredMessage)
            .typeError(invalideDateMessage);
        } else {
          return schema
            .nullable()
            .required(requiredMessage)
            .typeError(invalideDateMessage);
        }
      }),
    })
    .required();

  const {
    control,
    register,
    reset,
    formState: { errors },
    setError,
    handleSubmit,
    setValue,
  } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    setLoading(true);
    if (isAdding) {
      await dispatch(addEducation(data)).finally(() => {
        setIsAdding(false);
        setLoading(false);
      });
    } else {
      if (currentEdu) {
        data.id = currentEdu;
        await dispatch(updateEducation(data)).finally(() => {
          setIsEditing(false);
          setLoading(false);
        });
      }
      setIsEditing(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    let cancel = false;
    getEducationDegree().then((degrees) => {
      if (cancel) return;
      setDegrees(degrees);
    });
    getGrade().then((grades) => {
      if (cancel) return;
      setGrades(grades);
    });
    return () => {
      cancel = true;
      setDegrees(null);
      setGrades(null);
    };
  }, []);

  useEffect(() => {
    if (
      educations == null ||
      educations == undefined ||
      educations?.length == 0
    ) {
      reset();
      setShowAddBtn(false);
      setIsAdding(true);
    } else {
      setShowAddBtn(true);
      setIsAdding(false);
    }
  }, [educations?.length]);

  const handleBack = () => {
    if (current.prev) {
      dispatch(nextStep({ currentStep: current.prev, finalStep: finalStepNo }));
    }
  };
  const handleNext = async () => {
    if (current.next) {
      if (current.no > stepFinished) {
        await dispatch(goToNextStep());
      }
      dispatch(nextStep({ currentStep: current.next, finalStep: finalStepNo }));
    }
  };

  const handleAdd = () => {
    reset();
    setCurrentEdu(null);
    setIsAdding(true);
    setIsEditing(false);
  };

  const handleOpenEdit = (id) => {
    const edu = searchInArrayOfObjsById(educations, id);
    setCurrentEdu(edu ? edu.id : null);

    setValue("university_name", edu?.university_name);
    setValue("study_field", edu?.study_field);
    setValue("education_degree_id", edu?.education_degree_id);
    setValue("grade_id", edu?.grade_id);
    setValue(
      "from_date",
      edu?.from_year
        ? new Date(edu?.from_month + "/01/" + edu?.from_year)
        : null
    );
    setValue(
      "to_date",
      edu?.to_year ? new Date(edu?.to_month + "/01/" + edu?.to_year) : null
    );
    scrollIntoView("education");
    setIsAdding(false);
    setIsEditing(true);
  };

  const handleDelete = async () => {
    setLoading(true);
    if (currentEdu) {
      await dispatch(deleteEducation(currentEdu)).finally(() => {
        setIsEditing(false);
        setLoading(false);
      });
    }
    setLoading(false);
  };

  const handleCloseEdit = () => {
    setIsAdding(false);
    setIsEditing(false);
  };

  const renderForm = () => {
    return (
      <>
        <Grid item xs={12} md={6}>
          <IconInput
            label={t("study_field")}
            id="field_of_study"
            placeholder={t("study_field")}
            type="text"
            icon={Student}
            className="w100"
            fieldName="study_field"
            register={register}
            error={errors.study_field}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <IconInput
            label={t("school_field")}
            id="university"
            placeholder={t("school_field")}
            type="text"
            icon={Buildings}
            className="w100"
            fieldName="university_name"
            register={register}
            error={errors.university_name}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <JLSelect
            label={t("degree_field")}
            selectLabel={t("degree_field")}
            childs={degrees}
            fieldName="education_degree_id"
            control={control}
            error={errors.education_degree_id}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <JLSelect
            label={t("grade_field")}
            selectLabel={t("grade_field")}
            childs={grades}
            fieldName="grade_id"
            control={control}
            error={errors.grade_id}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid item className="datepicker-wrapper mb16">
            <Typography className="label" component="span">
              {t("from_date_label_optional")}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack className="datepicker">
                <Controller
                  defaultValue={null}
                  control={control}
                  name="from_date"
                  render={({ field }) => (
                    <MobileDatePicker
                      disableCloseOnSelect={false}
                      views={["year", "month"]}
                      value={field.value}
                      InputAdornmentProps={{
                        position: "start",
                      }}
                      onChange={(date) => field.onChange(date)}
                      renderInput={(params) => {
                        params.inputProps.placeholder = `${t(
                          "from_date"
                        )}`;
                        return (
                          <TextField
                            className="input"
                            {...params}
                            sx={{
                              backgroundColor: "white",
                              borderRadius: "0.5rem",
                            }}
                          />
                        );
                      }}
                    />
                  )}
                />
              </Stack>
              {errors.from_date && (
                <FormHelperText style={{ color: "red" }}>
                  {errors.from_date.message}
                </FormHelperText>
              )}
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid item className="datepicker-wrapper mb16">
            <Typography className="label" component="span">
              {t("to_date")}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack className="datepicker">
                <Controller
                  defaultValue={null}
                  control={control}
                  name="to_date"
                  render={({ field }) => (
                    <MobileDatePicker
                      className="height40"
                      disableCloseOnSelect={false}
                      views={["year", "month"]}
                      value={field.value}
                      InputAdornmentProps={{
                        position: "start",
                      }}
                      InputProps={{
                        error: errors.to_date,
                      }}
                      error={errors.to_date}
                      onChange={(date) => field.onChange(date)}
                      renderInput={(params) => {
                        params.inputProps.placeholder = `${t(
                          "to_date"
                        )}`;
                        return (
                          <TextField
                            className="input"
                            {...params}
                            sx={{
                              backgroundColor: "white",
                              borderRadius: "0.5rem",
                            }}
                          />
                        );
                      }}
                    />
                  )}
                />
              </Stack>
              {errors.to_date && (
                <FormHelperText style={{ color: "red" }}>
                  {errors.to_date.message}
                </FormHelperText>
              )}
            </LocalizationProvider>
          </Grid>
        </Grid>
      </>
    );
  };
  return (
    <Grid container direction="column">
      <Scroller id="education" />
      <Grid item container className="step-content applicant-education">
        <Grid item>
          <Typography className="title">
            {t("education_title")}
          </Typography>
          <Typography className="text">
            {t("education_text")}
          </Typography>
        </Grid>
        {isAdding && (
          <>
            <SaveBox
              title={t("eduaction_save_title")}
              handleSubmit={handleSubmit(onSubmit)}
              loading={loading}
            >
              {renderForm()}
            </SaveBox>
          </>
        )}
        {isEditing && (
          <>
            <EditBox
              title={t("eduaction_edit_title")}
              handleSubmit={handleSubmit(onSubmit)}
              handleCloseEdit={handleCloseEdit}
              handleDelete={handleDelete}
              loading={loading}
            >
              {renderForm()}
            </EditBox>
          </>
        )}

        {educations?.map((edu) => (
          <InfoBox
            data={edu}
            handleEdit={() => {
              handleOpenEdit(edu.id);
            }}
          />
        ))}

        <Grid container item>
          {showAddBtn && (
            <Button
              className="add-btn"
              startIcon={<Plus />}
              fullWidth
              onClick={handleAdd}
            >
              {t("add_edu_btn")}
            </Button>
          )}
        </Grid>
        <Grid item container justifyContent="end">
          {current.prev && (
            <button className="back-btn" onClick={handleBack}>
              {t("back_btn")}
            </button>
          )}
          <button
            className="next-btn"
            onClick={handleNext}
            disabled={loading}
            style={!loading ? { cursor: "pointer" } : {}}
          >
            {t("next_btn")}
          </button>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Education;

import { Box, Grid, Paper, Typography } from "@mui/material";
import "./StatsCard.scss";

const StatsCard = ({ type, stats, icon }) => {
  return (
    <Paper className="stats-card">
      <Grid container direction={"row"} >
        <Grid item xs={7.5}>
          <Typography className="stats-number">{stats}</Typography>
          <Typography className="stats-type">{type}</Typography>
        </Grid>
        <Grid item xs={4.5} >
          <Box className="stats-icon-box" display="flex" justifyContent="center" alignItems="center">
            {icon}

          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};
export default StatsCard;

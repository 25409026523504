import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { searchForApplicantByNameOrEmail } from '../../../services/Helper';


const useSearch = () => {
    const [searchTerm, setSearchTerm] = useState("")
    const [data, setData] = useState([])
    const [email, setEmail] = useState()
    const { handleSubmit, control, setValue } = useForm()
    const onSubmit = (data) => {
        setSearchTerm(data.searchTerm)
        console.log(data, searchTerm)
        searchForApplicantByNameOrEmail(data.search_term).then((res) => {
            if (res.email) {
                setEmail(res.email)
                setData([])
                return
            }
            else if (res.rows.length >= 1)
                setData(res.rows)
        })
    }
    useEffect(() => {
        console.log(data)
    }, [data])

    return (
        {
            setSearchTerm,
            handleSubmit,
            onSubmit,
            data,
            email,
            control,
            setValue,
            setEmail,
            setData
        }
    )
}

export default useSearch


import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { translateField } from '../../../../services/Helper';
import './Footer.scss';

const Footer = ({ socialMedia, appStores, logo, description, items }) => {
    const { t, i18n } = useTranslation();
    const [dividedItems, setDividedItems] = useState([[]]);

    const mode = useSelector((state) => state.mode.name);

    useEffect(() => {
        let newItems = [...items];
        let newUpper = newItems.length - 3 < 0 ? newItems.length : 3;
        let newLower = 0;
        let rowsAfterSlicing = [];
        let numberOfSections = Math.ceil(newItems.length / 3);

        for (let i = 0; i < numberOfSections; i++) {
            rowsAfterSlicing.push([...newItems.slice(newLower, newUpper)]);
            newLower = newUpper;
            newUpper = newItems.length - newUpper - 3 < 0 ? newItems.length : newUpper + 3;
        }

        setDividedItems(rowsAfterSlicing);
    }, []);

    return (
        <Box className="footer" paddingX={{ xs: '24px', md: '100px', marginTop: '80px' }} paddingY={'80px'}>
            <Stack spacing={{ xs: 5, md: 8 }} paddingX={{ lg: 3, xl: 0 }}>
                <Grid container direction={{ xs: 'column', md: 'row', lg: 'row' }} justifyContent={'space-between'} spacing={{ xs: 0, md: 1 }}>
                    <Grid item xs={4}>
                        <Box className="footer-main" marginBottom={{ xs: 3, md: 0 }}>
                            {logo}
                            <Typography className="footer-description">{translateField(description, 'name')}</Typography>
                        </Box>
                    </Grid>
                    {dividedItems.map((section) => (
                        <Grid item xs={2} container paddingBottom={{ xs: 1, md: 0 }} spacing={2} direction={'column'}>
                            {section.map((sectionItem) => (
                                <Grid item xs={1}>
                                    <Typography component={Link} to={sectionItem.to} className="footer-item">
                                        {translateField(sectionItem, 'name')}
                                    </Typography>
                                </Grid>
                            ))}
                        </Grid>
                    ))}
                </Grid>
                <Grid container direction={{ xs: 'column', md: 'row' }} justifyContent="space-between" alignItems={{ xs: 'flex-start', md: 'flex-end' }} spacing={{ xs: 5, md: 0 }}>
                    <Grid item order={{ xs: 1, md: 1 }}>
                        <Typography className="section-title">{t('social_media')}</Typography>
                        <Grid container direction="row" spacing={2}>
                            {socialMedia.map((social) => (
                                <Grid item>
                                    <IconButton className="link-button" onClick={() => window.open(social.url, '_blank', 'noopener,noreferrer')}>
                                        {social.icon}
                                    </IconButton>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item order={{ xs: 3, md: 2 }}>
                        <Typography className="copyright">
                            {t('copyright', {
                                year: new Date().getFullYear(),
                                name: 'joblinkshub.com',
                            })}
                        </Typography>
                    </Grid>
                    <Grid item order={{ xs: 2, md: 3 }}>
                        <Typography className="section-title">{t('download_app')}</Typography>
                        <Grid container direction="row" spacing={2}>
                            {appStores.map((appStore) => (
                                <Grid item>
                                    <IconButton
                                        className="link-button"
                                        onClick={() =>
                                            window.open(mode.toLowerCase().includes('applicant') ? appStore.applicantUrl : appStore.recruiterUrl, '_blank', 'noopener,noreferrer')
                                        }
                                    >
                                        {appStore.icon}
                                    </IconButton>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Stack>
        </Box>
    );
};
export default Footer;

import { Grid, Stack, useMediaQuery } from "@mui/material";
import { CaretCircleRight } from "phosphor-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import ErrorPage from "../../../../components/error-page/ErrorPage";
import Show from "../../../../components/Show";
import { getJobsByType, translateField } from "../../../../services/Helper";
import HeaderText from "../../zeinnab/components/HeaderText/HeaderText";
import EnhancedMiniJobCard from "../EnhancedMiniJobCard/EnhancedMiniJobCard";
import EmptyLottie from "../../../../assets/lootie-files/EmpySectionLottie.json";

const DashboardSection = ({ type, ApplicantDashboardBrowse }) => {
  const lgMatch = useMediaQuery("(min-width:1200px) and (max-width:1250px)");
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [jobs, setJobs] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    getJobsByType(type, 3, "", false)
      .then((response) => {
        setJobs(response.rows);
      })
      .finally(() => setLoaded(true));
  }, []);

  return (
    <Grid container direction={"column"} spacing={3}>
      <Grid
        item
        container
        direction={"row"}
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Grid item>
          <HeaderText
            text={t(`${type}`)}
            fontSize={"20px"}
            fontWeight={"bold"}
          />
        </Grid>
        <Grid item>
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Stack>
              <Link
                to={`/browsing-jobs?type=${type}`}
                className="featured-more"
              >
                {t("see_more")}
              </Link>
            </Stack>
            <Stack>
              <CaretCircleRight
                size={16}
                style={{
                  transform: i18n.language == "ar" ? "rotate(180deg)" : "",
                }}
              />
            </Stack>
          </Stack>
        </Grid>
      </Grid>

      <Grid item className="featured-area">
        <Grid container spacing={3}>
          {jobs?.map((job) => (
            // <Grid item xs={12} md={6} lg={lgMatch ? 5 : ApplicantDashboardBrowse ? 6 : 4} xl={4}>
            <Grid item xs={12} md={6} lg={ApplicantDashboardBrowse ? 6 : 4}>
              <EnhancedMiniJobCard
                title={translateField(job?.JobTitle, "name")}
                company={job?.Recruiter?.company_name}
                logo={job?.Recruiter?.image}
                date={job?.created_at}
                noOfApplicants={job?.count}
                tags={[
                  translateField(job?.WorkStyle, "name"),
                  translateField(job?.ExperienceType, "name"),
                ]}
                location={`${translateField(
                  job?.City,
                  "name"
                )}, ${translateField(job?.Country, "name")}`}
                onClick={() => navigate(`/jobs/${job?.id}`)}
                ApplicantDashboardBrowse
                jobId={job?.id}
                isSaved={job?.is_saved}
                isApplied={job?.is_applied}
              />
            </Grid>
          ))}

          <Show condition={loaded && jobs.length === 0}>
            <ErrorPage
              animationData={EmptyLottie}
              description={t("no_jobs")}
              hideAction={true}
            />
          </Show>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DashboardSection;

import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import EnhancedButton from "../../../yahya/components/enhanced-button/EnhancedButton";
import "./AboutSection.css";
import AboutSectionImage from "./Illustration.svg";

const AboutSection = () => {
    const mdMatches = useMediaQuery('(min-width:701px) and (max-width:1000px)');
    const smMatches = useMediaQuery('(max-width:1200px)');
    const navigate = useNavigate();

    const mode = useSelector((state) => state.mode.name);

    return (
        <Box id="about"
            component={"section"} className="section-about"
            // paddingX={mdMatches ? "50px" : smMatches ? "24px" : "70px"}
        >
            <Grid container justifyContent={"space-between"}>
                <Grid item lg={6.5}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography className="title">{t("joblinks")}</Typography>
                            <Typography className="subtitle">
                                {t(`landing_page_subtitle_${mode.replace("guest", "").toLowerCase()}`)}
                            </Typography>
                            <Typography className="content" sx={{width:"450px"}}>
                                {t("landing_page_about_desc")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2} alignItems={"center"}>
                                <Grid item xs={6} md={4.5}>
                                    <EnhancedButton
                                        buttonText={t("contact_us")}
                                        variant={"contained"}
                                        padding={smMatches ? "16px" : "16px 40px"}
                                        onClick={() => navigate("/contact")}
                                    />
                                </Grid>
                                <Grid item xs={6} md={4.5}>
                                    <EnhancedButton
                                        buttonText={t("know_more")}
                                        variant={"outlined"}
                                        padding={smMatches ? "16px" : "16px 40px"}
                                        onClick={() => navigate("/about")}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container lg={5} marginTop={mdMatches ? "16px" : "0px"} justifyContent={mdMatches ? "center" : "end"} sx={{ display: smMatches ? "none" : "flex" }}>
                    <img src={AboutSectionImage} />
                </Grid>
            </Grid>
        </Box>
    )
}
export default AboutSection;
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./Slider.css";

export default function ComponentSlider({
  items,
  slidesToShow,
  responsive,
  centerItems,
}) {
  const sliderSettings = {
    dots: true,
    speed: 5000,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToShow,
    autoplay: true,
    autoplaySpeed: 7000,
    infinite: items.length > slidesToShow,
    responsive: responsive,
  };

  return (
    // <div>
    //   {items}
    // </div>

    <Slider
      {...sliderSettings}
      // className="slick-carousel"
      className={`slick-carousel ${centerItems ? "center-slick-list" : ""}`}
    >
      {items}
    </Slider>
  );
}

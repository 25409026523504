import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { t } from "i18next";
import {
  ArrowRight,
  At,
  Chats,
  IdentificationBadge,
  List,
  MapPin,
  Phone
} from "phosphor-react";
import React from "react";
import { useForm } from "react-hook-form";
import { contactSupport } from "services/Helper";
import IconText from "../../../../../components/icon-with-text/IconText";
import IconInput from "../../../../../components/IconInput";
import EnhancedButton from "../../components/enhanced-button/EnhancedButton";
import ContactCard from "./../../../zeinnab/components/contact-card/ContactCard";
import HeroSection from "./../../components/hero-section/HeroSection";
import "./ContactUs.scss";

const ContactUs = () => {
  const {
    control,
    getValues,
    reset,
    watch,
    setValue,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
  });
  const matches = useMediaQuery("(min-width:600px)");
  //   const mdMatches = useMediaQuery('(min-width:1200px)');
  const mdMatches = useMediaQuery("(min-width:701px) and (max-width:1000px)");
  const smMatches = useMediaQuery("(max-width:700px)");
  const heroSectionCards = [
    <ContactCard
      title={t("contact_sale")}
      info={
        t("contact_sales_desc")
      }
      text={"+20 123 1254 236"}
      icon={<Phone weight={"fill"} size={20} />}
      iconDirection={"iconLeft"}
      onClick={() => {
        console.log("clicked");
      }}
    />,
    <ContactCard
      title={t("contact_customer_support")}
      info={
        t("contact_support_desc")
      }
      text={t("contact_now")}
      icon={<ArrowRight size={20} />}
      iconDirection={"iconRight"}
      onClick={() => {
        console.log("clicked");
      }}
    />,
  ];

  const handleSend = () => {
    contactSupport(getValues("content"), getValues("email"), getValues("name")).then(() => {
      reset({ name: "", email: "", content: "" });
    });
  }

  return (
    <Stack
      direction={"column"}
      spacing={{ xs: 10, md: 25 }}
      justifyContent={"center"}
      
      position={"relative"}
    >
      <HeroSection>
        <Stack align={"center"} mb={20} spacing={2}>
          <Typography className={"hero-section-title colored"}>
            {t("contact_us")}
          </Typography>
          <Typography className={"hero-section-description"}>
            {t("contact_us_desc")}
          </Typography>
        </Stack>
        {!mdMatches && !smMatches &&
          <>
            <Grid container direction={"row"} columnSpacing={2} justifyContent={"center"} sx={{
              position: "absolute",
              top: "300px",
              maxHeight: "max-content",
              right: "0px"
            }}>
              {heroSectionCards?.map((card) => {
                return <Grid item xs={5}>{card} </Grid>;
              })}
            </Grid>
          </>
        }
      </HeroSection>
      {smMatches || mdMatches ? (
        <Box>
          <Grid container direction={"column"} rowSpacing={10}>
            {heroSectionCards?.map((card) => {
              return <Grid item >{card} </Grid>;
            })}
          </Grid>
        </Box>
      ) : (
        <>

          {/* <Grid container direction={"row"} columnSpacing={2} justifyContent={"center"} sx={{
            position: "absolute",
            top: "185px",
            right: "0px"
          }}>
            {heroSectionCards?.map((card) => {
              return <Grid item xs={5}>{card} </Grid>;
            })}
          </Grid> */}
        </>
      )}

      <Grid
        container
        justifyContent={"space-between"}
        rowGap={{ xs: 4.3, sm: 10 }}
        alignItems={"center"}
      >
        <Grid item md={4}>
          <Stack direction={"column"} alignItems={"flex-start"} spacing={4}>
            <Box>
              <Typography className={"headquarters-title"}>
                {t("headquarters")}
              </Typography>
              <Typography className={"headquarters-description"}>
                Facere eos accusamus facere. Magni quia amet nisi voluptatibus
                molestiae harum. Ratione eveniet adipisci nihil ipsum cumque.
              </Typography>
            </Box>
            <IconText
              text={"96555 Casper Wall Susana Branch"}
              variant={"colored"}
              icon={<MapPin color={"#C7C7C7"} size={25} weight={"fill"} />}
              className={"headquarters-icon-text"}
            />
            <IconText
              text={"+20 123 4568 987"}
              variant={"colored"}
              icon={<Phone color={"#C7C7C7"} size={25} weight={"fill"} />}
              className={"headquarters-icon-text"}
            />
            <IconText
              text={"info@joblinkshub.com"}
              variant={"colored"}
              icon={<At color={"#C7C7C7"} size={25} weight={"fill"} />}
              className={"headquarters-icon-text"}
            />
          </Stack>
        </Grid>
        <Grid item md={5}><iframe className="map-container" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d55628.81190041218!2d47.914226822785444!3d29.376122933432853!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3fcf9c83ce455983%3A0xc3ebaef5af09b90e!2sKuwait%20City%2C%20Kuwait!5e0!3m2!1sen!2seg!4v1666292581154!5m2!1sen!2seg" allowFullscreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe></Grid>
        <Grid item xs={12}>
          <Stack
            className="send-us-message-wrapper"
            direction={"column"}
            spacing={4}
          >
            <IconText
              icon={
                <Box className={"message-icon-wrapper"}>
                  {" "}
                  <Chats size={28} weight="fill" color={"#FFFFFF"} />
                </Box>
              }
              text={t("send_a_message")}
              className={"send-us-message"}
            />
            <Grid container rowSpacing={2}>
              <Grid item xs={12} container columnSpacing={4} rowSpacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <IconInput
                    placeholder={t("name")}
                    type="text"
                    icon={IdentificationBadge}
                    className="w100 new-job-input from_to_input"
                    fieldName="name"
                    register={register}
                    error={errors.salary_from}
                    disabled={false}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <IconInput
                    placeholder={t("email")}
                    type="text"
                    icon={At}
                    className="w100 new-job-input from_to_input"
                    fieldName="email"
                    register={register}
                    error={errors.salary_from}
                    disabled={false}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {!matches && (
                  <IconInput
                    placeholder={t("message-content")}
                    iconTop={true}
                    multiline={true}
                    rows={5}
                    type="text"
                    icon={List}
                    className="w100 new-job-input from_to_input"
                    fieldName="content"
                    register={register}
                    error={errors.salary_from}
                    disabled={false}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                {matches && (
                  <Box>
                    <IconInput
                      placeholder={t("message-content")}
                      iconTop={true}
                      multiline={true}
                      rows={5}
                      type="text"
                      icon={List}
                      className="w100 new-job-input from_to_input"
                      fieldName="content"
                      register={register}
                      error={errors.salary_from}
                      disabled={false}
                    />
                  </Box>
                )}
              </Grid>
              <Grid
                item
                xs={10}
                sm={6}
                md={3.5}
                lg={2.5}
                justifyContent={"flex-end"}
              >
                <EnhancedButton
                  padding={"16px 40px"}
                  buttonText={t("send-message")}
                  variant={"contained"}
                  onClick={handleSend}
                />
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default ContactUs;

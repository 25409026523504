import { Grid, InputBase, Paper, useMediaQuery } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Hide from "../../../../../components/Hide";
import EnhancedDropdown from "../../../abdelrahman/EnhancedDropdown/EnhancedDropdown";
import EnhancedButton from "../../../yahya/components/enhanced-button/EnhancedButton";
import { getDocumentDirection } from "./../../../../../services/Helper";

const LandingPageSearchBar = ({ searchBarStyle, hideSearchButton, mini }) => {
  const mdMatches = useMediaQuery("(min-width:1200px)");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const role = useSelector((state) => state.profile.role.name);

  const searchTerm = useSelector((state) => state.filter.search);

  const [filter, setFilter] = useState("people");
  const [search, setSearch] = useState(searchTerm);

  const handleSearch = async () => {
    await dispatch({
      type: `SEARCH_FROM_HOME`,
      payload: { search, filter: role === "RECRUITER" ? "people" : filter },
    });
    navigate(
      `/search?${search.trim().length > 0 ? `search=${search}&` : ""
      }type=${role === "RECRUITER" ? "people" : filter}`
    );
    //setSearch("");
  };

  useEffect(() => {
    console.log("setSearch(searchTerm)", searchTerm);
    setSearch(searchTerm);
    // if (window.location.pathname.includes("/search")) {
    //   setSearch(searchTerm);
    // } else {
    //   setSearch("");
    // }
  }, [searchTerm]);

  const dropDownOptions = [
    {
      name_en: "People",
      name_ar: "الأفراد",
      id: "people",
    },
    {
      name_en: "Jobs",
      name_ar: "الوظائف",
      id: "jobs",
    },
    {
      name_en: "Companies",
      name_ar: "الشركات",
      id: "companies",
    },
  ];
  const documentDirection = getDocumentDirection();

  const handleChangeFilter = (selected) => {
    setFilter(selected?.id);
  };

  return (
    <Paper
      fullWidth
      elevation={0}
      sx={
        searchBarStyle
          ? { ...searchBarStyle }
          : {
            padding: "24px",
            borderRadius: "8px",
            maxHeight: "98px",
            marginTop: "100px",
            minWidth: { md: "100%", lg: "1048px" },
            boxShadow: !mdMatches ? "0" : "0px 0px 30px #0000000D",
          }
      }
    >
      <Grid container direction={"row"} columnSpacing={2} rowSpacing={1} sx={{ maxHeight: "80px" }}>
        <Grid
          item
          container
          xs={12}
          lg={!hideSearchButton ? 10 : 12}
          alignItems={"center"}
        >
          <Grid item xs={mini ? "auto" : 2} lg={mini ? "auto" : 2} alignSelf={"flex-start"}>
            <EnhancedDropdown
              role={role}
              options={dropDownOptions}
              onChange={handleChangeFilter}
            />
          </Grid>
          <Grid item xs={mini ? "auto" : 10} md={mini ? "auto" : 10} lg={mini ? "auto" : 10} xl={mini ? 9 : 10} alignSelf={"flex-start"} sx={{ maxHeight: "90px" }}>
            <InputBase
              fullWidth
              placeholder={t("search")}
              onChange={(e) => setSearch(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
              value={search}
              sx={{
                border: "1px solid #E4E4E4",
                borderRight:
                  documentDirection === "left" ? "1px solid #E4E4E4" : "0px",
                borderLeft:
                  documentDirection === "right" ? "1px solid #E4E4E4" : "0px",
                borderRadius: "8px",
                borderTopLeftRadius: documentDirection === "left" && "0px",
                borderBottomLeftRadius: documentDirection === "left" && "0px",
                borderTopRightRadius: documentDirection === "right" && "0px",
                borderBottomRightRadius: documentDirection === "right" && "0px",
                paddingLeft: "24px",
                paddingRight: "24px",
                minHeight: "50px",
                // minWidth: "700px"
              }}
            />
          </Grid>
        </Grid>
        <Hide condition={hideSearchButton}>
          <Grid item xs={12} lg={2}>
            <EnhancedButton
              buttonText={t("search")}
              variant={"contained"}
              padding={"16px 40px"}
              onClick={handleSearch}
            />
          </Grid>
        </Hide>
      </Grid>
    </Paper>
  );
};

export default LandingPageSearchBar;

import React from 'react'
import PageBack from './../../../../components/page-back/PageBack';
import { useTranslation } from "react-i18next"
const ApplicantProfileEditHeader = () => {

    const { t } = useTranslation();

    return (
        <>
            <PageBack
                title={t("back_btn")}
                link="/my-profile"
            />
        </>)
}

export default ApplicantProfileEditHeader
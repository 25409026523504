import Permissions from "../constants/permissions";
import Home from "../views/home/Home";
import Abdelrahman from "../views/pages/abdelrahman/Abdelrahman";
import ApplicantSubmit from "../views/pages/applicant-submit/ApplicantSubmit";
import EmailVerification from "../views/pages/email-verification/EmailVerification";
import ForgetPassword from "../views/pages/forget-password/ForgetPassword";
import LoginAs from "../views/pages/login-as/LoginAs";
import Submit from "../views/pages/recutiersubmit/information/Submit";
import Redirecting from "../views/pages/redirecting/Redirecting";
import Signin from "../views/pages/signin/Signin";
import Signup from "../views/pages/signup/Signup";
import Zeinab from "../views/pages/zeinnab/Zeinab";
import ApplicantLandingPage from "../views/pages/okba/ApplicantLandingPage";
import JoinUsReason from "./../views/pages/join-us-reason/JoinUsReason";
import RecruiterLandingPage from "../views/pages/okba/RecruiterLandingPage";
import ContactUs from "../views/pages/yahya/views/contact-us/ContactUs";
import HelpCenter from "../views/pages/yahya/views/help-center/HelpCenter";
import EnhancedTermsPage from "../views/pages/abdelrahman/EnhancedTermsPage/EnhancedTermsPage";
import About from "../views/pages/zeinnab/views/about/About";
import LandingPage from "../views/pages/okba/LandingPage";
import ResetPasswordVerification from "views/pages/reset-password-verification/ResetPasswordVerification";
import ResetPassword from "views/pages/reset-password/ResetPassword";
import ChangeEmailVerification from "views/pages/change-email-verification/ChangeEmailVerification";
import ChangeEmail from "views/pages/change-email/ChangeEmail";
import VerifyOTP from "views/pages/otp/VerifyOTP";

export const routes = [
  {
    path: "/",
    name: "Home Page",
    component: LandingPage,
    permissionRequired: false,
    permission: null,
  },
  {
    path: "/join-us-reason",
    name: "Join Us Reason",
    component: JoinUsReason,
    permissionRequired: true,
    permission: Permissions.chooseJoinReason,
  },
  {
    path: "/recruiter",
    name: "Home Page - Recruiter",
    component: RecruiterLandingPage,
    permissionRequired: false,
    permission: null,
  },
  {
    path: "enhanced-terms",
    parent: "/",
    name: "Terms & Conditions",
    component: EnhancedTermsPage,
    pageHeader: null,
    permissionRequired: false,
    permission: null,
    activeSidebar: "home",
  },
  {
    path: "enhanced-help",
    parent: "/",
    name: "Help Center",
    component: HelpCenter,
    pageHeader: null,
    permissionRequired: false,
    permission: null,
    activeSidebar: "home",
  },
  {
    path: "enhanced-contact",
    parent: "/",
    name: "Contact Us",
    component: ContactUs,
    pageHeader: null,
    permissionRequired: false,
    permission: null,
    activeSidebar: "home",
  },
  {
    path: "enhanced-about",
    parent: "/",
    name: "About",
    component: About,
    pageHeader: null,
    permissionRequired: false,
    permission: null,
    activeSidebar: "home",
  },
  {
    path: "/login",
    name: "Login Page",
    component: Signin,
    permissionRequired: true,
    permission: null,
    role: "GUEST_USER",
  },
  {
    path: "/sign-up",
    name: "Signup Page",
    component: Signup,
    permissionRequired: true,
    permission: null,
    role: "GUEST_USER",
  },
  {
    path: "/forget-password",
    name: "Forget Password Page",
    component: ForgetPassword,
    permissionRequired: false,
    permission: null,
  },
  {
    path: "/email-verification",
    name: "Email Verification  Page",
    component: EmailVerification,
    permissionRequired: false,
    permission: null,
  },
  {
    path: "/reset-password-verification",
    name: "Reset Password Verification Page",
    component: ResetPasswordVerification,
    permissionRequired: false,
    permission: null,
  },
  {
    path: "/reset-password",
    name: "Reset Password Page",
    component: ResetPassword,
    permissionRequired: false,
    permission: null,
  },
  {
    path: "/change-email-verification",
    name: "Reset Password Verification Page",
    component: ChangeEmailVerification,
    permissionRequired: false,
    permission: null,
  },
  {
    path: "/change-email",
    name: "Reset Password Page",
    component: ChangeEmail,
    permissionRequired: false,
    permission: null,
  },
  {
    path: "/verify-otp",
    name: "Verify OTP Page",
    component: VerifyOTP,
    permissionRequired: false,
    permission: null,
  },
  {
    path: "/recruiter-submit",
    name: "RecuiterSubmit Page",
    component: Submit,
    permissionRequired: true,
    permission: Permissions.createCompany,
  },
  {
    path: "/applicant-submit",
    name: "Applicant Submit Page",
    component: ApplicantSubmit,
    permissionRequired: true,
    permission: Permissions.createCV,
  },
  {
    path: "/login-as",
    name: "Login As",
    component: LoginAs,
    permissionRequired: false,
    permission: null,
  },
  {
    path: "/abdelrahman",
    name: "Abdelrahman's Components",
    component: Abdelrahman,
    permissionRequired: false,
    permission: null,
  },
  {
    path: "/zeinab",
    name: "Zeinab's Components",
    component: Zeinab,
    permissionRequired: false,
    permission: null,
  },
  {
    path: "/okba",
    name: "Okba's Components",
    component: RecruiterLandingPage,
    permissionRequired: false,
    permission: null,
  },
  {
    path: "/redirecting",
    name: "Redirecting",
    component: Redirecting,
    permissionRequired: false,
    permission: null,
  },
];

import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchAppliedApplicant } from "../../services/Helper";
import ApplicantProfile from "../applicant-profile/ApplicantProfile";

const AppliedApplicant = () => {
    const role = useSelector((state) => state.profile.role?.name)
    const [userData, setUserData] = useState()
    const { job_id, applicant_id } = useParams()

    useEffect(() => {
        fetchAppliedApplicant(applicant_id, job_id).then((response) => {
            let user = response.User
            delete user.id
            // delete response.User
            let result = {
                ...user,
                ...response
            }
            console.log("result", result)
            setUserData(result)

        }).catch((err) => {
            setUserData(null);
        });
    }, [])

    return <ApplicantProfile
        userData={userData}
    />
}

export default AppliedApplicant;
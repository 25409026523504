/* eslint-disable react/prop-types */
import { Button, Dialog, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteFirebaseToken } from '../../../../../firebase';
import { logout } from '../../../../../services/auth/Auth';
import { deleteMyAccount } from '../../../../../services/Helper';
import './DeleteAccountModal.css';
import { deleteProfile } from 'state/actions/profile/profile';
import { authLogout } from 'state/actions/auth/auth';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const DeleteAccountModal = ({ open, handleClose }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleDelete = async () => {
    setLoading(true);
    await deleteFirebaseToken();
    deleteMyAccount().then(() => {
      setTimeout(() => {
        dispatch(deleteProfile());
        dispatch(authLogout(navigate));
        setLoading(false);
      }, 2000);
    });
  };

  return (
    <Dialog className="delete-account-modal" onClose={handleClose} open={open} disableScrollLock={true}>
      <Typography className="title">{t('titles.you_are_about_delete_account')}</Typography>
      <Grid container justifyContent={'center'} className="btns">
        <Grid item>
          <Button className="btn-cancle" onClick={handleClose}>
            {t('cancel')}
          </Button>
        </Grid>
        <Grid item>
          <Button type="submit" className="btn-delete" onClick={handleDelete} disabled={loading}>
            {t('delete_my_account')}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default DeleteAccountModal;

import { Button, ListItemText, Menu, MenuItem } from "@mui/material";
import { Globe } from "phosphor-react";
import React, { useEffect, useState } from 'react';
import { LANGS } from '../../constants/langs';
import i18n from '../../i18n';
import "./ChangeLang.scss";

const supportedLanguages = ["en", "ar"];

const ChangeLang = (props) => {
  // const { i18n } = useTranslation();
  const [lang, setLang] = useState("en");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLangChange = (chosenLang) => {
    setLang(chosenLang);
    i18n.changeLanguage(chosenLang);
    if (chosenLang === "ar") {
      document.dir = "rtl";
    } else {
      document.dir = "ltr";
    }
  };

  useEffect(() => {
    let currentLang = i18n.language.split("-")[0]
    i18n.changeLanguage(currentLang)
    setLang(currentLang);
    if (currentLang === "ar") {
      document.dir = "rtl";
    } else {
      document.dir = "ltr";
    }
  }, [i18n.language])
  return (
    <>
      <Button
        className="lang-button"
        variant="contained"
        startIcon={<Globe className="icon" />}
        onClick={handleClick}
      >
        {lang == "en" ? "En" : "Ar "}
      </Button>
      <Menu
        className="menu"
        disableScrollLock={true}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        marginThreshold={0}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          //className: "social-menu"
          sx: { width: (anchorEl && anchorEl.offsetWidth) },
        }}
      >
        {supportedLanguages.map((language, index) => (
          <MenuItem
            key={index++}
            onClick={() => {
              handleClose();
              handleLangChange(language);
            }}
            className="lang-list"
          >
            {/* <ListItemIcon>
                  <FacebookLogo />
                </ListItemIcon> */}
            <ListItemText>{language == "en" ?
              <div>
                <img src={LANGS.EN.FLAG} className="flag-icon" />
                <span className="flag-text">En</span>
              </div>
              : <div>
                <img src={LANGS.AR.FLAG} className="flag-icon" />
                <span className="flag-text">Ar</span>
              </div>}
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
export default ChangeLang;

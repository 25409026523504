import {
  Avatar, Button,
  Fade,
  List, ListItemButton, Popover,
  Stack,
  Typography
} from "@mui/material";
import { Checks } from "phosphor-react";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Flag from "react-world-flags";
import { handleChangeLanguageAction } from "state/actions/languages-handler/languageHandler";
import { getLanguageDropdown } from './../../../../services/Helper';
import "./LanguageDropdown.scss";

const LanguageDropdown = () => {
  const { i18n } = useTranslation()
  const currentLanguage = useSelector(state => state.languageHandler.current_lang)
  const currentLocale = useSelector(state => state.languageHandler.locale)
  const currentCode = useSelector(state => state.languageHandler.code)
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null);
  const [lang, setLang] = useState("en");
  const [langs, setLangs] = useState([])
  const [selected, setSelected] = useState(currentLocale);
  const [opened, setOpened] = useState(null);

  const handleClick = (event) => {
    setOpened(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpened(false);
    setAnchorEl(null);
  };

  const handleLangChange = (code, locale) => {
    dispatch(handleChangeLanguageAction(code, locale))
    setLang(code + "-" + locale);
    i18n.changeLanguage(code);
    if (code === "ar") {
      document.dir = "rtl";
    } else {
      document.dir = "ltr";
    }
    let selectedLanguage = langs.find(option => option.code.toLowerCase() === currentLanguage);
    setSelected(selectedLanguage);
  };

  useEffect(() => {
    setSelected(currentLocale)
    getLanguageDropdown().then((res) => {
      setLangs(res)
    })
  }, [])

  const open = Boolean(anchorEl);
  return (
    <>
      <Button disableRipple className="language-button" onClick={handleClick}>
        {/* <Flag code={selected} className="language-icon" /> */}

        <Typography className="language-code">{currentCode}</Typography>
        <Typography className={`open-arrow${opened === null ? "" : (opened ? " open-rotate" : " close-rotate")}`}>▾</Typography>
      </Button>
      <Popover
        id={"language-popover"}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableScrollLock={true}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          horizontal: "right"
        }}
        classes={{ paper: "languages-container" }}
        // className="languages-container"
        TransitionComponent={Fade}
        transitionDuration={600}
      >
        <List
        >
          {langs.map((option, index) => {
            console.log({ option }, selected)
            return <ListItemButton
              onClick={() => {
                setSelected(option.locale);
                handleClose();
                handleLangChange(option.Language.code.toLowerCase(), option.locale);
              }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                //spacing={4}
                alignItems="center"
                className="language-option"
              >
                <Avatar className="language-icon">
                  <Flag code={option.locale} className="language-icon" />
                </Avatar>
                <Typography className="language-name">
                  {option.name}
                  {/* {translateField(option, "name")} */}
                </Typography>
                <Checks
                  color={
                    selected === option.locale ? "#1E9F38FF" : "#00000000"
                  }
                />
              </Stack>
            </ListItemButton>
          })}
        </List>
      </Popover>
    </>
  );
};
export default LanguageDropdown;

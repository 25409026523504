import React, { useEffect, useState } from 'react'
import { Grid, Typography, Button, Stack, IconButton, Box } from '@mui/material';
import { Plus } from 'phosphor-react';
import OneCategory from './components/one-category/OneCategory';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AddCategoryModal from '../modals/add-category-modal/AddCategoryModal';
import { getIdCategory, setActiveClass } from '../../../../state/actions/categoriesList/categoriesList';
import { translateField } from '../../../../services/Helper';
import "./CategoriesList.scss"

const CategoriesList = ({ handleShowComponents, categorList }) => {

  const { t } = useTranslation();
  const dispatch = useDispatch()


  const [openAddCategory, setOpenAddCategory] = useState(false);

  const active = useSelector((state) => state.categoryList.active);
  const idCategory = useSelector((state) => state.categoryList.idCategory);


  const handleClickOpenAddCategory = () => {
    setOpenAddCategory(true);
  };

  const handleCloseAddCategory = (value) => {
    setOpenAddCategory(false);
  };

  

  useEffect(() => {
    if (idCategory == 0) {
      if (categorList.length > 0) {
        dispatch(getIdCategory(categorList[0].id))
      }
    } else {
      dispatch(getIdCategory(idCategory))
    }

  }, [categorList.length, idCategory])

  
  const handleClick = (index) => {
    dispatch(setActiveClass(index))
  }

  const handleIdCategory = (idCategory) => {
    dispatch(getIdCategory(idCategory))
  }

  const handleAllClicks = (index, data) => {
    handleClick(index);
    handleShowComponents();
    handleIdCategory(data.id)
  }

  return (
    <>
      <div className='categories-list' style={{ height: "calc(100vh - 165px)" }}>
        <div className='scroll-inside' >
          <Grid container >
            <Grid item xs={12} className="add-category">
              <Grid container >
                <Grid item xs={10}>
                  <Typography className='title'>{t("categories")}</Typography>
                </Grid>
                <Grid item container justifyContent={"flex-end"} xs={2}>
                  <IconButton className='btn-plus-icon' onClick={handleClickOpenAddCategory}>
                    <Plus />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className="list">
              {categorList.map((data, index) => {
                return (
                  <div key={index}>
                    <OneCategory
                      active={index === active ? true : false}
                      title={data.name}
                      idCategory={data.id}
                      categroies={categorList}
                      onClick={() => handleAllClicks(index, data)}
                    />
                  </div>
                )
              })}
            </Grid>
          </Grid>
          <AddCategoryModal open={openAddCategory} onClose={handleCloseAddCategory} />
        </div>
      </div >
    </>
  )
}

export default CategoriesList
import "./ProfileContactInfo.scss"
import React, { useState, useEffect } from "react";
import { Typography, Box, Stack, IconButton } from "@mui/material";
import IconText from "../../../../components/icon-with-text/IconText";
import {
  At,
  Phone,
  FacebookLogo,
  LinkedinLogo,
  TwitterLogo,
  GithubLogo,
  GitlabLogo,
  DribbbleLogo,
  PinterestLogo,
  BehanceLogo,
} from "phosphor-react";
import { useNavigate } from "react-router-dom";
import Show from "../../../../components/Show";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const socialIcons = {
  Facebook: <FacebookLogo size={20} />,
  Twitter: <TwitterLogo size={20} />,
  Linkedin: <LinkedinLogo size={20} />,
  Github: <GithubLogo size={20} />,
  Gitlab: <GitlabLogo size={20} />,
  Dribbble: <DribbbleLogo size={20} />,
  Pinterest: <PinterestLogo size={20} />,
  Behance: <BehanceLogo size={20} />,
}

const ProfileContactInfo = ({ user, justifyLeft }) => {
  const [links, setLinks] = useState([]);


  const { t } = useTranslation();

  useEffect(() => {
    let links = user ? [...user?.CvSocialMedia, ...user?.CvPortfolios] : [];

    setLinks(links);
  }, []);

  return (
    <Box component="section" className="profile-contact-info">
      <Typography
        className="profile-section-title"
        variant="subtitle1"
        component="span"
      >
        {t("contact_info_links")}
      </Typography>
      <Box my={1}>
        <IconText
          searchPage
          icon={
            <a className="common-link" href={`mailto: ${user?.work_email}`}>
              {" "}
              <At size={16} />
            </a>
          }
          text={user?.work_email}
        />
      </Box>
      <Show condition={user?.mobile != null}>
        <Box mb={1}>
          <IconText
            searchPage
            icon={
              <a className="common-link" href={`tel: ${user?.mobile}`}>
                {" "}
                <Phone size={16} />{" "}
              </a>
            }
            text={user?.mobile}
            className={"icon-text-subtitle phone"}
          />
        </Box>
      </Show>
      <Stack
        direction="row"
        justifyContent={justifyLeft ? "flex-start" : `${length <= 3 ? "center" : "space-between"}`}
        alignItems="center"
      >
        {links.map((link) => {
          return (
            socialIcons[link.name] && (
              <IconButton
                color="inherit"
                size="medium"
                edge="false"
                key={Math.random()}
                // component={Link}
                onClick={() => {
                  let url = '';
                  if (!link.link.match(/^https?:\/\//i)) {
                    url = 'https://' + link.link;
                  } else {
                    url = link.link;
                  }
                  window.open(url, '_blank')
                }}
              >
                {socialIcons[link.name]}
              </IconButton>
            )
          );
        })}
      </Stack>
    </Box>
  );
};

export default ProfileContactInfo;

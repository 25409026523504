import React from "react";
import { Divider, Grid, Stack } from "@mui/material";
import Logo from "../../../../../../../../../assets/Logo2SVG";
import Links from "../../../links/Links";
import EnhancedButton from "../../../../../../../yahya/components/enhanced-button/EnhancedButton";
import { CHANGE_MODE } from "../../../../../../../../../state/actions/mode/types";
import { login } from "../../../../../../../../../services/auth/Auth";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import LanguageDropdown from "../../../../../../../abdelrahman/LanguageDropdown/LanguageDropdown";

const DrawerComponent = ({ links, currentSection, handleChange }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const mode = useSelector((state) => state.mode.name);

  return (
    <Grid container direction="row">
      <Grid item sx={{ marginBottom: "40px" }} xs={12}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Logo />
          <LanguageDropdown />
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Links
          links={links}
          orientation="vertical"
          mobileClass
          currentSection={currentSection}
          handleChange={handleChange}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ border: "1px solid #C7C7C7", marginBottom: "40px" }} />
      </Grid>
      <Grid item xs={12}>
        <Grid container columnSpacing={2} justifyContent={"center"}>
          <Grid item xs={6} md={4}>
            <EnhancedButton
              buttonText={mode === "guestApplicant" ? t("hire") : t("find_a_job")}
              variant={"outlined"}
              padding={"16px 0px"}
              onClick={() => {
                console.log("guestRecruiter");
                dispatch({
                  type: CHANGE_MODE,
                  payload: {
                    name:
                      mode === "guestApplicant"
                        ? "guestRecruiter"
                        : "guestApplicant",
                  },
                });
              }}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <EnhancedButton
              buttonText={t("login")}
              variant={"contained"}
              padding={"16px 0px"}
              onClick={() => {
                dispatch({
                  type: CHANGE_MODE,
                  payload: {
                    name: mode === "guestApplicant" ? "applicant" : "recruiter",
                  },
                });
                login(i18n.language);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DrawerComponent;

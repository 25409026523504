import React from "react";

import {
  AppBar,
  CssBaseline,
  Grid,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Logo from "../../../../../assets/Logo2SVG";
import Links from "./components/links/Links";
import MobileNavbar from "./components/mobile-navbar/MobileNavbar";
import "./Navbar.css";
import Show from "../../../../../components/Show";
import GuestApplicantContent from "../../../abdelrahman/GuestApplicantContent/GuestApplicantContent";
import GuestRecruiterContent from "../../../abdelrahman/GuestRecruiterContent/GuestRecruiterContent";
import RecruiterContent from "../../../abdelrahman/RecruiterContent/RecruiterContent";
import ApplicantContent from "../../../abdelrahman/ApplicantContent/ApplicantContent";

const Navbar = ({ mode, top, currentSection, handleSelection }) => {
  const theme = useTheme();

  return (
    <AppBar className={`navbar${top ? "" : " scrolling"}`} id="navbar">
      <CssBaseline />
      <Show condition={mode === "guestApplicant"}>
        <GuestApplicantContent
          currentSection={currentSection}
          handleSelection={handleSelection}
        />
      </Show>
      <Show condition={mode === "guestRecruiter"}>
        <GuestRecruiterContent
          currentSection={currentSection}
          handleSelection={handleSelection}
        />
      </Show>
      <Show condition={mode === "recruiter"}>
        <RecruiterContent
          currentSection={currentSection}
          handleSelection={handleSelection}
        />
      </Show>
      <Show condition={mode === "applicant"}>
        <ApplicantContent
          currentSection={currentSection}
          handleSelection={handleSelection}
        />
      </Show>
    </AppBar>
  );
};

export default Navbar;

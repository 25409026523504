import { CLOSE_LOGIN_MODAL, OPEN_LOGIN_MODAL } from "../../actions/login-modal/types";

let initialState = {
    opened: false
}

export const loginModalReducer = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_LOGIN_MODAL:
            return { ...state, opened: true };
        case CLOSE_LOGIN_MODAL:
            return { ...state, opened: false };
        default:
            return state;
    }
}

export default loginModalReducer;
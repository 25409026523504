import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import noDataAnimation from "../../assets/lootie-files/no-data.json";
import { getJob, translateField,applyJob } from "services/Helper";
import EnhancedMiniJobCard from "views/pages/abdelrahman/EnhancedMiniJobCard/EnhancedMiniJobCard";
import ErrorPage from "../error-page/ErrorPage";
import Hide from "../Hide";
import JobDetailsCard from "../job-details-card/JobDetailsCard";
import Loader from "../loader/Loader";
import Pagination from "../pagination/Pagination";
import Scroller from "../Scroller";
import "./BrowsingPage.scss";

// const noDataAnimation = React.lazy(() => import("../../assets/lootie-files/no-data.json"));

const BrowsingPage = ({
  data,
  showFilter,
  title,
  pagesManager,
  ready,
  showStatus,
}) => {
  const [selected, setSelected] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [changeXs, setChangeXs] = useState(false);
  const [loading, setLoading] = useState(false);
  const matches = useMediaQuery("(max-width:900px)");
  const navigate = useNavigate();
  const role = useSelector((state) => state.profile.role.name);

  const mdMatches = useMediaQuery("(min-width:701px) and (max-width:1300px)");
  const smMatches = useMediaQuery("(max-width:700px)");

  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();


  const fetchJob = (id) => {
    if (matches) {
      navigate(`/jobs/${id}`);
    } else {
      setSelected(id);
      getJob(id).then((job) => {
        setSelectedJob(job);
        document.getElementById("job_details_wrap").scrollTop = 0;
      });
    }
  };
  useEffect(() => {
    !matches && data?.[0]?.id && fetchJob(data?.[0]?.id);
  }, [data]);

  const [noDataAnimation, setNoDataAnimation] = useState(null);
  useEffect(() => {
    import("../../assets/lootie-files/no-data.json").then((data) =>
      setNoDataAnimation(data)
    );
  }, []);

 
  return (
    <>
      <Grid
        container
        direction={"row"}
        spacing={3}
        className="browsing-page"
        // paddingX={1}
        style={{ height: "calc(100vh - 165px)" }}
        sx={{ position: "relative" }}
      >
        <Grid item={true} xs={12}>
          <Typography className="header">{title}</Typography>
        </Grid>
        {data.length > 0 ? (
          <>
            {showFilter && (
              <></>
              // <Grid item xs={12}>
              //   {/* filter Hidden for the next sprint
              //   <Filter /> */}
              // </Grid>
            )}
            <Grid
              item
              px={0.75}
              xs={12}
              mt={1}
              component={"section"}
              sm={12}
              md={4}
              lg={5.5}
              xl={4}
              className="scroll-inside"
              id="jobs_wrap"
              sx={{ paddingX: "20px" }}
            >
              <Scroller id={"browsing-page-top"} />
              <Grid container justifyContent="center" alignItems={"flex-start"}>
                {data.map((job, index) => (
                  <Grid item xs={12} marginBottom={1} key={index}>
                    <Loader
                      data={ready}
                      width={"100%"}
                      height={"200px"}
                      style={{ borderRadius: "1rem" }}
                    >
                      <EnhancedMiniJobCard
                        selected={selected === job?.id}
                        noOfApplicants={job?.count}
                        title={`${translateField(job?.JobTitle, "name")}`}
                        location={`${translateField(
                          job?.Country,
                          "name"
                        )}, ${translateField(
                          job?.Governorate,
                          "name"
                        )}, ${translateField(job?.City, "name")}`}
                        company={`${job?.Recruiter?.company_name}`}
                        logo={job?.Recruiter?.image}
                        type={`${translateField(job?.ExperienceType, "name")}`}
                        date={job?.created_at}
                        url={`https://${window.location.host}/jobs/${job?.id}`}
                        onClick={() => {
                          console.log("job.id", job?.id);
                          fetchJob(job?.id);
                          document.getElementById("details_wrap").scrollTop = 0;
                        }}
                        tags={[
                          translateField(job?.WorkStyle, "name"),
                          translateField(job?.ExperienceType, "name"),
                        ]}
                        // showLogo
                        active={job?.status}
                        jobId={job?.id}
                        jobStatus={
                          job?.ApplicantApplyJobs?.[0]?.JobStatus?.name_en
                        }
                        AppliedJobsPage
                        isApplied={job?.is_applied}
                        isSaved={job?.is_saved}
                      // selectable
                      // status
                      />
                    </Loader>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            {selectedJob && (
              <Hide condition={matches}>
                <Grid
                  item
                  md={8}
                  lg={6.1}
                  xl={8}
                  className="scroll-inside"
                  id="job_details_wrap"
                >
                  <Loader
                    data={ready}
                    width={"100%"}
                    height={"100%"}
                    style={{ borderRadius: "1rem" }}
                  >
                    <JobDetailsCard
                      hiringTime={selectedJob?.HiringTime}
                      external_apply_website={selectedJob?.external_apply_website}
                      stickyApply={true}
                      showStatus={true}
                      jobId={selectedJob?.id}
                      recruiterId={selectedJob?.Recruiter?.id}
                      fetchJob={fetchJob}
                      jobTitle={translateField(selectedJob?.JobTitle, "name")}
                      date={selectedJob?.created_at}
                      location={
                        translateField(selectedJob?.Governorate, "name") +
                        ", " +
                        translateField(selectedJob?.Country, "name")
                      }
                      type={translateField(selectedJob?.ExperienceType, "name")}
                      noOfApplicants={selectedJob?.count}
                      yearsOfEx={translateField(
                        selectedJob?.YearsOfExperience,
                        "name"
                      )}
                      jobCategory={translateField(
                        selectedJob?.JobCategory,
                        "name"
                      )}
                      slaryRange={
                        selectedJob?.salary_from && selectedJob?.salary_to
                          ? selectedJob?.salary_from +
                          " - " +
                          selectedJob?.salary_to
                          : translateField(selectedJob?.Salary, "name")
                      }
                      currency={selectedJob?.currency}
                      jobDescreption={selectedJob?.job_descirption}
                      jobRequirement={selectedJob?.job_requirement}
                      companyName={selectedJob?.Recruiter?.company_name}
                      companyLogo={selectedJob?.Recruiter?.image}
                      followed={selectedJob?.Recruiter?.is_followed == 1}
                      companySize={translateField(
                        selectedJob?.Recruiter?.CompanySize,
                        "name"
                      )}
                      showBottomShare
                      isApplied={selectedJob?.is_applied}
                      isSaved={selectedJob?.is_saved}
                      experienceType={translateField(
                        selectedJob?.ExperienceType,
                        "name"
                      )}
                      viewDetails
                      careerLevel={translateField(
                        selectedJob?.CareerLevel,
                        "name"
                      )}
                      workStyle={translateField(selectedJob?.WorkStyle, "name")}
                      jobStatus={
                        selectedJob?.ApplicantApplyJobs?.[0]?.JobStatus
                          ?.name_en === "Pending"
                          ? selectedJob?.ApplicantApplyJobs?.[0]?.is_seen
                            ? "Seen"
                            : selectedJob?.ApplicantApplyJobs?.[0]?.JobStatus
                              ?.name_en
                          : selectedJob?.ApplicantApplyJobs?.[0]?.JobStatus
                            ?.name_en
                      }
                      showUserThreeicon
                    />
                  </Loader>
                </Grid>
              </Hide>
            )}
          </>
        ) : (
          ready && (
            <Grid item xs={12} justifyContent="center">
              <ErrorPage
                actionText={`${t("back_to")} ${t("home")}`}
                animationData={noDataAnimation}
                title={t("oops")}
                description={t("no_jobs")}
                action={() => navigate("/")}
              />
            </Grid>
          )
        )}
        <Grid
          item
          xs={12}
          container
          justifyContent={{ xs: "center", sm: "center", md: "space-between" }}
          alignItems={"center"}
        >
          {pagesManager.numberOfPages > 1 && (
            <Grid
              item
              mt={4}
              component={"section"}
              xs={12}
              sm={12}
              md={4}
              lg={5.5}
              xl={4}
              px={0.75}
            >
              <Box
                mt={1}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Pagination pagesManager={pagesManager} changeMdLg={true} />
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};
export default BrowsingPage;

import './EnhancedLayout.scss';

import { Box, Container, Stack, useMediaQuery } from '@mui/material';
import { Suspense, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Show from '../../../../components/Show';
import { requestForToken } from '../../../../firebase';
import { addDeviceToken, getUnreadNotificationsCount } from '../../../../services/Helper';
import { CHANGE_MODE } from '../../../../state/actions/mode/types';
import { NEW_NOTIFICATION, OPENED } from '../../../../state/actions/notification/types';
import Redirecting from '../../redirecting/Redirecting';
import Navbar from '../../zeinnab/components/navbar/Navbar';
import Footer from '../Footer/Footer';
import { appStores, description, logo, sections, socialMedia } from './footerData';
import { MAIN_PAGES } from '../../../../constants/constants';

const EnhancedLayout = () => {
    const mdMatches = useMediaQuery('(min-width:701px) and (max-width:1300px)');
    const smMatches = useMediaQuery('(max-width:700px)');

    const [y, setY] = useState(window.scrollY);
    const [sectionPositions, setSectionPositions] = useState([]);
    const [currentSection, setCurrentSection] = useState('home');
    const [scrolling, setScrolling] = useState('up');
    const [screenPadding, setScreenPadding] = useState(null);
    const mode = useSelector((state) => state.mode.name);
    const profile = useSelector((state) => state.profile.data);
    const b2cLogin = useSelector((state) => state.b2cLogin);
    const loaded = useSelector((state) => state.profile.loaded);
    const role = useSelector((state) => state.profile.role?.name);
    const loading = useSelector((state) => state.loading.loading);
    const location = useLocation();
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const defaultSelection = ['/about', '/terms', '/privacy', '/help'];

    const handleNavigation = useCallback(
        (e) => {
            const window = e.currentTarget;
            console.log('window.isNavigating', window.isNavigating);
            if (!window.isNavigating) {
                if (window.location.pathname === '/') {
                    for (let i = 0; i < sectionPositions.length; i++) {
                        if (y < sectionPositions[0].actualEnd) {
                            setCurrentSection(sectionPositions[0].id);
                            break;
                        } else if (i === sectionPositions.length - 1 && y > sectionPositions[sectionPositions.length - 1].actualEnd) {
                            setCurrentSection(sectionPositions[sectionPositions.length - 1].id);
                        } else if (y >= sectionPositions[i]?.actualStart && y < sectionPositions[i]?.actualEnd) {
                            setCurrentSection(sectionPositions[i].id);
                            break;
                        }
                    }
                }

                setY(window.scrollY);
            }
        },
        [y],
    );

    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener('scroll', handleNavigation);

        return () => {
            window.removeEventListener('scroll', handleNavigation);
        };
    }, [handleNavigation]);

    useEffect(() => {
        if (window.location.pathname === '/' && mode.includes('guest')) {
            const timer = setTimeout(() => {
                var sections = [...document.getElementsByTagName('section')];
                var positions = [];
                var ids = [];
                sections.map((section) => {
                    if (section.id) {
                        let position = document.getElementById(section.id);
                        position.actualStart = Math.max(
                            0,
                            position.offsetTop - parseInt(window.getComputedStyle(position).marginTop.replace('px', '')) - document.getElementById('navbar').clientHeight,
                        );
                        position.actualEnd = position.offsetTop + position.clientHeight - document.getElementById('navbar').clientHeight;

                        console.log('POSITION', position);
                        positions.push(position);
                    }
                });
                console.log('POSITIONS', positions);
                setSectionPositions(positions);
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [window.location.pathname, mode]);

    useEffect(() => {
        if (MAIN_PAGES.includes(window.location.pathname)) {
            setScreenPadding(null);
        } else {
            setScreenPadding('40px');
        }
        if (window.location.pathname !== '/') {
            window.scrollTo({
                top: 0,
                // behavior: "smooth",
            });
        }
    }, [window.location.pathname]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            // behavior: "smooth",
        });

        setTimeout(() => {
            setCurrentSection('home');
        }, 100);
    }, [mode]);

    const handleSelection = (event, newValue) => {
        setCurrentSection(newValue);
        // if (window.location.pathname === "/") {
        //   setCurrentSection(newValue);
        // }
    };

    const isCvCreated = useSelector((state) => state.profile.data?.cv?.User?.is_cv_created);
    const isCompanyCreated = useSelector((state) => state.profile.data?.recruiter?.is_company_created);
    useEffect(() => {
        if (loaded) {
            if (isCompanyCreated == 0 && !isCvCreated) {
                navigate('/recruiter-submit', { state: { switched: true } });
            } else if (isCvCreated == 0 && !isCompanyCreated) {
                navigate('/applicant-submit', { state: { switched: true } });
            }
        }
    }, [isCvCreated, isCompanyCreated]);

    useEffect(() => {
        if (role !== 'GUEST_USER' && role !== 'REGISTERD_USER')
            getUnreadNotificationsCount(role, isCvCreated == 0 || isCompanyCreated == 0).then((data) => {
                const count = data?.unread_notification_count;
                if (count > 0) dispatch({ type: NEW_NOTIFICATION });
                else dispatch({ type: OPENED });
            });
    }, [location.pathname, role]);

    useEffect(async () => {
        const requestTokenFromFirebase = async () => {
            return await requestForToken();
        };
        if (role != 'GUEST_USER' && role != 'REGISTERD_USER') {
            requestTokenFromFirebase().then((res) => {
                if (res) addDeviceToken({ device_token: res }, role, isCvCreated == 0 || isCompanyCreated == 0);
            });
        }
        console.log('location.pathname', location.pathname);
        if (location.pathname === '/contact') {
            setCurrentSection('contact-enhanced');
        } else if (defaultSelection.includes(location.pathname)) {
            setCurrentSection('home');
        }

        if (!window.location.pathname.includes('/search')) {
            console.log('DOES NOT INCLUDE SEARCH');
            await dispatch({
                type: `SEARCH_FILTER`,
                payload: '',
            });
        }
    }, [location.pathname]);

    useEffect(() => {
        if (role === 'RECRUITER') {
            dispatch({
                type: CHANGE_MODE,
                payload: { name: 'recruiter' },
            });
        } else if (role === 'APPLICANT') {
            dispatch({
                type: CHANGE_MODE,
                payload: { name: 'applicant' },
            });
        }
    }, [role]);

    return (
        <>
            <Show condition={loading}>
                <Redirecting />
            </Show>
            <Stack>
                <Navbar top={y === 0} mode={mode} currentSection={currentSection} handleSelection={handleSelection} />
                <Stack direction={'column'} spacing={17}>
                    <Container maxWidth={'xl'} className="outlet-container">
                        <Box paddingX={mdMatches ? '50px' : smMatches ? '0px' : '100px'} paddingTop={screenPadding}>
                            <Suspense fallback={<div></div>}>
                                <Outlet />
                            </Suspense>
                        </Box>
                    </Container>
                    <Footer socialMedia={socialMedia} appStores={appStores} logo={logo} description={description} items={sections} />
                </Stack>
            </Stack>
        </>
    );
};
export default EnhancedLayout;

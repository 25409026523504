import { Box, Grid, useMediaQuery } from '@mui/material';
import { t } from 'i18next';
import { Briefcase, Buildings, UsersThree } from 'phosphor-react';
import React from 'react';
import StatsCard from '../../../../../abdelrahman/StatsCard/StatsCard';
import HeaderText from '../../../../components/HeaderText/HeaderText';
import SubtitleText from '../../../../components/sub-title-text/SubtitleText';
import "./StatsInfoSecion.css";

const data = [
    { stats: "+3000", type: "Applicant", icon: <UsersThree width="47px" height="34px" weight="fill" color="#fff" /> },
    { stats: "+1500", type: "Recruiter", icon: <Buildings width="45px" height="38px" weight="fill" color="#fff" /> },
    { stats: "+4500", type: "Jobs", icon: <Briefcase width="39px" height="36px" weight="fill" color="#fff" /> },
]
const StatsInfoSection = () => {
    const smMatch = useMediaQuery('(max-width: 890px)')
    return (
        <Box >
            <Grid container direction={"row"} className="stats-info-section" justifyContent={"center"} alignItems={"center"}>
                <Grid item >
                    <HeaderText text={t("what_is_there_for_you")} fontSize={smMatch ? "30px" : "40px"} fontWeight={"800"} />
                </Grid>
                <Grid item xs={12} sx={{ marginTop: "24px", marginBottom: "40px" }}>
                    <Box sx={{ padding: smMatch ? "0 30px" : "0 150px", textAlign: "center" }}  >
                        <SubtitleText title={t("what_is_there_desc")} />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={10} md={11} lg={12} container spacing={2} justifyContent={"center"} direction={{ md: "column", lg: "row" }}>
                    <Grid item xs={12} lg={3.5}>
                        <Box sx={{ padding: smMatch ? "0 20px" : "0" }}  >
                            <StatsCard
                                type={t("applicant")}
                                stats={"+3000"}
                                icon={<UsersThree width="47px" height="34px" weight="fill" color="#fff" />}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={3.5}>
                        <Box sx={{ padding: smMatch ? "0 20px" : "0" }}  >
                            <StatsCard
                                type={t("recruiter")}
                                stats={"+1500"}
                                icon={<Buildings width="45px" height="38px" weight="fill" color="#fff" />}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={3.5}>
                        <Box sx={{ padding: smMatch ? "0 20px" : "0" }}  >
                            <StatsCard
                                type={t("radio_filter_jobs")}
                                stats={"+4500"}
                                icon={<Briefcase width="39px" height="36px" weight="fill" color="#fff" />}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Box >
    )
}

export default StatsInfoSection
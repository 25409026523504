import { Grid, useMediaQuery } from "@mui/material";
import { t } from 'i18next';
import { ArrowRight, Phone } from "phosphor-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import ContactCard from "../../../../components/contact-card/ContactCard";
import HeaderText from "../../../../components/HeaderText/HeaderText";

const NeedhelpSection = () => {
  const smMatch = useMediaQuery("(max-width: 899px)");
  const navigate = useNavigate();

  const data = [
    {
      title: t("contact_sale"),
      info: t("contact_sales_desc"),
      text: "+20 123 1254 236",
      icon: <Phone size={19} weight="fill" />,
      iconDirection: "iconLeft",
      onClick: () => (document.location.href = "tel:+201231254236"),
    },
    {
      title: t("contact_customer_support"),
      info: t("contact_support_desc"),
      text: "Contact Now",
      icon: <ArrowRight size={19} weight="fill" />,
      iconDirection: "iconRight",
      onClick: () => navigate("/contact"),
    },
  ];

  return (
    <Grid
      container
      direction={"row"}
      justifyContent={"center"}
      sx={{ marginTop: "80px" }}
    >
      <Grid item sx={{ marginBottom: "32px" }}>
        <HeaderText text={t("need_help")} fontSize={"40px"} fontWeight={"800"} />
      </Grid>
      <Grid item>
        <Grid container direction={"row"} justifyContent={"center"} spacing={2}>
          <Grid item xs={12} md={5.5}>
            <ContactCard
              title={t("contact_sale")}
              text={"+20 123 1254 236"}
              icon={<Phone size={19} weight="fill" />}
              info={t("contact_sales_desc")}
              iconDirection={"iconLeft"}
              onClick={() => (document.location.href = "tel:+201231254236")}
            />

          </Grid>
          <Grid item xs={12} md={5.5}>
            <ContactCard
              title={t("contact_customer_support")}
              text={t("contact_now")}
              icon={<ArrowRight size={19} />}
              info={t("contact_support_desc")}
              iconDirection={"iconRight"}
              onClick={() => navigate("/contact")}
            />

          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NeedhelpSection;

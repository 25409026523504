import {
  Avatar,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  At,
  Checks,
  PencilSimple,
  Phone,
  ShareNetwork,
  UsersThree,
} from "phosphor-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DecisionButton from "../../../../components/decision-button/DecisionButton";
import Hide from "../../../../components/Hide";
import Show from "../../../../components/Show";
import Permissions from "../../../../constants/permissions";
import { changeCompanyStatus, perm } from "../../../../services/Helper";
import ShareJobPost from "../../../job-details/components/share-job-post/ShareJobPost";
import "./ProfileCard.scss";

const ProfileCard = ({
  logo,
  name,
  industry,
  companySize,
  email,
  phone,
  location,
  description,
  followed,
  id,
  viewProfile,
  searchPage,
  recruiterId,
  fetchCompany,
}) => {
  console.log(recruiterId);
  const navigate = useNavigate();
  const loc = useLocation();

  const params = useParams();

  const { t, i18n } = useTranslation();

  const toggleDivider = useMediaQuery("(max-width:890px)");
  const isXs = useMediaQuery("(max-width:890px)");

  const permissions = useSelector((state) => state.profile.role?.permissions);
  const role = useSelector((state) => state.profile?.role.name);
  const companyId = useSelector((state) => state.profile?.data?.recruiter?.id);

  const [isFollowed, setIsFollowed] = useState(followed);

  const [openShare, setOpenShare] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleFollow = () => {
    // setLoading(true);
    // changeCompanyStatus(id).then((res) => {
    //   setIsFollowed(!isFollowed);
    //   setLoading(false);
    // });
    if (!loading) {
      setLoading(true);
      changeCompanyStatus(recruiterId, role)
        .then(async (res) => {
          await fetchCompany?.(recruiterId);
          setIsFollowed(!isFollowed);
          // setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleClickOpenShare = () => {
    setOpenShare(true);
  };

  const handleCloseShare = (value) => {
    setOpenShare(false);
  };
  const handleViewFullProfile = () => {
    navigate(`/company/${recruiterId}`);
  };
  return (
    <Grid className="company-profile-card" direction="column">
      <Grid item xs={12} container direction="column">
        <Grid item xs={12} container direction="row">
          {searchPage ? (
            <>
              <Grid item xs={3} lg={7}>
                <Avatar className="image" variant="rounded" src={logo} />
                <Typography className="name">{name}</Typography>
                <Typography className="info">{industry}</Typography>
                <div className="container">
                  <UsersThree />
                  <Typography className="company-size">
                    {companySize}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={9} lg={5} container direction={"row"} spacing={1}>
                <Grid item xs={6}>
                  <DecisionButton active>
                    <Button
                      onClick={handleViewFullProfile}
                      className="btn btn-dark view-profile-btn"
                    >
                      {" "}
                      {t("view-full-profile")}
                    </Button>
                  </DecisionButton>
                </Grid>
                <Grid item xs={6}>
                  <DecisionButton
                    active={!isFollowed}
                    onClick={handleFollow}
                    disabled={loading}
                  >
                    {isFollowed ? (
                      <>
                        <Checks className="followed-icon" />
                        <Typography className="followed">
                          {t("followed")}
                        </Typography>
                      </>
                    ) : (
                      <Typography className="follow">
                        {t("follow")}
                      </Typography>
                    )}
                  </DecisionButton>
                </Grid>
              </Grid>

              {/* <Grid item xs={9} container direction="row" justifyContent={"flex-end"}>
                <Grid item xs={5} >
                  <Show
                    condition={
                      params.company_id
                        ? perm(permissions, Permissions.editCompanyProfile) &&
                        params.company_id == companyId
                        : perm(permissions, Permissions.editCompanyProfile)
                    }
                  >
                    <IconButton
                      className="edit-icon"
                      onClick={() => navigate("/company/edit")}
                    >
                      <PencilSimple />
                    </IconButton>
                  </Show>

                  <Show
                    condition={
                      !perm(permissions, Permissions.editCompanyProfile)
                    }
                  >
                    <DecisionButton active>
                      <Button
                        onClick={handleViewFullProfile}
                        className="btn btn-dark view-profile-btn"
                      >
                        {" "}
                        {t("view-full-profile")}
                      </Button>
                    </DecisionButton>
                    <DecisionButton
                      active={!isFollowed}
                      onClick={handleFollow}
                      disabled={loading}
                    >
                      {isFollowed ? (
                        <>
                          <Checks className="followed-icon" />
                          <Typography className="followed">
                            {t("followed")}
                          </Typography>
                        </>
                      ) : (
                        <Typography className="follow">
                          {t("follow")}
                        </Typography>
                      )}
                    </DecisionButton>
                  </Show>
                </Grid>

                <Grid xs={1} sx={{ marginLeft: "20px" }}>
                  <Show condition={loc.pathname == "/my-profile/company"}>
                    <Grid >
                      <IconButton className="icons" onClick={handleClickOpenShare}>
                        <ShareNetwork />
                      </IconButton>
                    </Grid>
                  </Show>
                </Grid>

              </Grid> */}
            </>
          ) : (
            <>
              <Grid item xs={8} md={10}>
                <Avatar className="image" variant="rounded" src={logo} />
                <Typography className="name">{name}</Typography>
                <Typography className="info">{industry}</Typography>
                <div className="container">
                  <UsersThree />
                  <Typography className="company-size">
                    {companySize}
                  </Typography>
                </div>
              </Grid>

              <Grid
                item
                xs={4}
                md={2}
                container
                direction="row"
                spacing={2}
                justifyContent={"flex-end"}
              >
                <Grid item>
                  <Show condition={loc.pathname == "/my-profile/company"}>
                    <IconButton
                      className="icons"
                      onClick={handleClickOpenShare}
                    >
                      <ShareNetwork />
                    </IconButton>
                  </Show>
                </Grid>
                <Grid item>
                  <Show
                    condition={
                      params.company_id
                        ? perm(permissions, Permissions.editCompanyProfile) &&
                          params.company_id == companyId
                        : perm(permissions, Permissions.editCompanyProfile)
                    }
                  >
                    <IconButton
                      className="edit-icon"
                      onClick={() => navigate("/company/edit")}
                    >
                      <PencilSimple />
                    </IconButton>
                  </Show>
                </Grid>
                <Show
                  condition={
                    !perm(permissions, Permissions.editCompanyProfile) &&
                    role?.name !== "GUEST_USER"
                  }
                >
                  <Grid item>
                    {viewProfile ? (
                      <DecisionButton active>
                        <Button
                          onClick={handleViewFullProfile}
                          className="btn btn-dark view-profile-btn"
                        >
                          {" "}
                          {t("view-full-profile")}
                        </Button>
                      </DecisionButton>
                    ) : (
                      <DecisionButton
                        active={!isFollowed}
                        onClick={handleFollow}
                        disabled={loading}
                      >
                        {isFollowed ? (
                          <>
                            <Checks className="followed-icon" />
                            <Typography className="followed">
                              {t("followed")}
                            </Typography>
                          </>
                        ) : (
                          <Typography className="follow">
                            {t("follow")}
                          </Typography>
                        )}
                      </DecisionButton>
                    )}
                  </Grid>
                </Show>
              </Grid>
            </>
          )}
        </Grid>
        <Divider sx={{ margin: "10px 0" }} />
        <Grid
          item
          xs={12}
          className="contact-info"
          sx={{
            marginTop: isXs ? "16px" : "0",
          }}
        >
          <Typography className="title">{t("contact_info")}</Typography>
          <div className="container">
            <At className="icon" />
            <Typography className="desc">{email}</Typography>
          </div>
          <div className="container">
            <Phone className="icon" />
            <Typography className="desc">{phone}</Typography>
          </div>
          <div className="location">
            <Typography className="title">
              {t("headquarters")}
            </Typography>
            <Typography className="desc">{location}</Typography>
          </div>
        </Grid>
        <Divider sx={{ margin: "10px 0" }} />
      </Grid>
      <Grid item xs={12} className="about">
        <Typography className="title">{t("about")}</Typography>
        <Typography className="desc">{description}</Typography>
      </Grid>

      <ShareJobPost
        // selectedValue={selectedValue}
        open={openShare}
        onClose={handleCloseShare}
        id={recruiterId}
        type="Company"
      />
    </Grid>
  );
};

export default ProfileCard;

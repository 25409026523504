import { yupResolver } from "@hookform/resolvers/yup";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import {DatePicker} from "@mui/lab";
// import { MobileDatePicker } from "@mui/lab";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';


import {
  Box,
  Button,
  ClickAwayListener,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import moment from "moment";
import {
  Briefcase,
  GenderFemale,
  GenderMale,
  Image,
  User,
  UserCircle,
  XCircle
} from "phosphor-react";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { getStep } from "../../../services/Stepper";
import { nextStep } from "../../../state/actions/stepper/stepper";
import { stepperData } from "./stepper";

//shared component
import ChoiceInput from "../../../components/ChoiceInput";
import IconInput from "../../../components/IconInput";
import JLSelect from "../../../components/JLSelect";
import { IMAGE_TYPES } from "../../../constants/constants";
import {
  getCity,
  getCountry,
  getGovernorate,
  getJobTitles,
  getMarital,
  getMilitary,
  getNationality,
  getSeniorityLevel
} from "../../../services/Helper";
import {
  addGeneralInfo,
  deleteProfileImage,
  updateGeneralInfo
} from "../../../state/actions/profile/profile";

import { translateField } from "../../../services/Helper";
const GeneralInfo = () => {
  const dispatch = useDispatch();
  const stepFinished = useSelector(
    (state) => state.profile.data?.cv?.User?.step_finished
  );
  const currentStepNo = useSelector((state) => state.stepper.currentStep);
  const finalStepNo = useSelector((state) => state.stepper.finalStep);
  const user = useSelector((state) => state.profile.data?.cv?.User);
  const current = getStep(stepperData, currentStepNo);
  const nats = useSelector((state) => {
    let returned = [];
    state?.profile.data?.cv?.User?.UserNationalities?.forEach((value) => {
      returned.push(value.Nationality);
    });

    return returned;
  });
  const [military, setMilitary] = useState(null);
  const [marital, setMarital] = useState(null);
  const [image, setImage] = useState(null);
  const [countries, setCountries] = useState(null);
  const [countryId, setCountryId] = useState(null);
  const [governorates, setGovernorates] = useState(null);
  const [cities, setCities] = useState(null);
  const [careerLevels, setCareerLevels] = useState(null);
  const [nationalities, setNationalities] = useState(null);
  const [currentImg, setCurrentImg] = useState(user?.image);
  const [currentImgFile, setCurrentImgFile] = useState(null);

  //real-time-search
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState(searchTerm);
  const [jobTitles, setJobTitles] = useState([]);
  const [openJobTitlesMenu, setOpenJobTitlesMenu] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [anchorRef, setAnchorRef] = useState(null)
  const anchorRef = React.useRef();

  useEffect(() => {
    const timer = setTimeout(() => setSearchTerm(debouncedTerm), 500);
    return () => clearTimeout(timer);
  }, [debouncedTerm]);

  useEffect(() => {
    if (searchTerm.trim().length <= 45 && searchTerm.trim().length !== 0) {
      handleJobTitleChange(searchTerm.trim());
    } else {
      setDebouncedTerm("");
      setSearchTerm("");
    }
  }, [searchTerm]);

  const handleJobTitleChange = (searchTerm) => {
    getJobTitles(searchTerm).then((res) => {
      setJobTitles(res);
      setOpenJobTitlesMenu(true);
    });
  };
  const handleBack = () => {
    if (current?.prev) {
      dispatch(
        nextStep({ currentStep: current?.prev, finalStep: finalStepNo })
      );
    }
  };
  const handleNext = () => {
    if (current.next) {
      dispatch(nextStep({ currentStep: current.next, finalStep: finalStepNo }));
    }
  };
  const onCountryIdChange = (e) => {
    setCountryId(e.target.value);
  };
  // functionality for Gender
  const [gender, setGender] = useState();
  useEffect(() => {
    setGender(user?.gender_id ? user?.gender_id : 1);
  }, [user?.gender_id]);

  function handleGenderMale() {
    setGender(1);
  }
  const handleGenderFemale = () => {
    setGender(2);
  };
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
    }
  };
  //Date
  const [age, setAge] = React.useState(new Date());
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (
      typeof currentImg !== "string" &&
      currentImg !== null &&
      currentImg !== undefined
    ) {
      setCurrentImg(URL.createObjectURL(currentImg));
    }
  }, [currentImg]);
  const futureDateMessage = t("future_date_all");
  const pastDateMessage = t("past_date");
  const invalideDateMessage = t("invalid_date");
  const schema = yup
    .object({
      first_name: yup
        .string()
        .required(t("required_input"))
        .matches(/^[aA-zZ\s\u0600-\u06FF]+$/, t("only_alphapet"))
        .min(3, t("min_input", { number: 3 }))
        .max(15, t("max_input", { number: 15 })),
      second_name: yup
        .string()
        .required(t("required_input"))
        .matches(/^[aA-zZ\s\u0600-\u06FF]+$/, t("only_alphapet"))
        .min(3, t("min_input", { number: 3 }))
        .max(15, t("max_input", { number: 15 })),
      job_title: yup
        .string()
        .required(t("required_input"))
        .min(3, t("min_input", { number: 3 }))
        .max(45, t("max_input", { number: 45 }))
        .matches(
          /[a-zA-Z\u0600-\u06FF][\w\[\]`!@#$%\^&*()={}:;<>+'-]*/,
          t("contain_alphapet")
        ),
      nationality_id: yup
        .array()
        .test(
          "nationality_id",
          t("required_selection"),
          (value, context) => {
            return value && value.length > 0;
          }
        ),
      // military_id: yup.string().required(t("required_input")),
      city_id: yup
        .string()
        .required(t("required_input"))
        .not([null]),
      marital_id: yup.string().required(t("required_input")),
      // career_level_id: yup.string().required(t("required_input")),
      bio: yup
        .string()
        .required(t("required_input"))
        .matches(
          /[a-zA-Z\u0600-\u06FF][\w\[\]`!@#$%\^&*()={}:;<>+'-]*/,
          t("contain_alphapet")
        )
        .min(3, t("min_input", { number: 3 }))
        .max(500, t("max_input", { number: 500 })),
      birth_of_date: yup
        .date()
        .nullable()
        .min(new Date("1960"), pastDateMessage)
        .max(new Date(), futureDateMessage)
        .test("birth_of_date", t("valid_date"), (date) => {
          if (date == null) {
            return true;
          }
          return moment().diff(moment(date), "years") >= 8;
        }),

      image: yup
        .mixed()
        .test("fileFormat", t("file_type"), (value) => {
          if (
            typeof value === "string" ||
            value == null ||
            value == undefined
          ) {
            return true;
          }
          if (value === null) {
            return true;
          }
          return value && IMAGE_TYPES.includes(value.type);
        })
        .test("fileSize", t("file_size", { number: 5, unit: "MB" }), (value) => {
          if (
            typeof value === "string" ||
            value == null ||
            value == undefined
          ) {
            return true;
          }
          if (value === null) {
            return true;
          }
          return value && value.size / 1024 / 1024 < 5;
        }),
    })
    .required();
  const {
    control,
    register,
    formState: { errors },
    setError,
    handleSubmit,
    setValue,
    setFocus,
    trigger,
    getValues,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      first_name: user?.first_name,
      second_name: user?.second_name,
      job_title: user?.job_title
        ? user?.job_title
        : translateField(user?.JobTitle, "name"),
      bio: user?.bio,
      gender_id: user?.gender,
      image: user?.image,
      city_id: user?.City?.id,
      birth_of_date: user?.birth_of_date,
      military_id: user?.Military?.id,
      marital_id: user?.Marital?.id,
      country_id: user?.City?.Governorate?.country,
      governorate_id: user?.City?.Governorate?.id,
      job_title_id: user?.JobTitle ? user?.JobTitle?.id : null,
      career_level_id: user?.CareerLevel?.id,
      nationality_id: nats,
    },
  });

  useEffect(() => {
    getSeniorityLevel().then((data) => {
      setCareerLevels(data);
    });
    getNationality().then((data) => {
      setNationalities(data);
    });
    getMilitary().then((data) => {
      setMilitary(data);
    });
    getMarital().then((data) => {
      setMarital(data);
    });
    getCountry().then((country) => {
      setCountries(country);
    });
    if (user?.City?.Governorate?.id) {
      getCity(user?.City?.Governorate?.id).then((data) => {
        setCities(data);
      });
      getGovernorate(user?.City?.Governorate?.country).then((data) => {
        setGovernorates(data);
      });
    }
    return () => {
      setNationalities(null);
      setMilitary(null);
      setMarital(null);
      setCountries(null);
    };
  }, []);
  const onSubmit = async (data) => {
    setLoading(true);
    const sendData = Object.keys(data)
      .filter((key) => data[key] !== "")
      .reduce((newObj, key) => {
        newObj[key] = data[key];
        return newObj;
      }, {});

    sendData.currentImgFile = currentImgFile;
    if (stepFinished && stepFinished >= current.no) {
      // edit
      await dispatch(updateGeneralInfo(sendData, gender)).finally(() => {
        setLoading(false);
      });
      handleNext();
    } else {
      // add
      await dispatch(addGeneralInfo(sendData, gender)).finally(() => {
        setLoading(false);
      });
      handleNext();
    }
  };
  const onCountryChange = (e) => {
    const value = e.target.value;
    if (value !== "") {
      getGovernorate(value).then((data) => {
        setGovernorates(data);
        setCities(null);
        setValue("city_id", "");
        setValue("governorate_id", "");
      });
    } else {
      setGovernorates(null);
      setCities(null);
      setValue("city_id", "");
      setValue("governorate_id", "");
    }
  };
  const onGovernorateChange = (e) => {
    const value = e.target.value;
    if (value !== "") {
      getCity(value).then((data) => {
        setValue("city_id", "");
        setCities(data);
      });
    } else {
      setCities(null);
      setValue("city_id", "");
    }
  };

  const deleteProfileImg = () => {
    if (user?.image) {
      dispatch(deleteProfileImage()).then((data) => {
        setCurrentImg("");
        setCurrentImgFile(null);
        setValue("image", "");
      });
    } else {
      setCurrentImg("");
      setCurrentImgFile(null);
      setValue("image", "");
      document.getElementById("icon-button-file").value = null;
    }
  };
  const handleSearchResult = () => {
    if (jobTitles?.length > 1) {
      return jobTitles?.map((job) => {
        return (
          <MenuItem
            onClick={() => {
              setValue("job_title", translateField(job, "name"));
              setValue("job_title_id", job.id);
              setOpenJobTitlesMenu(false);
            }}
            key={job.id}
          >
            {translateField(job, "name")}
          </MenuItem>
        );
      });
    } else {
      if (searchTerm !== "") {
        setValue("job_title_id", null);
      }
      return <MenuItem>there is no result</MenuItem>;
    }
  };
  return (
    <>
      <Grid container direction="column" className="step-content">
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* title and text of General Info , use common class step-content */}
          <Grid item sx={{ marginBottom: "24px" }}>
            <Typography className="title">
              {t("general_title")}
            </Typography>
            <Typography className="text">
              {t("general_text")}
            </Typography>
          </Grid>
          {/* content of general info */}
          <Grid item container direction="column" className="general-info">
            {/* personal info content */}
            <Grid item container direction="column" className="personal-info">
              {/* personal info title */}
              <Grid item>
                <Typography className="title">
                  {t("general_title_personal")}
                </Typography>
              </Grid>
              {/* personal info change picture*/}
              <Grid item className={`pic ${currentImg && "no-bg"}`}>
                <img
                  src={currentImg ? currentImg : ""}
                  width="73"
                  height="73"
                  alt="Profile"
                />
                {currentImg && (
                  <IconButton
                    size="small"
                    className="img-cancel-btn"
                    onClick={deleteProfileImg}
                  >
                    <XCircle className="img-cancel-icon" />
                  </IconButton>
                )}
                <label htmlFor="icon-button-file" className="icon">
                  <Controller
                    control={control}
                    name="image"
                    render={({ field }) => {
                      return (
                        <Input
                          id="icon-button-file"
                          type="file"
                          style={{ display: "none" }}
                          inputProps={{
                            accept: "image/png, image/jpg, image/jpeg",
                          }}
                          onChange={(e) => {
                            if (IMAGE_TYPES.includes(e.target.files[0]?.type)) {
                              field.onChange(e.target.files[0]);
                              setCurrentImg(e.target.files[0]);

                              setCurrentImgFile(e.target.files[0]);
                            } else {
                              setError("image", {
                                type: "manual",
                                message: t("file_type"),
                              });
                            }
                          }}
                        />
                      );
                    }}
                  />
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <Image style={{ color: "rgb(145, 145, 145)" }} />
                  </IconButton>
                </label>
              </Grid>
              <Grid item>
                {errors.image && (
                  <FormHelperText style={{ color: "red" }}>
                    {errors.image.message}
                  </FormHelperText>
                )}
              </Grid>
              {/* firstName ,lastName */}
              <Grid item container direction="row" spacing={1}>
                <Grid item xs={6}>
                  <IconInput
                    label={t("first_name")}
                    placeholder={t("first_name")}
                    icon={User}
                    id="first_name"
                    className="w100 mb16"
                    fieldName="first_name"
                    register={register}
                    error={errors.first_name}
                    control={control}
                  />
                </Grid>
                <Grid item xs={6}>
                  <IconInput
                    label={t("last_name")}
                    placeholder={t("last_name")}
                    icon={User}
                    id="second_name"
                    className="w100 mb16"
                    fieldName="second_name"
                    register={register}
                    error={errors.second_name}
                    control={control}
                  />
                </Grid>
              </Grid>
              {/* Job name */}
              <Grid item xs={12}>
                <IconInput
                  label={t("current_job_title")}
                  placeholder={t("enter_job_title")}
                  icon={Briefcase}
                  id="current_job_title"
                  className="w100 mb16"
                  fieldName="job_title"
                  register={register}
                  error={errors.job_title}
                  onChange={(e) => {
                    setDebouncedTerm(e.target.value);
                  }}
                />
                <Box component="div" ref={anchorRef} sx={{}}></Box>
              </Grid>
              <Grid item xs={12}>
                <JLSelect
                  label={t("seneority_level_label_optional")}
                  selectLabel={t("seneority_level")}
                  control={control}
                  fieldName="career_level_id"
                  childs={careerLevels}
                  error={errors.career_level_id}
                  optional={true}
                />
              </Grid>
              <Grid item xs={12}>
                <Menu
                  open={openJobTitlesMenu}
                  anchorEl={anchorRef?.current}
                  disableScrollLock={true}
                  disableAutoFocusItem
                  disableAutoFocus
                  autoFocus={false}
                  sx={{
                    maxHeight: 200,
                    marginTop: "4px",
                  }}
                >
                  <ClickAwayListener
                    onClickAway={() => {
                      setOpenJobTitlesMenu(false);
                      setDebouncedTerm("");
                      setSearchTerm("");
                    }}
                  >
                    <MenuList
                      p={0}
                      sx={{
                        paddingTop: 0,
                        backgroundColor: "none",
                        minWidth: anchorRef?.current?.offsetWidth,
                      }}
                    >
                      {handleSearchResult()}
                    </MenuList>
                  </ClickAwayListener>
                </Menu>
              </Grid>
              {/* Birthdate */}
              <Grid item className="datepicker-wrapper mb16 mt16">
                <Typography className="label" component="span">
                  {t("general_birthdate")}
                </Typography>
                {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Basic example"
                    // value={value}
                    onChange={(newValue) => {
                      setValue(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />

                </LocalizationProvider> */}
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack className="datepicker">
                    <Controller
                      defaultValue={null}
                      control={control}
                      name="birth_of_date"
                      render={({ field }) => (
                        <MobileDatePicker
                          disableCloseOnSelect={false}
                          clearable
                          value={field.value}
                          InputAdornmentProps={{
                            position: "start",
                          }}
                          onChange={(date) => field.onChange(date)}
                          renderInput={(params) => {
                            params.inputProps.placeholder = `${t(
                              "general_birthdate"
                            )}`;
                            return <TextField className="input" {...params} />;
                          }}
                        />
                      )}
                    />
                  </Stack>
                  {errors.birth_of_date && (
                    <FormHelperText style={{ color: "red" }}>
                      {errors.birth_of_date.message}
                    </FormHelperText>
                  )}
                </LocalizationProvider>
              </Grid>
              {/* Gender */}
              <Grid item className="gender-wrapper mb16">
                <Typography className="label" component="span">
                  {t("general_gender")}
                </Typography>
                <div className="gender">
                  <div>
                    <Button
                      className={`male-icon ${gender === 1 ? "male-bg" : ""}`}
                      onClick={handleGenderMale}
                    >
                      <GenderMale />
                      {/* <p className={`${gender === 1 ? 'show' : 'hide'}`}>male</p> */}
                    </Button>
                  </div>
                  <div>
                    <Button
                      className={`female-icon ${gender === 2 ? "female-bg" : ""
                        }`}
                      onClick={handleGenderFemale}
                    >
                      <GenderFemale />
                      {/* <p className={`${gender === 1 ? 'show' : 'hide'}`}>male</p> */}
                    </Button>
                  </div>
                </div>
              </Grid>
              {/* Location */}
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} md={4}>
                    <JLSelect
                      label={t("general_location")}
                      childs={countries}
                      selectLabel={t("general_country")}
                      fieldName="country_id"
                      control={control}
                      onChange={onCountryChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <JLSelect
                      label={t("governorate_placeholder")}
                      selectLabel={t("governrate")}
                      control={control}
                      fieldName="governorate_id"
                      childs={governorates}
                      onChange={onGovernorateChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <JLSelect
                      label={t("city")}
                      selectLabel={t("general_city")}
                      childs={cities}
                      control={control}
                      fieldName="city_id"
                      error={errors.city_id}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* Nationality and Malitary */}
            <Grid item className="nationalandmartial-info">
              <Typography className="title">
                {t("general_title_nationality")}
              </Typography>
              <Grid item xs={12}>
                <ChoiceInput
                  control={control}
                  label={t("general_nationality")}
                  className="w100 mb16"
                  register={register}
                  fieldName="nationality_id"
                  error={errors}
                  setValue={setValue}
                  trigger={trigger}
                  data={nationalities}
                  defaultValue={getValues("nationality_id")}
                  placeholder={t("nationality_placeholder")}
                />
              </Grid>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={6}>
                  <JLSelect
                    label={t("general_military")}
                    selectLabel={t("general_military")}
                    childs={military}
                    control={control}
                    fieldName="military_id"
                    error={errors.military_id}
                    optional={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <JLSelect
                    label={t("general_marital")}
                    selectLabel={t("general_marital")}
                    childs={marital}
                    control={control}
                    fieldName="marital_id"
                    error={errors.marital_id}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* Bio */}
            <Grid item className="bio-info">
              <Typography className="title">
                {t("general_title_bio")}
              </Typography>
              <IconInput
                label={t("general_bio")}
                placeholder={t("bio_placeholder")}
                type="text"
                icon={UserCircle}
                className="mb16 w100"
                multiline={true}
                fieldName="bio"
                register={register}
                error={errors.bio}
              // onChange={() => trigger("bio")}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end">
            {current?.prev && (
              <button className="back-btn" onClick={handleBack}>
                {t("back_btn")}
              </button>
            )}
            <button
              type="submit"
              className="next-btn"
              disabled={loading}
              style={!loading ? { cursor: "pointer" } : {}}
            >
              {t("next_btn")}
            </button>
          </Grid>
        </form>
      </Grid>
    </>
  );
};
export default GeneralInfo;

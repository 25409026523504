import {
  Box,
  Button,
  Divider,
  FormControl,
  FormGroup,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import {
  AppleLogo,
  At,
  FacebookLogo,
  GoogleLogo,
  Lock,
  User,
  WindowsLogo,
} from "phosphor-react";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import AuthPageWrapper from "../../../components/auth-page-wrapper/AuthPageWrapper";
import IconInput from "../../../components/IconInput";
const socialLoginIcons = [AppleLogo, FacebookLogo, GoogleLogo, WindowsLogo];
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { authSignup } from "state/actions/auth/auth";

const Signup = () => {
  let width = window.innerWidth;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = useSelector((state) => state.auth.token);

  const schema = yup
    .object({
      first_name: yup.string().required(t("required_input")),
      last_name: yup.string().required(t("required_input")),
      email: yup
        .string()
        .email(t("email_format"))
        .required(t("required_input")),
      password: yup
        .string()
        .required(t("required_input"))
        .min(8, t("min_input", { number: 8 }))
        .max(16, t("max_input", { number: 16 })),
    })
    .required();

  const {
    control,
    register,
    reset,
    setValue,
    formState: { errors },
    setError,
    handleSubmit,
    getValues,
  } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
  });

  const onSubmit = (formData) => {
    const { first_name, last_name, email, password } = formData;
    dispatch(authSignup(navigate, first_name, last_name, email, password));
  };

  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [token]);
  return (
    <AuthPageWrapper
      titleSlice1="Join our"
      titleSlice2="Community"
      description="Job Links allows connection between people and organizations through the world."
      className="sign-in-wrapper"
      single={width <= 991 ? true : false}
    >
      <Grid container direction="row" justifyContent="center">
        <Grid item className="signin-form" justifyContent="center" xs={8}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <FormControl className="mb16">
                <Typography className="title">joblink</Typography>
                <Typography className="desc">
                  {t("lets_create")}
                </Typography>
              </FormControl>
              <Grid
                mt={1}
                container
                item
                xs={12}
                direction="row"
                justifyContent="space-between"
              >
                <Grid item md={5.5} xs={12} sm={5.5}>
                  <IconInput
                    label={t("first_name")}
                    id="First Name"
                    placeholder={t("first_name")}
                    type="text"
                    icon={User}
                    className="mb16"
                    fieldName="first_name"
                    register={register}
                    error={errors.first_name}
                  />
                </Grid>
                <Grid item md={5.5} xs={12} sm={5.5}>
                  <IconInput
                    label={t("last_name")}
                    id="Last Name"
                    placeholder={t("last_name")}
                    type="text"
                    icon={User}
                    className="mb16"
                    fieldName="last_name"
                    register={register}
                    error={errors.last_name}
                  />
                </Grid>
              </Grid>

              <IconInput
                label={t("email")}
                id="email"
                placeholder={t("email_placeholder")}
                type="email"
                icon={At}
                className="mb16"
                fieldName="email"
                register={register}
                error={errors.email}
              />
              <IconInput
                label={t("password")}
                id="password"
                placeholder={t("enetr_password")}
                type="password"
                icon={Lock}
                className="mb16"
                fieldName="password"
                register={register}
                error={errors.password}
              />
              <FormControl className="mb16">
                <Button className="login-btn" variant="contained" type="submit">
                  {t("create_acc_btn")}
                </Button>
              </FormControl>
              <FormControl>
                <Typography variant="div" className="navigation-text">
                  {t("already_member")}{" "}
                  <Typography
                    component={Link}
                    className="navigation-btn"
                    to="/login"
                  >
                    {t("login")}
                  </Typography>
                </Typography>
              </FormControl>
            </FormGroup>
          </form>
        </Grid>
      </Grid>
    </AuthPageWrapper>
  );
};
export default Signup;

import { Box } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ApplyIcon from "../assets/Apply.svg";
import FindTheJobIcon from "../assets/FindTheJob.svg";
import JoinUsIcon from "../assets/JoinUs.svg";
import InformationCardSection from '../information-card-section/InformationCardSection';
import "./OurAdvantagesSection.css";

const OurAdvantagesSection = ({ backColored }) => {
    const { t } = useTranslation();
    const data = [
        {
            number: 1,
            image: JoinUsIcon,
            title: t("control_hiring"),
            description: t("recruiter_control_hiring_desc")
        },
        {
            number: 1,
            image: FindTheJobIcon,
            title: t("save_time"),
            description: t("recruiter_save_time_desc")
        },
        {
            number: 1,
            image: ApplyIcon,
            title: t("go_beyond"),
            description: t("recruiter_go_beyond_desc")

        }
    ]
    return (
        <Box component={"section"} id="our-advantages" className="our-advantages-section">
            <InformationCardSection title={t("our_advgs")} data={data} backColored={backColored} cardWithoutNumber />
        </Box >
    );
};

export default OurAdvantagesSection;
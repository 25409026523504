import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { createAndAddToCategory, getCategories } from '../../../services/Helper';

const useAddToCvBank = ({ applicant_id }) => {
    const [data, setData] = useState([])
    const [closeTrigger, setCloseTrigger] = useState(false)
    const { handleSubmit, control, setValue } = useForm()
    const onSubmit = (data) => {
        console.log(data, applicant_id)
        createAndAddToCategory(applicant_id, data.category_name).then(() => {
            setCloseTrigger(true)
        })
    }
    useEffect(() => {
        getCategories().then((res) => {
            setData(res.rows)
        })
    }, [])
    return (
        {
            data,
            handleSubmit,
            control,
            setValue,
            closeTrigger,
            onSubmit
        }
    )
}

export default useAddToCvBank
import React, { useEffect, useState } from 'react';
import { Grid, IconButton } from "@mui/material";
import { Trash } from "phosphor-react";
import UserAvatar from '../user-avatar/UserAvatar';
import Show from "../Show";
import "./ApplicantAvatarCard.scss"
import { useNavigate } from 'react-router-dom';
import Loader from '../loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { deleteApplicantFromList } from '../../state/actions/applicantList/applicantList';
import { changePageBackLink } from '../../state/actions/pageBack/pageBack';
import CompanyTag from '../company-tag/CompanyTag';


const ApplicantAvatarCard = ({
    id,
    firstName,
    secondName,
    jobTitle,
    showAndHideTrash,
    redirect,
    onClick,
    selected,
    cvBankId,
    pageLink,
    img,
    showCardTag,
    location
}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();


    const [cvid, setCvId] = useState();
    const [stopRedirect, setStopRedirect] = useState();

    const idCategory = useSelector((state) => state.categoryList.idCategory);


    const handleClick = () => {
        if (redirect)
            navigate(`/applicant/${id}`);
        dispatch(changePageBackLink(pageLink))
    }
    useEffect(() => {
        if (cvBankId) {
            setCvId(cvBankId)
        }
    }, [cvBankId])


    const handleDeleteClick = (e) => {
        e.stopPropagation()

        dispatch(deleteApplicantFromList(cvid, idCategory))
        //     // deletApplicantFromCvBank(cvid);
        //     // getApplicantToCvBank(idCategory)
    }

    return (
        <Loader data={firstName} width={'100%'} height={'95px'}>
            <Grid container className='applicant-avatar'
                onClick={() => {
                    redirect ? handleClick() : onClick();
                }}
                sx={{
                    boxShadow: !redirect && selected ? "0px 0px 12px #00000038" : ""
                }}
                style={{ cursor: redirect || onClick ? 'pointer' : 'default' }} >
                <Grid item xs={11}>
                    <UserAvatar
                        showAvatar
                        showName
                        showTitle
                        changeNameStyle="applicantAvatarCardName"
                        changeTitleStyle="applicantAvatarCardTitle"
                        size='large'
                        name={`${firstName} ${secondName}`}
                        title={jobTitle}
                        avatar={img}
                        showCardTag
                        location={location}
                    />
                </Grid>
                <Grid item xs={1} container alignContent={"center"} justifyContent={"flex-end"}>
                    <Show condition={showAndHideTrash}>
                        <IconButton className='del-icon' onClick={(e) => handleDeleteClick(e)}>
                            <Trash />
                        </IconButton>
                    </Show>
                </Grid>
                <Grid container spacing={2}>
                </Grid>
            </Grid>
        </Loader>
    )
}

export default ApplicantAvatarCard
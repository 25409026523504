import './AccountSettings.scss';

import { Box, FormControl, Grid, MenuItem, Select, Stack, Typography } from '@mui/material';
import { CaretDown } from 'phosphor-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import DecsisionButton from '../../../../../components/decision-button/DecisionButton';
import { changePassword } from '../../../../../services/auth/Auth';
import { changeDefaultRoleSettings } from '../../../../../state/actions/profile/profile';
import { useNavigate } from 'react-router-dom';
import { sendOTP } from 'services/AuthHelper';
import { startOTPVerification } from 'state/actions/otp/otp';

const AccountSettings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const roles = useSelector((state) => state.profile.data.role);
  let rolesArray = [];
  if (typeof roles == 'object') {
    Object.keys(roles).forEach((key) => {
      rolesArray.push(roles[key]);
    });
  } else {
    rolesArray = roles;
  }

  const currentRole = useSelector((state) =>
    state.profile.data?.azure_account?.default_role ? state.profile.data?.azure_account?.default_role : state.profile.data?.role?.[0]?.name,
  );

  const email = useSelector((state) => state.profile.data?.azure_account?.email);

  const [role, setRole] = useState(currentRole);
  const [loading, setLoading] = useState(false);

  const handleSaveChanges = async () => {
    setLoading(true);
    await dispatch(changeDefaultRoleSettings(role));
    setLoading(false);
  };

  return (
    <Grid container direction="row" className="account-settings-container" rowGap={2}>
      <Grid item xs={12}>
        <Typography className="settings-title">{t('account-settings')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Box>
            <Typography className="settings-header">{t('change-password')}</Typography>
            {/* <Typography className="settings-change-password-button">
              {t("change-account-password")}
            </Typography> */}
          </Box>
          <Box align={'right'}>
            <DecsisionButton
              active
              onClick={() => {
                dispatch(startOTPVerification('Enter OTP To Reset Password', email, 'RESET_PASSWORD'));
                sendOTP(email);
                navigate('/reset-password-verification');
              }}
            >
              <Typography className="settings-button">{t('change-password')}</Typography>
            </DecsisionButton>
          </Box>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Typography className="settings-header">{t('default-role')}</Typography>
        <FormControl variant="standard" fullWidth className="standart-form-controller-select">
          <label className="label">{t('role')}</label>
          <Select
            value={role}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            className="select settings-select"
            MenuProps={{
              disableScrollLock: true,
            }}
            variant="outlined"
            IconComponent={CaretDown}
            onChange={(e) => setRole(e.target.value)}
          >
            {rolesArray?.map((item) => (
              <MenuItem key={item.name} value={item.name}>
                {t(`${item.name.toLowerCase()}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          height: '80%',
          width: '100%',
        }}
      >
        <Stack
          direction={'row'}
          justifyContent={'flex-end'}
          alignItems={'flex-end'}
          sx={{
            alignSelf: 'flex-end',
          }}
        >
          <Box alignSelf={'flex-end'}>
            <DecsisionButton active onClick={() => handleSaveChanges()} disabled={loading}>
              <Typography className="settings-button">{t('save_changes')}</Typography>
            </DecsisionButton>
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};
export default AccountSettings;

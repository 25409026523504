import {
  Avatar,
  Box, Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem, Typography
} from "@mui/material";
import {
  CaretDown,
  CaretRight,
  Checks,
  GearSix,
  Newspaper,
  Phone,
  SignOut,
  Translate,
  Users
} from "phosphor-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Flag from "react-world-flags";
import { handleChangeLanguageAction } from "state/actions/languages-handler/languageHandler";
import Show from "../../../../../../../components/Show";
import { LANGUAGES } from "../../../../../../../constants/constants";
import { logout } from "../../../../../../../services/auth/Auth";
import { getLanguageDropdown } from "../../../../../../../services/Helper";
import { getCurrentUser } from "../../../../../../../services/ReduxHelper";
import {
  assignRole,
  fetchProfile
} from "../../../../../../../state/actions/profile/profile";
import { store } from "../../../../../../../state/store";

import "./ProfileDropdown.css";
import { authLogout } from "state/actions/auth/auth";
const ProfileDropdown = ({ usedInMobile, setOpenDrawer }) => {
  // translation
  const { t, i18n } = useTranslation();
  const currentLanguage = useSelector(state => state.languageHandler.current_lang)
  const currentLocale = useSelector(state => state.languageHandler.locale)
  const currentCode = useSelector(state => state.languageHandler.code)
  const dispatch = useDispatch();

  // navigator
  const navigate = useNavigate();

  // states
  const [lang, setLang] = useState("en");
  const [selectedLang, setSelectedLang] = useState(null);
  const [langs, setLangs] = useState([])
  const [selected, setSelected] = useState(currentLocale);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // redux
  const role = useSelector((state) => state.profile.role.name);
  const mode = useSelector((state) => state.mode.name);

  const user = getCurrentUser(role);

  const handleClick = (event) => {
    const target = document.getElementById("profile-button-side");
    setAnchorEl(target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleElementClick = (route) => {
    if (usedInMobile) {
      setOpenDrawer(false);
    }
    setAnchorEl(null);
    navigate(route);
  };
  const handleLangChange = (code, locale) => {
    dispatch(handleChangeLanguageAction(code, locale))
    setLang(code + "-" + locale);
    i18n.changeLanguage(code);
    if (code === "ar") {
      document.dir = "rtl";
    } else {
      document.dir = "ltr";
    }
    let selectedLanguage = langs.find(option => option.code.toLowerCase() === currentLanguage);
    setSelected(selectedLanguage);
  };
  useEffect(() => {
    setSelected(currentLocale)
    getLanguageDropdown().then((res) => {
      setLangs(res)
    })
  }, [])

  const handleNavigateToProfile = (pRole) => {
    switch (pRole) {
      case "RECRUITER":
        handleElementClick("/my-profile/company");
        break;
      case "APPLICANT":
        handleElementClick("/my-profile");
        break;
      default:
        break;
    }
  };

  const handleRoleClick = async (pRole) => {
    let roles = store.getState().profile.data?.role;
    if (typeof roles === "object") {
      roles = Object.values(roles);
    }
    const result = roles?.find((item) => item.name == pRole);
    if (result) {
      navigate("/");
      await dispatch(fetchProfile(pRole));
      dispatch(assignRole(result));
    } else {
      switch (pRole) {
        case "APPLICANT":
          navigate("/applicant-submit", { state: { switched: true } });
          break;
        case "RECRUITER":
          navigate("/recruiter-submit", { state: { switched: true } });
          break;
        case "INTERVIEWER":
          break;
        case "COACH":
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    let currentLang = i18n.language.split("-")[0];
    i18n.changeLanguage(currentLang);
    setLang(currentLang);
    let selectedLanguage = LANGUAGES.find(
      (option) => option.code.toLowerCase() === currentLang
    );
    setSelectedLang(selectedLanguage);
    if (currentLang === "ar") {
      document.dir = "rtl";
    } else {
      document.dir = "ltr";
    }
  }, [i18n.language]);

  const MenuItems = () => {
    return (
      <>
        <Grid
          container
          alignItems={"center"}
          justifyContent={"space-between"}
          padding={2}
        >
          <Grid item xs={10}>
            <Grid container alignItems={"center"} spacing={1}>
              <Grid item xs={3.5} sx={{ textAlign: "center" }}>
                <Avatar
                  src={user?.avatar}
                  sx={{
                    width: "60px",
                    height: "60px",
                  }}
                />
              </Grid>
              <Grid item xs={8.5}>
                <Typography className="profile-dropdown-title">
                  {user?.name}
                </Typography>
                <Typography className="profile-dropdown-description">
                  {user?.title}
                </Typography>
                <Typography className="profile-dropdown-description">
                  {t("switch-to")} :{" "}
                  <Show condition={role == "RECRUITER"}>
                    <Box
                      component={"span"}
                      onClick={() => handleRoleClick("APPLICANT")}
                    >
                      {t("applicant")}
                    </Box>
                  </Show>
                  <Show condition={role == "APPLICANT"}>
                    <Box
                      component={"span"}
                      onClick={() => handleRoleClick("RECRUITER")}
                    >
                      {t("recruiter")}
                    </Box>
                  </Show>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2} sx={{ textAlign: "center" }}>
            <IconButton
              onClick={() => handleNavigateToProfile(role)}
              sx={{
                background: "#9191911A",
                color: "#000",
                "&:hover": {
                  background: "#9191911A",
                },
              }}
            >
              <CaretRight
                style={{
                  transform: i18n.language == "ar" ? "rotate(180deg)" : "",
                }}
              />
            </IconButton>
          </Grid>
        </Grid>
        <Divider sx={{ marginBottom: "8px" }} />
        <Show condition={mode == "recruiter"}>
          <MenuItem onClick={() => handleElementClick("/followers")}>
            <ListItemIcon>
              <Users color="#919191" size={20} />
            </ListItemIcon>
            <ListItemText>{t("followers")}</ListItemText>
          </MenuItem>
        </Show>
        <Show condition={mode == "applicant"}>
          <MenuItem onClick={() => handleElementClick("/career-interests")}>
            <ListItemIcon>
              <Users color="#919191" size={20} />
            </ListItemIcon>
            <ListItemText>{t("career_title")}</ListItemText>
          </MenuItem>
        </Show>
        <Show condition={mode == "applicant"}>
          <MenuItem onClick={() => handleElementClick("/followed-companies")}>
            <ListItemIcon>
              <Users color="#919191" size={20} />
            </ListItemIcon>
            <ListItemText>{t("companies_followed")}</ListItemText>
          </MenuItem>
        </Show>
        <MenuItem onClick={() => handleElementClick("/news")}>
          <ListItemIcon>
            <Newspaper color="#919191" size={20} />
          </ListItemIcon>
          <ListItemText>{t("only-news")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleElementClick("/settings")}>
          <ListItemIcon>
            <GearSix color="#919191" size={20} />
          </ListItemIcon>
          <ListItemText>{t("settings")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleElementClick("/contact")}>
          <ListItemIcon>
            <Phone color="#919191" size={20} />
          </ListItemIcon>
          <ListItemText>{t("contact_us")}</ListItemText>
        </MenuItem>
        <Divider sx={{ marginBottom: "8px" }} />
        <MenuItem
          disableRipple
          disableTouchRipple
          sx={{
            cursor: "unset",
            "&:hover": {
              background: "#fff",
            },
          }}
        >
          <ListItemIcon>
            <Translate color="#919191" size={20} />
          </ListItemIcon>
          <ListItemText>{t("languages")}</ListItemText>
        </MenuItem>
        {/* <MenuItem onClick={() => handleLangChange("en")}>
          <ListItemIcon>
            <Avatar className="language-icon">
              <Flag code={"GB"} className="language-icon" />
            </Avatar>
          </ListItemIcon>
          <ListItemText
            className={[i18n.language == "en" ? "selected" : ""].join(" ")}
          >
            {translateField(LANGUAGES[0], "name")}
          </ListItemText>
          <Show condition={i18n.language == "en"}>
            <Typography variant="body2">
              <Checks color="#1E9F38" size={20} />
            </Typography>
          </Show>
        </MenuItem> */}
        {langs.map((language) => {
          console.log({ language }, selected)
          return (
            <MenuItem onClick={() => handleLangChange(language.Language.code.toLowerCase(), language.locale)}>
              <ListItemIcon>
                <Avatar className="language-icon">
                  <Flag code={language.locale} className="language-icon" />
                </Avatar>
              </ListItemIcon>
              <ListItemText
                className={[i18n.language == language?.code?.toLowerCase() ? "selected" : ""].join(" ")}
              >
                {language.name}
              </ListItemText>
              <Checks
                color={
                  currentLocale === language.locale ? "#1E9F38FF" : "#00000000"
                }
              />
            </MenuItem>
          )
        })}
        {/* <MenuItem onClick={() => handleLangChange("ar")}>
          <ListItemIcon>
            <Avatar className="language-icon">
              <Flag code={"SA"} className="language-icon" />
            </Avatar>
          </ListItemIcon>
          <ListItemText
            className={[i18n.language == "ar" ? "selected" : ""].join(" ")}
          >
            {translateField(LANGUAGES[1], "name")}
          </ListItemText>
          <Show condition={i18n.language == "ar"}>
            <Typography variant="body2">
              <Checks color="#1E9F38" size={20} />
            </Typography>
          </Show>
        </MenuItem> */}
        <Divider sx={{ marginBottom: "8px" }} />
        <MenuItem
          onClick={() => dispatch(authLogout(navigate))}
          sx={{
            marginBottom: "8px",
          }}
        >
          <ListItemIcon>
            <SignOut
              color="#919191"
              size={20}
              style={{
                transform: "rotate(180deg)",
              }}
            />
          </ListItemIcon>
          <ListItemText>{t("log_out")}</ListItemText>
        </MenuItem>
      </>
    );
  };

  return (
    <>
      <Show condition={!usedInMobile}>
        <Box>
          <Button
            id="profile-button"
            aria-controls={open ? "profile-dropdown" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            disableRipple
          >
            <Avatar src={user?.avatar} />
            <Box component={"span"} width={"8px"}></Box>
            <CaretDown
              className={`${open ? "open" : "close"}-rotate`}
              size={18}
            />
          </Button>
          <Box id="profile-button-side" component={"span"}></Box>
        </Box>
      </Show>
      <Show condition={!usedInMobile}>
        <Menu
          id="profile-dropdown"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          disableScrollLock={true}
          transitionDuration={200}
        >
          <MenuItems />
        </Menu>
      </Show>
      <Show condition={usedInMobile}>
        <List id="profile-dropdown" sx={{ borderRadius: "8px" }}>
          <MenuItems />
        </List>
      </Show>
    </>
  );
};

export default ProfileDropdown;

import React, { useEffect, useState, useRef } from "react";
import { Grid, IconButton, Box } from "@mui/material";
import { Trash } from "phosphor-react";
import ApplicantAvatarCard from "../../../../components/applicant-avatar-card/ApplicantAvatarCard";
import Hide from "../../../../components/Hide";
import Pagination from "../../../../components/pagination/Pagination";
import PageBack from "../../../../components/page-back/PageBack";
import { useTranslation } from "react-i18next";
import Show from "../../../../components/Show";
import { getApplicantToCvBank } from "../../../../services/Helper";
import { translateField } from "../../../../services/Helper";
import ErrorPage from "../../../../components/error-page/ErrorPage";
// import emptyPage from "../../../../assets/empty-page.json";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../components/loader/Loader";
import { deletApplicantFromCvBank } from "../../../../services/Helper";
import { useDispatch, useSelector } from "react-redux";
import {
  getApplicantList,
  deleteApplicantFromList,
} from "../../../../state/actions/applicantList/applicantList";
import "./ApplicantListView.scss";

// const emptyPage = React.lazy(() => import("../../../../assets/empty-page.json"));


const ApplicantListView = ({
  showAndHideTrash,
  handleHideComponents,
  categorList,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.applicantList.data);
  const idCategory = useSelector((state) => state.categoryList.idCategory);

  const [numberOfPages, setNumberOfPages] = useState(1);
  // const [data, setData] = useState([])
  const [page, setPage] = useState(1);
  const [cvBankId, setCvBankId] = useState(0);
  const [loadedApplicants, setLoadedApplicants] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (idCategory != 0) {
      dispatch(getApplicantList(idCategory));
      setLoadedApplicants(true);
    }
  }, [idCategory]);

  // useEffect(() => {
  //     if (idCategory != 0) {
  //         getApplicantToCvBank(idCategory).then((response) => {
  //             setData(response.rows)
  //             setLoadedApplicants(true);
  //         });
  //     }

  // }, [idCategory,updateData])

  const changePage = () => {
    setPage(2);
  };

  const scrollIntoJobs = () => {
    document.getElementById("applicant_list_view").scrollTop = 0;
  };

  const handleDeleteClick = async (cvBankId) => {
    setLoading(true);
    await dispatch(deleteApplicantFromList(cvBankId, idCategory));
    setLoading(false);
  };

  const [emptyPage, setEmptyPage] = useState(null);
    useEffect(() => {
        import("assets/empty-page.json").then(data => setEmptyPage(data));
    }, [])

  return (
    <>
      <div
        className="applicant-list-view"
        style={{ height: "calc(100vh - 165px)" }}
      >
        <div className="scroll-inside">
          <Grid
            container
            id="#applicant_list_view"
            spacing={1}
            // style={{ height: "calc(100vh - 165px)" }}
          >
            <Show condition={showAndHideTrash}>
              <Grid item xs={12}>
                <PageBack
                  title={t("back")}
                  onClick={() => {
                    handleHideComponents();
                  }}
                />
              </Grid>
            </Show>
            {data.map((data) => {
              return (
                <Grid
                  item
                  xs={12}
                  container
                  key={data?.id}
                  id={`applicant-${data?.id}`}
                >
                  <Grid item xs={12} md={10.5} lg={11}>
                    <ApplicantAvatarCard
                      id={data?.User?.id}
                      // name={data?.User.first_name}
                      firstName={data?.User.first_name}
                      secondName={data?.User.second_name}
                      jobTitle={translateField(data?.User?.JobTitle, "name")}
                      showAndHideTrash={showAndHideTrash}
                      location={`${translateField(
                        data?.User?.City,
                        "name"
                      )}, ${translateField(
                        data?.User?.City?.Governorate?.Country,
                        "name"
                      )}`}
                      cvBankId={data?.id}
                      idCategory={idCategory}
                      redirect
                      pageLink="candidates-bank"
                      showCardTag
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={1.5}
                    lg={1}
                    container
                    alignContent={"center"}
                    justifyContent={"flex-end"}
                  >
                    <Hide condition={showAndHideTrash}>
                      <IconButton
                        className="del-icon"
                        onClick={() => handleDeleteClick(data.id)}
                        disabled={loading}
                      >
                        <Trash />
                      </IconButton>
                    </Hide>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          <Show condition={loadedApplicants && data.length == 0}>
            <Box component="div" padding={4}>
              <ErrorPage
                title={t("oops")}
                description={t("no_applicants")}
                hideAction
                animationData={emptyPage}
              />
            </Box>
          </Show>
        </div>
      </div>
      <Show condition={numberOfPages > 1}>
        <Grid item xs={12} className="pagination">
          <Pagination
            pagesManager={{
              page: page,
              setPage: changePage,
              numberOfPages: numberOfPages,
              onNext: scrollIntoJobs,
              onBack: scrollIntoJobs,
            }}
          />
        </Grid>
      </Show>
    </>
  );
};

export default ApplicantListView;

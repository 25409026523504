import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { t } from "i18next";
import { Bag, CheckCircle } from "phosphor-react";
import { useNavigate } from 'react-router-dom';
import EnhancedButton from "../../../yahya/components/enhanced-button/EnhancedButton";
import FloatingNotificationCard from "../../../yahya/components/hero-section/components/floating-notification-card/FloatingNotificationCard";
import HeroSection from "../../../yahya/components/hero-section/HeroSection";
import "./RecruiterHeroSection.css";

const RecruiterHeroSection = () => {
    const navigate = useNavigate()
    const mdMatches = useMediaQuery('(min-width:1200px)');
    const leftCard = <FloatingNotificationCard
        icon={<Bag size={24} color={"#FFFF"} weight={"regular"} />}
    >
        <Typography
            sx={{
                font: " normal normal 600 12px/19px Urbanist"
            }}
        >
            {t("left_float_hero_card_recruiter_title")}

            <br />
            <Typography
                sx={{
                    color: "#3A3B81",
                    font: "normal normal bold 16px/19px Urbanist"
                }}
            >
                {t("left_float_hero_card_recruiter_desc")}
            </Typography>
        </Typography>
    </FloatingNotificationCard>

    const rightCard = <FloatingNotificationCard
        icon={<CheckCircle size={24} color={"#FFFF"} />}
    >
        <Typography
            sx={{
                font: "normal normal 600 12px/19px Urbanist"
            }}
        >
            {t("right_float_hero_card_recruiter_desc")}

            <br />
            <Typography
                sx={{
                    color: "#3A3B81",
                    font: "normal normal bold 16px/19px Urbanist"
                }}
            >
                {t("right_float_hero_card_recruiter_title")}

            </Typography>
        </Typography>
    </FloatingNotificationCard>

    return (
        <Box component={"section"} id="home">
            <Grid container direction={"column"}>
                <Grid item>
                    <HeroSection
                        leftCard={leftCard}
                        rightCard={rightCard}
                    >
                        <Stack direction={"column"} spacing={3}>
                            <Stack align={"center"} spacing={2}>
                                <Typography
                                    className={"hero-section-title"}
                                >
                                    {t("hire")} {" "}
                                    <Typography
                                        className={"hero-section-title colored"}
                                    >
                                        {t("professionals")} {" "}
                                    </Typography><br />
                                    {t("just_click")}
                                </Typography>
                                <Typography
                                    className={"hero-section-description"}
                                    sx={{ whiteSpace: "pre-line" }}
                                >
                                    {t("hero_recruiter_desc")}
                                </Typography>
                            </Stack>

                            <Grid item container direction={"row"} columnSpacing={2} justifyContent={"center"}>
                                <Grid item xs={"auto"}  >
                                    <EnhancedButton buttonText={t("contact_us")} variant={"contained"} padding={"18px 40px"} onClick={() => navigate("/contact")} />
                                </Grid>
                                <Grid item xs={"auto"} >
                                    <EnhancedButton buttonText={t("know_more")} variant={"outlined"} padding={"16px 40px"} onClick={() => navigate("/about")} />
                                </Grid>
                            </Grid>
                        </Stack>

                    </HeroSection>
                </Grid>

                {!mdMatches &&
                    <Grid item xs={12}>
                        {/* <LandingPageSearchBar /> */}
                    </Grid>
                }
            </Grid>
        </Box>
    )
}
export default RecruiterHeroSection;
import { yupResolver } from "@hookform/resolvers/yup";
import { FormGroup, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import ChoiceInput from "../../../components/ChoiceInput";
import LocationInput from "../../../components/LocationInput";
import {
  getCountry,
  getFirstStepNotCompleted,
  getJobCategory, getJobTitle, getSeniorityLevel, getWorkType
} from "../../../services/Helper";
import { getStep } from "../../../services/Stepper";
import {
  addInterests,
  editInterests
} from "../../../state/actions/profile/profile";
import { nextStep } from "../../../state/actions/stepper/stepper";
import { stepperData } from "./stepper";

const CareerInterests = () => {
  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [jobTitles, setJobTitles] = useState([]);
  const [workTypes, setWorkTypes] = useState([]);
  const [seniorityLevels, setSeniorityLevels] = useState([]);
  const [jobIndustries, setJobIndustries] = useState([]);
  const [countries, setCountries] = useState([]);
  const [formErrors, setFormErrors] = useState({
    jobTitle: false,
    workType: false,
    seniorityLevel: false,
    jobIndustry: false,
    governorates: false,
    country: false,
  });
  const stepsFromApi = useSelector((state) => {
    let steps = state.profile.data?.cv?.User?.UserSteps;
    return steps && steps.length > 0 ? steps[0] : {};
  });
  let CvCareerInterests = useSelector((state) => {
    let careerInterests = state.profile?.data?.cv?.User?.CvCareerInterests;
    if (Array.isArray(careerInterests)) return careerInterests;
    else return [];
  });

  const jobTitle = useSelector((state) => {
    let returned = [];
    state.profile?.data?.cv?.User?.CvCareerInterests?.[0]?.CvCareerInterestJobTitles?.forEach(
      (data) => {
        returned.push(data.JobTitle);
      }
    );

    return returned;
  });

  const workType = useSelector((state) => {
    let returned = [];
    state.profile?.data?.cv?.User?.CvCareerInterests?.[0]?.CvCareerInterestExperienceTypes?.forEach(
      (data) => {
        returned.push(data.ExperienceType);
      }
    );

    return returned;
  });

  const jobIndustry = useSelector((state) => {
    let returned = [];
    state.profile?.data?.cv?.User?.CvCareerInterests?.[0]?.CvCareerInterestJobCategories?.forEach(
      (data) => {
        returned.push(data.JobCategory);
      }
    );

    return returned;
  });

  //let country = null;

  const governorates = useSelector((state) => {
    let returned = [];
    state.profile?.data?.cv?.User?.CvCareerInterests?.[0]?.CvCareerInterestGovernorates?.forEach(
      (data) => {
        returned.push(data.Governorate);
      }
    );

    // 
    // if(returned.length > 0){
    //   country = returned[returned.length - 1].Country;
    // }

    return returned;
  });

  const seniorityLevel = useSelector((state) => {
    let returned = [];
    state.profile?.data?.cv?.User?.CvCareerInterests?.[0]?.CvCareerInterestCareerLevels?.forEach(
      (data) => {
        returned.push(data.CareerLevel);
      }
    );

    return returned;
  });


  useEffect(() => {
    let cancel = false;
    getJobTitle().then((jobTitles) => {
      if (cancel) return;
      setJobTitles(jobTitles);
    });
    getWorkType().then((workTypes) => {
      if (cancel) return;
      setWorkTypes(workTypes);
    });
    getSeniorityLevel().then((seniorityLevels) => {
      if (cancel) return;
      setSeniorityLevels(seniorityLevels);
    });
    getJobCategory().then((jobIndustries) => {
      if (cancel) return;
      setJobIndustries(jobIndustries);
    });
    getCountry().then((countries) => {
      if (cancel) return;
      setCountries(countries);
    });

    return () => {
      cancel = true;
      setJobTitles([]);
      setWorkTypes([]);
      setSeniorityLevels([]);
      setJobIndustries([]);
      setCountries([]);
    };
  }, []);

  const schema = yup
    .object({
      // country_id: yup.object().nullable
      // .min(1, t("common.required_selection")),
      //country: yup.string().when("governorates", (value, schema) => value.length < 1 ? schema : schema.required(t("common.required_input")) )
    })
    .required();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    trigger,
    reset,
    setError,
  } = useForm({
    reValidateMode: "onBlur",
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      jobTitle: jobTitle,
      workType: workType,
      seniorityLevel: seniorityLevel,
      jobIndustry: jobIndustry,
      // country_id: "",
      governorates: governorates,
    },
  });

  const dispatch = useDispatch();
  const currentStepNo = useSelector((state) => state.stepper.currentStep);
  const finalStepNo = useSelector((state) => state.stepper.finalStep);
  const stepFinished = useSelector(
    (state) => state.profile.data?.cv?.User?.step_finished
  );
  const current = getStep(stepperData, currentStepNo);

  useEffect(() => {

  }, [errors]);

  const onSubmit = async (data) => {
    setLoading(true);
    let errorsArray = Object.values(formErrors);
    let canSubmit = !errorsArray.includes(true);
    let errorsTemp = formErrors;
    data = getValues();




    if (data.country == null && data.governorates.length < 1) {

      canSubmit = false;
      errorsTemp["country"] = true;
    }

    Object.entries(data).forEach(([key, value]) => {
      if (Array.isArray(value) && value.length < 1) {
        if (key !== "governorate") {
          canSubmit = false;
          errorsTemp[key] = true;
        }
        else {
          if (data.country) {
            canSubmit = false;
            errorsTemp[key] = true;
          }
        }
      }
    });

    if (canSubmit) {


      let jobTitleData = [];
      let workTypeData = [];
      let seniorityLevelData = [];
      let jobIndustryData = [];
      let governoratesData = [];

      data.jobTitle.forEach((element) => {
        jobTitleData.push(element.id);
      });

      data.workType.forEach((element) => {
        workTypeData.push(element.id);
      });

      data.seniorityLevel.forEach((element) => {
        seniorityLevelData.push(element.id);
      });

      data.jobIndustry.forEach((element) => {
        jobIndustryData.push(element.id);
      });

      data.governorates.forEach((element) => {
        governoratesData.push(element.id);
      });

      let interestsData = {
        job_category: jobIndustryData,
        experience_type: workTypeData,
        career_level: seniorityLevelData,
        job_title: jobTitleData,
        governorate: governoratesData,
      };

      const notCompletedStep = getFirstStepNotCompleted(stepsFromApi, "cv");

      if (stepFinished && stepFinished >= current.no) {
        if (notCompletedStep !== 0) {
          dispatch(
            nextStep({ currentStep: notCompletedStep, finalStep: finalStepNo })
          );
        }
        await dispatch(editInterests(interestsData)).finally(() => {
          setLoading(false);
        });
      } else {
        if (notCompletedStep !== 0) {
          dispatch(
            nextStep({ currentStep: notCompletedStep, finalStep: finalStepNo })
          );
        }
        await dispatch(addInterests(interestsData)).finally(() => {
          setLoading(false);
        });
      }

      handleNext();
    } else {
      setFormErrors(errorsTemp);
      setLoading(false);
    }
  };

  const handleBack = () => {

    if (current?.prev) {
      dispatch(nextStep({ currentStep: current?.prev, finalStep: finalStepNo }));
    }
  };
  const handleNext = () => {

  };
  return (
    <Grid container direction="column">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid item className="step-content">
          <Typography className="title">
            {t("career_title")}
          </Typography>
          <Typography className="text">
            {t("career_text")}
          </Typography>
          <FormGroup className="mb16">
            <ChoiceInput
              limitNumber={5}
              control={control}
              label={t("job_title")}
              register={register}
              fieldName="jobTitle"
              error={formErrors}
              setValue={setValue}
              trigger={trigger}
              data={jobTitles}
              setError={setFormErrors}
            // defaultValue={getValues("jobTitle")}
            />
            <ChoiceInput
              control={control}
              limitNumber={5}

              label={t("work_type_label")}
              register={register}
              fieldName="workType"
              error={formErrors}
              setValue={setValue}
              trigger={trigger}
              data={workTypes}
              setError={setFormErrors}
            // defaultValue={getValues("workType")}
            />
            <ChoiceInput
              control={control}
              limitNumber={5}

              label={t("seneority_level_label")}
              register={register}
              fieldName="seniorityLevel"
              error={formErrors}
              setValue={setValue}
              trigger={trigger}
              data={seniorityLevels}
              setError={setFormErrors}
            // defaultValue={getValues("seniorityLevel")}
            />
            <ChoiceInput
              control={control}
              limitNumber={5}

              label={t("job_industry")}
              register={register}
              fieldName="jobIndustry"
              error={formErrors}
              setValue={setValue}
              trigger={trigger}
              data={jobIndustries}
              setError={setFormErrors}
            // defaultValue={getValues("jobIndustry")}
            />
            <LocationInput
              control={control}
              label={t("location")}
              register={register}
              fieldName="governorates"
              governoratesError={formErrors}
              error={formErrors}
              setError={setFormErrors}
              setValue={setValue}
              trigger={trigger}
              countries={countries}
              setCountries={setCountries}
              defaultValue={getValues("governorates")}
            />
          </FormGroup>
        </Grid>
        <Grid container justifyContent="flex-end">
          {current?.prev && (
            <button className="back-btn" onClick={handleBack}>
              {t("back_btn")}
            </button>
          )}
          <button className="next-btn" type="submit" disabled={loading} style={!loading ? { cursor: "pointer" } : {}}>
            {t("next_btn")}
          </button>
        </Grid>
      </form>
    </Grid>
  );
};
export default CareerInterests;

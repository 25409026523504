import { useState, useEffect } from "react";
import BrowsingPage from "../../components/browsing-page/BrowsingPage";
import { getJobs, scrollIntoView } from "../../services/Helper";
import Scroller from "../../components/Scroller";
import { useTranslation } from "react-i18next";

const SavedJobs = () => {
  const [data, setData] = useState([]);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [page, setPage] = useState(1);
  const [ready, setReady] = useState(false);

  const { t, i18n } = useTranslation();

  const fetchPage = (pageNumber) => {
    setReady(false);
    getJobs("Saved", pageNumber).then((jobs) => {
      setData(jobs?.rows);
      setNumberOfPages(jobs?.meta?.totalNumberOfPages);
      setReady(true);
    });
  };

  useEffect(() => {
    fetchPage(1);
  }, []);

  // useEffect(() => {
  //   document.body.style.overflow = "hidden"
  // }, [])

  const changePage = (newPage) => {
    fetchPage(newPage);
    setPage(newPage);
    scrollIntoView("browsing-page-top");
  };

  const scrollIntoJobs = () => {
    document.getElementById("jobs_wrap").scrollTop = 0;
    
  }

  return (
    <>
      <Scroller id={"browsing-page-top"} />
      <BrowsingPage
        data={data}
        showFilter={false}
        title={t("saved_jobs")}
        pagesManager={{
          page: page,
          setPage: changePage,
          numberOfPages: numberOfPages,
          onNext: scrollIntoJobs,
          onBack: scrollIntoJobs
        }}
        ready={ready}
      />
    </>
  );
};
export default SavedJobs;

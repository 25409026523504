import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import "./CategoryCard.scss";
import CategoryTag from "./components/category-tag/CategoryTag";

const CategoryCard = ({ title, icon, description, tags , ApplicantDashboardBrowse }) => {

    const renderTags = () => {
        return tags.map((tag, index) => {
            return (
            <Box    key={index + Math.random()} >
                <CategoryTag tag={tag} />
            </Box>
            )
        })
    }
    return (
        <Box
            // className="category-card"
            className={`category-card ${ApplicantDashboardBrowse ? "applicant-dashboard-browse" : ""}`}
        >
            <Stack
                direction={{
                    sm: "column",
                    xs: "column"
                }}
                justifyContent={"flex-start"}
                // spacing={4}
                spacing={ ApplicantDashboardBrowse ? 2 : 4}
            >
                <Stack
                    direction={"column"}
                    spacing={1}
                >
                    <Stack
                        direction={"row"}
                        alignItems={"center"}
                        columnGap={1.1}
                    >
                        {icon}
                        <Typography className="category-title" >{title}</Typography>
                    </Stack>
                    <Typography className="category-desc">{description}</Typography>
                </Stack>
                <Box
                   className={`category-tags ${ApplicantDashboardBrowse ? "applicant-dashboard-browse" : ""}`}
                  
                >
                        {renderTags()}
                </Box>
            </Stack>
        </Box>
      );
};

export default CategoryCard;

import { Box, Typography, useMediaQuery } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { getStaticPages, translateField } from "../../../../services/Helper";
import HeroSection from "../../yahya/components/hero-section/HeroSection";
import "./EnhancedPrivacyPage.scss";

const EnhancedPrivacyPage = () => {
  const mdMatches = useMediaQuery("(min-width:701px) and (max-width:1000px)");
  const smMatches = useMediaQuery("(max-width:700px)");

  const [policy, setPolicy] = useState("");

  useEffect(() => {
    const handleGetTerms = async () => {
      return await getStaticPages("PrivacyPolicy");
    };

    handleGetTerms().then((data) => {
      setPolicy(data.length > 0 ? data[0] : null);
    });
  }, []);

  return (
    <Box >
      <HeroSection small>
        <Typography className={"hero-section-title colored"}>
          {t("privacy")}
        </Typography>
        <Typography className={"hero-section-description"}>
          {t("privacy_desc")}
        </Typography>
      </HeroSection>
      <Box className="policy-page" component="div" dangerouslySetInnerHTML={{ __html: translateField(policy, "value") }}>
        {/* <Typography className="terms-text">
          {translateField(terms, "value")}
        </Typography> */}
      </Box>
    </Box>
  );
};

export default EnhancedPrivacyPage;

import React, { useState } from "react";
//mui
import { Grid, Typography, TextField } from "@mui/material";
import { Buildings, Phone, At, Student } from "phosphor-react";
import { useDispatch, useSelector } from "react-redux";
import {
  getNextStep,
  getPrevStep,
  getStep,
} from "../../../../services/Stepper";
import { stepperData } from "./stepper";
import { nextStep } from "../../../../state/actions/stepper/stepper";
import IconInput from "../../../../components/IconInput";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import {
  addCompanyContactInfo,
  updateCompanyContactInfo,
} from "../../../../state/actions/profile/profile";

const ContactInfo = () => {
  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState(false);

  const companyName = useSelector(
    (state) => state.profile.data?.recruiter?.company_name
  );
  const mobile = useSelector((state) => state.profile.data?.recruiter?.mobile);
  const workEmail = useSelector(
    (state) => state.profile.data?.recruiter?.work_email
  );

  const stepFinished = useSelector(
    (state) => state.profile.data?.recruiter?.step_finished
  );
  const currentStepNo = useSelector((state) => state.stepper.currentStep);
  const finalStepNo = useSelector((state) => state.stepper.finalStep);
  const current = getStep(stepperData, currentStepNo);

  const requiredMessage = t("required_input");
  const emailMessage = t("email_format");
  const minChar = t("min_input", { number: 3 });
  const maxChar = t("max_input", { number: 30 });

  const schema = yup
    .object({
      company_name: yup
        .string()
        .min(3, minChar)
        .max(30, maxChar)
        .required(requiredMessage)
        .trim(),
      mobile: yup
        .string()
        .matches(
          // /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
          /^\+[0-9]{1,3}[0-9]{4,14}(?:x.+)?$/,
          {
            message: t("phone_format"),
            excludeEmptyString: true,
          }
        )
        .required(requiredMessage),
      work_email: yup.string().email(emailMessage).required(requiredMessage),
    })
    .required();

  const {
    control,
    register,
    reset,
    formState: { errors },
    setError,
    handleSubmit,
    setValue,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      company_name: companyName,
      mobile: mobile,
      work_email: workEmail,
    },
  });

  const dispatch = useDispatch();

  const handleBack = () => {
    if (current.prev) {
      dispatch(nextStep({ currentStep: current.prev, finalStep: finalStepNo }));
    }
  };

  const handleNext = () => {
    if (current.next) {
      dispatch(nextStep({ currentStep: current.next, finalStep: finalStepNo }));
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    if (stepFinished && stepFinished >= current.no) {
      // edit
      await dispatch(updateCompanyContactInfo(data)).finally(() => {
        setLoading(false);
      });
      handleNext();
    } else {
      // add
      await dispatch(addCompanyContactInfo(data)).finally(() => {
        setLoading(false);
      });
      handleNext();
    }
    setLoading(false);
  };

  return (
    <>
      <Grid container direction="column">
        <Grid item container className="step-content contact-info">
          <Grid item xs={12}>
            <Typography className="title">
              {t("recruriter_contact_title")}
            </Typography>
            <Typography className="text">
              {t("contact_text")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid item xs={12}>
                <IconInput
                  label={t("company_name")}
                  id="company_name"
                  placeholder={t("company_name")}
                  type="text"
                  icon={Buildings}
                  className="w100 mb16"
                  fieldName="company_name"
                  register={register}
                  error={errors.company_name}
                />
              </Grid>
              <Grid item xs={12}>
                <IconInput
                  label={t("phone_number")}
                  id="mobile"
                  placeholder={t("phone_number")}
                  type="text"
                  icon={Phone}
                  className="w100 mb16"
                  fieldName="mobile"
                  register={register}
                  error={errors.mobile}
                />
              </Grid>
              <Grid item xs={12}>
                <IconInput
                  label={t("company_email")}
                  id="work_email"
                  placeholder={t("company_email")}
                  type="text"
                  icon={At}
                  className="w100 mb16"
                  fieldName="work_email"
                  register={register}
                  error={errors.work_email}
                />
              </Grid>
              <Grid item container justifyContent="end">
                {current.prev && (
                  <button className="back-btn" onClick={handleBack}>
                    {t("back_btn")}
                  </button>
                )}
                <button
                  type="submit"
                  className="next-btn"
                  disabled={loading}
                  style={!loading ? { cursor: "pointer" } : {}}
                >
                  {t("next_btn")}
                </button>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ContactInfo;

import Permissions from '../constants/permissions';
import AccountSettingsPage from '../views/account-settings/AccountSettingsPage';
import ApplicantCvProfile from '../views/applicant-cv-profile/ApplicantCvProfile';
import ApplicantProfileEdit from '../views/applicant-profile-edit/ApplicantProfileEdit';
import Profile from '../views/applicant-profile-page/ApplicantProfilePage';
import ApplicantsLists from '../views/applicants-list/ApplicantsList';
import AppliedJobs from '../views/applied-jobs/AppliedJobs';
import AppliedApplicant from '../views/appllied-applicant/AppliedApplicant';
import BrowsingJobsPage from '../views/browsing-jobs-page/BrowsingJobsPage';
import CandidatesBank from '../views/candidates-bank/CandidatesBank';
import CareerInterests from '../views/career-interests/CareerInterests';
import CompanyProfileEdit from '../views/company-profile-edit/CompanyProfileEdit';
import FollowedCompanies from '../views/followed-companies/FollowedCompanies';
import Followers from '../views/followers/Followers';
import HomePageHeader from '../views/home-page/components/header/HomePageHeader';
import JobDetails from '../views/job-details/JobDetails';
import Jobs from '../views/jobs/Jobs';
import NewJob from '../views/new-job/NewJob';
import News from '../views/news/News';
import Notifications from '../views/notifications/Notifications';
import EnhancedPrivacyPage from '../views/pages/abdelrahman/EnhancedPrivacyPage/EnhancedPrivacyPage';
import EnhancedTermsPage from '../views/pages/abdelrahman/EnhancedTermsPage/EnhancedTermsPage';
import LandingPage from '../views/pages/okba/LandingPage';
import Page400 from '../views/pages/page400/Page400';
import Page401 from '../views/pages/page401/Page401';
import Page403 from '../views/pages/page403/Page403';
import Page404 from '../views/pages/page404/Page404';
import ContactUs from '../views/pages/yahya/views/contact-us/ContactUs';
import HelpCenter from '../views/pages/yahya/views/help-center/HelpCenter';
import About from '../views/pages/zeinnab/views/about/About';
import SavedJobs from '../views/saved-jobs/SavedJobs';
import Settings from '../views/settings/Settings';
import SingleNewsPage from '../views/single-news-page/SingleNewsPage';
import PageBack from './../components/page-back/PageBack';
import CompanyProfile from './../views/Company-profile-page/CompanyProfile';
import ApplicantProfileEditHeader from './../views/applicant-profile-edit/components/header/ApplicantProfileEditHeader';
import SearchPage from './../views/search-page/SearchPage';

// const LandingPage = React.lazy(() => import("../views/pages/okba/LandingPage"));
export const dashboardRoutes = [
    {
        path: '/',
        name: 'Home Page',
        component: LandingPage,
        pageHeader: <HomePageHeader />,
        permissionRequired: true,
        permission: Permissions.viewHome,
        activeSidebar: 'home',
    },
    {
        path: 'my-profile',
        parent: '/',
        name: 'My Profile Page',
        component: Profile,
        // pageHeader: <PageBack title={"back"} link={"/"} />,
        permissionRequired: true,
        permission: Permissions.viewProfile,
        activeSidebar: 'my-profile',
    },
    {
        path: 'my-profile/edit',
        parent: '/',
        name: 'Profile Edit Page',
        component: ApplicantProfileEdit,
        pageHeader: <ApplicantProfileEditHeader />,
        permissionRequired: true,
        permission: Permissions.editApplicantProfile,
        activeSidebar: 'my-profile',
    },
    {
        path: 'account-settings',
        parent: '/',
        name: 'Account Settings',
        component: AccountSettingsPage,
        // pageHeader: <PageBack title={"back"} link={"/settings"} />,
        permissionRequired: true,
        permission: Permissions.viewProfile,
        activeSidebar: 'my-profile',
    },
    {
        path: 'company/edit',
        parent: '/',
        name: 'Company Edit Page',
        component: CompanyProfileEdit,
        // pageHeader: <CompanyProfileEditHeader />,
        permissionRequired: true,
        permission: Permissions.editCompanyProfile,
        activeSidebar: 'my-profile',
    },
    {
        path: 'followed-companies',
        parent: '/',
        name: 'Followed Companies Page',
        component: FollowedCompanies,
        // pageHeader: <PageBack title={"back"} link={"/"} />,
        permissionRequired: true,
        permission: Permissions.viewFollowedCompanies,
        activeSidebar: 'followed-companies',
    },
    {
        path: 'saved-jobs',
        parent: '/',
        name: 'Saved Jobs Page',
        component: SavedJobs,
        // pageHeader: <PageBack title={"back"} link={"/"} />,
        permissionRequired: true,
        permission: Permissions.canSaveJob,
        activeSidebar: 'saved-jobs',
    },
    {
        path: 'applied-jobs',
        parent: '/',
        name: 'Applied Jobs Page',
        component: AppliedJobs,
        // pageHeader: <PageBack title={"back"} link={"/"} />,
        permissionRequired: true,
        permission: Permissions.canApplyJob,
        activeSidebar: 'applied-jobs',
    },
    {
        path: 'browsing-jobs',
        parent: '/',
        name: 'browsing Jobs Page',
        component: BrowsingJobsPage,
        // pageHeader: <PageBack title={"back"} link={"/"} />,
        permissionRequired: true,
        permission: Permissions.canBrowsingJobs,
        activeSidebar: 'home',
    },
    {
        path: 'new/job',
        parent: '/',
        name: 'New Job Page',
        component: NewJob,
        // pageHeader: <NewJobHeader />,
        permissionRequired: true,
        permission: Permissions.createJob,
        activeSidebar: 'home',
    },
    {
        path: 'jobs/:job_id',
        parent: '/',
        name: 'Job Detail',
        component: JobDetails,
        pageHeader: <PageBack title={'back_to_jobs'} link="/" returnHome={true} />,
        permissionRequired: true,
        permission: Permissions.viewJob,
        activeSidebar: 'home',
    },
    {
        path: 'jobs/:job_id/edit',
        parent: '/',
        name: 'Job Edit',
        component: NewJob,
        // pageHeader: <NewJobHeader />,
        permissionRequired: true,
        permission: Permissions.editJob,
        activeSidebar: 'home',
    },
    {
        path: 'jobs/:job_id/applicants',
        parent: '/',
        name: 'Job Applicants',
        component: ApplicantsLists,
        // pageHeader: <ApplicantsListHeader />,
        permissionRequired: true,
        permission: Permissions.viewJobApplicants,
        activeSidebar: 'home',
    },
    {
        // path: "/search?type:search_type&keywords=:search_term&industry_id=:industry_id",
        path: 'search',
        parent: '/',
        name: 'Search',
        component: SearchPage,
        permissionRequired: false,
        permission: null,
        activeSidebar: 'home',
    },

    {
        path: 'career-interests',
        parent: '/',
        name: 'Career Interests',
        component: CareerInterests,
        // pageHeader: <PageBack title={"back"} link={"/"} />,
        permissionRequired: true,
        permission: Permissions.viewInterestsJobs,
        activeSidebar: 'career-interests',
    },
    {
        path: 'jobs/:job_id/applicants/:applicant_id',
        parent: '/',
        name: 'Job Applicant Profile',
        component: AppliedApplicant,
        // pageHeader: <AppliedApplicantHeader />,
        permissionRequired: true,
        permission: Permissions.viewJobApplicants,
        activeSidebar: 'home',
    },
    {
        path: 'news',
        parent: '/',
        name: 'All News',
        component: News,
        // pageHeader: <NewsHeader />,
        permissionRequired: true,
        permission: Permissions.viewNotifications,
        activeSidebar: 'home',
    },
    {
        path: 'news/:news_id',
        parent: '/',
        name: 'All News',
        component: SingleNewsPage,
        // pageHeader: <PageBack title={"back"} link={"/news"} />,
        permissionRequired: true,
        permission: Permissions.viewNotifications,
        activeSidebar: 'home',
    },
    {
        path: 'notifications',
        parent: '/',
        name: 'All Notifications',
        component: Notifications,
        // pageHeader: <NotificationsHeader />,
        permissionRequired: true,
        permission: Permissions.viewNotifications,
        activeSidebar: 'home',
    },
    {
        path: 'candidates-bank',
        parent: '/',
        name: 'Candidates Bank Page',
        component: CandidatesBank,
        // pageHeader: <CandiatesPageHeader />,
        permissionRequired: true,
        permission: Permissions.viewcandidatesBank,
        activeSidebar: 'candidates-bank',
    },
    {
        path: 'followers',
        parent: '/',
        name: 'Followers Page',
        // pageHeader: <PageHeader title={"followers"} translate />,
        component: Followers,
        permissionRequired: true,
        permission: Permissions.viewFollowers,
        activeSidebar: 'followers',
    },
    {
        path: 'settings',
        parent: '/',
        name: 'Settings Page',
        component: Settings,
        // pageHeader: <PageBack title={"back"} link={"/"} />,
        permissionRequired: true,
        permission: Permissions.viewSettings,
        activeSidebar: 'settings',
    },
    {
        path: 'my-profile/company',
        parent: '/',
        name: 'Company Profile',
        component: CompanyProfile,
        pageHeader: <PageBack title={'back'} link={'/'} returnHome={true} />,
        permissionRequired: true,
        permission: Permissions.viewCompanyProfile,
        activeSidebar: 'my-profile-company',
    },
    {
        path: 'my-jobs',
        parent: '/',
        name: 'My Jobs',
        component: Jobs,
        // pageHeader: <PageBack title={"back"} link={"/"} />,
        permissionRequired: true,
        permission: Permissions.createJob,
    },
    // {
    //   path: "company/:company_id",
    //   parent: "/",
    //   name: "Company Profile",
    //   component: CompanyProfile,
    //   pageHeader: <PageBack title={"back"} link={"/"} />,
    //   permissionRequired: false,
    //   permission: null,
    //   activeSidebar: "home",
    // },
    {
        path: 'help',
        parent: '/',
        name: 'FAQ',
        component: HelpCenter,
        // pageHeader: <PageBack title={"back"} link={"/settings"} />,
        permissionRequired: false,
        permission: null,
        activeSidebar: 'settings',
    },
    {
        path: 'terms',
        parent: '/',
        name: 'Terms & Conditions',
        component: EnhancedTermsPage,
        // pageHeader: <PageBack title={"back"} link={"/settings"} />,
        permissionRequired: false,
        permission: null,
        activeSidebar: 'settings',
    },
    {
        path: 'contact',
        parent: '/',
        name: 'Contact Us',
        component: ContactUs,
        pageHeader: null,
        permissionRequired: false,
        permission: null,
        activeSidebar: 'home',
    },
    {
        path: 'about',
        parent: '/',
        name: 'About',
        component: About,
        pageHeader: null,
        permissionRequired: false,
        permission: null,
        activeSidebar: 'home',
    },
    {
        path: 'privacy',
        parent: '/',
        name: 'Privacy Policy',
        component: EnhancedPrivacyPage,
        pageHeader: null,
        permissionRequired: false,
        permission: null,
        activeSidebar: 'home',
    },
    {
        path: '404',
        parent: '/',
        name: 'Not Found',
        component: Page404,
        permissionRequired: false,
        permission: null,
        activeSidebar: 'home',
    },
    {
        path: '400',
        parent: '/',
        name: 'Bad Request',
        component: Page400,
        permissionRequired: false,
        permission: null,
        activeSidebar: 'home',
    },
    {
        path: '401',
        parent: '/',
        name: 'Unautharized',
        component: Page401,
        permissionRequired: false,
        permission: null,
        activeSidebar: 'home',
    },
    {
        path: '403',
        parent: '/',
        name: 'Unautharized',
        component: Page403,
        permissionRequired: false,
        permission: null,
        activeSidebar: 'home',
    },

    {
        path: 'career-interests',
        parent: '/',
        name: 'Career Interests',
        component: CareerInterests,
        // pageHeader: <PageBack title={"back"} link={"/"} />,
        permissionRequired: true,
        permission: Permissions.viewInterestsJobs,
        activeSidebar: 'career-interests',
    },
    {
        path: 'jobs/:job_id/applicants/:applicant_id',
        parent: '/',
        name: 'Job Applicant Profile',
        component: AppliedApplicant,
        // pageHeader: <AppliedApplicantHeader />,
        permissionRequired: true,
        permission: Permissions.viewJobApplicants,
        activeSidebar: 'home',
    },
    {
        path: 'notifications',
        parent: '/',
        name: 'All Notifications',
        component: Notifications,
        // pageHeader: <NotificationsHeader />,
        permissionRequired: true,
        permission: Permissions.viewNotifications,
        activeSidebar: 'home',
    },
    {
        path: 'candidates-bank',
        parent: '/',
        name: 'Candidates Bank Page',
        component: CandidatesBank,
        permissionRequired: true,
        permission: Permissions.viewcandidatesBank,
        activeSidebar: 'candidates-bank',
    },
    {
        path: 'followers',
        parent: '/',
        name: 'Followers Page',
        component: Followers,
        permissionRequired: true,
        permission: Permissions.viewFollowers,
        activeSidebar: 'followers',
    },
    {
        path: 'settings',
        parent: '/',
        name: 'Settings Page',
        component: Settings,
        pageHeader: <PageBack title={'back'} link={'/'} returnHome={true} />,
        permissionRequired: true,
        permission: Permissions.viewSettings,
        activeSidebar: 'settings',
    },
    {
        path: 'my-profile/company',
        parent: '/',
        name: 'Company Profile',
        component: CompanyProfile,
        // pageHeader: <PageBack title={"back"} link={"/"} />,
        permissionRequired: true,
        permission: Permissions.viewCompanyProfile,
        activeSidebar: 'my-profile-company',
    },
    {
        path: 'company/:company_id',
        parent: '/',
        name: 'Company Profile',
        component: CompanyProfile,
        // pageHeader: <PageBack title={"back"} link={"/"} />,
        permissionRequired: false,
        permission: null,
        activeSidebar: 'followed-companies',
    },
    {
        path: 'applicant/:applicant_id',
        parent: '/',
        name: 'Applicant Cv Profile',
        component: ApplicantCvProfile,
        // pageHeader: <PageBack title={"back"} />,
        permissionRequired: false,
        permission: null,
        activeSidebar: 'my-profile-company',
    },
    {
        path: '404',
        parent: '/',
        name: 'Not Found',
        component: Page404,
        permissionRequired: false,
        permission: null,
        activeSidebar: 'home',
    },
    {
        path: '400',
        parent: '/',
        name: 'Bad Request',
        component: Page400,
        permissionRequired: false,
        permission: null,
        activeSidebar: 'home',
    },
    {
        path: '401',
        parent: '/',
        name: 'Unautharized',
        component: Page401,
        permissionRequired: false,
        permission: null,
        activeSidebar: 'home',
    },
    {
        path: '403',
        parent: '/',
        name: 'Unautharized',
        component: Page403,
        permissionRequired: false,
        permission: null,
        activeSidebar: 'home',
    },
];

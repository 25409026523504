import { Dialog, Divider, Grid, IconButton, InputBase, Paper, Typography } from '@mui/material';
import Permissions from 'constants/permissions';
import { t } from 'i18next';
import { FacebookLogo, Link, LinkedinLogo, ShareNetwork, TwitterLogo, UserPlus, WhatsappLogo } from 'phosphor-react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import EnhancedButton from '../../pages/yahya/components/enhanced-button/EnhancedButton';
import { perm } from './../../../services/Helper';
import UserCard from './UserCard';
import useSearch from './useSearch';

const EnhancedShareJobPost = ({ open, url, setOpen, job_id }) => {
    const { setSearchTerm, handleSubmit, control, onSubmit, data, email, setData, setValue, setEmail } = useSearch()
    const permissions = useSelector((state) => state.profile.role?.permissions);

    const renderApplicants = () => {
        if (email) {
            return <Grid item xs={12}>
                <UserCard
                    job_id={job_id}
                    name={"External User"}
                    image={null}
                    email={email}
                    isExternal
                />
            </Grid>

        }
        return data?.map((applicant) => {
            return <Grid item xs={12}>
                <UserCard
                    job_id={job_id}
                    name={applicant.first_name + " " + applicant.second_name}
                    image={applicant.image}
                    email={applicant.email}
                />
            </Grid>
        })
    }


    const handleClose = () => {
        setOpen(false)
        setValue("search_term", "")
        setEmail(null)
        setSearchTerm("")
        setData([])
    }

    return (
        <Dialog open={open} sx={{
            minWidth: "max-content"
        }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Paper
                    sx={{
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                        borderRadius: "8px",
                        height: "auto",
                        width: "592px"
                    }}
                    component={Grid}
                    container
                    alignItems={"flex-start"}
                >
                    <Grid item container padding={3} direction={"row"} justifyContent={"space-between"} alignItems={"center"} xs={12}>
                        <Grid item container direction={"row"} xs={5} columnSpacing={2} justifyContent={"flex-start"} alignItems={"center"}>
                            <Grid item xs={2}>
                                <ShareNetwork size={25} weight={"regular"} color={"#3A3B81"} />
                            </Grid>
                            <Grid item xs={8}>
                                <Typography sx={{ font: " normal normal bold 25px/30px Urbanist" }}>Share Job</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={7} container columnSpacing={2} alignItems={"center"}>
                            <Grid item>
                                <LinkedinShareButton url={url} className="social-media-share-icon">
                                    <IconButton className="social-media-share-icon">
                                        <LinkedinLogo size={26} color={"#3A3B81"} />
                                    </IconButton>
                                </LinkedinShareButton>
                            </Grid>
                            <Grid item>
                                <FacebookShareButton url={url} className="social-media-share-icon">
                                    <IconButton className="social-media-share-icon">
                                        <FacebookLogo size={26} color={"#3A3B81"} />
                                    </IconButton>
                                </FacebookShareButton>
                            </Grid>
                            <Grid item>
                                <TwitterShareButton url={url} className="social-media-share-icon">
                                    <IconButton className="social-media-share-icon">
                                        <TwitterLogo size={26} color={"#3A3B81"} />
                                    </IconButton>
                                </TwitterShareButton>
                            </Grid>
                            <Grid item>
                                <WhatsappShareButton url={url} className="social-media-share-icon">
                                    <IconButton className="social-media-share-icon">
                                        <WhatsappLogo size={26} color={"#3A3B81"} />
                                    </IconButton>
                                </WhatsappShareButton>
                            </Grid>
                            <Grid item>
                                <IconButton className="social-media-share-icon" onClick={() => {
                                    navigator.clipboard.writeText(url);
                                    toastr.info(t("clipboard-copy"));
                                }}>
                                    <Link size={26} color={"#3A3B81"} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} >
                        <Divider flexItem sx={{ width: "100%", height: "20px" }} />
                    </Grid>
                    {perm(permissions, Permissions.deleteJob) &&
                        <>
                            <Grid item xs={12} padding={3} container rowSpacing={2} columnSpacing={1} alignItems={"flex-start"}>
                                <Grid item >
                                    <UserPlus size={20} color={"#3A3B81"} weight={"bold"} />
                                </Grid>
                                <Grid item >
                                    <Typography sx={{ font: "normal normal bold 20px/24px Urbanist" }}>Invite to apply</Typography>
                                </Grid>
                                <Grid item xs={12} container columnSpacing={1} justifyContent={"space-between"}>
                                    <Grid item xs={9}>
                                        <Controller
                                            control={control}
                                            name="search_term"
                                            render={({
                                                field: { onChange, onBlur, value, name, ref },
                                            }) => (
                                                <InputBase
                                                    placeholder="Enter Email or Name"
                                                    value={value}
                                                    onChange={onChange}
                                                    sx={{
                                                        border: " 1px solid #E4E4E4",
                                                        borderRadius: "8px",
                                                        height: "40px",
                                                        paddingX: "10px",
                                                        width: "100%"
                                                    }} />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <EnhancedButton buttonText={`Search`} padding={"11px 24px"} type={"submit"} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container padding={3} rowSpacing={1}>
                                {renderApplicants()}
                            </Grid>
                        </>

                    }
                    <Grid item xs={12} container columnSpacing={2} padding={3} alignItems={"flex-end"} justifyContent={"flex-end"}>
                        <Grid item>
                            <EnhancedButton buttonText={"Cancel"} onClick={handleClose} padding={"12px 20px"} variant={"outlined"} />
                        </Grid>
                        <Grid item>
                            <EnhancedButton buttonText={"Done"} onClick={handleClose} padding={"14px 30px"} variant={"contained"} />
                        </Grid>
                    </Grid>
                </Paper>
            </form>
        </Dialog >
    )
}

export default EnhancedShareJobPost

import "./SidebarElement.scss";

import { Grid, Icon, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";

const SidebarElement = ({ id, icon, title, onClick, active, end }) => {
  const { t, i18n } = useTranslation();

  const className = ["sidebar-element", active ? "active" : " ", end ? "end" : " "].join(
    " "
  );

  return (
    <>
      <Box key={id} className={className} paddingY={1} onClick={onClick}>
        <Grid container sx={{ alignItems: "center" }}>
          <Grid item>
            <Icon className="icon">{icon}</Icon>
          </Grid>
          <Grid item>
            <Typography className="sidebar-title">{t(`${id}`)}</Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default SidebarElement;

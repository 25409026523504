import React from "react";
import FeaturedCompany from "./../../../../components/featured-company/FeaturedCompany";
import ErrorPage from "../../../../components/error-page/ErrorPage";
import EmptyLottie from "../../../../assets/lootie-files/empty-box.json";
import Show from "./../../../../components/Show";
import Loader from "../../../../components/loader/Loader";
import { Box } from "@mui/material";
import "./Companies.scss";

const Companies = ({ companies, emptySectionData, loaded, followedCompanies }) => {
  return (
    <>
      <Loader
        data={loaded}
        width={"100%"}
        height={300}
        style={{ borderRadius: "1rem" }}
      >
        <Show condition={companies?.length > 0}>
          {companies?.length > 0 && (
            <Box className="wrapper">
              {companies?.map((company) => {
                return <FeaturedCompany company={company} followedCompanies={followedCompanies} />;
              })}
            </Box>
          )}
        </Show>
      </Loader>
      <Show condition={loaded && companies?.length === 0}>
        <ErrorPage
          animationData={EmptyLottie}
          description={emptySectionData?.description}
          actionText={emptySectionData?.actionText}
          hideAction={emptySectionData?.hideAction}
          action={emptySectionData?.action}
        />
      </Show>
    </>
  );
};

export default Companies;

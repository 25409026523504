import {
  Autocomplete,
  Box,
  Chip, FormControl, FormHelperText, IconButton, InputLabel, TextField, Typography
} from "@mui/material";
import { CaretDown, Plus, X } from "phosphor-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { translateField } from "../services/Helper";

import { Controller } from "react-hook-form";
const ChoiceInput = ({
  control,
  limitNumber,
  label,
  register,
  getValues,
  fieldName,
  error,
  setValue,
  trigger,
  onChange,
  id,
  data,
  setError,
  className,
  placeholder,
  editCareer, }) => {

  const [choicesLength, setChoicesLength] = useState(0)
  const [choices, setChoices] = useState([]);
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setChoicesLength(choices?.length)
  }, [choices])

  useEffect(() => {
    if (editCareer) {
      setChoices(getValues?.(fieldName))
    }
  }, [])


  return (
    <FormControl
      variant="standard"
      className={["standart-form-controller", className].join(" ")}
    >
      {label && (
        <InputLabel
          className="choice-input-label"
          focused={false}
          htmlFor={id}
        >
          {label}
        </InputLabel>
      )}
      <Controller
        control={control}
        name={fieldName}
        render={({ field }) => {
          return (
            <Autocomplete
              multiple
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              id={id}
              popupIcon={choices?.length > 0 ? "" : <CaretDown />}
              options={data ? data : []}
              value={field.value}
              isOptionEqualToValue={(option, value) => option.id == value.id}
              getOptionLabel={(option) => translateField(option, "name")}
              filterSelectedOptions
              className="autocomplete-field"
              error={error[fieldName]}
              onBlur={(event) => {
                setChoices(field?.value);
                setError?.({ ...error, [fieldName]: field?.value?.length < 1 });
                if (field?.value?.length > limitNumber)
                  setError?.({ ...error, [fieldName]: field?.value?.length > limitNumber });
              }}
              onChange={(event, newValue) => {
                field.onChange(newValue);
                setChoices(newValue);
                onChange?.(newValue);
                setError?.({ ...error, [fieldName]: newValue.length < 1 });
                if (newValue.length > limitNumber)
                  setError?.({ ...error, [fieldName]: newValue.length > limitNumber });
              }}
              renderTags={(tagValue, getTagProps) => {
                return tagValue.map((option, index) => {
                  if (index === tagValue.length - 1) {
                    return (
                      <>
                        <Chip
                          {...getTagProps({ index })}
                          onDelete={() => {
                            getTagProps({ index }).onDelete();
                          }}
                          label={translateField(option, "name")}
                          className="choice-chips"
                          deleteIcon={<X className="chip-icon" />}
                        />
                        <IconButton
                          onClick={() => {
                            setOpen(true);
                          }}
                        >
                          <Plus />
                        </IconButton>
                      </>
                    );
                  }
                  return (
                    <Chip
                      {...getTagProps({ index })}
                      label={translateField(option, "name")}
                      className="choice-chips"
                      deleteIcon={<X className="chip-icon" />}
                    />
                  );
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={error[fieldName]}
                  placeholder={
                    placeholder ==
                      `${t(
                        "nationality_placeholder"
                      )}`
                      ? placeholder
                      : `${t("select_placeholder")}`
                  }
                />
              )}
            />
          );
        }}
      />
      {limitNumber && !error[fieldName] &&
        <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }} mt={1}>
          <Typography sx={{ fontSize: "0.85rem" }}>
            <Typography component={"span"} sx={{ fontSize: "0.85rem" }}>{choicesLength}</Typography> {` / ${limitNumber}`}
          </Typography>
        </Box>
      }
      {error[fieldName] && (
        <>
          {limitNumber ?
            <FormHelperText style={{ color: "red" }}>
              <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }} mt={1}>
                {choicesLength >= limitNumber ?
                  <>
                    {t("required_selection_maxed", { number: limitNumber })}
                  </>
                  : <>
                    {t("required_selection")}
                  </>
                }
                <Typography sx={{ fontSize: "0.85rem" }}>
                  <Typography component={"span"} sx={{ color: error[fieldName] && "red", fontSize: "0.85rem" }}>{choicesLength > 5 ? 5 : choicesLength}</Typography> {` / ${limitNumber}`}
                </Typography>
              </Box>
            </FormHelperText> :
            <FormHelperText style={{ color: "red" }}>
              {t("required_selection")}
            </FormHelperText>
          }
        </>
      )}
    </FormControl>
  );
};

export default ChoiceInput;

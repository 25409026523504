import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import BrowsingPage from "components/browsing-page/BrowsingPage";
import Scroller from "components/Scroller";
import { getJobs, scrollIntoView } from "services/Helper";

const AppliedJobs = () => {
  const [data, setData] = useState([]);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [page, setPage] = useState(1);
  const [ready, setReady] = useState(false);


  const { t, i18n } = useTranslation();

  const fetchPage = (pageNumber) => {
    setReady(false);
    getJobs("Applied", pageNumber).then((jobs) => {
      setData(jobs?.rows);
      setNumberOfPages(jobs?.meta?.totalNumberOfPages);
      setReady(true);
    });
  };

  useEffect(() => {
    fetchPage(1);
  }, []);

  const changePage = (newPage) => {
    fetchPage(newPage);
    setPage(newPage);
    scrollIntoView("browsing-page-top");
  };

  return (
    <>
      {/* <Scroller id={"browsing-page-top"} /> */}
      <BrowsingPage
        data={data}
        showFilter={false}
        title={t("applied_jobs")}
        pagesManager={{
          page: page,
          setPage: changePage,
          numberOfPages: numberOfPages,
        }}
        ready={ready}
        showStatus
      />
    </>
  );
};
export default AppliedJobs;

/* eslint-disable react/react-in-jsx-scope */
import { Dialog, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { login } from '../../services/auth/Auth';
import { CLOSE_LOGIN_MODAL } from '../../state/actions/login-modal/types';
import DecisionButton from '../decision-button/DecisionButton';
import "./LoginModal.css";

const LoginModal = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const location = useLocation()
    const loginModalOpened = useSelector((state) => state.loginModal.opened);

    const cancelAction = () => {
        dispatch({ type: CLOSE_LOGIN_MODAL })
    }

    const loginAction = () => {
        cancelAction();
        login();
    }
    useEffect(() => {
        cancelAction()
    }, [location])
    return (
        <>
            <Dialog
                open={loginModalOpened}
                onClose={cancelAction}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="login-modal"
                maxWidth={"406px"}
                disableScrollLock={true}
            >
                <Stack
                    direction={"column"}
                    spacing={5}
                    justifyContent={"flex-start"}
                >
                    <Stack
                        direction={"column"}
                        spacing={0.5}
                    >
                        <Typography className="title" marginBottom={0.5}>
                            {t("you_not_signed_in")}
                        </Typography>
                        <Typography className="subtitle">
                            {t("need_an_account")}
                        </Typography>
                    </Stack>
                    <Stack
                        direction={"column"}
                        spacing={3}
                    >
                        <DecisionButton active onClick={() => loginAction()}>
                            <Typography color="#fff" fontSize={"14px"} fontWeight={"500"} fontFamily={"Urbanist"}>
                                {t("login")}
                            </Typography>
                        </DecisionButton>
                        <DecisionButton onClick={() => cancelAction()}>
                            <Typography color="#919191" fontSize={"14px"} fontWeight={"500"} fontFamily={"Urbanist"}>
                                {t("cancel")}
                            </Typography>
                        </DecisionButton>
                    </Stack>
                </Stack>
            </Dialog>
        </>
    );
};
export default LoginModal;
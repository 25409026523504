import { Box, Grid, Grow, Paper, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getDocumentDirection } from '../../../../../../../services/Helper';
import "./FloatingNotificationCard.scss";

const FloatingNotificationCard = ({ icon, children }) => {
    const [cardsState, setCardsState] = useState(false)
    const documentDirection = getDocumentDirection()
    useEffect(() => {
        setCardsState(true)
    }, [])
    return (
        <Grow
            in={cardsState}
            timeout={2002}
        >
            <Paper
                className={"floating-card-wrapper"}
                component={Stack}
                direction={"row"}
                alignItems={"center"}
                sx={{ backgroundColor: "transparent" }}
            >
                <Grid container spacing={2} align={documentDirection == "right" ? "right" : "left"} >
                    <Grid item>
                        <Box
                            sx={{
                                backgroundColor: "#3A3B81",
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            {icon}
                        </Box>
                    </Grid>
                    <Grid item sx={{ opacity: "1" }}>
                        <Box className="text-wrapper">
                            {children}
                        </Box>
                    </Grid>
                </Grid>

            </Paper>
        </Grow>
    )
}

export default FloatingNotificationCard

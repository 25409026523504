import React from 'react';
import { Rating, Grid, Typography, Stack } from '@mui/material';
import { Star } from "phosphor-react";
import './RatingBox.scss'

const RatingBox = ({ title, value, yearsOfExp }) => {

    // const  = props;

    return (
        <Grid
            item
            xs={"auto"}
            container
            className='rating-box'
            alignItems={"center"}
        >
            <Grid item>
                <Stack
                    direction={"column"}
                >
                    <Typography className='title'>
                        {title}
                    </Typography>
                    <Typography className="years-of-exp" >
                        {yearsOfExp}
                    </Typography>
                </Stack>
            </Grid>
            <Grid item ml={0.5}>
                <Rating className='rating'
                    max={5}
                    defaultValue={value}
                    readOnly
                    emptyIcon={
                        <Star size={18} color={"#919191"} weight="fill" />
                    }
                    icon={
                        <Star size={18} color={"#fff"} weight="fill" />
                    }
                />
            </Grid>

        </Grid>
    )
}

export default RatingBox


const MaritalIcon = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <g id="Group_34" data-name="Group 34" transform="translate(0.133 0.133)">
    <rect id="Rectangle_50" data-name="Rectangle 50" width="20" height="20" transform="translate(-0.133 -0.133)" fill="none" />
    <g id="XMLID_1160_" transform="translate(1.583 -53.932)">
      <g id="Group_33" data-name="Group 33" transform="translate(0 57.5)">
        <path id="Path_2" data-name="Path 2" d="M16.491,64.014A5.2,5.2,0,0,0,15.469,61.7l.145-.053a1.208,1.208,0,0,0,.718-1.544l-.363-1a2.414,2.414,0,0,0-3.1-1.448l-1,.364a1.208,1.208,0,0,0-.718,1.544l.053.147a5.192,5.192,0,0,0-2.926,1.036A5.193,5.193,0,0,0,5.358,59.7l.053-.147a1.208,1.208,0,0,0-.718-1.544l-1-.364A2.414,2.414,0,0,0,.6,59.1l-.363,1a1.208,1.208,0,0,0,.718,1.544L1.1,61.7a5.216,5.216,0,0,0-.613,5.4,5.167,5.167,0,0,0,7.8,1.963,5.184,5.184,0,0,0,8.207-5.043Zm-.808-3.68a.516.516,0,0,1-.306.66l-2.136.781-.024.008.7-1.516a2.731,2.731,0,0,0,1.733-.014ZM13.107,58.3a1.724,1.724,0,0,1,2.213,1.035l.1.272a2.049,2.049,0,0,1-1.524-.087h0a2.064,2.064,0,0,1-1.051-1.121Zm-1,.364.079-.029a2.748,2.748,0,0,0,1.1,1.344l-.7,1.515-.008-.022-.778-2.147A.516.516,0,0,1,12.112,58.66Zm-7.735-.029.079.029a.516.516,0,0,1,.306.66l-.778,2.147-.008.022-.7-1.515A2.747,2.747,0,0,0,4.377,58.631Zm-3.129.7A1.724,1.724,0,0,1,3.46,58.3l.268.1A2.069,2.069,0,0,1,1.149,59.6Zm-.363,1,.03-.081a2.731,2.731,0,0,0,1.733.014l.7,1.516-.024-.008-2.136-.781A.516.516,0,0,1,.884,60.335Zm6.189,8.649A4.477,4.477,0,0,1,1.112,66.8a4.508,4.508,0,0,1,.673-4.857l1.3.477A1.2,1.2,0,0,0,4.633,61.7l.476-1.313a4.448,4.448,0,0,1,2.642.809,5.217,5.217,0,0,0,0,7.394A4.491,4.491,0,0,1,7.073,68.984Zm.251-5.991a4.532,4.532,0,0,1,.96-1.353,4.535,4.535,0,0,1,0,6.511A4.53,4.53,0,0,1,7.324,62.993ZM15.455,66.8a4.477,4.477,0,0,1-6.636,1.789,5.217,5.217,0,0,0,0-7.393,4.453,4.453,0,0,1,2.642-.809l.476,1.313a1.2,1.2,0,0,0,1.543.721l1.3-.477A4.5,4.5,0,0,1,15.455,66.8Z" transform="translate(0 -57.5)" fill="#404040" />
      </g>
    </g>
  </g>
</svg>
export default MaritalIcon

const apiConfig = {
    b2cScopes: ["https://digitalrootsb2c.onmicrosoft.com/api/reservation.write", "https://digitalrootsb2c.onmicrosoft.com/api/reservation.read"],
    webApi: "http://localhost:3000",
    clientId: "272b3015-bc68-42d8-823c-26bc7e38ca76"
};

const b2cPolicies = {
    names: {
        signUpSignIn: "B2C_1_signup_signin",
        signUp: "B2C_1_signup"
    },
    authorities: {
        signUpSignIn: {
            authority: "https://digitalrootsb2c.b2clogin.com/digitalrootsb2c.onmicrosoft.com/B2C_1_signup_signin",
        },
        signUp: {
            authority: "https://digitalrootsb2c.b2clogin.com/digitalrootsb2c.onmicrosoft.com/B2C_1_signup",
        },
        changePassword:{
            authority: "https://digitalrootsb2c.b2clogin.com/digitalrootsb2c.onmicrosoft.com/B2C_1_reset_password"
        }
    },
    authorityDomain: "https://digitalrootsb2c.b2clogin.com"
}

const msalConfig = (type) => {
    return {
        auth: {
            clientId: "d38bb8ce-49be-4603-85ea-493c6d7676de", // This is the ONLY mandatory field; everything else is optional.
            authority: type == "login" ? b2cPolicies.authorities.signUpSignIn.authority : b2cPolicies.authorities.signUp.authority, // Choose sign-up/sign-in user-flow as your default.
            knownAuthorities: [b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
            redirectUri: "http://localhost:3000", // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
            postLogoutRedirectUri: "http://localhost:3000"
        },
        cache: {
            cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
            storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
        },
    };
}
let loginRequest = {
    scopes: ["openid", ...apiConfig.b2cScopes],
    extraQueryParameters: { ui_locales: document.dir == "rtl" ? "ar" : "en" }
};

const tokenRequest = {
    scopes: [...apiConfig.b2cScopes],  // e.g. ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]
    forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token

};

export {
    apiConfig,
    b2cPolicies,
    msalConfig,
    loginRequest,
    tokenRequest
};

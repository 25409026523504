export const getStep = (stepper, no) => {
    let result = stepper?.find((step) => step.no == no);

    return result;
}

export const getStepByNo = (stepper, no) => {
    let result = stepper.find((step) => step.no == no);
    return result;
}

export const getNextStep = (stepper, no) => {
    const current = getStep(stepper, no);
    const next = getStep(stepper, current.next);
    return next;
}

export const getPrevStep = (stepper, no) => {
    const current = getStep(stepper, no);
    const prev = getStep(stepper, current.prev);
    return prev;
}
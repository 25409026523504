import { COMPANY_STEPPER } from "../../../../constants/constants";
import RecuriterStepperLang from './RecuriterStepperLang';

export const stepperData = [
    {
        no: 1,
        title_en:"Contact Info",
        title_ar:"معلومات الاتصال",

        name: COMPANY_STEPPER.CONTACT_INFO,
        next: 2,
        prev: false
    },
    {
        no: 2,
        title_en:"Company Info",
        title_ar:"معلومات الشركة",
        name: COMPANY_STEPPER.COMPANY_INFO,
        next: 3,
        prev: 1,
    },
    {
        no: 3,
        title_en:"Review & Submit",
        title_ar:"مراجعة وإرسال",
        name: COMPANY_STEPPER.REVIEW_SUBMIT,
        next: false,
        prev: 2,
    },
];
import React, { useEffect, useState } from 'react';
import ContactInfo from './ContactInfo';
import CompanyInfo from './CompanyInfo';
import ReviewAndSubmit from './ReviewAndSubmit';
//mui 
import AuthStepper from '../../../../components/auth-stepper/Stepper';
import { stepperData } from './stepper';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';


const Submit = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const stepFinished = useSelector((state) => state.profile.data?.recruiter?.step_finished);
  const isCompanyCreated = useSelector((state) => state.profile.data?.recruiter?.is_company_created);
  const currentStepName = useSelector((state) => state.stepper.currentStep);
  const title = "JOBLINK"

  const stepsFromApi = useSelector((state) => {
    let steps = state.profile.data?.recruiter?.RecruiterSteps;
    return steps && steps.length > 0 ? steps[0] : {};
  });

  const [stepperDataState, setStepperDataState] = useState(stepperData);
  useEffect(() => {
    let tempStepperData = stepperData;
    Object.keys(stepsFromApi).map((key, index) => {
      tempStepperData[index].completed = stepsFromApi[key]
    })
    setStepperDataState(tempStepperData);
    
  }, [stepsFromApi])


  const switched = location.state?.switched;

  useEffect(() => {
    if (!switched || isCompanyCreated == 1) {
      navigate("/")
    }
  }, [isCompanyCreated])

  const renderStepContent = () => {
    switch (currentStepName) {
      case 1:
        return (
          <>
            <Helmet>
              <title>{title} - {t("contact_info")}</title>
            </Helmet>
            <ContactInfo />
          </>
        );
      case 2:
        return (<>
          <Helmet>
            <title>{title} - {t("company_info")}</title>
          </Helmet>
          <CompanyInfo />
        </>
        );
      case 3:
        return (<>
          <Helmet>
            <title>{title} - {t("review_and_submit")}</title>
          </Helmet>
          <ReviewAndSubmit />
        </>
        );
      default:
        break;
    }
  }

  return (
    <>
      <AuthStepper
        data={stepperDataState}
        finalStep={stepFinished ? stepFinished : 0}
        startStep={0}
        renderStepContent={renderStepContent}
        pass={true}
        label={t("recuriter_sideba_label")} />
    </>
  )
}

export default Submit

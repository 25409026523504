import { Box, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FeaturedSection from "../../../../../components/featured-section/FeaturedSection";
import Loader from "../../../../../components/loader/Loader";
import {
  getFeaturedCompanies,
  translateField,
} from "../../../../../services/Helper";
import CompanyCard from "../../../zeinnab/components/company-card/CompanyCard";
import ComponentSlider from "../../../zeinnab/components/slider/Slider";
import "./FeaturedCompaniesSection.css";
import { useSelector } from "react-redux";

const FeaturedCompaniesSection = ({ from }) => {
  const mdMatches = useMediaQuery("(min-width:701px) and (max-width:1000px)");
  const smMatches = useMediaQuery("(max-width:700px)");
  const { t, i18n } = useTranslation();
  const [featuredCompanies, setFeaturedCompanies] = useState([]);
  const [companiesLoaded, setCompaniesLoaded] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const role = useSelector((state) => state.profile.role?.name);
  const token = useSelector((state) => state.auth?.token);

  useEffect(() => {
    console.log("FEATURED COMPANIES ROLE", role, token, role === "GUEST_USER" && !!token);
    getFeaturedCompanies("", (role === "GUEST_USER" && token) || (role === "RECRUITER"), 10, from).then((response) => {
      setFeaturedCompanies(response.rows);
    });
  }, []);

  useEffect(() => {
    console.log("FEATURED COMPANIES ROLE", role, token, role === "GUEST_USER" && !!token);
    getFeaturedCompanies("", (role === "GUEST_USER" && token) || (role === "RECRUITER"), 10, from).then(
      (response) => {
        let companies = featuredCompanies.map((company) => (
          <CompanyCard
            image={company?.image}
            companyId={company?.id}
            companyName={company?.company_name}
            companyIndustry={translateField(company?.CompanyIndustry, "name")}
            companyFollowers={t("followers", {
              count: company?.follower_count,
            })}
            companyUser={translateField(company?.CompanySize, "name")}
            // companyUser={"1000 - 5000"}
            // companyLocation={"null"}
            companyLocation={`${translateField(
              company?.City,
              "name"
            )}, ${translateField(company?.City?.Governorate?.Country, "name")}`}
            hovered
            onClick={() => navigate(`company/${company?.id}`)}
            ltrChangesInSlider
            isFollowed={company?.is_followed}
          />
        ));
        console.log("FEATURED DATA", data);
        setData(companies);
        setCompaniesLoaded(true);
      }
    );
  }, [i18n.language, featuredCompanies]);

  const responsive = [
    {
      breakpoint: 1250,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 670,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
      },
    },
  ];

  return (
    <Box
      component="section"
      className="featured-companies-section"
      // padding={smMatches ? "24px" : "0px"}
    >
      <FeaturedSection title={t("featured_companies")}>
        <Loader
          data={companiesLoaded}
          width={"100%"}
          height={340}
          style={{ borderRadius: "1rem" }}
        >
          <Box marginTop={0}>
            <ComponentSlider
              slidesToShow={4}
              items={data.length > 0 ? data : []}
              responsive={responsive}
            />
          </Box>
        </Loader>
      </FeaturedSection>
    </Box>
  );
};

export default FeaturedCompaniesSection;

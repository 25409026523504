import { APPLICANT_STEPPER } from "../../../constants/constants";


export const stepperData = [
    {
        no: 1,
        title_en:"General Info",
        title_ar:"معلومات عامة",
        name: APPLICANT_STEPPER.GENERAL_INFO,
        next: 2,
        prev: false,
    },
    {
        no: 2,
        title_en:"Contact Info & Portfolio",
        title_ar:"معلومات الاتصال والمحفظة",
        name: APPLICANT_STEPPER.CONTACT_INFO_PORTFOLIO,
        next: 3,
        prev: 1,
    },
    {
        no: 3,
        title_en:"Education",
        title_ar:"تعليم",
        name: APPLICANT_STEPPER.EDUCATION,
        next: 4,
        prev: 2,
    },
    {
        no: 4,
        title_en:"Experience",
        title_ar:"خبرة",
        name: APPLICANT_STEPPER.EXPERIENCE,
        next: 5,
        prev: 3,
    },
    {
        no: 5,
        title_en:"Skills",
        title_ar:"مهارات",
        name: APPLICANT_STEPPER.SKILLS,
        next: 6,
        prev: 4,
    },
    {
        no: 6,
        title_en:"Career Interests",
        title_ar:"مهارات",
        title_ar:"الاهتمامات المهنية",
        name: APPLICANT_STEPPER.CAREER_INTERESTS,
        next: false,
        prev: 5,
    },
];

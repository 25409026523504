import React, { useEffect, useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import OneAplicant from "./components/one-applicant/OneAplicant";
import Pagination from "../../components/pagination/Pagination";
import Hide from "../../components/Hide";
import FilterStatus from "./components/filter-status/FilterStatus";
import "./ApplicantsList.scss";
import { getAppliedApplicants, translateField } from "../../services/Helper";
// import noDataAnimation from "../../assets/lootie-files/no-data.json";
import ErrorPage from "../../components/error-page/ErrorPage";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

// const noDataAnimation = React.lazy(() => import("../../assets/lootie-files/no-data.json"));

const ApplicantsLists = () => {
  const isXs = useMediaQuery("(max-width:890px)");
  const { job_id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [jobInfo, setJobInfo] = useState(null);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [page, setPage] = useState(1);
  const [id, setId] = useState(0);
  const [filter, setFilter] = useState(null);
  const [ready, setReady] = useState(false);

  const { t, i18n } = useTranslation();

  const fetchPage = (filter = null, pageNumber = page, jobId = id) => {
    getAppliedApplicants(jobId, pageNumber, filter).then((applicants) => {
      setData(applicants ? applicants?.rows : []);
      setJobInfo(applicants?.job);
      setNumberOfPages(applicants?.meta?.totalNumberOfPages);
      setReady(true);
    });
  };

  useEffect(() => {
    // let params = window.location.href.split("/");
    // let id;
    // params.forEach((value) => {
    //   if (!isNaN(value)) {
    //     id = value;
    //   }
    // });
    setId(job_id);
    fetchPage(null, 1, job_id);
  }, [job_id]);

  const changePage = (newPage) => {
    fetchPage(filter, newPage, id);
    setPage(newPage);
  };

  const [noDataAnimation, setNoDataAnimation] = useState(null);
  useEffect(() => {
    import("../../assets/lootie-files/no-data.json").then(data => setNoDataAnimation(data));
  }, [])

  return (
    <Grid container direction="column" className="applicants-lists">
      <Grid item container direction="row">
        <Grid item xs={6}>
          <Box className="title-container">
            <Typography className="title">{t("applicants")}</Typography>
            <Typography className="number">({data?.length})</Typography>
            <Hide condition={isXs}>
              <Typography className="sub-title">
                {t("for")} "{translateField(jobInfo?.JobTitle, "name")}"
              </Typography>
            </Hide>
          </Box>
        </Grid>
        <Grid item xs={6} container justifyContent={"flex-end"}>
          <FilterStatus changeFilter={fetchPage} />
        </Grid>
      </Grid>
      {data?.length > 0 ? (
        <>
          <Grid item sx={{ background: "#fafafa" }}>
            <Grid container direction="row">
              {data?.map((value, index) => (
                <OneAplicant
                  jobName={value?.User?.JobTitle}
                  name={`${value?.User?.first_name} ${value?.User?.second_name}`}
                  jobId={value?.job_id}
                  applicantId={value?.User?.id}
                  location={`${translateField(
                    value.User?.City?.Governorate?.Country,
                    "name"
                  )}, ${translateField(
                    value.User?.City?.Governorate,
                    "name"
                  )}, ${translateField(value.User?.City, "name")}`}
                  experience={""}
                  status={
                    value?.JobStatus?.name_en === "Pending"
                      ? value?.is_seen
                        ? "Seen"
                        : value?.JobStatus?.name_en
                      : value?.JobStatus?.name_en
                  }
                  avatar={value?.User?.image}
                />
              ))}
            </Grid>
          </Grid>
          {numberOfPages > 1 && (
            <Grid item sx={{ marginTop: "24px" }}>
              <Pagination
                pagesManager={{
                  page: page,
                  setPage: changePage,
                  numberOfPages: numberOfPages,
                }}
              />
            </Grid>
          )}
        </>
      ) : (
        ready && (
          <ErrorPage
            action={() => {
              navigate("/");
            }}
            actionText={t("back_to_home")}
            animationData={noDataAnimation}
            title={t("oops")}
            description={t("no_applicants")}
          />
        )
      )}
    </Grid>
  );
};

export default ApplicantsLists;

import { Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Show from "../../components/Show";
import Content from "./components/content/Content";
import Sidebar from "./components/sidebar/Sidebar";
import "./Settings.scss";

const Settings = () => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState("");
  const matched = useMediaQuery("(max-width:900px)");
  useEffect(() => {
    if (!matched) {
      setSelected("account-settings");
    }
  }, []);

  return (
    <>
      <Typography className="settings-page-title">
        {" "}
        {t("settings")}
      </Typography>
      <Grid container direction={"row"} className="settings-container">
        <Grid item xs={12} md={2}>
          <Sidebar setContent={setSelected} />
        </Grid>
        <Show condition={!matched}>
          <Grid item>
            <Divider
              className="settings-divider"
              orientation="vertical"
              flexItem
            />
          </Grid>
        </Show>
        <Show condition={!matched}>
          <Grid item xs={12} md={true}>
            <Content selected={selected} />
          </Grid>
        </Show>
      </Grid>
    </>
  );
};
export default Settings;

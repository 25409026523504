/* eslint-disable react/jsx-key */
import { Box, Button, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import emptyPage from "../../assets/empty-page.json";
import ErrorPage from '../../components/error-page/ErrorPage';
import Loader from '../../components/loader/Loader';
import Show from '../../components/Show';
import { translateField } from '../../services/Helper';
import { getNews } from './../../state/actions/news/news';
import NewsListCard from './components/NotificationListCard';

// const emptyPage = React.lazy(() => import("../../assets/empty-page.json"));

const preLoader = (data) => {
    return (
        <>
            <Grid item xs={12}>
                <Loader data={data} width={"100%"} height={"40px"} ><></></Loader>
            </Grid>
            <Grid item xs={12}>
                <Loader data={data} width={"100%"} height={"40px"} ><></></Loader>
            </Grid>
            <Grid item xs={12}>
                <Loader data={data} width={"100%"} height={"40px"} ><></></Loader>
            </Grid>
            <Grid item xs={12}>
                <Loader data={data} width={"100%"} height={"40px"} ><></></Loader>
            </Grid>
            <Grid item xs={12}>
                <Loader data={data} width={"100%"} height={"40px"} ><></></Loader>
            </Grid>
        </>
    )
}

const News = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const news = useSelector((state) => state.news.data);
    const dataLoaded = useSelector((state) => state.news.loaded);
    const limit = useSelector((state) => state.news.limit);
    const count = useSelector((state) => state?.news?.newsMeta?.totalCount);
    const role = useSelector((state) => state.profile?.role);


    useEffect(() => {
        if (role !== "GUEST_USER" && role !== "REGISTERD_USER")
            dispatch(getNews(11))
    }, [])

    const loadMore = () => {
        if (role !== "GUEST_USER" && role !== "REGISTERD_USER")
            dispatch(getNews(limit + 11))
    }

    const [emptyPage, setEmptyPage] = useState(null);
    useEffect(() => {
        import("assets/empty-page.json").then(data => setEmptyPage(data));
    }, [])

    return (
        <Box component="div" width={'100%'}>
            <Grid container direction="row" justify-justifyContent="center">
                <Show condition={dataLoaded && news?.length == 0}>
                    <Grid item xs={12}>
                        <Box component="div" padding={2}>
                            <ErrorPage
                                title={t("oops")}
                                description={t("no_news")}
                                action={() => { navigate("/") }}
                                actionText={t('back_to_home')}
                                animationData={emptyPage}
                            />
                        </Box>
                    </Grid>
                </Show>
                {preLoader(dataLoaded)}
                <Show condition={dataLoaded && news?.length > 0}>
                    {news?.map((item) => {

                        return (
                            <Grid item xs={12}>
                                <NewsListCard
                                    id={item.id}
                                    status={item.status}
                                    name={() => {
                                        if (item.User)
                                            return item.User.first_name + " " + item.User.second_name;
                                        else
                                            return item.Recruiter.company_name;
                                    }}
                                    job_id={item.job_id}
                                    date={item.created_at}
                                    image={item.image ? item.image : null}
                                    message={translateField(item, "title")}
                                    applicant_id={item?.User?.id}
                                    recruiter_id={item?.Recruiter?.id}
                                    redirect={item?.id}
                                />
                            </Grid>
                        )
                    })}
                </Show>
                <Show condition={dataLoaded && news?.length > 0 && count > limit}>
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                        <Button className="load-more" onClick={() => loadMore()}>
                            {t("load_more")}
                        </Button>
                    </Grid>
                </Show>
            </Grid>

        </Box>
    );
}
export default News;
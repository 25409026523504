import './NewJob.css';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AuthStepper from '../../components/auth-stepper/Stepper';

import BasicDetails from './components/basic-details/BasicDetails';
import JobDetails from './components/job-details/JobDetails';
import ReviewJob from './components/review-job/ReviewJob';

import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { useForm } from 'react-hook-form';
import Show from '../../components/Show';
import DecisionButton from '../../components/decision-button/DecisionButton';
import { getStep } from '../../services/Stepper';
import { nextStep } from '../../state/actions/stepper/stepper';
import { stepperData } from './stepper';

import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { editJob, getCurrencies, getJob, postNewJob } from '../../services/Helper';

const CancelNextButtons = ({ onCancel, onNext, onBack, onPublish, publish, cancel, cancelText, backText, nextText, publishText, loading }) => {
    return (
        <Grid container item justifyContent="end" spacing={2} marginTop={1}>
            <Grid item xs={4} md={3}>
                <DecisionButton
                    onClick={() => {
                        cancel ? onCancel() : onBack();
                    }}
                >
                    <Typography className="standard-text" color="#3A3B81">
                        {cancel ? cancelText : backText}
                    </Typography>
                </DecisionButton>
            </Grid>
            <Grid item xs={4} md={3}>
                <DecisionButton
                    active
                    submit={publish}
                    onClick={() => {
                        if (onNext) onNext();
                        if (onPublish) onPublish();
                    }}
                    disabled={loading}
                >
                    {/* {loading ? (
            <CircularProgress size={20} />
          ) : (
            <Typography className="standard-text" color="#FFFFFF">
              {publish ? publishText : nextText}
            </Typography>
          )} */}

                    <Typography className="standard-text" color="#FFFFFF">
                        {publish ? publishText : nextText}
                    </Typography>
                </DecisionButton>
            </Grid>
        </Grid>
    );
};

const NewJob = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { job_id } = useParams();

    // state
    const [stepperDataState, setStepperDataState] = useState(stepperData);

    useEffect(() => {
        if (job_id && job_id != 'new') {
            let tempStepperData = stepperData;
            tempStepperData.map((key, index) => {
                tempStepperData[index].completed = 1;
            });
            setStepperDataState(tempStepperData);
        } else {
            stepperDataState.map((item) => {
                item.completed = 0;
            });
        }
    }, [job_id]);

    const [passStage, setPassStage] = useState(true);
    const [jobTitles, setJobTitles] = useState(null);
    const [noOfApplicants, setNoOfApplicants] = useState();
    const [date, setDate] = useState();
    const [applied, setApplied] = useState(false);
    const [startStep, setStartStep] = useState(0);
    const [loading, setLoading] = useState(false);

    // selector
    const currentStep = useSelector((state) => state.stepper.currentStep);
    const currentStepNo = useSelector((state) => state.stepper.currentStep);
    const finalStepNo = useSelector((state) => state.stepper.finalStep);

    const basicDetailsFields = ['job_title_id', 'years_of_experience_id', 'career_level_id', 'experience_type_id', 'hiring_time_id', 'job_category_id'];
    const jobDetailsFields = [
        'work_style_id',
        'job_requirement',
        'job_descirption',
        'city_id',
        'salary_id',
        'external_apply_website',
        'apply_type',
        'salary_from',
        'salary_to',
        'currency_id',
    ];

    // schema
    const requiredField = t('required_input');
    const invalidNumber = t('invalid_number');
    const minNumber = t('min_number', { number: 1 });
    const maxNumber = t('max_number', { number: 6 });
    const minChar = t('min_input', { number: 500 });
    const maxChar = t('max_input', { number: 1000 });
    const lessThanNumber = t('less_than_from_salary');
    const moreThanNumber = t('more_than_from_salary');
    const correctURL = t('correct_url');
    const schema = yup
        .object({
            job_title_id: yup.object().nullable().notOneOf([null, undefined, NaN], requiredField),
            years_of_experience_id: yup.string().required(requiredField),
            hiring_time_id: yup.string().required(requiredField),
            apply_type: yup.string().required(requiredField),
            external_apply_website: yup
                .string(correctURL)
                .when('apply_type', {
                    is: (apply_type) => apply_type == 'EXTERNAL_APPLY',
                    then: yup.string(correctURL).required(requiredField),
                    otherwise: yup.string().nullable().optional(),
                })
                .matches(/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/, correctURL),
            career_level_id: yup.string().required(requiredField),
            experience_type_id: yup.string().required(requiredField),
            job_category_id: yup.string().required(requiredField),
            work_style_id: yup.string().required(requiredField),
            city_id: yup.string().required(requiredField),
            salary_id: yup.string().required(requiredField),
            currency_id: yup.string().when('salary_id', {
                is: (salary_id) => salary_id == 2 || salary_id == 3 || salary_id == 4,
                then: yup.string().required(requiredField),
                otherwise: yup.string().nullable(),
            }),
            salary_from: yup.number().when('salary_id', {
                is: '1',
                then: yup.number().nullable(),
                otherwise: yup
                    .number()
                    .transform((value, originalValue) => {
                        return originalValue ? value : undefined;
                    })
                    .min(1, minNumber)
                    .required(requiredField)
                    .typeError(invalidNumber),
            }),
            salary_to: yup
                .number()
                .transform((value, originalValue) => {
                    return originalValue ? value : undefined;
                })
                .when('salary_id', {
                    is: '2',
                    then: yup
                        .number()
                        .transform((value, originalValue) => {
                            return originalValue ? value : undefined;
                        })
                        .min(1, minNumber)
                        //.max(999999, maxNumber)
                        .moreThan(yup.ref('salary_from'), moreThanNumber)
                        .required(requiredField)
                        .typeError(invalidNumber),
                    otherwise: yup
                        .number()
                        .transform((currentValue, originalValue) => {
                            return isNaN(currentValue) ? null : currentValue;
                        })
                        .nullable(),
                }),
            job_descirption: yup.string().min(500, minChar).max(1000, maxChar).required(requiredField),
            job_requirement: yup.string().min(500, minChar).max(1000, maxChar).required(requiredField),
        })
        .required();

    const mdMatches = useMediaQuery('(min-width:701px) and (max-width:1300px)');
    const smMatches = useMediaQuery('(max-width:700px)');

    // form hook
    const {
        control,
        register,
        reset,
        formState: { errors },
        setError,
        clearErrors,
        handleSubmit,
        setValue,
        getValues,
        trigger,
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'all',
        reValidateMode: 'onChange',
    });

    const basicDetailsErrors = [
        errors.job_title_id,
        errors.years_of_experience_id,
        errors.career_level_id,
        errors.experience_type_id,
        errors.job_category_id,
        errors.hiring_time_id,
    ];

    const jobDetailsErrors = [
        errors.work_style_id,
        errors.external_apply_website,
        errors.city_id,
        errors.salary_from,
        errors.salary_to,
        errors.job_descirption,
        errors.apply_type,
        errors.job_requirement,
        errors.currency_id,
    ];

    useEffect(() => {}, [errors]);

    useEffect(() => {
        if (Object.keys(errors).length == 0) {
            setPassStage(false);
        } else {
            setPassStage(true);
        }
    }, [...basicDetailsErrors]);

    useEffect(() => {
        if (Object.keys(errors).length == 0) {
            setPassStage(false);
        } else {
            setPassStage(true);
        }
    }, [...jobDetailsErrors]);

    useEffect(() => {
        const getJobForEdit = async () => {
            return await getJob(job_id, false);
        };

        if (job_id && job_id != 'new') {
            getJobForEdit().then((data) => {
                setValue('years_of_experience_id', data?.YearsOfExperience.id);
                setValue('career_level_id', data?.CareerLevel.id);
                setValue('experience_type_id', data?.ExperienceType.id);
                setValue('job_category_id', data?.JobCategory.id);
                setValue('work_style_id', data?.WorkStyle.id);
                setValue('country_id', data?.Country.id);
                setValue('governorate_id', data?.Governorate.id);
                setValue('city_id', data?.City.id);
                setValue('salary_id', data?.Salary?.id);
                setValue('salary_from', data?.salary_from);
                setValue('salary_to', data?.salary_to);
                setValue('job_descirption', data?.job_descirption);
                setValue('job_requirement', data?.job_requirement);
                setValue('job_title_id', data?.JobTitle);
                console.log('getJobForEdit STATUS', data?.status);
                setValue('status', data?.status);
                if (data?.external_apply_website) {
                    setValue('apply_type', 'EXTERNAL_APPLY');
                    setValue('external_apply_website', data?.external_apply_website);
                } else {
                    setValue('apply_type', 'EASY_APPLY');
                }
                if (data.hiring_time_id || data.hiring_time_id === 0) {
                    setValue('hiring_time_id', data?.hiring_time_id);
                }
                if (data?.currency) {
                    getCurrencies().then((currencies) => {
                        currencies.forEach((value) => {
                            if (value.code === data?.currency) {
                                setValue('currency_id', value.id);
                            }
                        });
                    });
                }

                setJobTitles([data?.JobTitle]);
                setNoOfApplicants(data?.count);
                setDate(data?.created_at);
                if (data?.count > 0) {
                    // toastr.warning(t("job_is_applied"));
                    // setApplied(true);
                    dispatch(nextStep({ currentStep: 3, finalStep: finalStepNo }));
                }
            });
        }
    }, [job_id]);

    const current = getStep(stepperData, currentStepNo);
    const triggerBasicDetails = async () => {
        return await trigger([...basicDetailsFields]);
    };

    const triggerJobDetails = async () => {
        return await trigger([...jobDetailsFields]);
    };
    const handleBack = (stage) => {
        switch (stage) {
            case !job_id && 'basic_details':
                stepperDataState[0].completed = 1;
                break;
            case !job_id && 'job_details':
                stepperDataState[1].completed = 0;
                break;
            case !job_id && 'review_job':
                stepperDataState[2].completed = 0;
                break;
            default:
                break;
        }
        if (current.prev) {
            dispatch(nextStep({ currentStep: current.prev, finalStep: finalStepNo }));
        }
    };
    const handleNext = async (stage) => {
        let pass = false;
        console.log('BASIC DETAILS ERROR', stage);
        switch (stage) {
            case 'basic_details':
                pass = await triggerBasicDetails();
                console.log('BASIC DETAILS ERROR', pass);
                setPassStage(pass);
                stepperDataState[0].completed = 1;
                break;
            case 'job_details':
                pass = await triggerJobDetails();
                setPassStage(pass);
                stepperDataState[1].completed = 1;
                break;
            default:
                stepperDataState[2].completed = 1;
                break;
        }
        if (pass) {
            if (current.next) {
                dispatch(nextStep({ currentStep: current.next, finalStep: finalStepNo }));
            }
        }
    };

    const onSubmit = async (data) => {
        setLoading(true);
        if (data.salary_id == 1) {
            delete data.currency_id;
        }
        if (job_id && job_id != 'new') {
            data.job_id = job_id;
            let sendData = {};
            if (applied) {
                sendData.job_id = data.job_id;
                sendData.status = data.status;
            } else {
                sendData = data;
            }

            editJob(sendData, applied)
                .then((response) => {
                    navigate(`/jobs/${response.id}`);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            postNewJob(data)
                .then((response) => {
                    setLoading(false);
                    navigate(`/jobs/${response.id}`);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const renderStepContent = () => {
        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <Show condition={currentStep == 1}>
                    <BasicDetails
                        control={control}
                        handleBack={() => navigate('/')}
                        handleNext={() => handleNext('basic_details')}
                        errors={errors}
                        getValues={getValues}
                        pJobTitles={jobTitles}
                        // disabled={applied}
                        loading={loading}
                    />
                </Show>
                <Show condition={currentStep == 2}>
                    <JobDetails
                        control={control}
                        register={register}
                        setValue={setValue}
                        handleBack={() => handleBack('job_details')}
                        handleNext={() => handleNext('job_details')}
                        errors={errors}
                        clearErrors={clearErrors}
                        getValues={getValues}
                        // disabled={applied}
                        loading={loading}
                    />
                </Show>
                <Show condition={currentStep == 3}>
                    <ReviewJob
                        control={control}
                        register={register}
                        setValue={setValue}
                        handleBack={() => handleBack('review_job')}
                        handleNext={handleNext}
                        getValues={getValues}
                        errors={errors}
                        noOfApplicants={noOfApplicants ? noOfApplicants : 0}
                        date={date ? date : new Date()}
                        loading={loading}
                    />
                </Show>
            </form>
        );

        // switch (currentStep) {
        //     case 1:
        //         return <BasicDetails control={control} handleBack={handleBack} handleNext={handleNext} />;
        //     case 2:
        //         return <JobDetails control={control} handleBack={handleBack} handleNext={handleNext} />;
        //     case 3:
        //         return <ReviewJob control={control} handleBack={handleBack} handleNext={handleNext} />;
        //     default:
        //         break;
        // }
    };

    return (
        <Box>
            <Box className="new-job common-dark-stepper">
                <AuthStepper
                    data={stepperDataState}
                    finalStep={0}
                    startStep={startStep}
                    renderStepContent={renderStepContent}
                    label={'test'}
                    hideHeader
                    pass={true}
                    hideLogoutLang={true}
                    // stepsFromApi={stepsFromApi}
                />
            </Box>
            {/* <Hide condition={job_id && job_id != "new"}>
        <StarterDialog />
      </Hide>  */}
        </Box>
    );
};

export { CancelNextButtons };
export default NewJob;

import React, { useState } from 'react';
import { Grid, Box, Typography, Avatar } from '@mui/material';
import UserAvatar from '../../../components/user-avatar/UserAvatar';
import Hide from '../../../components/Hide';
import Show from '../../../components/Show';
import './NotificationListCard.scss';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { readSingleNotification } from '../../../services/Helper';
import { useSelector } from 'react-redux';
import { Buildings } from 'phosphor-react';
import dateFormat from "dateformat";

const NotificationListCard = (props) => {
    const { image, message, status, date, job_id, id, applicant_id, recruiter_id, redirect } = props;

    const navigate = useNavigate();

    const role = useSelector((state) => state.profile.role?.name);


    const displayDate = new Date(date);
    const displayDateString = dateFormat(displayDate, "dd mmm yy, h:MM tt")

    const openNotification = () => {
        readSingleNotification(role, id).then((response) => {
            navigate(redirect)

        })
    }

    return (
        <Box className={status == 0 ? "notification-list not-readed-card" : "notification-list"} onClick={() => openNotification()}>
            <Grid container >
                <Grid item container xs={12} md={9} >
                    <Grid item xs="auto">
                        <Show condition={image}>
                            <Avatar sx={{ width: "20px", height: "20px", display: "inline-block" }} src={image} />
                        </Show>
                        <Show condition={!image}>
                            <Buildings width="20px" height="20px" />
                        </Show>
                    </Grid>
                    <Grid item>
                        <span className='title'>{message}</span>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={3} container justifyContent={"flex-end"}>
                    <Typography className='title'>{displayDateString}</Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

export default NotificationListCard
import { Avatar, Grid, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import moment from 'moment';
import { Clock } from 'phosphor-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AvatarCompany from "../../../../../../../../assets/Avatar Company"
import "./EnhancedDropdownElement.css";
const EnhancedDropdownElement = ({ active, name, image, message, date, onClick }) => {

    // translation
    const { t } = useTranslation();

    // difference between given date and now
    const date1 = moment(date);
    const date2 = moment(new Date());
    const diff = date2.diff(date1);
    const duration = moment.duration(diff);
    const days = Math.floor(duration.asDays());
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.asMinutes());

    let durationText = "";
    if (minutes <= 60) {
        durationText = t("min-ago", { duration: minutes });
    } else if (hours > 1 && hours <= 24) {
        durationText = t("hour-ago", { duration: hours });
    } else {
        durationText = t("day-ago", { duration: days });
    }


    return (
        <>
            <Box onClick={onClick} className={["enhanced-dropdown-element", active ? "unread" : ""].join(" ")}>
                <Grid container>
                    <Grid item xs={2.5}>
                        <Avatar src={image} />
                    </Grid>
                    <Grid item xs={9.5}>
                        <Typography className="element-title">{name}</Typography>
                        <Typography className="element-description">{message}</Typography>
                    </Grid>
                </Grid>
                <Grid container justifyContent={"flex-end"} alignItems={"center"} spacing={0.5}>
                    <Grid item>
                        <Typography className="element-time">{durationText}</Typography>
                    </Grid>
                    <Grid item>
                        <Clock color="#838383" size={14} weight="fill" />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default EnhancedDropdownElement;
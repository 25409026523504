import { Box, Button, FormControl, FormGroup, Typography } from '@mui/material';
import { At, Password } from 'phosphor-react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import IconInput from './IconInput';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
const VerifyOTP = ({ title, onSubmit }) => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const schema = yup
        .object({
            otp: yup
                .string()
                .matches(/^[0-9]+$/, 'Must be only digits')
                .min(6, 'Must be exactly 6 digits')
                .max(6, 'Must be exactly 6 digits')
                .required(t('required_input')),
        })
        .required();
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm({
        mode: 'all',
        reValidateMode: 'onBlur',
        resolver: yupResolver(schema),
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
                <FormControl className="mb24">
                    <Typography className="title">Joblink</Typography>
                    <Typography className="desc">{title}</Typography>
                </FormControl>
                <IconInput
                    label={'OTP'}
                    id="email"
                    placeholder={'Enter OTP number'}
                    type="text"
                    icon={Password}
                    className="mb16"
                    fieldName="otp"
                    register={register}
                    error={errors.otp}
                />
                <FormControl className="mb24">
                    <Button type="submit" className="login-btn" variant="contained">
                        {'Submit'}
                    </Button>
                </FormControl>

                <FormControl>
                    <Typography variant="div" className="navigation-text">
                        {location.pathname === 'reset-password-verification' && t('remember_password')}
                        <Box component={Link} className="navigation-btn" to="/login">
                            {t('back_to_login')}
                        </Box>
                    </Typography>
                </FormControl>
            </FormGroup>
        </form>
    );
};

export default VerifyOTP;

import { SET_NOTIFICATIONS } from "../../actions/notifications/types";

let initialState = {
    limit: 11,
    data: [],
    loaded: false
}

export const notificationsReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_NOTIFICATIONS:
            return {
                ...state,
                data: payload.data,
                limit: payload.limit,
                total: payload.total,
                loaded: true
            };
        default:
            return state;
    }
}

export default notificationsReducer;

import { getNotificationList, readAllNotifications as readAllNotificationsHelper } from "../../../services/Helper"
import { OPENED } from "../notification/types";
import { SET_NOTIFICATIONS } from "./types"

export const getNotifications = (limit) => async (dispatch, getState) => {
    const role = getState().profile.role.name;
    const data = await getNotificationList(role, limit)
    const payload = {
        limit,
        data: data.rows
    }
    dispatch({ type: SET_NOTIFICATIONS, payload })
}

export const readAllNotifications = () => async (dispatch, getState) => {
    const role = getState().profile.role.name;
    const limit = getState().notifications.limit;
    const data = await readAllNotificationsHelper(role, limit)
    const payload = {
        limit: limit,
        data: data.rows,
        count: data.meta.totalCount
    }
    dispatch({ type: SET_NOTIFICATIONS, payload })
    dispatch({ type: OPENED })
} 
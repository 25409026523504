import { Box, Divider, Paper, Stack, Typography } from "@mui/material";
import "./InformationCard.scss";

const InformationCard = ({
  asset,
  number,
  title,
  description,
  bgGray,
  aboutSectionInfo,
}) => {
  return (
    <Stack alignItems="center" justifyContent="center" sx={{ height: "100%" }}>
      {number && (
        <Box
          className={
            aboutSectionInfo ? "information-icon" : "information-number"
          }
        >
          {number}
        </Box>
      )}
      <Paper
        className={`information-paper${number ? "" : " no-number"}`}
        elevation={0}
        direction={"column"}
        component={Stack}
        alignItems={"center"}
        sx={{ backgroundColor: `${bgGray ? "#F1F1F1" : " #ffffff"} ` ,maxHeight:"fit-content"}}
      >
        <Box alignSelf="center" marginBottom={3}>
          {asset}
        </Box>
        <Box sx={{width:"100%"}}>
          <Divider
            flexItem
            className="information-title"
            sx={{
              "&.MuiDivider-root::before": {
                borderTop: bgGray ? "1px solid #9191914D" : "1px solid #F1F1F1",
              },
              "&.MuiDivider-root::after": {
                borderTop: bgGray ? "1px solid #9191914D" : "1px solid #F1F1F1",
              },
              mr: "-1px",
              alignItems: "center"
            }}
          >
            <Typography className="information-title-text">{title}</Typography>
          </Divider>
        </Box>
        <Typography className="information-description">
          {description}
        </Typography>
      </Paper>
    </Stack>
  );
};
export default InformationCard;

import { Avatar, Box, Grid, Typography } from "@mui/material";
import { MapPin, Buildings, UsersThree } from "phosphor-react";
import { useNavigate } from "react-router-dom";
import CompanyTag from "../company-tag/CompanyTag";
import Show from "../Show";
import "./UserAvatar.scss";

const UserAvatar = ({
  showAvatar,
  showTitle,
  showName,
  isCompany,
  avatar,
  title,
  name,
  rounded,
  size,
  bolded,
  companySize,
  applicantCardAvatar,
  fontWeight,
  changeNameStyle,
  changeTitleStyle,
  recruiterId,
  viewProfile,
  showUserThreeicon,
  showCardTag,
  location,
  isCV
}) => {
  const navigate = useNavigate();

  const sizes = {
    miniSmall: 30,
    small: 38,
    medium: 60,
    large: 80,
  };
  const fontWeights = {};
  const changesInNameStyle = {
    applicantAvatarCardName: "applicant-card-avatar-name",
    applicantAvatarName: "applicant-avatar-name",
  };
  const changesInTitleStyle = {
    applicantAvatarCardTitle: "applicant-card-avatar-title",
    applicantAvatarTitle: "applicant-avatar-title",
  };

  return (
    <>
      <Grid
        container
        direction={"row"}
        alignItems={"center"}
        spacing={1}
        sx={{ justifyContent: "center" }}
      >
        <Grid
          item
          xs="auto"
          onClick={() => {
            if (recruiterId) {
              navigate(`/company/${recruiterId}`);
            }
          }}
          sx={
            recruiterId && {
              cursor: "pointer",
            }
          }
        >
          {showAvatar &&
            (isCompany && !avatar ? (
              <Buildings size={60} />
            ) : (
              <Avatar
                sx={{
                  height: sizes[size],
                  width: sizes[size],
                  marginBottom: "5px",
                }}
                src={avatar}
                variant={rounded ? "rounded" : "circular"}
                className={`avatar-image ${rounded ? "rounded-avatar" : ""}`}
              />
            ))}
        </Grid>
        {(showName || showTitle) && (
          <Grid item xs>
            <Grid container direction={"column"}>
              <Grid
                item
                style={{ textTransform: "capitalize", width: "fit-content" }}
                onClick={() => {
                  if (recruiterId) {
                    navigate(`/company/${recruiterId}`);
                  }
                }}
                sx={
                  recruiterId && {
                    cursor: "pointer",
                  }
                }
              >
                {showName && (
                  <Typography
                    sx={{
                      fontWeight: bolded ? "600" : "400",
                      color: "#000000",
                    }}
                    className={`${changesInNameStyle[changeNameStyle]}${isCV ? " cv-name" : " avatar-name"}`}
                  >
                    {name}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                style={{ width: "fit-content" }}
                onClick={() => {
                  if (recruiterId) {
                    navigate(`/company/${recruiterId}`);
                  }
                }}
                sx={
                  recruiterId && {
                    cursor: "pointer",
                  }
                }
              >
                {showTitle && (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "3px",
                      }}
                    >
                      {showUserThreeicon && (
                        <UsersThree className="employee-icon" size={18} />
                      )}
                      <Typography
                        className={`${changesInTitleStyle[changeTitleStyle]}${isCV ? " cv-title" : " avatar-title"}`}
                      >
                        {title}
                      </Typography>
                    </Box>
                  </>
                )}
              </Grid>
              <Show condition={showCardTag}>
                <Grid sx={{ marginTop: "8px" }}>
                  <CompanyTag icon={<MapPin />} text={location} />
                </Grid>
              </Show>
            </Grid>

          </Grid>
        )}
      </Grid>
    </>
  );
};
export default UserAvatar;

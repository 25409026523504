import { Typography ,Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';

import React from 'react'

const SubtitleText = ({ title }) => {

    const useStyles = makeStyles({
        typography: () => ({
            '& .MuiTypography-root': {
                color: '#838383',
                fontFamily: "Urbanist",
                fontSize: "16px",
                fontWeight: "600"
            }
        }),
    });

    const classes = useStyles();

    return (
        <Stack className={classes.typography}>
            <Typography >
                {title}
            </Typography>
        </Stack>
    )
}

export default SubtitleText
import { Box, Button, Grid, IconButton } from "@mui/material";
import { Check, Trash, X } from "phosphor-react";

const EditBox = (props) => {
    return (
        <Grid container item className="grey-box" >
            <form onSubmit={props.handleSubmit} >
                <Grid item xs={12} sx={{ mb: 1 }}>
                    <Grid item container justifyContent="space-between" alignItems="center">
                        <Box component="div" className="title m0">{props.title}</Box>
                        <IconButton size="small" onClick={props.handleCloseEdit}>
                            <X />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid item container spacing={2}>
                    {props.children}
                    <Grid item container justifyContent="end">
                        <IconButton className="trash" type="button" disabled={props.loading} onClick={props.handleDelete}>
                            <Trash size={20} />
                        </IconButton>
                        <IconButton className="check" type="submit" disabled={props.loading}>
                            <Check size={20} />
                        </IconButton>
                    </Grid>
                </Grid>
            </form>
        </Grid>
    )
}
export default EditBox;
import "./PageHeader.css";
import { Box, Typography, IconButton, Grid, useMediaQuery } from "@mui/material";
import Show from "../Show"
import { useTranslation } from "react-i18next";
import Logo from '../../assets/Logo2SVG';
import Hide from "../Hide";
const PageHeader = ({ showButton, title, icon, onClick, translate }) => {
    const { t, i18n } = useTranslation();
    const mdMatch = useMediaQuery('(max-width:899px)')

    return (
        <>
            <Box component="div" className="page-title">
                <Grid container direction="row" columnSpacing={2}>
                    <Hide condition={mdMatch}>
                        <Grid item>
                            <Logo />
                        </Grid>
                    </Hide>
                   
                    <Show condition={showButton}>
                        <Grid item>
                            <IconButton className="jb-icon-btn" onClick={onClick} >
                                {icon}
                            </IconButton>
                        </Grid>
                    </Show>
                </Grid>
            </Box>
        </>
    );
}
export default PageHeader;
import React from 'react';
import { Grid, IconButton, Divider } from "@mui/material";
import { CaretRight, CaretLeft } from "phosphor-react";
import UserAvatar from "../../../../components/user-avatar/UserAvatar";
import "./ApplicantCard.scss"
import { useNavigate, useParams } from 'react-router-dom';


const ApplicantsAvatar = (props) => {
    const navigate = useNavigate();
    const { name, jobtitle, image, job_id, applicant_id } = props;
    // const { job_id } = useParams()
    return (
        <div className='applicant-avatar' style={{ border: 'none' }}>
            <Grid container >
                <Grid item xs={10}>
                    <UserAvatar
                        showAvatar
                        showName
                        showTitle
                        changeNameStyle="applicantAvatarName"
                        changeTitleStyle="applicantAvatarTitle"
                        name={name}
                        title={jobtitle}
                        avatar={image}
                    />
                </Grid>
                <Grid item xs={2} container justifyContent={"flex-end"}>
                    <IconButton className='applicant-avatar-icon right' onClick={() => navigate(`/jobs/${job_id}/applicants/${applicant_id}`)}>
                        <CaretRight />
                        {/* <CaretLeft /> */}
                    </IconButton>
                    <IconButton className='applicant-avatar-icon left' onClick={() => navigate(`/jobs/${job_id}/applicants/${applicant_id}`)}>
                        {/* <CaretRight/> */}
                        <CaretLeft />
                    </IconButton>
                </Grid>
            </Grid>
            <Divider className='divider' />
        </div>
    )
}

export default ApplicantsAvatar
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Dialog, FormHelperText, Grid, TextField,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import {
  addNewCategory,
  getCategoriesList
} from "../../../../../state/actions/categoriesList/categoriesList";
import "./AddCategoryModal.scss";

const AddCategoryModal = ({ onClose, open }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    newCategory: Yup.string()
      .required(t("required_input"))
      .matches(
        /^[^-\s][a-zA-Z0-9_$&+,:;=?@#|'<>.^*()%!\s-]+$/,
        t("invalid_input")
      )
      .min(2, t("min_input", { number: 2 }))
      .max(50, t("max_input", { number: 50 })),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm(formOptions);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await dispatch(addNewCategory(data.newCategory));
      dispatch(getCategoriesList());
      reset();
      onClose();
    } catch (error) {
      //Ignore
    }
    setLoading(false);
  };

  const handleCancelAddCategoryDialog = () => {
    reset();
    onClose();
  };

  return (
    <Dialog onClose={onClose} open={open} className="add-category-dialog" disableScrollLock={true}>
      <Typography className="title">{t("add_category")}</Typography>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
        <Grid container>
          <Grid item xs={12}>
            {/* <TextField
              style={{ width: '100%' }}
              placeholder={t('category_name')}
              className='input'
              {...register("newCategory")}
              error={errors}
            />
            <FormHelperText style={{ color: "red" }}>{errors.newCategory?.message}</FormHelperText> */}
            <Controller
              control={control}
              name="newCategory"
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <>
                  <TextField
                    value={value}
                    onChange={onChange}
                    className="add-category-input"
                    error={!!errors?.newCategory}
                  />
                  {errors?.newCategory && (
                    <FormHelperText style={{ color: "red" }}>
                      {errors?.newCategory?.message}
                    </FormHelperText>
                  )}
                </>
              )}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent={"center"} className="btns">
          <Grid item>
            <Button
              onClick={handleCancelAddCategoryDialog}
              className="btn-cancle"
            >
              {t("cancel")}
            </Button>
          </Grid>
          <Grid item>
            <Button type="submit" className="btn-add" disabled={loading}>
              {/* {loading ? <CircularProgress size={20} /> : t("add_category")} */}
              {t("add_category")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};

export default AddCategoryModal;

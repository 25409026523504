import { Grid } from "@mui/material";
import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import Permissions from "../../constants/permissions";
import { translateField } from "../../services/Helper";
import Loader from "../loader/Loader";
import UserAvatar from "../user-avatar/UserAvatar";
import { getJob, perm } from "./../../services/Helper";
import DownloadCv from "./download-cv/DownloadCv";
import EnhancedInviteAndToCvBank from "./invite-add-to-cv-bank/EnhancedInviteAndToCvBank";
import EnhancedRefuseAndShortlist from "./refuse-and-shortlist/EnhancedRefuseAndShortlist";

const ApplicantProfileViewHeader = ({
  userData,
}) => {
  const permissions = useSelector((state) => state.profile.role?.permissions);
  const role = useSelector((state) => state.profile?.role?.name);
  const location = useLocation()
  const [currentJob, setCurrentJob] = useState("")
  console.log(location.pathname === "/applicant/:applicant_id")

  useEffect(() => {
    if (role === "RECRUITER" && userData?.job_status_id) {
      getJob(userData?.job_id, false).then((res) => setCurrentJob(res))
    }
  }, [userData])

  return (
    <Loader
      data={userData}
      width={"100%"}
      height={100}
      style={{ borderRadius: "1rem" }}
    >
      {role === "RECRUITER" && userData?.job_status_id && (
        <>
          <Grid item xs={12} mb={2}>
            <EnhancedRefuseAndShortlist
              status_id={userData?.job_status_id}
              job_name={translateField(currentJob?.JobTitle, "name")}
              applicant_applied_job_id={userData?.id}
            />
          </Grid>
        </>
      )}
      <Grid
        item
        xs={12}
        container
        component={"section"}
        sx={{
          backgroundColor: "#3A3B81 ",
          borderRadius: "16px",
          padding: "20px 20px",
        }}
        alignItems={"center"}
        rowSpacing={{ sm: 2, xs: 2, md: 0 }}
        justifyContent={{
          xs: "center",
          sm: "space-between",
          md: "space-between",
        }}
      >
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <UserAvatar
            showAvatar
            showTitle
            showName
            name={
              userData?.first_name + " " + userData?.second_name
            }
            title={
              userData?.CareerLevel === null
                ? `${translateField(userData?.JobTitle, "name")}`
                : userData?.JobTitle === null
                  ? `${translateField(userData?.CareerLevel, "name")}`
                  : `${translateField(
                    userData?.CareerLevel,
                    "name"
                  )} ${translateField(userData?.JobTitle, "name")}`
            }
            size={"medium"}
            avatar={userData?.image}
            isCV
          />
        </Grid>
        {(role === "RECRUITER" || location.pathname === "/my-profile") && <>
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            lg={6}
            xl={6}
            container
            columnSpacing={1}
            justifyContent={"flex-end"}
            // justifyContent={{ xs: "center", sm: "space-evenly", md: "flex-end", }}
            alignItems={"center"}
          >
            {perm(permissions, Permissions.deleteJob) && (
              <EnhancedInviteAndToCvBank applicant_id={userData?.id} cvBank={{ isAdded: userData?.is_cv_bank_added, cvBankCategories: userData?.cv_bank }} />
            )}
            {perm(permissions, Permissions.viewApplicantProfile) && (
              <DownloadCv applicant_id={userData?.id} />
            )}
          </Grid>
        </>}
      </Grid>
    </Loader >
  );
};

export default ApplicantProfileViewHeader;

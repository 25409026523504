import {
  Button, FormControl, ListItemIcon, ListItemText, Menu,
  MenuItem
} from "@mui/material";
import {
  BehanceLogo, Browser, DribbbleLogo, FacebookLogo, GithubLogo,
  GitlabLogo, LinkedinLogo, PinterestLogo, Plus,
  TwitterLogo
} from "phosphor-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";


const AddButton = ({ type, id, placeholder, className, onClick, menu, selectedSocialTypes, setSelectedSocialTypes, selectedPortfolioTypes, setSelectedPortfolioTypes }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { t, i18n } = useTranslation();


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <FormControl
      variant="standard"
      className={["standart-form-controller", className].join(" ")}
      fullWidth
    >
      <Button
        //sx={{ justifyContent: "flex-start", marginBottom: 5, textTransform: "none" }}
        className="add-btn"
        startIcon={<Plus />}
        onClick={handleClick}
      >
        {t("add")} {type}
      </Button>
      {menu === "social" && (
        <Menu
          className="social-menu"
          disableScrollLock={true}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          marginThreshold={0}
          MenuListProps={{
            "aria-labelledby": "basic-button",
            //className: "social-menu"
            sx: { width: (anchorEl && anchorEl.offsetWidth) + 5 },
          }}
        >
          {!selectedSocialTypes.includes("Facebook") && (
            <MenuItem
              className="social-menu"
              onClick={() => {
                let selectedSocialMedia = [...selectedSocialTypes];
                selectedSocialMedia.push("Facebook");
                setSelectedSocialTypes(selectedSocialMedia);
                handleClose();
                onClick({type: FacebookLogo, url: ""});
              }}
            >
              <ListItemIcon>
                <FacebookLogo />
              </ListItemIcon>
              <ListItemText>Facebook</ListItemText>
            </MenuItem>
          )}
          {!selectedSocialTypes.includes("Linkedin") && (
            <MenuItem
              onClick={() => {
                let selectedSocialMedia = [...selectedSocialTypes];
                selectedSocialMedia.push("Linkedin");
                setSelectedSocialTypes(selectedSocialMedia);
                handleClose();
                //onClick(LinkedinLogo);
                onClick({type: LinkedinLogo, url: ""});
              }}
            >
              <ListItemIcon>
                <LinkedinLogo />
              </ListItemIcon>
              <ListItemText>LinkedIn</ListItemText>
            </MenuItem>
          )}
          {!selectedSocialTypes.includes("Twitter") && (
            <MenuItem
              onClick={() => {
                let selectedSocialMedia = [...selectedSocialTypes];
                selectedSocialMedia.push("Twitter");
                setSelectedSocialTypes(selectedSocialMedia);
                handleClose();
                //onClick(TwitterLogo);
                onClick({type: TwitterLogo, url: ""});
              }}
            >
              <ListItemIcon>
                <TwitterLogo />
              </ListItemIcon>
              <ListItemText>Twitter</ListItemText>
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              handleClose();
              //onClick(Browser);
              onClick({type: Browser, url: ""});
            }}
          >
            <ListItemIcon>
              <Browser />
            </ListItemIcon>
            <ListItemText>Other</ListItemText>
          </MenuItem>
        </Menu>
      )}

      {menu === "portfolio" && (
        <Menu
          className="social-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          marginThreshold={0}
          MenuListProps={{
            "aria-labelledby": "basic-button",
            //className: "social-menu"
            sx: { width: (anchorEl && anchorEl.offsetWidth) + 5 },
          }}
        >
          {!selectedPortfolioTypes.includes("Github") && (
            <MenuItem
              className="social-menu"
              onClick={() => {
                let selectedPortfolio = [...selectedPortfolioTypes];
                selectedPortfolio.push("Github");
                setSelectedPortfolioTypes(selectedPortfolio);
                handleClose();
                onClick({type: GithubLogo, url: ""});
              }}
            >
              <ListItemIcon>
                <GithubLogo />
              </ListItemIcon>
              <ListItemText>Github</ListItemText>
            </MenuItem>
          )}
          {!selectedPortfolioTypes.includes("Gitlab") && (
            <MenuItem
              className="social-menu"
              onClick={() => {
                let selectedPortfolio = [...selectedPortfolioTypes];
                selectedPortfolio.push("Gitlab");
                setSelectedPortfolioTypes(selectedPortfolio);
                handleClose();
                onClick({type: GitlabLogo, url: ""});
              }}
            >
              <ListItemIcon>
                <GitlabLogo />
              </ListItemIcon>
              <ListItemText>Gitlab</ListItemText>
            </MenuItem>
          )}
          {!selectedPortfolioTypes.includes("Behance") && (
            <MenuItem
              className="social-menu"
              onClick={() => {
                let selectedPortfolio = [...selectedPortfolioTypes];
                selectedPortfolio.push("Behance");
                setSelectedPortfolioTypes(selectedPortfolio);
                handleClose();
                onClick({type: BehanceLogo, url: ""});
              }}
            >
              <ListItemIcon>
                <BehanceLogo />
              </ListItemIcon>
              <ListItemText>Behance</ListItemText>
            </MenuItem>
          )}
          {!selectedPortfolioTypes.includes("Dribbble") && (
            <MenuItem
              className="social-menu"
              onClick={() => {
                let selectedPortfolio = [...selectedPortfolioTypes];
                selectedPortfolio.push("Dribbble");
                setSelectedPortfolioTypes(selectedPortfolio);
                handleClose();
                onClick({type: DribbbleLogo, url: ""});
              }}
            >
              <ListItemIcon>
                <DribbbleLogo />
              </ListItemIcon>
              <ListItemText>Dribbble</ListItemText>
            </MenuItem>
          )}
          {!selectedPortfolioTypes.includes("Pinterest") && (
            <MenuItem
              className="social-menu"
              onClick={() => {
                let selectedPortfolio = [...selectedPortfolioTypes];
                selectedPortfolio.push("Pinterest");
                setSelectedPortfolioTypes(selectedPortfolio);
                handleClose();
                onClick({type: PinterestLogo, url: ""});
              }}
            >
              <ListItemIcon>
                <PinterestLogo />
              </ListItemIcon>
              <ListItemText>Pinterest</ListItemText>
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              handleClose();
              //onClick(Browser);
              onClick({type: Browser, url: ""});
            }}
          >
            <ListItemIcon>
              <Browser />
            </ListItemIcon>
            <ListItemText>Other</ListItemText>
          </MenuItem>
        </Menu>
      )}
    </FormControl>
  );
};
export default AddButton;

import { Box, Button, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import emptyPage from "../../assets/empty-page.json";
import ErrorPage from '../../components/error-page/ErrorPage';
import Loader from '../../components/loader/Loader';
import Show from '../../components/Show';
import { translateField } from '../../services/Helper';
import { getNotifications } from '../../state/actions/notifications/notifications';
import NotificationListCard from './components/NotificationListCard';

// const emptyPage = React.lazy(() => import("../../assets/empty-page.json"));

const preLoader = (data) => {
    return (
        <>
            <Grid item xs={12}>
                <Loader data={data} width={'100%'} height={'40px'}>
                    <></>
                </Loader>
            </Grid>
            <Grid item xs={12}>
                <Loader data={data} width={'100%'} height={'40px'}>
                    <></>
                </Loader>
            </Grid>
            <Grid item xs={12}>
                <Loader data={data} width={'100%'} height={'40px'}>
                    <></>
                </Loader>
            </Grid>
            <Grid item xs={12}>
                <Loader data={data} width={'100%'} height={'40px'}>
                    <></>
                </Loader>
            </Grid>
            <Grid item xs={12}>
                <Loader data={data} width={'100%'} height={'40px'}>
                    <></>
                </Loader>
            </Grid>
        </>
    );
};

const Notifications = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { t, i18n } = useTranslation();

    const notifications = useSelector((state) => state.notifications.data);
    const dataLoaded = useSelector((state) => state.notifications.loaded);
    const limit = useSelector((state) => state.notifications.limit);
    const count = useSelector((state) => state.notifications.count);

    useEffect(() => {
        dispatch(getNotifications(11));
    }, []);

    const loadMore = () => {
        dispatch(getNotifications(limit + 11));
    };

    const [emptyPage, setEmptyPage] = useState(null);
    useEffect(() => {
        import('assets/empty-page.json').then((data) => setEmptyPage(data));
    }, []);

    return (
        <Box component="div" width={'100%'}>
            <Grid container direction="row" justify-justifyContent="center">
                <Show condition={dataLoaded && notifications?.length == 0}>
                    <Grid item xs={12}>
                        <Box component="div" padding={2}>
                            <ErrorPage
                                title={t('oops')}
                                description={t('no_notifications')}
                                action={() => {
                                    navigate('/');
                                }}
                                actionText={t('back_to_home')}
                                animationData={emptyPage}
                            />
                        </Box>
                    </Grid>
                </Show>
                {preLoader(dataLoaded)}
                <Show condition={dataLoaded && notifications?.length > 0}>
                    {notifications?.map((item) => {
                        return (
                            <Grid item xs={12}>
                                <NotificationListCard
                                    id={item.id}
                                    status={item.status}
                                    name={() => {
                                        if (item.User) return item.User.first_name + ' ' + item.User.second_name;
                                        else return item.Recruiter.company_name;
                                    }}
                                    job_id={item.job_id}
                                    date={item.created_at}
                                    image={item.User ? item.User.image : item.Recruiter.image}
                                    message={translateField(item, 'message_property')}
                                    applicant_id={item?.User?.id}
                                    recruiter_id={item?.Recruiter?.id}
                                    redirect={item?.redirect}
                                />
                            </Grid>
                        );
                    })}
                </Show>
                <Show condition={dataLoaded && notifications?.length > 0 && count > limit}>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Button className="load-more" onClick={() => loadMore()}>
                            {t('load_more')}
                        </Button>
                    </Grid>
                </Show>
            </Grid>
        </Box>
    );
};
export default Notifications;

import { Grid, IconButton, InputBase, Paper, Popper, Typography } from "@mui/material";
import { t } from "i18next";
import { FacebookLogo, Link, LinkedinLogo, PencilSimple, ShareNetwork, TwitterLogo, WhatsappLogo, X } from "phosphor-react";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toastr } from 'react-redux-toastr';
import { useLocation, useNavigate } from "react-router-dom";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import { downloadCV } from "./../../../services/Helper";
import EnhancedIconButton from "./../../../views/pages/abdelrahman/EnhancedIconButton/EnhancedIconButton";
import EnhancedButton from "./../../../views/pages/yahya/components/enhanced-button/EnhancedButton";

const DownloadCv = ({ applicant_id }) => {
  const [loading, setLoading] = useState(false);
  const anchorEl = useRef();
  const [openShare, setOpenShare] = useState(false);
  const permissions = useSelector((state) => state.profile.role?.permissions);
  const location = useLocation();
  console.log(location);
  const navigate = useNavigate();
  const handleDownloadCV = async () => {
    setLoading(true);
    const link = await downloadCV(applicant_id);
    setLoading(false);
    var element = document.createElement("a");
    element.setAttribute("href", link);
    element.setAttribute("target", "_blank");
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const toggleShare = (e) => {
    e.stopPropagation();
    setOpenShare((prev) => !prev);
  };
  const renderShare = () => {
    const url = `https://joblinkshub.com/applicant/${applicant_id}`;
    return (
      <>
        <Popper
          open={openShare}
          disablePortal={true}
          placement={"bottom-start"}
          anchorEl={anchorEl.current}
          sx={{
            height: "210px",
            maxWidth: "450px",
            zIndex: "2323",
          }}
        >
          <Paper
            sx={{
              padding: "24px",
              background: "#FFFFFF 0% 0 % no-repeat padding-box",
              boxShadow: "0px 0px 30px #0000001A",
              borderRadius: " 0px 0px 8px 8px",
              zIndex: "32165",
              cursor: "default"
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <Grid container alignItems={"center"} rowSpacing={2}>
              <Grid
                item
                container
                direction={"row"}
                columnSpacing={1}
                justifyContent={"space-between"}
                alignItems="center"
                xs={12}
              >
                <Grid
                  item
                  container
                  direction={"row"}
                  xs={10}
                  columnSpacing={2}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                >
                  <Grid item xs={1}>
                    <ShareNetwork size={25} weight={"fill"} color={"#3A3B81"} />
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                      sx={{ font: " normal normal bold 25px/30px Urbanist" }}
                    >
                      {t("share_profile")}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={2} align={"right"}>
                  <IconButton onClick={toggleShare}>
                    <X size={30} />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item xs={12} container columnSpacing={2}>
                <Grid item>
                  <LinkedinShareButton
                    url={url}
                    className="social-media-share-icon"
                  >
                    <IconButton className="social-media-share-icon">
                      <LinkedinLogo size={26} color={"#3A3B81"} />
                    </IconButton>
                  </LinkedinShareButton>
                </Grid>
                <Grid item>
                  <FacebookShareButton
                    url={url}
                    className="social-media-share-icon"
                  >
                    <IconButton className="social-media-share-icon">
                      <FacebookLogo size={26} color={"#3A3B81"} />
                    </IconButton>
                  </FacebookShareButton>
                </Grid>
                <Grid item>
                  <TwitterShareButton
                    url={url}
                    className="social-media-share-icon"
                  >
                    <IconButton className="social-media-share-icon">
                      <TwitterLogo size={26} color={"#3A3B81"} />
                    </IconButton>
                  </TwitterShareButton>
                </Grid>
                <Grid item>
                  <WhatsappShareButton
                    url={url}
                    className="social-media-share-icon"
                  >
                    <IconButton className="social-media-share-icon">
                      <WhatsappLogo size={26} color={"#3A3B81"} />
                    </IconButton>
                  </WhatsappShareButton>
                </Grid>
              </Grid>
              <Grid item xs={12} container columnSpacing={1}>
                <Grid item xs={10}>
                  <InputBase
                    sx={{
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      border: " 1px solid #E4E4E4",
                      borderRadius: "8px",
                      maxWidth: "max-content",
                      maxHeight: "50px",
                      padding: "10px 15px",
                      minWidth: "100%",
                    }}
                    value={url}
                  />
                </Grid>
                <Grid item xs={2}>
                  <EnhancedIconButton
                    icon={<Link />}
                    active
                    onClick={(e) => {
                      e.stopPropagation();
                      navigator.clipboard.writeText(url);
                      toastr.info(t("clipboard-copy"));
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Popper>
      </>
    );
  };
  return (
    <>
      <Grid item xs={2} sm={2} md={"auto"} ref={anchorEl}>
        <EnhancedIconButton
          icon={<ShareNetwork color={"white"} />}
          variant={"gray"}
          onClick={toggleShare}
        />
        {renderShare()}
      </Grid>
      <Grid item xs={2} sm={2} md={"auto"}>
        {location.pathname === "/my-profile" && (
          <EnhancedIconButton
            icon={<PencilSimple color={"white"} />}
            variant={"gray"}
            onClick={() => navigate("/my-profile/edit")}
          />
        )}
      </Grid>
      <Grid item xs={6} sm={6} md={"auto"}>
        <EnhancedButton
          variant={"outlined"}
          buttonText={`${t("download_cv")}`}
          padding={"16px 20px"}
          onClick={handleDownloadCV}
          loading={loading}
          noHover
        />
      </Grid>
    </>
  );
};

export default DownloadCv;

import React from 'react';
import ErrorPage from '../../../components/error-page/ErrorPage';
// import animateData from "../../../assets/empty-page.json"
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useState } from 'react';

// const animateData = React.lazy(() => import("../../../assets/empty-page.json"));

const Page400 = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [animateData, setAnimateData] = useState(null);
    useEffect(() => {
        import("assets/empty-page.json").then(data => setAnimateData(data));
    }, [])
    return (
        <ErrorPage
            title={t("400")}
            animationData={animateData}
            actionText={t("back_to_home")}
            action={() => navigate("/")}
        />
    );
};

export default Page400;
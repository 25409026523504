import React from 'react';
import { Typography } from "@mui/material";
import "./TitlesAndDescreption.scss"

const TitlesAndDescreption = ({ title, descreption }) => {
  return (
    <div className='res-title-desc'>
      <Typography className='title'>{title}</Typography>
      <Typography className='desc'>{descreption}</Typography>
    </div>
  )
}

export default TitlesAndDescreption
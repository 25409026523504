import { Box, Typography } from "@mui/material";
import Logo from "assets/Logo2SVG";
import "../views/pages/redirecting/Redirecting.css";

const SkeletonRedirecting = () => {
    return (
        <>
            <Box className="redirect-page">
                <Box className="animation">
                    {/* <ColoredLogo fontSize={"60px"} /> */}
                    <Logo />
                    <Typography style={{ textAlign: "center" }} marginTop={2} >
                        Redirecting ...
                    </Typography>
                </Box>
            </Box>
        </>
    )
}

export default SkeletonRedirecting
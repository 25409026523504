import React from "react";
import { Grid, Typography, Divider, IconButton, Avatar } from "@mui/material";
import UserAvatar from "../../../../components/user-avatar/UserAvatar";
import ApplicationStatus from "../../../../components/application-status/ApplicationStatus";
import {
  DotsThreeVertical,
  Eye,
  Spinner,
  X,
  CheckSquareOffset,
} from "phosphor-react";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import Hide from "../../../../components/Hide";
import Show from "../../../../components/Show";
import { translateField } from "../../../../services/Helper";
import "./OneApplicant.scss";

const OneAplicant = (props) => {
  const { jobName, location, experience, status, avatar, jobId, applicantId, name } = props;
  const isXs = useMediaQuery("(max-width:890px)");
  const navigate = useNavigate();
  
  return (
    <>
      <Grid
        container
        className="applicant-list"
        onClick={() => navigate(`/jobs/${jobId}/applicants/${applicantId}`)}
      >
        <Grid
          item
          xs={6}
          md={2.5}
          container
          alignItems={"center"}
          sx={{ width: "200px" }}
        >
          <UserAvatar
            showAvatar
            showName
            name={name}
            avatar={avatar}
            miniSmall
            bolded
          />
          {/* <Grid item>
            <Avatar className="avtar" src={avatar} />
          </Grid>
          <Grid item>
            <Typography className="name">{name}</Typography>
          </Grid> */}
        </Grid>
        <Hide condition={isXs}>
          <Grid
            item
            xs={2.5}
            md={2.5}
            container
            alignItems={"center"}
            className="info"
          >
            <Typography className="title">{translateField(jobName, 'name')}</Typography>
          </Grid>
        </Hide>
        <Hide condition={isXs}>
          <Grid
            item
            xs={3}
            md={3}
            container
            alignItems={"center"}
            className="info"
          >
            <Typography className="title">{location}</Typography>
          </Grid>
        </Hide>
        {/* <Hide condition={isXs && experience === ""}>
          <Grid item xs={2} md={2} container alignItems={"center"} className="info" style={{ borderLeft: 'none' }}>
            <Typography className="title">{experience}</Typography>
          </Grid>
        </Hide> */}
        <Hide condition={isXs}>
          <Grid
            item
            xs={2}
            md={2}
            container
            alignItems={"center"}
            className="info"
          >
            <div className="ml-10">
              <ApplicationStatus status={status} />
            </div>
          </Grid>
        </Hide>
        <Show condition={isXs}>
          <Grid item xs={3} md={2} container alignItems={"center"}>
            <div className="ml-10">
              <ApplicationStatus status={status} hideText />
            </div>
          </Grid>
        </Show>
        <Grid
          item
          xs={3}
          md={2}
          container
          justifyContent={"flex-end"}
          alignItems={"center"}
        >
          <IconButton className="dots-icon">
            <DotsThreeVertical />
          </IconButton>
        </Grid>
      </Grid>
      <Divider light sx={{ margin: "16px" }} />
    </>
  );
};

export default OneAplicant;

import "./StarterDialog.css";

import {
  Dialog,
  DialogActions,
  DialogContent, DialogTitle,
  Grid,
  Typography
} from "@mui/material";
import Lottie from "lottie-react";
import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
// import WeAreHiring from "../../../../assets/lootie-files/we-are-hiring.json";
import DecisionButton from "../../../../components/decision-button/DecisionButton";
import { useEffect } from "react";

// const WeAreHiring = React.lazy(() => import("../../../../assets/lootie-files/we-are-hiring.json"));

const StarterDialog = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [open, setOpen] = useState(true);

  const handleCLose = () => {
    navigate("/");
    setOpen(false);
  };

  const handleAgree = () => {
    setOpen(false);
  };

  const lottieOptions = {
    animationData: WeAreHiring,
    loop: true,
    autoplay: true,
  };

  const [WeAreHiring, setWeAreHiring] = useState(null);
  useEffect(() => {
    import("assets/lootie-files/we-are-hiring.json").then(data => setWeAreHiring(data));
  }, [])

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCLose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="new-job-dialog"
        disableScrollLock={true}
        disableEscapeKeyDown
        onBackdropClick={() => navigate("/layout")}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography className="title">{t("hello_there")}</Typography>
          <Typography className="subtitle">
            {t("lets_create_job")}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item>
              <Lottie
                loop={true}
                autoPlay={true}
                animationData={WeAreHiring}
                style={{ width: "100%", height: "293px" }}
                // width={"100%"}
                // height={"293px"}
                isClickToPauseDisabled
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="end" spacing={1}>
            <Grid item xs={6} md={4}>
              <DecisionButton onClick={handleCLose}>
                <Typography className="standard-text" color="#3A3B81">
                  {t("cancel")}
                </Typography>
              </DecisionButton>
            </Grid>
            <Grid item xs={6} md={6}>
              <DecisionButton active onClick={handleAgree}>
                <Typography className="standard-text" color="#FFFFFF">
                  {t("start")}
                </Typography>
              </DecisionButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default StarterDialog;

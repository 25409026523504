const TabIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 300 242.6">
            <g id="logo-05" transform="translate(-390 -358.2)">
                <g id="Group_4" data-name="Group 4">
                    <path id="Path_15" data-name="Path 15" d="M410.15,578.07,390,557.91V600.8h53.09A110.328,110.328,0,0,1,410.15,578.07Z" fill="#393c7d" />
                    <path id="Path_16" data-name="Path 16" d="M668.88,380.94,690,402.06V358.21H635.95A109.975,109.975,0,0,1,668.88,380.94Z" fill="#393c7d" />
                    <path id="Path_17" data-name="Path 17" d="M642.76,407.07c-28.73-28.73-75.4-28.53-104.13.2L449.6,496.3l-26.22-26.22,89.03-89.03a110.221,110.221,0,0,1,33.01-22.85H390V505.66l46.28,46.28c28.73,28.73,75.4,28.53,104.13-.21l62.81-62.81-49.97-49.97,26.22-26.22,76.19,76.19-89.03,89.03a110.22,110.22,0,0,1-33.01,22.85H690V454.3Zm-87.05,81.86-56.74,56.74-26.22-26.22,56.74-56.74Z" fill="#393c7d" />
                </g>
            </g>
        </svg>

    )
}

export default TabIcon
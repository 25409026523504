/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, matchPath, Route, Routes } from "react-router-dom";
import LoginModal from "./components/login-modal/LoginModal";
import ProtectedRoute from "./components/protected-route/ProtectedRoute";
import Layout from "./containers/dashboard/layout/Layout";
import DefaultLayout from "./containers/default/layout/Layout";
import { dashboardRoutes } from "./routes/dashboard";
import { routes } from "./routes/pages";
import { getPermissionsArray } from "./services/Helper";
import EnhancedLayout from "./views/pages/abdelrahman/EnhancedLayout/EnhancedLayout";
import Page404 from "./views/pages/page404/Page404";

const Main = () => {
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile.data);
  const role = useSelector((state) => state.profile.role);
  const permissionsString = useSelector(
    (state) => state.profile.role?.permissions
  );
  const rehydrated = useSelector((state) => state._persist.rehydrated);
  const permissions = getPermissionsArray(permissionsString);

  useEffect(() => {
    const match = matchPath(
      { path: "/jobs/:job_id", exact: true },
      window.location.pathname
    );
  }, []);

  useEffect(() => {
    if (rehydrated) setLoaded(true);
  }, [rehydrated]);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Page404 />} />
          <Route path="/" element={<EnhancedLayout />}>
            {dashboardRoutes.map((route, id) => {
              return (
                <Route
                  key={id}
                  path={route.path}
                  name={route.name}
                  element={
                    <ProtectedRoute
                      route={route.path}
                      permission={route.permission}
                      permissions={permissions}
                      loaded={loaded}
                    >
                      <route.component />
                    </ProtectedRoute>
                  }
                  state={{ [route]: route.id }}
                />
              );
            })}
          </Route>
          <Route path="/" element={<DefaultLayout />}>
            {routes.map((route, id) => {
              return (
                <Route
                  key={id}
                  path={route.path}
                  name={route.name}
                  element={
                    <ProtectedRoute
                      route={route.path}
                      permission={route.permission}
                      permissions={permissions}
                      loaded={loaded}
                    >
                      <route.component />
                    </ProtectedRoute>
                  }
                  state={{ [route]: route.id }}
                />
              );
            })}
          </Route>
        </Routes>
        <LoginModal />
      </BrowserRouter>
    </>
  );
};
export default Main;

import { Box, Button, Grid } from "@mui/material";
import { useTranslation } from 'react-i18next';


const SaveBox = (props) => {
    const { t, i18n } = useTranslation();

    return (
        <Grid container item className="grey-box">
            <form onSubmit={props.handleSubmit}>
                <Grid item xs={12}>
                    <Box component="div" className="title">{props.title}</Box>
                </Grid>
                <Grid item container spacing={2}>
                    {props.children}
                    <Grid item container justifyContent="end">
                        <Button type="submit" className="save-btn" disabled={props.loading}>{t("save_btn") }</Button>
                    </Grid>
                </Grid>
            </form>
        </Grid>
    )
}
export default SaveBox;
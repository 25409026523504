import {
  Avatar,
  Box,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Popper,
  Typography
} from "@mui/material";
import ApplicationStatus from "components/application-status/ApplicationStatus";
import {
  BookmarkSimple,
  Buildings,
  Checks,
  FacebookLogo,
  Link,
  LinkedinLogo,
  ShareNetwork,
  TwitterLogo,
  WhatsappLogo,
  X
} from "phosphor-react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useNavigate } from "react-router-dom";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";
import Show from "../../../../components/Show";
import EnhancedButton from "../../yahya/components/enhanced-button/EnhancedButton";
import EnhancedIconButton from "../EnhancedIconButton/EnhancedIconButton";
import EnhancedJobTag from "../EnhancedJobTag/EnhancedJobTag";
import {
  applyJob,
  getDocumentDirection,
  saveJob
} from "./../../../../services/Helper";
import "./EnhancedMiniJobCard.scss";

const EnhancedMiniJobCard = ({
  logo,
  title,
  company,
  location,
  date,
  noOfApplicants,
  tags,
  isSaved,
  inactive,
  ApplicantDashboardBrowse,
  recruiter,
  isApplied,
  jobId,
  onClick,
  invite,
  handleInvite,
  selected,
  jobStatus,
  AppliedJobsPage,
}) => {
  const anchorEl = useRef();
  const [openShare, setOpenShare] = useState(false);
  const [invited, setInvited] = useState(false);
  const [hover, setHover] = useState(false);
  const [applied, setApplied] = useState(isApplied);
  const [saved, setSaved] = useState(isSaved);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const documentDirection = getDocumentDirection();
  const role = useSelector((state) => state.profile.role);
  const isLoggedIn = useSelector((state) => state.profile.loginCompleted);
  const getDaysAgo = (date) => {
    const date1 = new Date(date);
    const date2 = new Date();
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };
  const daysAgo = getDaysAgo(date);
  const apply = (e) => {
    e.stopPropagation();
    if (isLoggedIn) {
      console.log("applying");
      applyJob(jobId, role).then((data) => {
        setApplied(data.is_applied);
      });
    } else {
      dispatch({ type: "OPEN_LOGIN_MODAL" });
    }
  };
  const save = (e) => {
    e.stopPropagation();
    if (isLoggedIn) {
      console.log("applying");
      saveJob(jobId, role).then((data) => {
        setSaved(data.is_saved);
      });
    } else {
      dispatch({ type: "OPEN_LOGIN_MODAL" });
    }
  };
  const toggleShare = (e) => {
    e.stopPropagation();
    if(jobId){
      setOpenShare((prev) => !prev);
    }
  };
  const navigateToJob = () => {
    console.log(jobId);
    navigate(`jobs/${jobId}`);
  };

  console.log("LOGOLOGO", jobStatus);

  const renderShare = () => {
    const url = `https://joblinkshub.com/jobs/${jobId}`;
    return (
      <>
        <Popper
          open={openShare}
          disablePortal={true}
          placement={"bottom-start"}
          anchorEl={anchorEl.current}
          sx={{
            height: "210px",
            maxWidth: "100%",
            zIndex: "2323",
          }}
        >
          <Paper
            sx={{
              padding: "24px",
              background: "#FFFFFF 0% 0 % no-repeat padding-box",
              boxShadow: "0px 0px 30px #0000001A",
              borderRadius: " 0px 0px 8px 8px",
              zIndex: "32165",
              cursor: "default"
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <Grid container alignItems={"center"} rowSpacing={2}>
              <Grid
                item
                container
                direction={"row"}
                columnSpacing={1}
                justifyContent={"space-between"}
                alignItems="center"
                xs={12}
              >
                <Grid
                  item
                  container
                  direction={"row"}
                  xs={10}
                  columnSpacing={2}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                >
                  <Grid item xs={1}>
                    <ShareNetwork size={25} weight={"fill"} color={"#3A3B81"} />
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                      sx={{ font: " normal normal bold 25px/30px Urbanist" }}
                    >
                      {t("share_job_post_title")}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={2} align={"right"}>
                  <IconButton onClick={toggleShare}>
                    <X size={30} />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item xs={12} container columnSpacing={2}>
                <Grid item>
                  <LinkedinShareButton
                    url={url}
                    className="social-media-share-icon"
                  >
                    <IconButton className="social-media-share-icon">
                      <LinkedinLogo size={26} color={"#3A3B81"} />
                    </IconButton>
                  </LinkedinShareButton>
                </Grid>
                <Grid item>
                  <FacebookShareButton
                    url={url}
                    className="social-media-share-icon"
                  >
                    <IconButton className="social-media-share-icon">
                      <FacebookLogo size={26} color={"#3A3B81"} />
                    </IconButton>
                  </FacebookShareButton>
                </Grid>
                <Grid item>
                  <TwitterShareButton
                    url={url}
                    className="social-media-share-icon"
                  >
                    <IconButton className="social-media-share-icon">
                      <TwitterLogo size={26} color={"#3A3B81"} />
                    </IconButton>
                  </TwitterShareButton>
                </Grid>
                <Grid item>
                  <WhatsappShareButton
                    url={url}
                    className="social-media-share-icon"
                  >
                    <IconButton className="social-media-share-icon">
                      <WhatsappLogo size={26} color={"#3A3B81"} />
                    </IconButton>
                  </WhatsappShareButton>
                </Grid>
              </Grid>
              <Grid item xs={12} container columnSpacing={1}>
                <Grid item xs={10}>
                  <InputBase
                    sx={{
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      border: " 1px solid #E4E4E4",
                      borderRadius: "8px",
                      maxWidth: "max-content",
                      maxHeight: "50px",
                      padding: "10px 15px",
                      minWidth: "100%",
                    }}
                    value={url}
                  />
                </Grid>
                <Grid item xs={2}>
                  <EnhancedIconButton
                    icon={<Link />}
                    active
                    onClick={(e) => {
                      e.stopPropagation();
                      navigator.clipboard.writeText(url);
                      toastr.info(t("clipboard-copy"));
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Popper>
      </>
    );
  };
  return (
    <>
      <Paper
        ref={anchorEl}
        className={`enhanced-job-card${!inactive ? " enhanced-job-card-hover" : ""
          }${selected ? " enhanced-job-card-selected" : ""}`}
        direction={"column"}
        elevation={0}
        onClick={onClick}
        sx={{ cursor: "pointer" }}
      >
        {renderShare()}
        {inactive && <div className="job-overlay"></div>}
        <Grid
          container
          rowSpacing={2}
          justifyContent={"flex-end"}
          align={documentDirection === "right" ? "right" : "left"}
        // sx={{ cursor: "pointer" }}
        >
          <Grid
            item
            xs={12}
            container
            justifyContent={"space-between"}
            align={documentDirection === "right" ? "right" : "left"}
          >
            <Grid
              item
              xs={9}
              sm={8}
              md={9}
              lg={true}
              container
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
            >
              <Grid item xs={3} sm={2.2} md={3} lg={3.4} xl={3}>
                <Show condition={logo}>
                  <Avatar className="job-logo" src={logo} />
                </Show>
                <Show condition={!logo}>
                  <Buildings size={60} />
                </Show>
              </Grid>
              <Grid item xs={true}>
                <Typography className="job-title">{title}</Typography>
                <Typography className="job-subtitle">{company}</Typography>
                <Typography className="job-subtitle">{location}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={2} sm={2} md={3} lg={3.2}>
              <Typography
                className="job-subtitle"
                align={documentDirection == "right" ? "left" : "right"}
              >
                {daysAgo === 0
                  ? t("today")
                  : t("days", { count: daysAgo })}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            justifyContent={"space-between"}
            alignItems={"center"}
            onClick={onClick}
          >
            <Grid item xs={4}>
              <Box className="tags-section">
                <Typography
                  className={`job-applicants${hover ? " hovered-text" : ""}`}
                >
                  {t("applicants", { count: noOfApplicants })}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={8}
              container
              columnSpacing={1}
              justifyContent={"flex-end"}
            >
              {tags.map((value) => (
                <Grid item>
                  <EnhancedJobTag tag={value} hovered={hover} />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            container
            alignItems={"center"}
            justifyContent={
              !recruiter
                ? {
                  xl: "space-between",
                  lg: "space-between",
                  sm: "space-between",
                  xs: "space-between",
                }
                : "flex-end"
            }
            columnSpacing={2}
          >
            {!recruiter ? (
              <Grid item xs={true} sm={true} md={true} lg={true} xl={true}>
                {(AppliedJobsPage && window.location.pathname === "/applied-jobs" && jobStatus) ? (
                  <Grid container justifyContent={"center"}>
                    <Grid item>
                      <ApplicationStatus status={jobStatus} />
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    {applied ? (
                      <Grid
                        container
                        alignItems={"flex-start"}
                        justifyContent={"center"}
                        spacing={1}
                      >
                        <Grid item>
                          <Checks size={20} color={"#3A3B81"} weight={"fill"} />
                        </Grid>
                        <Grid item>
                          <Typography
                            color={"#3A3B81"}
                            sx={{
                              font: "normal normal 600 16px/19px Urbanist",
                            }}
                          >
                            {t("applied")}
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : (
                      <EnhancedButton
                        buttonText={t("apply")}
                        padding="16px"
                        onClick={apply}
                      />
                    )}
                  </>
                )}
              </Grid>
            ) : (
              <>
                {invite && (
                  <Grid item xs={10}>
                    {invited ? (
                      <>
                        <Grid
                          container
                          alignItems={"flex-start"}
                          justifyContent={"center"}
                          spacing={1}
                        >
                          <Grid item>
                            <Checks
                              size={20}
                              color={"#3A3B81"}
                              weight={"fill"}
                            />
                          </Grid>
                          <Grid item>
                            <Typography
                              color={"#3A3B81"}
                              sx={{
                                font: "normal normal 600 16px/19px Urbanist",
                              }}
                            >
                              {t("invited")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <EnhancedButton
                          buttonText={t("invite")}
                          padding="16px"
                          onClick={() =>
                            handleInvite().then((res) => setInvited(true))
                          }
                        />
                      </>
                    )}
                  </Grid>
                )}
              </>
            )}
            <Grid
              item
              xs={"auto"}
              sm={"auto"}
              md={!invite ? "auto" : "auto"}
              lg={!invite ? "auto" : "auto"}
              xl={"auto"}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "16px",
                  justifyContent: "flex-end",
                }}
              >
                {!recruiter && (
                  <EnhancedIconButton
                    icon={<BookmarkSimple />}
                    onClick={save}
                    active={saved}
                  />
                )}
                <EnhancedIconButton
                  icon={<ShareNetwork />}
                  onClick={toggleShare}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      {/* <LoginModal /> */}
    </>
  );
};
export default EnhancedMiniJobCard;

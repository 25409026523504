import { useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import "./ApplicantLandingPage.css";

import { t } from "i18next";
import AboutSection from "./components/about-section/AboutSection";
import ApplicantHeroSection from "./components/applicant-hero-section/ApplicantHeroSection";
import ApplyIcon from "./components/assets/Apply.svg";
import FindTheJobIcon from "./components/assets/FindTheJob.svg";
import JoinUsIcon from "./components/assets/JoinUs.svg";
import BrowseJobsBySection from "./components/browse-jobs-by-section/BrowseJobsBySection";
import DownloadTheAppSection from './components/download-the-app-section/DownloadTheAppSection';
import FeaturedCompaniesSection from "./components/featured-companies-section/FeaturedCompaniesSection";
import HowDoesWorkSection from "./components/how-does-work-section/HowDoesWorkSection";
import RecentlyAddedJobsSection from "./components/recently-added-jobs-section/RecentlyAddedJobsSection";

const GuestApplicantLandingPage = () => {
  const mdMatches = useMediaQuery("(min-width:701px) and (max-width:1300px)");
  const smMatches = useMediaQuery("(max-width:700px)");
  const data = [
    {
      number: 1,
      image: JoinUsIcon,
      title: t("join_us"),
      description: t("join_us_desc")
    },
    {
      number: 1,
      image: FindTheJobIcon,
      title: t("find_job"),
      description: t("find_job_desc")
    },
    {
      number: 1,
      image: ApplyIcon,
      title: t("apply"),
      description: t("apply_desc")
    },
  ];

  return (
    <Box  sx={{ width: "100%" }} align={"center"}>
      <ApplicantHeroSection />
      <AboutSection />
      <HowDoesWorkSection backColored data={data} />
      <Box id="browse-jobs" component={"section"} className="browse-jobs">
        <BrowseJobsBySection />
        <RecentlyAddedJobsSection />
        <FeaturedCompaniesSection from="Guest Applicant" />
      </Box>
      <DownloadTheAppSection />
    </Box>
  );
};

export default GuestApplicantLandingPage;

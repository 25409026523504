import { Box } from "@mui/material";
import { t } from "i18next";
import React from "react";
import InformationCardSection from "../information-card-section/InformationCardSection";
import "./HowDoesWorkSection.css";

const HowDoesWorkSection = ({ backColored, data }) => {

  return (
    <Box component={"section"} id="how-it-works" className="how-it-works-section">
      <InformationCardSection
        title={t("how_it_works")}
        data={data}
        backColored={backColored}
      />
    </Box>
  );
};

export default HowDoesWorkSection;

import { User, Question, FileText, Trash, LockLaminated } from "phosphor-react";

export const sidebarElements = [
    {
        id: "account-settings",
        title: "Account Settings",
        icon: User,
        end: false
    },
    {
        id: "terms",
        title: "Terms & Conditions",
        icon: FileText,
        end: false
    },
    {
        id: "privacy-policy",
        title: "Privacy Policy",
        icon: LockLaminated,
        end: false
    },
    {
        id: "faq",
        title: "FAQs",
        icon: Question,
        end: false
    },
    {
        id: "delete",
        title: "Delete My Account",
        icon: Trash,
        end: true
    }
]
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputBase,
  FormHelperText,
} from "@mui/material";
import { X } from "phosphor-react";
import React from "react";
import { remove } from "react-redux-toastr/lib/actions";

const MultipleInput = (props) => {
  return (
    <FormControl
      variant="standard"
      className={["standart-form-controller", props.className].join(" ")}
    >
      <InputBase
        id={props.id}
        className={["input", props.label ? "" : "no-margin-top"].join(" ")}
        placeholder={props.placeholder}
        autoComplete="off"
        type={props.type}
        error={props.error !== undefined}
        {...props?.register?.(props?.fieldName + ".url")}
        startAdornment={
          <InputAdornment className="adornment" position="start">
            {React.createElement(props.icon, {
              className: "icon",
            })}
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment className="adornment" position="end">
            <IconButton
              onClick={() => {
                
                props.removeType(parseInt(props.id.slice(-1)));
                props.remove(parseInt(props.id.slice(-1)));
              }}
            >
              <X />
            </IconButton>
          </InputAdornment>
        }
      />

      {props.error && (
        <FormHelperText style={{ color: "red" }}>
          {props.error.message}
        </FormHelperText>
      )}
    </FormControl>
  );
};
export default MultipleInput;

import Show from "../../../../components/Show";
import AccountSettings from "./account-settings/AccountSettings";
import "./Content.scss";
import FAQ from "./faq/FAQ";
import PrivacyPolicy from "./privacy-policy/PrivacyPoilcy";
import Terms from "./terms/Terms";

const Content = ({ selected }) => {

  return (
    <>
      <Show condition={selected === "account-settings"}>
        <AccountSettings />
      </Show>
      <Show condition={selected === "faq"}>
        <FAQ />
      </Show>
      <Show condition={selected === "terms"}>
        <Terms />
      </Show>
      <Show condition={selected === "privacy-policy"}>
        <PrivacyPolicy />
      </Show>
    </>
  );
};
export default Content;

import { Box, Grid, IconButton, Typography, useMediaQuery } from '@mui/material';
import { ClipboardText, Files } from 'phosphor-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { matchRoutes, useNavigate } from 'react-router-dom';

const RecruiterNavigationButtons = () => {

    const isMobile = useMediaQuery("(max-width:1200px)");

    // navigate
    const navigate = useNavigate();

    // translation
    const { t, i18n } = useTranslation();

    const recruiterContentPaths = {
        myJobs: {
            patterns: [
                { path: "/" },
                { path: "/my-jobs" },
                { path: "/jobs/:job_id/edit" },
                { path: "/jobs/:job_id/applicants" },
                { path: "/jobs/:job_id/applicants/:applicant_id" },
            ]
        },
        candidatesBank: {
            patterns: [
                { path: "candidates-bank" }
            ]
        }
    };



    const checkCurrentPath = (patterns) => {
        const exist = matchRoutes(patterns, location)

        return exist ? true : false;
    }

    return (
        <>
            <IconButton
                className={["nav-button", checkCurrentPath(recruiterContentPaths.myJobs.patterns) ? "active" : ""].join(" ")}
                onClick={() => navigate("/")}
            >
                <Grid
                    container
                    className={[isMobile ? "navigation-button" : ""].join(" ")}
                    justifyContent={"center"}>
                    <Grid item xs={12} md={"auto"}>
                        <Box className={[!isMobile ? "icon" : ""].join(" ")}>
                            <ClipboardText weight="fill" />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={"auto"}>
                        <Box width={"8px"}></Box>
                    </Grid>
                    <Grid item xs={12} md={"auto"}>
                        <Typography>{t("my-jobs")}</Typography>
                    </Grid>
                </Grid>
            </IconButton>

            <IconButton
                className={["nav-button", checkCurrentPath(recruiterContentPaths.candidatesBank.patterns) ? "active" : ""].join(" ")}
                onClick={() => navigate("/candidates-bank")}
            >
                <Grid
                    container
                    className={[isMobile ? "navigation-button" : ""].join(" ")}
                    justifyContent={"center"}>
                    <Grid item xs={12} md={"auto"}>
                        <Box className={[!isMobile ? "icon" : ""].join(" ")}>
                            <Files weight="fill" />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={"auto"}>
                        <Box width={"8px"}></Box>
                    </Grid>
                    <Grid item xs={12} md={"auto"}>
                        <Typography>{t("candidates_bank")}</Typography>
                    </Grid>
                </Grid>
            </IconButton>
        </>
    );
};

export default RecruiterNavigationButtons;
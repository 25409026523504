import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getFeaturedCompanies
} from "../../../../../services/Helper";
import HeaderText from "../../../zeinnab/components/HeaderText/HeaderText";
import Clients from "../../../zeinnab/components/our-clients/Clients";
import ComponentSlider from "../../../zeinnab/components/slider/Slider";
import image from '../../../../../assets/companyImage.png'

import "./OurClientsSection.css";
import { useTranslation } from "react-i18next";

const OurClientsSection = () => {

  const { t } = useTranslation();

  const mdMatches = useMediaQuery("(min-width:701px) and (max-width:1000px)");
  const smMatches = useMediaQuery("(max-width:700px)");
  const [featuredCompanies, setFeaturedCompanies] = useState([]);

  useEffect(() => {
    getFeaturedCompanies("", false, 10, "Our Clients").then((response) => {
      const rows = response.rows.filter((company) => company.image != null);
      let data = rows.map((company) => (
        <Clients logo={company.image} />
      ));
      setFeaturedCompanies(data);

    });
  }, []);

  // const featuredCompanies = [
  //   <Clients logo={image} />,
  //   <Clients logo={image} />,
  //   <Clients logo={image} />,
  //   <Clients logo={image} />,
  //   <Clients logo={image} />,
  //   <Clients logo={image} />,
  //   <Clients logo={image} />,
  //   <Clients logo={image} />,
  //   <Clients logo={image} />,
  //   <Clients logo={image} />,
  //   <Clients logo={image} />,
  //   <Clients logo={image} />,
  //   <Clients logo={image} />,
  //   <Clients logo={image} />,
  //   <Clients logo={image} />,
  //   <Clients logo={image} />,
  //   <Clients logo={image} />,
  //   <Clients logo={image} />,
  //   <Clients logo={image} />,
  //   <Clients logo={image} />,
  // ]

  const lessNumberOfClientsResponsive = [


    {
      breakpoint: 670,
      settings: {
        slidesToShow: featuredCompanies.length <= 2 ? featuredCompanies.length : 3,
        slidesToScroll: featuredCompanies.length <= 2 ? featuredCompanies.length : 3,
      }
    }
  ];
  const largeNumberOfClientsResponsive = [
    {
      breakpoint: 1250,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
      }
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
      }
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 6,
      }
    },
    {
      breakpoint: 740,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
      }
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      }
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      }
    }
  ];

  return (
    <Box
      component="section"
      id="our-clients"
      className="our-clients-section"
      padding={smMatches ? "24px" : "0px"}
    >
      <Box
        display="flex"
        justifyContent="center"
        sx={{ marginBottom: "20px" }}
      >
        <HeaderText text={t("our_clients")} fontSize={"30px"} fontWeight={"bold"} />
      </Box>
      {/* {featuredCompanies.length < 10 ? (
          <ComponentSlider slidesToShow={featuredCompanies.length >= 10 ? 10 : featuredCompanies.length} items={featuredCompanies.length > 0 ? featuredCompanies : []} responsive={lessNumberOfClientsResponsive} centerItems/>
      ) : (
          <ComponentSlider slidesToShow={featuredCompanies.length >= 10 ? 10 : featuredCompanies.length} items={featuredCompanies.length > 0 ? featuredCompanies : []} responsive={largeNumberOfClientsResponsive} />
      )} */}
      {featuredCompanies.length < 10 ? (
        <ComponentSlider slidesToShow={featuredCompanies.length} items={featuredCompanies.length > 0 ? featuredCompanies : []} responsive={lessNumberOfClientsResponsive} centerItems />
      ) : (
        <ComponentSlider slidesToShow={featuredCompanies.length} items={featuredCompanies.length > 0 ? featuredCompanies : []} responsive={largeNumberOfClientsResponsive} />
      )}

    </Box>
  );
};

export default OurClientsSection;

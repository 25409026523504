import { START_STEPPER, NEXT_STEP, FINISH_STEPPER } from "./types"

export const startStepper = (data) => (dispatch) => {
    dispatch({ type: START_STEPPER, payload: data })
}

export const nextStep = (data) => (dispatch) => {
    let { currentStep, finalStep } = data
    if (currentStep > finalStep) {
        finalStep = finalStep + 1;
    }
    dispatch({ type: NEXT_STEP, payload: { currentStep, finalStep } })
}

export const finishStepper = (data) => (dispatch) => {
    dispatch({ type: FINISH_STEPPER, payload: data })
}
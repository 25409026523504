import { Grid, Stack, Typography } from "@mui/material";
import { CaretCircleRight } from 'phosphor-react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import "./FeaturedSection.scss";

const FeaturedSection = ({ title, url, children, data }) => {

  const { t, i18n } = useTranslation();


  return (
    <Grid container direction={"row"}>
      <Grid item container justifyContent="space-between" alignItems={"center"}>
        <Grid item>
          <Typography className="featured-title" sx={{ marginBottom: "12px" }}>{title}</Typography>
        </Grid>
        <Grid item>
          {url && (
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <Stack>
                <Link to={url} className="featured-more">{t("see_more")}</Link>
              </Stack>
              <Stack>
                <CaretCircleRight size={16} style={{ transform: i18n.language == "ar" ? "rotate(180deg)" : "" }} />
              </Stack>
            </Stack>

          )}
          {/* {data.length > 6 && (
              <Link to={url} className="featured-more">{t("see_more")}</Link>
          )} */}
        </Grid>
      </Grid>

      <Grid item className="featured-area">
        {children}
      </Grid>
    </Grid>
  );
};
export default FeaturedSection;

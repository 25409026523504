import { Grid, Typography } from "@mui/material";
import { Globe, Money, TextAlignLeft } from "phosphor-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import IconInput from "../../../../components/IconInput";
import JLSelect from "../../../../components/JLSelect";
import { SALARY_TYPES, SALARY_TYPES_ID } from "../../../../constants/constants";
import {
  getCity,
  getCountry,
  getCurrencies,
  getGovernorate,
  getSalaryType,
  getWorkStyle
} from "../../../../services/Helper";
import { CancelNextButtons } from "../../NewJob";

import Hide from "../../../../components/Hide";
import Show from "../../../../components/Show";
import { APPLY_TYPES } from './../../../../constants/constants';
import "./JobDetails.css";

const JobDetails = ({
  control,
  register,
  setValue,
  getValues,
  clearErrors,
  setError,
  errors,
  handleBack,
  handleNext,
  disabled,
  loading
}) => {
  const { t } = useTranslation();
  // state
  const [workStyle, setWorkStyle] = useState(null);
  const [countries, setCountries] = useState(null);
  const [governorates, setGovernorates] = useState(null);
  const [cities, setCities] = useState(null);
  const [salaryType, setSalaryType] = useState(null);
  const [currencies, setCurrencies] = useState([]);
  const [currentApplyType, setCurrentApplyType] = useState()
  const [applyTypes, setAppylTypes] = useState(APPLY_TYPES)
  const [currentSalaryType, setCurrentSalaryType] = useState();

  useEffect(() => {
    getCountry().then((data) => setCountries(data));
    getWorkStyle().then((data) => setWorkStyle(data));
    getSalaryType().then((data) => setSalaryType(data));
    getCurrencies().then((data) => {
      let processedData = data.map((value) => ({
        id: value.id,
        name_en: value.code,
        name_ar: value.code,
      }));
      setCurrencies(processedData);
    });
    setCurrentApplyType(getValues("apply_type"))
    return () => {
      setCurrentApplyType(null)
      setWorkStyle(null);
      setCountries(null);
      setSalaryType(null);
    };
  }, []);

  useEffect(() => {
    setCurrentSalaryType(SALARY_TYPES_ID[getValues("salary_id")]);
  }, [getValues("salary_id")]);

  useEffect(() => {
    const country_id = getValues("country_id");
    if (country_id !== "") {
      getGovernorate(country_id).then((data) => {
        setGovernorates(data);
      });
    }
  }, [getValues("country_id")]);

  useEffect(() => {
    const governorate_id = getValues("governorate_id");
    if (
      governorate_id !== "" &&
      governorate_id != undefined &&
      governorate_id != null
    ) {
      getCity(governorate_id).then((data) => {
        setCities(data);
      });
    }
  }, [getValues("governorate_id")]);

  const onCountryChange = (e) => {
    const value = e.target.value;

    if (value !== "") {
      getGovernorate(value).then((data) => {
        setGovernorates(data);
        setCities(null);
        setValue("city_id", "");
        setValue("governorate_id", "");
      });
    } else {
      setGovernorates(null);
      setCities(null);
      setValue("city_id", "");
      setValue("governorate_id", "");
    }
  };
  const onGovernorateChange = (e) => {
    const value = e.target.value;
    if (value !== "") {
      getCity(value).then((data) => {
        setValue("city_id", "");
        setCities(data);
      });
    } else {
      setCities(null);
      setValue("city_id", "");
    }
  };
  const onSalaryTypeChange = (e) => {
    setValue("salary_from", null);
    setValue("salary_to", null);

    clearErrors("salary_from");
    clearErrors("salary_to");
    const value = e.target.value;
    if (value !== "") {
      const selected = SALARY_TYPES_ID[value];
      setCurrentSalaryType(selected);
    } else {
      setCurrentSalaryType(null);
    }
  };
  const onApplyTypeChange = (e) => {
    // setValue("external_apply_website", null)
    const value = e.target.value
    setCurrentApplyType(value)
    setValue("apply_type", value)
  }
  const renderSalaryFrom = (placeholder) => {
    return (
      <>
        <IconInput
          label={" "}
          placeholder={placeholder}
          type="text"
          icon={Money}
          className="w100 new-job-input from_to_input"
          fieldName="salary_from"
          register={register}
          error={errors.salary_from}
          disabled={disabled}
        />
      </>
    );
  };

  return (
    <>
      <Grid sx={{ marginBottom: 8 }} container direction="column" spacing={1}>
        <Grid item marginBottom={1}>
          <Typography className="step-title">
            {t("job_details")}
          </Typography>
        </Grid>
        <Grid item>
          <JLSelect
            control={control}
            fieldName="work_style_id"
            label={t("work_style")}
            selectLabel={t("work_style")}
            className="new-job-input"
            childs={workStyle}
            error={errors.work_style_id}
            disabled={disabled}
          />
        </Grid>
        <Grid item>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} md={4}>
              <JLSelect
                control={control}
                label={t("general_location")}
                childs={countries}
                selectLabel={t("general_country")}
                fieldName="country_id"
                className="new-job-input"
                onChange={onCountryChange}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <JLSelect
                label=" "
                control={control}
                selectLabel={t("governrate")}
                fieldName="governorate_id"
                className="new-job-input"
                childs={governorates}
                onChange={onGovernorateChange}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <JLSelect
                control={control}
                label=" "
                selectLabel={t("general_city")}
                childs={cities}
                fieldName="city_id"
                className="new-job-input"
                error={errors.city_id}
                disabled={disabled}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <JLSelect
            control={control}
            fieldName="apply_type"
            label={t("apply_type")}
            selectLabel={t("apply_type")}
            className="new-job-input"
            childs={applyTypes}
            onChange={onApplyTypeChange}
            error={errors.apply_type}
            disabled={disabled}
          />
        </Grid>
        {currentApplyType === "EXTERNAL_APPLY" &&
          <Grid item xs={12}>
            <IconInput
              label="External Apply Website"
              placeholder={t("external_apply_website")}
              type="text"
              icon={Globe}
              className="w100 new-job-input from_to_input"
              fieldName="external_apply_website"
              register={register}
              error={errors.external_apply_website}
              disabled={disabled}
            />
          </Grid>
        }
        <Grid item>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={
                currentSalaryType == null ||
                  currentSalaryType == SALARY_TYPES.NEGOTIABLE
                  ? 12
                  : 4
              }
            >
              <JLSelect
                control={control}
                fieldName="salary_id"
                label={t("salary_type")}
                selectLabel={t("salary_type")}
                className="new-job-input"
                childs={salaryType}
                onChange={onSalaryTypeChange}
                error={errors.salary_id}
                disabled={disabled}
              />
            </Grid>
            <Hide
              condition={
                currentSalaryType == null ||
                currentSalaryType == SALARY_TYPES.NEGOTIABLE
              }
            >
              <Grid item xs={12} md={8}>
                <Show condition={currentSalaryType == SALARY_TYPES.FIXED}>
                  <Grid container item spacing={2}>
                    <Grid item xs={6}>
                      {renderSalaryFrom(t("fixed_salary"))}
                    </Grid>
                    <Grid item xs={6}>
                      <JLSelect
                        control={control}
                        fieldName="currency_id"
                        label=" "
                        selectLabel={t("currency")}
                        className="w100 new-job-input from_to_input"
                        childs={currencies}
                        //onChange={onCurrencyChange}
                        error={errors.currency_id}
                        disabled={disabled}
                      />
                    </Grid>
                  </Grid>
                </Show>
                <Show
                  condition={currentSalaryType == SALARY_TYPES.SALARY_RANGE}
                >
                  <Grid container item spacing={2}>
                    <Grid item xs={4}>
                      {renderSalaryFrom(t("from"))}
                    </Grid>
                    <Grid item xs={4}>
                      <IconInput
                        label=" "
                        placeholder={t("to")}
                        type="text"
                        icon={Money}
                        className="w100 new-job-input from_to_input"
                        fieldName="salary_to"
                        register={register}
                        error={errors.salary_to}
                        disabled={disabled}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <JLSelect
                        control={control}
                        fieldName="currency_id"
                        label=" "
                        selectLabel={t("currency")}
                        className="w100 new-job-input from_to_input"
                        childs={currencies}
                        //onChange={onCurrencyChange}
                        error={errors.currency_id}
                        disabled={disabled}
                      />
                    </Grid>
                  </Grid>
                </Show>
                <Show condition={currentSalaryType == SALARY_TYPES.NET_SALARY}>
                  <Grid container item spacing={2}>
                    <Grid item xs={6}>
                      {renderSalaryFrom(t("net_salary_bonus"))}
                    </Grid>
                    <Grid item xs={6}>
                      <JLSelect
                        control={control}
                        fieldName="currency_id"
                        label=" "
                        selectLabel={t("currency")}
                        className="w100 new-job-input from_to_input"
                        childs={currencies}
                        //onChange={onCurrencyChange}
                        error={errors.currency_id}
                        disabled={disabled}
                      />
                    </Grid>
                  </Grid>
                </Show>
              </Grid>
            </Hide>
          </Grid>
        </Grid>
        <Grid item>
          <IconInput
            label={t("job_description")}
            placeholder={t("write_here")}
            type="text"
            icon={TextAlignLeft}
            className="w100 new-job-input"
            multiline={true}
            fieldName="job_descirption"
            rows={5}
            iconTop
            register={register}
            error={errors.job_descirption}
            disabled={disabled}
          />
        </Grid>
        <Grid item>
          <IconInput
            label={t("job_requirements")}
            placeholder={t("write_here")}
            type="text"
            icon={TextAlignLeft}
            className="w100 new-job-input"
            multiline={true}
            fieldName="job_requirement"
            rows={5}
            iconTop
            register={register}
            error={errors.job_requirement}
            disabled={disabled}
          />
        </Grid>
        <CancelNextButtons
          onBack={handleBack}
          onNext={handleNext}
          backText={t("back_btn")}
          nextText={t("next_btn")}
          loading={loading}
        />
      </Grid>
    </>
  );
};
export default JobDetails;

import { CacheProvider } from '@emotion/react';
import React from 'react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import stylisRTLPlugin from 'stylis-plugin-rtl';
import i18next from 'i18next';

// Create rtl cache
const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, stylisRTLPlugin],
});

const RTL = (props) => {
    const lang = i18next.language;
    console.log(lang);

    return lang === 'ar' ? <CacheProvider value={cacheRtl}>{props.children}</CacheProvider> : <>{props.children}</>;
};

export default RTL;

import { Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { DotsThreeVertical, PencilSimple, Trash } from 'phosphor-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteCategoryModal from '../../../modals/delete-category-modal/DeleteCategoryModal';
import EditCategoryModal from '../../../modals/edit-category-modal/EditCategoryModal';
import './OneCategory.scss';

const OneCategory = ({ title, idCategory, active, categroies, onClick }) => {
    const { t, i18n } = useTranslation();

    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState();

    const handleClickOpenEditCategory = (e) => {
        e.stopPropagation();
        setOpenEditModal(true);
        handleClose();
    };

    const handleCloseEditCategory = () => {
        setOpenEditModal(false);
    };

    const handleClickOpenDeleteCategory = () => {
        setOpenDeleteModal(true);
        handleClose();
    };

    const handleCloseDeleteCategory = () => {
        setOpenDeleteModal(false);
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div className="one-category">
            <Grid container>
                <Grid item xs={10.5} container alignItems={'center'} className={active ? 'one-category-title active' : 'one-category-title'} onClick={() => onClick()}>
                    <Typography style={{ wordBreak: 'break-word' }}>{title}</Typography>
                </Grid>
                <Grid item xs={1.5} container justifyContent={'flex-end'}>
                    <IconButton className="dots-icon" onClick={handleClick} aria-controls={open ? 'account-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined}>
                        <DotsThreeVertical />
                    </IconButton>
                    {/* <Typography className='dots-icon'>test</Typography> */}

                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        disableScrollLock={true}
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: i18n.language === 'en' ? 'right' : 'left', vertical: 'top' }}
                        anchorOrigin={{ horizontal: i18n.language === 'en' ? 'right' : 'left', vertical: 'bottom' }}
                    >
                        <MenuItem className="menu-name" sx={{ display: 'flex', justifyContent: 'space-between' }} onClick={(e) => handleClickOpenEditCategory(e)}>
                            {t('edit')}
                            <div className="delete-icon">
                                <PencilSimple color="#3A3B81" />
                            </div>
                        </MenuItem>
                        <MenuItem className="menu-name" sx={{ display: 'flex', justifyContent: 'space-between' }} onClick={handleClickOpenDeleteCategory}>
                            {t('delete')}

                            <div className="delete-icon">
                                <Trash color="#823a3d" />
                            </div>
                        </MenuItem>
                    </Menu>
                    {/* <EditAndDeleteMenu 
                handleClose={handleClose}
                open={open}
              /> */}
                </Grid>
            </Grid>
            <EditCategoryModal open={openEditModal} onClose={handleCloseEditCategory} title={title} idCategory={idCategory} categroies={categroies} />
            <DeleteCategoryModal title={title} open={openDeleteModal} onClose={handleCloseDeleteCategory} idCategory={idCategory} />
        </div>
    );
};

export default OneCategory;
